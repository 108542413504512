<template>
    <keep-alive>
    <StepTwo
        v-if="step === 2"
        :activePlatformType="contentType"
        :products="getProducts"
        :dataprops="dataprops"
        :config="config"

        :isPopUp="isPopUp"
        @changeStep="(val) => step = val"
        @headerGoBackPopUp="headerGoBackPopUp"
        
        @stepThree="val => {cart = val; step = 4}"
    />
    <!--        :selectedProduct="config.selectedProduct"-->

        <StepThree
            v-else-if="step === 3"

            :config="config"
            :productGroup="getProductGroup"
            :isPopUp="isPopUp"
            @stepThree="val => {cart = val; step = 4}"
            @headerGoBackPopUp="headerGoBackPopUp"
        />

        <FreeTrialUsed
            v-else-if="step === 4 && config.freePanel"
        />



    <AddToCartStep
        v-else-if="step === 4 && !config.freePanel"
        :config="config"
        :cart="cart"
        :isPopUp="isPopUp"
        :productGroup="getProductGroup"
        :activeGroup="activeGroup"

        :tiktokPlatform="tiktokPlatform"

        @closeModal="() => $emit('closeModal')"
        @headerGoBackPopUp="headerGoBackPopUp"
    />
    </keep-alive>


</template>

<script>

import StepTwo from '@/components/Panel/StepTwo.vue'
import StepThree from "@/components/Panel/StepThree.vue";
import AddToCartStep from "@/components/Panel/AddToCartStep.vue";
import FreeTrialUsed from "@/views/FreeTrialUsed.vue";
export default {
    data() {
        return {
            step: 2,
            tiktokPlatform: null,
            activeGroup: null,
            config: {
                selectedProduct: null,
                userData: null,
                mediaData: null,
                username: null,
            },

            cart: null,
        }
    },
    props: ['dataprops', 'isPopUp', 'popUpContentType'],
    components: {
        FreeTrialUsed,
    // StepOne,
    StepTwo,
    StepThree,
    AddToCartStep
},
    watch: {
        'dataprops.platforms': {
            handler(newValue) {
                if (newValue) {
                    this.createGroup()
                }
            },
            deep: false,
            immediate: true
        }
    },
    methods: {
        createGroup() {
            const tiktok = this.dataprops.platforms.filter(el => el.identifier === 'tiktok')[0]

            const group = {}
            tiktok.product_groups.forEach(el => {
                if (group[el.general_name.toLowerCase()] && !el.sub_groups) return

                group[el.general_name.toLowerCase() || el.name.toLowerCase()] = {
                    groupItems: el.sub_groups ? Object.keys(el.sub_groups).map(identifier => {
                        return tiktok.product_groups.find(item => item.identifier === identifier)
                    })
                    : [el]
                }
            })

            this.tiktokPlatform = group

            if (!this.activeGroup) {
                this.activeGroup = group[this.contentType].groupItems[0].identifier
                this.config.groupIdentifier = group[this.contentType].groupItems[0].identifier
            }
        
        },
        headerGoBackPopUp() {

            if (this.step === 2) {
                if (!this.isPopUp) {
                    this.$router.push(`/service-page/${this.contentType}`)
                } else {
                    this.$emit('headerGoBackPopUp')
                }
            } else if (this.step === 3){
                this.step = 2
                this.config.userData = null
                this.config.mediaData = null
            } else {
                this.step = this.step - 1
                this.cart = null
            }
        },
        headerGoBack(e) {
            e.preventDefault()
            if (this.step === 2) {
                this.$router.push(`/service-page/${this.contentType}`)
            } else if (this.step === 3){
                this.step = 2
                this.config.userData = null
                this.config.mediaData = null
            } else {
                this.step = this.step - 1
                this.cart = null
            }
        }
    },
    computed: {
        contentType() {
            if (this.popUpContentType) {
                return this.popUpContentType
            } else {
                return this.$route.params.type
            }
        },
        getProducts() {
            const products = this.tiktokPlatform?.[this.contentType].groupItems.find(el => el.identifier === this.activeGroup)?.products
            return products
        },
        getProductGroup() {
            return this.tiktokPlatform?.[this.contentType].groupItems.find(el => el.identifier === this.activeGroup)
        }
    },
    mounted() {
        if (this.dataprops.platforms) {
            this.createGroup()
        }
        
        console.log(this.dataprops.platformConfig, 'plaform')
        
        if (this.dataprops.platformConfig?.selectedProduct) {
            this.config.selectedProduct = this.dataprops.platformConfig.selectedProduct
        }
        if (this.dataprops.platformConfig?.groupIdentifier) {
            const identifier = this.dataprops.platformConfig.groupIdentifier
            this.activeGroup = identifier
            this.config.groupIdentifier = identifier
        }


        const myButton = document.getElementById('headerGoBack');
        myButton && myButton.addEventListener('click', this.headerGoBack)
    },

}
</script>