<template>
    <section class="section pt-[14px] lg:pt-0 flex-auto flex lg:block">
        <div class="container mx-0 container-dash flex flex-col flex-auto" v-if="!result">
            <h4 class="text-center lg:text-[40px] mb-[20px]">Instagram <span class="lg:text-primary-500">Profile Viewer</span></h4>
            <p class="text-secondary-500 text-center mb-[40px]">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.
            </p>

            <SearchInput :small-variant="true" :placeholder="'Instagram username'" :call-back="search" />
            <p v-if="errorMessage" style="color: red; text-align: center;">{{ errorMessage }}</p>
        </div>
        <div class="container mx-0 container-dash flex justify-center flex-auto" v-if="loading">
            <Loader />
        </div>
        <div class="container mx-0 container-dash" v-if="result">
            <div class="flex items-center justify-center relative mb-[50px] mt-[14px] md:hidden">
                <a href="#" class="btn w-[40px] h-[40px] bg-primary-100 rounded-[11px] left-0 absolute" @click.prevent="result = null">
                    <img src="@/assets/img/icons/left-arrow-long.svg" alt="">
                </a>
                <span class="def-text--1 font-semibold">Profile Viewer</span>
            </div>
            <div class="relative row md:flex hidden mb-[23px] sm:mb-[58px]">
                <div class="col-12 px-[70px]">
                    <a href="#" class="btn w-[40px] y-center h-[40px] bg-primary-100 rounded-[11px] left-0 absolute" @click.prevent="result = null">
                        <img src="@/assets/img/icons/left-arrow-long.svg" alt="">
                    </a>
                    <div class="flex">
                        <h2 class="font-[700] text-[24px] leading-[32px]">
                            Instagram Profile Viewer
                        </h2>
                    </div>
                </div>
            </div>
            <div class="max-w-[712px] mx-auto">
                <div class="row">
                    <div class="col-12">
                        <div class="profile-block">
                            <div class="profile-wrapper">
                                <b class="text-[18px] leading-[24px]">
                                    <a :href="`https://www.instagram.com/${result.userData.username}`" target="_blank">@{{ result.userData.username }}</a>
                                </b>
                            </div>
                            <div class="profile-content items-center w-full">
                                <div class="profile-photo mt-[-45px] z-[1] rounded-[10px] profile-photo--90">
                                    <img :src="result.userData.thumbnail">
                                </div>
                            </div>
                            <ul class="profile-list profile-list--bordered text-center justify-center">
                                <li class="profile-list--item profile-list--item-6-380 sm:mt-[40px] mt-[25px]">
                                    <h6 class="text-[20px] leading-[24px] ff-fotnFamilyMontserrat tracking-[0.4px]">{{ thousandFilter(result.userData.following) }}</h6>
                                    <p class="ff-fotnFamilyMontserrat def-text--2 text-text font-[400] mt-[6px] tracking-[0.32px]">
                                        Following
                                    </p>
                                </li>
                                <li class="profile-list--item profile-list--item-6-380 sm:mt-[40px] mt-[25px]">
                                    <h6 class="ff-fotnFamilyMontserrat text-[20px] leading-[24px] tracking-[0.4px]">{{ thousandFilter(result.userData.followers) }}</h6>
                                    <p class="ff-fotnFamilyMontserrat def-text--2 text-text font-[400] mt-[6px] tracking-[0.32px]">
                                        Followers
                                    </p>
                                </li>
                                <li class="profile-list--item profile-list--item-6-380 sm:mt-[40px] mt-[25px]">
                                    <h6 class="ff-fotnFamilyMontserrat text-[20px] leading-[24px] tracking-[0.4px]">{{ thousandFilter(result.userData.post_count) }}</h6>
                                    <p class="ff-fotnFamilyMontserrat def-text--2 text-text font-[400] mt-[6px] tracking-[0.32px]">
                                        Posts
                                    </p>
                                </li>
                            </ul>
                            <tabs class="mt-[30px]" :class-priority="1" >
                                <div class="tabs tabs-custom-2" data-tabs="">
                                    <div class="tabs-body" tabs-body="">
                                        <tab :title="`${ thousandFilter(result.userData.post_count) } Posts`" class="tabs-item sm:pt-[45px] pt-[20px] active" id="posts">
                                            <div class="gallery">
                                                <div class="gallery-item" v-for="(post, index) in result.postData.posts" :key="index">
                                                    <a :href="post.url" target="_blank" class="gallery-link">
                                                        <img :src="post.thumbnail">
                                                        <div class="overlay-gallery items-end justify-end">
                                                            <div class="btn btn-download">
                                                                <a :href="post.thumbnail" download class="ico ico-25 ico-15-576">
                                                                    <img src="@/assets/img/icons/ico-download2.svg">
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </tab>
                                        <tab :title="`${ thousandFilter(result.userData.followers) } Followers`" class="tabs-item sm:pt-[45px] pt-[30px] active" id="followers">
                                            <div class="custom-columns mt-[-20px] custom-columns-8 custom-columns-0-576">
                                                <div
                                                    class="custom-col-4 custom-col-12-768 mt-[20px]" 
                                                    v-for="(follower, followerIndex) in result.followerData.followers" :key="followerIndex"
                                                >
                                                    <a :href="`https://www.instagram.com/${follower.userId}`" target="_blank" class="flex items-center">
                                                        <div class="user-icon user-icon-58 rounded-full">
                                                            <img :src="follower.thumbnail">
                                                        </div>
                                                        <div class="flex flex-col ml-[20px]">
                                                            <div class="def-text--1 text-black-100 font-[600]">
                                                                @{{ follower.userId }}
                                                            </div>
                                                            <div class="mt-[3px] def-text--1 font-[600] text-secondary-500">
                                                                {{ follower.username }}
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </tab>
                                        <tab :title="`${ thousandFilter(result.userData.following) } Following`" class="tabs-item sm:pt-[45px] pt-[30px] active" id="following">
                                            <div class="custom-columns mt-[-20px] custom-columns-8 custom-columns-0-576">
                                                <div 
                                                    class="custom-col-4 custom-col-12-768 mt-[20px]"
                                                    v-for="(following, followingIndex) in result.followingData.followings" :key="followingIndex"
                                                >
                                                    <a :href="`https://www.instagram.com/${following.userId}`" target="_blank" class="flex items-center">
                                                        <div class="user-icon user-icon-58 rounded-full">
                                                            <img :src="following.thumbnail">
                                                        </div>
                                                        <div class="flex flex-col ml-[20px]">
                                                            <div class="def-text--1 text-black-100 font-[600]">
                                                                @{{ following.userId }}
                                                            </div>
                                                            <div class="mt-[3px] def-text--1 font-[600] text-secondary-500">
                                                                {{ following.username }}
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </tab>
                                    </div>
                                </div>
                            </tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import SearchInput from '@/components/Base/SearchInput.vue';
import Tabs from '@/components/Base/Tabs.vue';
import Tab from '@/components/Base/Tab.vue';
import { toolsApi } from "@/api/toolsApi";
import Loader from "@/components/Base/Loader";

export default {
    components: {
        Tab,
        Tabs,
        SearchInput,
        Loader,
    },
    name: "VideoDownloader",    
    data(){
        return{
            step: 1,
            tabs: [{
                name: 'posts',
            }],
            activeTab: 'posts',
            loading: false,
            result: null,
            errorMessage: null,
        }
    },
    methods: {
        toggleAccordeon(item){
            item.isOpen = !item.isOpen;
        },
        nextStep(val) {
            this.step++;
        },
        async search(value){
            this.errorMessage = null;
            this.result = null;
            this.loading = true;

            try {
                const res = await toolsApi.instagramProfileViewer(value);

                console.log('res:', res);

                if(res?.userData){
                    this.result = res;
                }

                if(res?.error?.message){
                    this.errorMessage = res?.error?.message;
                }
            } catch (error) {
                console.log('error:', error);
                this.loading = false;
            } finally {
                this.loading = false;
            }
        },
        thousandFilter(x) {
            let number=x
            if (number < 1000) {
                return number;
            } else if (number >= 1000 && number < 1_000_000) {
                return (number / 1000).toFixed(1).replace(/\.0$/, "") + "K";
            } else if (number >= 1_000_000 && number < 1_000_000_000) {
                return (number / 1_000_000).toFixed(1).replace(/\.0$/, "") + "M";
            } else if (number >= 1_000_000_000 && number < 1_000_000_000_000) {
                return (number / 1_000_000_000).toFixed(1).replace(/\.0$/, "") + "B";
            } else if (number >= 1_000_000_000_000 && number < 1_000_000_000_000_000) {
                return (number / 1_000_000_000_000).toFixed(1).replace(/\.0$/, "") + "T";
            }
        }
    }
}
</script>