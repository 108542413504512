<template>
    <div>
        <header class="header-dash hidden lg:block">
            <div class="container mx-0 container-dash">
                <div class="header-dash-body">
                    <ul class="header-dash-list">
                        <li>
                            <router-link to="/orders" class="btn header-dash-link pl-0 hover:opacity-70">My orders</router-link>
                        </li>
                        <li>
                            <router-link to="/contact-us" class="btn header-dash-link hover:opacity-70">Message us</router-link>
                        </li>
                        <li>
                            <router-link to="/cart" class="btn btn-header-card font-semibold">
                                <span class="btn-icon relative mr-[12px]">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7646 3.55596C15.4742 3.01465 14.9409 2.67717 14.36 2.66711H3.78416L3.30495 0.658283C3.20525 0.258991 2.8628 -0.0136274 2.47871 0.000526357H0.826237C0.369919 0.000526357 0 0.398482 0 0.889386C0 1.38029 0.369919 1.77825 0.826237 1.77825H1.85077L4.13118 10.8979C4.23088 11.2972 4.57333 11.5699 4.95742 11.5557H12.3936C12.7047 11.5547 12.9889 11.3657 13.1289 11.0668L15.839 5.23591C16.0775 4.69793 16.0496 4.06712 15.7646 3.55596ZM11.8813 9.77798H5.58536L4.23859 4.44482H14.36L11.8813 9.77798ZM5.78366 14.6667C5.78366 15.4031 5.22878 16 4.5443 16C3.85983 16 3.30495 15.4031 3.30495 14.6667C3.30495 13.9304 3.85983 13.3334 4.5443 13.3334C5.22878 13.3334 5.78366 13.9304 5.78366 14.6667ZM12.8067 16C13.4911 16 14.046 15.4031 14.046 14.6667C14.046 13.9304 13.4911 13.3334 12.8067 13.3334C12.1222 13.3334 11.5673 13.9304 11.5673 14.6667C11.5673 15.4031 12.1222 16 12.8067 16Z" fill="#222B45"></path>
                                    </svg>
                                    <div v-if="cartTotal" class="indicator bg-primary-500"></div>
                                </span>
                                {{ cartTotal ? `$${cartTotal.toFixedNoRounding(2)}` : '$0.00' }}
                            </router-link>
                        </li>
                    </ul>
                    <div class="header__buttons--login" v-if="isLogged">
                        <router-link to="/settings" class="btn">
                            <img src="@/assets/img/icons/cog.svg" alt="">
                        </router-link>
                        <router-link to="/discounts" class="btn">
                            <img src="@/assets/img/icons/percent-icon.svg" alt="">
                        </router-link>
                        <div class="dropdown" v-click-outside="closeNotificationDropdown" :class="{'dropdown-open':isNotificationDropdopdownOpen}" data-dropdown="dropdown">
                            <a @click.prevent="toggleNotificationDropdown" href="" class="btn notification notification-btn" :data-count="dataprops.notifications.length" data-role="button">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.66 11.6C17.91 10.85 17.5 9.86 17.5 8.8V7.75C17.5 3.48 14.02 0 9.75 0C5.48 0 2 3.48 2 7.75V8.8C2 9.86 1.59 10.85 0.84 11.6C0.3 12.14 0 12.86 0 13.63C0 15.21 1.29 16.5 2.87 16.5H16.63C18.21 16.5 19.5 15.21 19.5 13.63C19.5 12.86 19.2 12.14 18.66 11.6Z" fill="currentColor"></path>
                                    <path d="M12.75 18H6.75C6.34 18 6 18.34 6 18.75C6 19.16 6.34 19.5 6.75 19.5H12.75C13.16 19.5 13.5 19.16 13.5 18.75C13.5 18.34 13.16 18 12.75 18Z" fill="currentColor"></path>
                                </svg>
                            </a>
                            <div class="dropdown__body notification-dropdown" :class="{'is-open':isNotificationDropdopdownOpen}" data-role="dropdown">
                                <div class="notification-dropdown__header">
                                    <div class="flex justify-between items-center max-w-[220px] w-full mx-auto">
                                        <span class="font-semibold">Notifications ({{ getNotificationCount }})</span>
                                        <a href="#" class="btn btn--primary-outline rounded-[12px] px-[12px] py-[10px]">
                                            <span class="text-[10px]">Mark as read</span>
                                        </a>
                                    </div>
                                </div>
                                <div class="notification-dropdown__body">
                                    <div
                                        class="notification-dropdown__body--item"
                                        v-for="(notification, index) in dataprops.notifications" :key="index"
                                    >
                                        <div class="flex justify-between items-center mb-[4px]">
                                            <span class="font-semibold def-text--1">{{ notification.content }}</span>
                                            <span class="text-[#8F9BB3]">{{ new Date(notification.created_at).toLocaleDateString() }}</span>
                                        </div>
                                        <div class="flex justify-between items-center">
                                            <p class="text-secondary-500 def-text--4">Lorem ipsum</p>
                                            <a href="#" class="btn">
                                                <img src="@/assets/img/icons/right-arrow-long.svg" alt="">
                                            </a>
                                        </div>
                                    </div>
                                    <!-- <div class="notification-dropdown__body--item">
                                        <div class="flex justify-between items-center mb-[4px]">
                                            <span class="font-semibold def-text--1">Your order #3HASUA has been completed.</span>
                                            <span class="text-[#8F9BB3]">3 hrs</span>
                                        </div>
                                        <div class="flex justify-between items-center">
                                            <p class="text-secondary-500 def-text--4">Rate your experience and get a reward.</p>
                                            <a href="#" class="btn">
                                                <img src="@/assets/img/icons/right-arrow-long.svg" alt="">
                                            </a>
                                        </div>
                                    </div>
                                    <div class="notification-dropdown__body--item">
                                        <div class="flex justify-between items-center mb-[4px]">
                                            <span class="font-semibold def-text--1">Your order #3HASUA has been completed.</span>
                                            <span class="text-[#8F9BB3]">3 hrs</span>
                                        </div>
                                        <div class="flex justify-between items-center">
                                            <p class="text-secondary-500 def-text--4">Rate your experience and get a reward.</p>
                                            <a href="#" class="btn">
                                                <img src="@/assets/img/icons/right-arrow-long.svg" alt="">
                                            </a>
                                        </div>
                                    </div>
                                    <div class="notification-dropdown__body--item">
                                        <div class="flex justify-between items-center mb-[4px]">
                                            <span class="font-semibold def-text--1">Your order #3HASUA has been completed.</span>
                                            <span class="text-[#8F9BB3]">3 hrs</span>
                                        </div>
                                        <div class="flex justify-between items-center">
                                            <p class="text-secondary-500 def-text--4">Rate your experience and get a reward.</p>
                                            <a href="#" class="btn">
                                                <img src="@/assets/img/icons/right-arrow-long.svg" alt="">
                                            </a>
                                        </div>
                                    </div>
                                    <div class="notification-dropdown__body--item">
                                        <div class="flex justify-between items-center mb-[4px]">
                                            <span class="font-semibold def-text--1">Your order #3HASUA has been completed.</span>
                                            <span class="text-[#8F9BB3]">3 hrs</span>
                                        </div>
                                        <div class="flex justify-between items-center">
                                            <p class="text-secondary-500 def-text--4">Rate your experience and get a reward.</p>
                                            <a href="#" class="btn">
                                                <img src="@/assets/img/icons/right-arrow-long.svg" alt="">
                                            </a>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
        
                        </div>
                        <router-link to="/add-funds">
                            <span class="flex items-center">
                                <img class="mr-[8px]" src="@/assets/img/icons/coin.svg" alt="">
                                <span class="font-semibold text-[15px]">{{ dataprops.cartData ? dataprops.cartData.currency : 0 }}{{ ((dataprops.userMe ? dataprops.userMe.balance : dataprops.user.balance) || 0) | floatNumberWithSpaces }}</span>
                            </span>
                        </router-link>
                        <div v-click-outside="closeUserDropdown"  class="dropdown" :class="{'dropdown-open':isUserDropdownOpen}" data-dropdown="dropdown">
                            <a @click.prevent="toggeleUserDropdown" href="#" class="btn" data-role="button">
                                <div class="w-[40px] h-[40px] rounded-full overflow-hidden my-[4px]">
                                    <img class="w-full h-full" src="@/assets/img/ava-40x40.png" alt="">
                                </div>
                            </a>
                            <div class="dropdown__body rounded-[13px] px-6 py-10 bg-primary-100" :class="{'is-open':isUserDropdownOpen}" data-role="dropdown">
                                <div class="max-w-[225px] text-center">
                                    <p class="font-semibold text-text whitespace-normal">Select profile photo from your saved accounts</p>
                                </div>
                                <div class="flex py-8 border-bottom mb-8 px-8 image-switch-row">
                                    <label class="image-switch">
                                        <input type="radio" name="r1" id="" value="" checked="" class="">
                                        <div class="w-[55px] h-[55px] rounded-full overflow-hidden image-switch__image">
                                            <img class="w-full h-full" src="@/assets/img/user-ava-3.png" alt="">
                                        </div>
                                    </label>
                                    <label class="image-switch">
                                        <input type="radio" name="r1" id="" value="" class="">
                                        <div class="w-[55px] h-[55px] rounded-full overflow-hidden image-switch__image">
                                            <img class="w-full h-full" src="@/assets/img/user-ava-3.png" alt="">
                                        </div>
                                    </label>
                                    <label class="image-switch">
                                        <input type="radio" name="r1" id="" value="" class="">
                                        <div class="w-[55px] h-[55px] rounded-full overflow-hidden image-switch__image">
                                            <img class="w-full h-full" src="@/assets/img/user-ava-3.png" alt="">
                                        </div>
                                    </label>
                                </div>
                                <a href="" class="btn btn--primary px-[23px] py-[13px] rounded-lg">Manage saved accounts</a>
                            </div>
        
                        </div>
                    </div>
                    <div class="header-buttons" v-else>
                        <a href="" class="btn text-primary-500 underline" @click.prevent="openModal('login')">
                            Log in
                        </a>
                        <a href="" class="btn px-[24px] py-[14px] bg-primary-500 rounded-[8px] text-white-100" 
                        @click.prevent="openModal('register')">
                            Sign up
                        </a>
                    </div>
                </div>
            </div>
        </header>

        <div v-if="isSideBar">
            <div class="header-dash-mobile lg:hidden">
                <div class="container header__container-mobile">
                    <div class="header-dash-mobile-body">
                        <div class="header-dash-mobile-top mb-[24px]">
                            <a href="" class="mobile-dash-logo">
                                <img src="@/assets/img/logo-tink-ws.png" alt="">
                            </a>
                            <router-link to="/cart" class="btn btn-header-card font-semibold ml-auto">
                                <span class="btn-icon relative mr-[12px]">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7646 3.55596C15.4742 3.01465 14.9409 2.67717 14.36 2.66711H3.78416L3.30495 0.658283C3.20525 0.258991 2.8628 -0.0136274 2.47871 0.000526357H0.826237C0.369919 0.000526357 0 0.398482 0 0.889386C0 1.38029 0.369919 1.77825 0.826237 1.77825H1.85077L4.13118 10.8979C4.23088 11.2972 4.57333 11.5699 4.95742 11.5557H12.3936C12.7047 11.5547 12.9889 11.3657 13.1289 11.0668L15.839 5.23591C16.0775 4.69793 16.0496 4.06712 15.7646 3.55596ZM11.8813 9.77798H5.58536L4.23859 4.44482H14.36L11.8813 9.77798ZM5.78366 14.6667C5.78366 15.4031 5.22878 16 4.5443 16C3.85983 16 3.30495 15.4031 3.30495 14.6667C3.30495 13.9304 3.85983 13.3334 4.5443 13.3334C5.22878 13.3334 5.78366 13.9304 5.78366 14.6667ZM12.8067 16C13.4911 16 14.046 15.4031 14.046 14.6667C14.046 13.9304 13.4911 13.3334 12.8067 13.3334C12.1222 13.3334 11.5673 13.9304 11.5673 14.6667C11.5673 15.4031 12.1222 16 12.8067 16Z" fill="currentColor"></path>
                                    </svg>
                                    <div class="indicator bg-primary-500"></div>
                                </span>
                                {{ cartTotal ? `$${cartTotal.toFixedNoRounding(2)}` : '$0.00' }}
                            </router-link>
                            <a href="" class="btn btn-burger ml-5" data-modal="#heared-mobile-not-log" @click.prevent="openModal('header-mobile-menu')">
                                <img src="@/assets/img/setting-slider-horizontal.svg" alt="">
                            </a>
                        </div>
                        <nav class="header-dash-mobile-nav">
                            <ul class="header-dash-mobile-menu">
                                <li>
                                    <router-link to="/service-page/followers">Followers</router-link>
                                </li>
                                <li>
                                    <router-link to="/service-page/likes" >Likes</router-link>
                                </li>
                                <li>
                                    <router-link to="/service-page/views">Views</router-link>
                                </li>
                                <li>
                                    <router-link to="/service-page/comments">Comments</router-link>
                                </li>
                                <li>
                                    <router-link to="/service-page/shares">Shares</router-link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { floatNumberWithSpaces } from '@/helpers/filters.js';

export default{
    props: ["isSideBar", "dataprops"],
    data() {
        return {
            isLogged: false,
            isNotificationDropdopdownOpen: false,
            isUserDropdownOpen: false,
            cartTotal: null
        };
    },
    filters: { floatNumberWithSpaces, },
    computed:{
        getNotificationCount() {
            return this.dataprops?.notifications?.length || 0
        }
    },
    watch: {
        'dataprops.user': {
            handler(newValue) {
                console.log(this.dataprops.user, 'this.dataprops.user')
                   if (this.dataprops.user?.email) {
                       this.isLogged = true
                   } else {
                       this.isLogged = false
                   }
            },
            deep: true,
            immediate: true
        },
        'dataprops.cartData.items': {
            handler(newVal) {
                if (this.dataprops?.cartData?.items?.length > 0) {
                    this.cartTotal = this.dataprops.cartData.totals.total
                }
            }
        }
    },
    created() {
        if (this.dataprops?.cartData?.items?.length > 0) {
            this.cartTotal = this.dataprops.cartData.totals.total
        }
    },
    methods: {
        openModal(name){
            this.$bus.$emit('modal',{
                name: name,
                overlay: true,
                payload: {},
            })
        },
        closeUserDropdown (){
            this.isUserDropdownOpen = false;
        },
        toggeleUserDropdown(){
            this.isUserDropdownOpen = !this.isUserDropdownOpen;
        },
        toggleNotificationDropdown(){
            this.isNotificationDropdopdownOpen = !this.isNotificationDropdopdownOpen;
        },
        closeNotificationDropdown(){
            this.isNotificationDropdopdownOpen = false;
        },
    },
}
</script>

<style scoped>
    .dropdown__body.is-open{
        top: 74px;
        right: 15px;
        transform: translate(0px, 0px);
        width: 267.344px;
        max-width: 1394px;
        max-height: 939px;
    }

    .dropdown__body.is-open.notification-dropdown{
        top: 58.5px;
        right: 15px;
        transform: translate(0px, 0px);
        width: 390px;
        max-width: 1154px;
        max-height: 939px;
        z-index: 10;
    }

    .header__container-mobile {
        max-width: 1280px;
    }

    .header-dash-mobile-menu li a.active {
        background-color: unset;
    }
    .header-dash-mobile-menu li a.router-link-exact-active {
        background-color: #FE2C55;
    }
</style>