// import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import staticDataprops from './test'

const Vue = require('vue').default;

Vue.config.productionTip = false

//Register Global Filters
import { numberWithSpaces, floatNumberWithSpaces, numberWithComas, dateFilter, thousandFilter} from './helpers/filters';
Vue.filter('numberWithSpaces', numberWithSpaces);
Vue.filter('floatNumberWithSpaces', floatNumberWithSpaces);
Vue.filter('numberWithComas', numberWithComas);
Vue.filter('dateFilter', dateFilter);
Vue.filter('thousandFilter', thousandFilter);

import VueSecureHTML from 'vue-html-secure';
Vue.use(VueSecureHTML);
Vue.prototype.$safeHTML = VueSecureHTML.safeHTML;
Vue.prototype.$escapeHTML = VueSecureHTML.escapeHTML;
Vue.prototype.$removeHTML = VueSecureHTML.removeHTML;
import * as helpersFunctions from './helpers/functions.js';
import * as helpersMaps from './helpers/maps.js';
Vue.prototype.$console = console
Vue.prototype.$helpers = {
    helpersMaps:helpersMaps,
    getGroupTypeName:helpersFunctions.getGroupTypeName,
    getGroupMainName: helpersFunctions.getGroupMainName,
    // getAvatarUrl: helpersFunctions.getAvatarUrl,
    // getUserDataCountersTemplate: helpersFunctions.getUserDataCountersTemplate,
    // getOrderStatusClass: (arg1)=>{return helpersFunctions.getOrderStatusClass(arg1, helpersMaps.getOrderStatusClassMap)},
    // getIsOrderStatusAwaitingPayment: helpersFunctions.getIsOrderStatusAwaitingPayment,
    getServiceProductGroups: helpersFunctions.getServiceProductGroups,
    // slideUp: helpersFunctions.slideUp,
    // slideDown: helpersFunctions.slideDown,
    // toggleDropdownWithTransition: helpersFunctions.toggleDropdownWithTransition,
    // resetAccordionTransitionHeight: helpersFunctions.resetAccordionTransitionHeight,
    // thousandFilter: thousandFilter
    // getOrderStatusText: ($event)=>{return helpersFunctions.getOrderStatusText($event, helpersMaps.getOrderStatusTextMap)}
};

import { translate } from './helpers/functions.js';
Vue.prototype.$translate = translate;

import { icons } from './helpers/icons';
Vue.prototype.$icons = icons;

import API from './lib/api.js';
Vue.prototype.$api = new API();

import rest from './lib/rest';
Vue.prototype.$rest = rest;

import vClickOutside from 'v-click-outside'
Vue.use(vClickOutside)

const bus = new Vue({
    data: () => ({
        dataprops: null
    }),
    methods: {
        // setPropetry(prop, data) {
        //     if (prop === 'dataprops') {  
        //         this.dataprops = data;
        //     } else {
        //         this.$set(this.dataprops, prop, data);
        //     }
        // }
        updateDataprops(prop, updateObj) {
            // this.dataprops = { ...this.dataprops, ...updateObj };
            if (prop === 'dataprops') {  
                        this.dataprops = updateObj;
                    } else {
            this.$set(this.dataprops, prop, updateObj);
                    }
            // example
            // updateUser() {
            // const updateObject = {
            //     user: { name: 'John Doe' },
            // };
            // this.$bus.emit('updateDataprops', updateObject);
            // },
            // updateUserName() {
            // const updateObject = {
            //     'user.name': 'Jane Doe',
            // };
            // this.$bus.emit('updateDataprops', updateObject);
            // },
            // addNewKey() {
            // const updateObject = {
            //     newKey: 'New Value',
            // };
            // this.$bus.emit('updateDataprops', updateObject);
            // },
        }
    }
});
Vue.prototype.$bus = bus;
window.bus = bus;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
