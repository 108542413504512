<template>
    <footer class="footer bg-black-100 " >
        <div class="footer-item container-px--lg mb-[-25px] pt-[50px] pb-[25px]">
            <router-link to="/service-page/likes" class="logo pb-[25px]">
                <img src="@/assets/img/tinkws-logo.svg" alt="">
            </router-link>
            <div class="list-rows">
                <ul class="list-row--columns text-secondary-500 pb-[25px]">
                    <li class="row--columns-item">
                        <router-link to="/service-page/followers" class="def-text--2 font-semibold hover:opacity-70">
                            <span class="info font-semibold text-white-100">New order</span>
                        </router-link>
                    </li>
                    <li class="row--columns-item">
                        <router-link to="/free-trial" class="def-text--2 font-semibold hover:opacity-70">
                            <span class="info font-semibold text-white-100">Free trial</span>
                        </router-link>
                    </li>
                    <li class="row--columns-item">
                        <router-link to="/tools/fake-ig-post-generator" class="def-text--2 font-semibold hover:opacity-70">
                            <span class="info font-semibold text-white-100">Free tools</span>
                        </router-link>
                    </li>
                </ul>
                <ul class="list-row--columns text-secondary-500 pb-[25px]">
                    <li class="row--columns-item">
                        <router-link to="contact-us" class="def-text--2 font-semibold hover:opacity-70">
                            <span class="info font-semibold text-white-100">Contact Us</span>
                        </router-link>
                    </li>
                    <li class="row--columns-item">
                        <router-link to="/affiliate-landing" href="#" class="def-text--2 font-semibold hover:opacity-70">
                            <span class="info font-semibold text-white-100">Affiliate</span>
                        </router-link>
                    </li>
                    <li class="row--columns-item">
                        <router-link to="/blogs" class="def-text--2 font-semibold hover:opacity-70">
                            <span class="info font-semibold text-white-100">Blog</span>
                        </router-link>
                    </li>
                </ul>
            </div>
            
        </div>
        <div class="footer-item container-px--lg mb-[-25px] pb-[50px] pt-[25px]">
            <div class="def-text--3 color-white pb-[25px] d-flex-576 flex-columns-576 order-1-576"> 
                <span>Copyright 2022 · All rights reserved</span>
                <router-link to="/terms-and-privacy" class="ml-[5px] hover:opacity-70">
                    <span class="mt-10-576">
                        <span class="text-primary-500">Terms</span> / 
                        <span class="text-primary-500">Privacy</span>
                    </span>
                </router-link>
            </div>
            <!-- <div class="form-group pb-[25px] ">
                <SelectBox
                    key="select-1"
                    identifier='select-icon-3'
                    :options="langs"
                /> 
            </div> -->
        </div>
    </footer>
</template>

<script>
import SelectBox from "../Base/SelectBox.vue"
export default {
    components: {
        SelectBox,
    },
    data() {
        return {
            langs: [
                {
                    value: "1",
                    label: "EN",
                    selected: true,
                },
                {
                    value: "2",
                    label: "FR",
                },
                {
                    value: "3",
                    label: "GE",
                },
                {
                    value: "4",
                    label: "IS",
                },
            ],
        };
    },
    computed: {},
    methods: {
        closeModal() {
            this.$bus.$emit("modal", {
                name: "",
                payload: {},
            });
        },
    },
};
</script>