import Vue from 'vue'
import Vuex from 'vuex'
import rest from '../lib/rest'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		faq: []
	},
	getters: {

	},
	mutations: {
		SET_FAQ(state, value) {
			state.faq = value
		}
	},
	actions: {
		async FETCH_FAQ({commit}) {
			commit('SET_FAQ', await rest.fetchFaq());
		}
	},
	modules: {

	}
})
