import restInstance from "@/lib/rest"

const rest = restInstance

export const authApi = {

    async login(payload)  {
        // 2fa_code: ‘nullable, ‘boolean’ - флаг для логики 2fa
        // code: required if 2fa_code == 1
        const data = {
            email: payload.email,
            password: payload.password,
            '2fa_code': payload['2fa_code'] || null,
            ...(payload.code ? {code: payload.code} : {})
        }

        return await rest.request('/login', 'POST', data, 'login')
    },

    async register(payload) {
        // address - не обязательный параметр
        const data = {
            name: payload.name,
            email: payload.email,
            password: payload.password,
            password_confirmation: payload.password_confirmation
        }
        return await rest.request('/register', 'POST', data, 'register', 10000)
    },

    async forgotPass(payload) {
        return await rest.request('/forgot-password', 'POST', {email: payload.email}, 'forgot password')
    }

}
