<template>
    <div class="pt-[24px] pb-[50px] text-center">
        <div class="container mx-0 container-dash">
            <div class="relative row md:flex hidden mb-[23px] sm:mb-[23px]">
                <div class="col-12 px-[70px]">
                    <a href="#" class="btn w-[40px] y-center h-[40px] bg-primary-100 rounded-[11px] left-0 absolute">
                        <img src="@/assets/img/icons/left-arrow-long.svg" alt="">
                    </a>
                    <div class="flex max-w-[712px] mx-auto flex-col items-center text-center">
                        <h2 class="font-[700] text-[24px] sm:text-[40px] leading-[32px] sm:leading-[60px] w-[250px] sm:w-auto">
                            Live Instagram <span class="text-primary-500">Followers Counter</span>
                        </h2>
                    </div>
                </div>
            </div>
            <div class="max-w-[712px] mx-auto">
                <div class="row md:flex hidden">
                    <div class="col-12">
                        <div class="flex flex-col items-center text-center">
                            <p class="mb-[17px] sm:mb-[32px] font-[600] text-[14px] leading-[18px] text-secondary-500">
                                Lorem ipsum dolor sit amet, 
                                consectetur adipiscing elit, sed do.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="profile-block">
                            <div class="profile-wrapper">
                                <b class="text-[18px] leading-[24px]">
                                    @janesharee
                                </b>
                            </div>
                            <div class="max-w-[336px] mx-auto">
                                <div class="profile-content items-center w-full">
                                    <div class="profile-photo mt-[-45px] z-[1] rounded-[10px] profile-photo--90">
                                        <img src="@/assets/img/profile--photo.png">
                                    </div>
                                </div>
                            </div>
                            <div class="max-w-[336px] mx-auto">
                                <ul class="profile-list sm:flex-row flex-col justify-center">
                                    <li class="profile-list--item sm:mt-[40px] mt-[25px] w-full">
                                        <h2 class="text-[32px] leading-[40px]">13,502,29</h2>
                                        <p class="def-text--2 text-secondary-500 font-[600] mt-[15px]">
                                            followers
                                        </p>
                                    </li>
                                    <li class="profile-list--item sm:mt-[40px] mt-[25px]">
                                        <h6 class="heading heading-6">1,417</h6>
                                        <p class="def-text--2 text-secondary-500 font-[600] mt-[15px]">
                                            posts
                                        </p>
                                    </li>
                                    <li class="profile-list--item sm:mt-[40px] mt-[25px]">
                                        <h6 class="heading heading-6">5,103,21</h6>
                                        <p class="def-text--2 text-secondary-500 font-[600] mt-[15px]">
                                            Following
                                        </p>
                                    </li>
                                </ul>
                                <a href="#" class="mt-[40px] btn btn--lg btn--primary w-full rounded-[8px]">
                                    <span class="info text-[14px] font-[800]">
                                        Change user
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"LiveFollowersCounter"

}
</script>

<style>

</style>