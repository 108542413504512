<template>
    <div id="delete-account" class="modal modal-3 modal--show">
        <div class="modal__dialog modal__dialog--522">
            <div class="modal__content" v-click-outside="closeModal">
                <div class="modal__header px-[28px] py-[24px] md:px-[93px]">
                    <img
                        src="@/assets/img/icons/icon-triangle-danger.svg"
                        class="mr-[17px]"
                    />
                    <span
                        class="text-[14px] leading-[18px] text-black-100 max-w-[263px] font-[600]"
                    >
                        Once you delete your account and related data it cannot
                        be recovered.
                    </span>
                </div>
                <div class="modal__body pb-[40px] px-[28px] md:px-[93px]">
                    <p
                        class="text-[14px] leading-[18px] pt-[27px] pb-[40px] text-secondary-500 text-center font-[600]"
                    >
                        We respect your data privacy, security, and the right to
                        be forgotten. You can request account deletion below.
                    </p>
                    <p
                        v-if="deletionRequested"
                        class="text-[14px] leading-[18px] text-black-100 font-[600] text-center mb-[20px]"
                    >
                        Deletion request created successfully
                    </p>
                    <a
                        href="#"
                        class="btn btn--primary px-[23px] py-[19px] rounded-[8px] mb-[12px] font-[700] text-[14px] leading-[19px] whitespace-nowrap"
                        data-close-modal=""
                        @click.prevent="submit"
                        v-else
                        >Request deletion</a
                    >
                    <a
                        href="#"
                        class="btn btn--primary-outline px-[23px] py-[19px] rounded-[8px] font-[700] text-[14px] leading-[19px]"
                        data-close-modal=""
                        @click.prevent="closeModal()"
                    >
                        <span class="info">Cancel</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { profileApi } from '@/api/profileApi';

    export default{
        data() {
            return {
                deletionRequested: false
            };
        },
        computed:{},
        methods: {
            closeModal(){
                this.$bus.$emit('modal',{
                    name: '',
                    payload: {},
                })
            },
            async submit() {
                const response = await profileApi.requestDeletion();
                if(response.hasError) return;
                this.deletionRequested = true;
            }
        },
    }
</script>

<style scoped>
    .modal{
        z-index: 10000;
    }
</style>
