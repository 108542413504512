<template>
    <section class="pb-[25px] pt-[25px]">
        <div class="container mx-0 container-dash">
            <div class="flex items-center justify-center relative mb-[50px] mt-[14px] lg:hidden">
                <router-link to="/affiliate" class="btn w-[40px] h-[40px] bg-primary-100 rounded-[11px] left-0 absolute">
                    <img src="@/assets/img/left-arrow-long.svg" alt="">
                </router-link>
                <span class="def-text--1 font-semibold">Order details</span>
            </div>
            <div class="flex items-center justify-between mb-[25px]">
                <div class="flex items-center">
                    <router-link to="/affiliate" class="btn w-[40px] h-[40px] bg-primary-100 rounded-[11px] mr-[20px] hidden lg:flex">
                        <img src="@/assets/img/left-arrow-long.svg" alt="">
                    </router-link>
                    <h6>Commission details</h6>
                </div>
            </div>
            <template v-if="order && !dataLoading">
                <div class="row pb-[30px] mb-[13px]">
                    <div class="col-lg-6">
                        <div class="card">
                            <div class="card__body">
                                <div class="card-body--item">
                                    <span class="text-text font-semibold">Client ID</span>
                                    <span class="font-semibold">Client-{{order.user_id }}</span>
                                </div>
                                <div class="card-body--item">
                                    <span class="text-text font-semibold">Order Status</span>
                                    <span class="flex items-center font-semibold" 
                                        style="text-transform: capitalize;"
                                        :class="{
                                            'text-[#F3BD55]': order.status === 'pending' || order.status.includes('pending'),
                                            'text-[#22E5E0]': order.status === 'completed' || order.status.includes('completed'),
                                            'text-[#FE2C55]': order.status === 'declined' || order.status.includes('declined')
                                        }"
                                    >
                                        {{ order.status }}
                                        <div class="ml-[16px]">
                                            <img src="@/assets/img/icon-warning.svg">
                                        </div>
                                    </span>
                                </div>
                                <div class="card-body--item">
                                    <span class="text-text font-semibold">Order Date</span>
                                    <span class="font-semibold">{{ new Date(order.created_at).toLocaleDateString() }} {{ new Date(order.created_at).toLocaleTimeString() }}</span>
                                </div>
                                <div class="card-body--item">
                                    <span class="text-text font-semibold">Release Date</span>
                                    <span class="font-semibold">{{ new Date(order.release_date).toLocaleDateString() }} {{ new Date(order.release_date).toLocaleTimeString() }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="card">
                            <div class="card__body">
                                <div class="card-body--item">
                                    <span class="text-text font-semibold">Subtotal</span>
                                    <span class="font-semibold">${{ order.cart_data.totals.totalBeforeDiscount }}</span>
                                </div>
                                <div class="card-body--item">
                                    <span class="text-text font-semibold">Discount [RJDAIWADH]</span>
                                    <span class="font-semibold text-teal">{{ order.cart_data.totals.totalDiscount ? '-' : '' }}${{ order.cart_data.totals.totalDiscount }}</span>
                                </div>
                                <div class="card-body--item">
                                    <span class="text-text font-semibold">Total</span>
                                    <span class="font-semibold">${{ order.cart_data.totals.total }}</span>
                                </div>
                                <div class="card-body--item-2">
                                    <span class="text-text font-semibold">Commission</span>
                                    <!-- <span class="font-semibold">$1.99</span> -->
                                    <span class="font-semibold">-</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <h6 class="mb-[22px]">Products</h6>

                <div
                    class="card-gray rounded-[18px] px-[20px] py-[14px] mb-[20px]"
                    v-for="(item, index) in order.cart_data.items" :key="index"
                >
                    <div class="flex items-center justify-between flex-wrap ">
                        <div class="flex items-center">
                            <div class="mr-[15px]">
                                <img :src="item.image || require('@/assets/img/tiktok-logo.svg')">
                            </div>
                            <div class="flex flex-col">
                                <span class="font-[600] leading-[20px]">{{ item.product.title }}</span>
                                <span class="font-[600] underline text-teal">{{ item.product.position }} posts</span>
                            </div>
                        </div>
                        <span class="text-primary-500 font-[600]">${{ item.product.base_price }}</span>
                    </div>
                </div>

                <!-- <div class="card-gray rounded-[18px] px-[20px] py-[14px] mb-[20px]">
                    <div class="flex items-center justify-between flex-wrap ">
                        <div class="flex items-center">
                            <div class="mr-[15px]">
                                <img src="@/assets/img/tiktok-logo.svg">
                            </div>
                            <div class="flex flex-col">
                                <span class="font-[600] leading-[20px]">10 custom comments</span>
                                <span class="font-[600] underline text-teal">3 posts</span>
                            </div>
                        </div>
                        <span class="text-primary-500 font-[600]">$4.99</span>
                    </div>
                </div>
                <div class="card-gray rounded-[18px] px-[20px] py-[14px] mb-[20px]">
                    <div class="flex items-center justify-between flex-wrap ">
                        <div class="flex items-center">
                            <div class="mr-[15px]">
                                <img src="@/assets/img/tiktok-logo.svg">
                            </div>
                            <div class="flex flex-col">
                                <span class="font-[600] leading-[20px]">10 custom comments</span>
                                <span class="font-[600] underline text-teal">3 posts</span>
                            </div>
                        </div>
                        <span class="text-primary-500 font-[600]">$4.99</span>
                    </div>
                </div> -->
            </template>
            <template v-else>
                <div class="row pb-[30px] mb-[13px]">
                    <div class="col-xxl-12 col-md-12 card" style="padding: 20px; display: flex; aling-items: center; justify-content: center;">
                        <Loader />
                    </div>
                </div>
            </template>
        </div>
    </section>
</template>

<script>
import { icons } from '@/helpers/icons.js';
import { numberWithSpaces, floatNumberWithSpaces, dateFilter, ucfirst } from '@/helpers/filters.js';
import { translate, getCashbackValue, getCashbackPrecent } from '@/helpers/functions.js';
// import CartProducts from "../Cart/CartProducts";
import Loader from "@/components/Base/Loader.vue";
// import QuickOrderMessage from "./Popups/QuickOrderMessage.vue";
// import ChangesSaved from './Popups/ChangesSavedPopUp.vue';


export default {
    components: {
        // CartProducts,
        Loader,
        // QuickOrderMessage,
        // ChangesSaved
    },
    filters: {
        numberWithSpaces,
        floatNumberWithSpaces,
        dateFilter,
        ucfirst
    },
    props: ['dataprops'],
    data: () => ({
        icons,
        accordions: [],
        trialInterval: null,
        counterValue: false,
        isOutdated: false,
        trialEmail: '',
        trialEmailInvalid: false,
        isAffiliatorOrder: false,
        dataLoading: false,
        orders: [],// костыль
        showTrialResult: false,
        orderStatuses: {
			'paid': 'Paid',
            'awaiting_payment': 'Awaiting for payment',
			'awaiting_confirmation': 'Awaiting confirmation',
			'confirmed': 'Confirmed',
			'processing': 'Processing',
			'completed': 'Completed',
			'partially_paid': 'Partially paid',
			'canceled': 'Canceled',
			'refunded': 'Refunded'
        },
        showQuickOrderMessage:false,
    }),
    computed: {
        isBalanceOrderDetails(){
            return this.order.is_topup;
        },
        checkoutPayload(){
            if(!this.order) return {}
            if(this.$route.query['payment-callback']==='1' || (this.$route.query['payment-callback']==='2' && this.order.status!=='awaiting_payment')){
                return {
                    success: ['awaiting_payment', 'partially_paid'].includes(this.order.status) ? false : this.dataprops.orderSuccessStatuses.includes(this.order.status),
                    orderHash : this.order.hash,
                    // total : this.order.total_price, 
                    total : this.order.total_price, 
                    message: 'Please recheck your card details or choose a different payment method.',
                    orderStatus:this.order.status
                }
            }
            else if(this.$route.query['payment-callback']==='2' && this.order.status==='awaiting_payment'){
                return {
                    success: false,
                    status:'warning',
                    orderHash : this.order.hash,
                    total : this.order.total_price, 
                    message: 'Waiting for confirmation',
                    orderStatus:this.order.status
                }
            }
        },
        trialErrorPayload(){
            if(!this.order.is_free) return {}
            return {
                message: 'Your free trial has been started',
                order: this.order,
                specialError: false,
                success: true,
            }
        },
        order(){
            return this.isAffiliatorOrder
                ? Object.values(this.dataprops.affiliator.referredOrders).find(item => item.hash === this.$route.params.id)
                : this.orders.find(item => (item && item.hash) === (this.$route.params.id));
        },
        originURL(){ 
            return window.location.origin;
        },
        countryList() {
            return Object.entries(this.dataprops.countryList).map(([code, name]) => ({ code, name }));
        },
        savedCards() {
            let cards = (this.dataprops.paymentMethods || []).filter(el => el.payment_type == 'cardinity');

            return cards.length ? cards : false;
        },
        isTopUpOrder() {
            return this.order && this.order.line_items[0].product_information.product_group.identifier === 'balance_top_up';
        },
        paymentPayload() {
            return {
                safechargeEnabled: this.dataprops.safechargeEnabled,
                paypalEnabled: this.dataprops.paypalEnabled,
                balancePaymentEnabled: this.dataprops.balancePaymentEnabled,
                user: this.dataprops.user,
                billingInfo: this.order?.cart_data.billingInformation,
                currency: this.order?.cart_data.currency,
                countryList: this.countryList,
                savedCards: this.savedCards,
                order: this.order,
            }
        },
        showTrialCounter() {
            return this.order && this.order.status == 'awaiting_confirmation'
        },
        rateSectionText() {
            let text = 'Get up to, 10% OFF, for your next order';

            if (this.order?.review && !this.order.review?.discount) {
                text = `Your discount reward will be sent to ${this.paymentPayload.user.email} within 24 hours!`;
            } else if (this.order?.review?.discount) {
                text = `Enjoy a discount for your next order with code ${this.order.review.discount.code}`;
            }

            return text;
        }
    },
    watch: {
        trialEmail: {
            handler(val) {
                this.trialEmailInvalid = !(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(val));
            }
        },
        order(value){
            console.log('order:', value);
        }
    },
    methods: {
        translate,
      
        getCashbackPrecent,
        expirDateReadable(info) {
            let y = info.cardExpiryYear
            let m = info.cardExpiryMonth;
            m = m<10 ? ('0'+m) : m;
            y = y>2000 ? y-2000 : y;
            return y + '/' + m;
        },
		getCashbackVal(order) {
			return getCashbackValue(order, this.dataprops.cashbackOptions);
		},
        payNow(withoutTransactionStatus) {
            this.$emit('popup', { name: 'pay-now-2', payload: {
                ...this.paymentPayload,
                withoutTransactionStatus: withoutTransactionStatus===true,
                onSuccess: (function() {
                    const order = this.dataprops.orders.find(el => el.id == this.order.id);
                    
                    this.$set(order, 'status', 'paid');
                    this.$forceUpdate()
                }).bind(this)
            }});
        },
        millisToMinutesAndSeconds(millis) {
            let minutes = Math.floor(millis / 60000);
            let seconds = ((millis % 60000) / 1000).toFixed(0);
            return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
        },
        setCounter(reset=false) {
            let targetDate = new Date((reset ? new Date() : new Date(this.order.created_at)).getTime() + (60 * 60 * 1000));

            if ((new Date()).getTime() >= targetDate.getTime()) {
                this.isOutdated = true;
                return;
            }

            this.trialInterval = setInterval(() => {
                if ((new Date()).getTime() >= targetDate.getTime()) {
                    this.isOutdated = true;
                    return;
                }

                this.counterValue = this.millisToMinutesAndSeconds(targetDate.getTime() - Date.now());
            }, 1000);
        },
        async resendEmailConfirmation() {
            if (this.trialEmailInvalid) {
                return;
            }

            const response = (await axios.post(`${this.originURL}/orders/trials/resend/${this.order.url_hash}`, {
                email: this.trialEmail
            })).data;

            if (response.success) {
                this.setCounter(true);
            }
        },
        async getOrder(id) {
            this.dataLoading = true;
			// const response = await this.$api.loadOrderById(id);
			this.dataLoading = false;

			// this.$set(this.dataprops, 'orders', [ ...this.dataprops.orders, response.data ]);
			this.$set(this.dataprops, 'orders', [ ...this.dataprops.orders, ...this.dataprops.affiliator.referredOrders]);
            this.orders = this.dataprops.orders;
        }
    },
    async created() {
        this.orders = this.dataprops.orders;
        // log
        if(this.$route.query['payment-callback']==='1' || (this.$route.query['payment-callback']==='2' && this.order.status!=='awaiting_payment')){
            this.showQuickOrderMessage=true
        }
        else if(this.$route.query['payment-callback']==='2' && this.order.status==='awaiting_payment'){
            console.log('payment-callback 2', this.order);
            this.showQuickOrderMessage=true
            let fetchOrder = async () =>{
                if (!this.showQuickOrderMessage) return
                const response = await Vue.http["get"](`/order-fetch/${this.order.hash }`).then((response) => response.body.data);
                if(response && response.status==='awaiting_payment' ){
                    console.log('Fetch order response awaiting', response);
                    setTimeout(() => {
                        fetchOrder()

                    }, 2000);
                }else{
                    console.log('fetch Order responce change status');
                    // this.order[this.orders.findIndex(item => (item && item.hash) === (this.$route.params.id))] = response
                    // window.location.href="/dashboard/order-details/hash"
                    window.location.reload();
                }
            }
            fetchOrder()
        }
        if(this.$route.query['free-trial-confirmed']==='1' && this.order.is_free){
            this.showTrialResult=true
        }
    },
    async mounted() {
      

        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('type') && urlParams.get('type') === 'affiliator'){
            this.isAffiliatorOrder = true;
        }

   

        if (!this.order) {
            await this.getOrder(this.$route.params.id)
        }

        this.trialEmail = this.order && this.order.buyer_information.email;

        if (this.showTrialCounter) {
            this.setCounter();
        }

        if(urlParams.get('payment') && urlParams.get('payment') === 'coinpayments') {
            this.$emit('popup', { name: 'pay-now-2', payload: this.paymentPayload });
        }
    }
}
</script>
<style scoped>
.status.paid,
.status.processing {
    color: #05A6DB;
}
.status.awaiting_payment,
.status.partially_paid {
    color: #CCA52D;
}
.status.canceled {
    color: #DB1919;
}
.disabled {
    opacity: 0.7;
    pointer-events: none;
}

.input--iconed {
    padding-left: 53px !important;
}
.footer-no-discount-fix .order-item-footer{
    border-top: none;
    margin: 0;
    padding: 0;
}
@media (max-width: 1400px){
        .order-item-footer{
            margin-top: 0 !important;
        }
    }
    @media (max-width: 1200px){
        .row.order-details-block .col-xl-4 {
            margin-top: 20px;
        }
        
    }
    @media (max-width: 768px){
        .order-item-footer{
            border-top:none;
        }
        .order-item {
            padding: 15px 20px;
        }
    }
</style>