<template>
    <main class="content">
    <div class="header-dash-mobile lg:hidden ">
    <div class="container">
        <div class="header-dash-mobile-body">
            <div class="header-dash-mobile-top">
                <a href="" class="mobile-dash-logo">
                <img src="@/assets/img/icons/tinkws-logo-mobile-dash.svg" alt="">
                </a>
                <a href="" class="btn btn-header-card font-semibold ml-auto">
                <span class="btn-icon relative mr-[12px]">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7646 3.55596C15.4742 3.01465 14.9409 2.67717 14.36 2.66711H3.78416L3.30495 0.658283C3.20525 0.258991 2.8628 -0.0136274 2.47871 0.000526357H0.826237C0.369919 0.000526357 0 0.398482 0 0.889386C0 1.38029 0.369919 1.77825 0.826237 1.77825H1.85077L4.13118 10.8979C4.23088 11.2972 4.57333 11.5699 4.95742 11.5557H12.3936C12.7047 11.5547 12.9889 11.3657 13.1289 11.0668L15.839 5.23591C16.0775 4.69793 16.0496 4.06712 15.7646 3.55596ZM11.8813 9.77798H5.58536L4.23859 4.44482H14.36L11.8813 9.77798ZM5.78366 14.6667C5.78366 15.4031 5.22878 16 4.5443 16C3.85983 16 3.30495 15.4031 3.30495 14.6667C3.30495 13.9304 3.85983 13.3334 4.5443 13.3334C5.22878 13.3334 5.78366 13.9304 5.78366 14.6667ZM12.8067 16C13.4911 16 14.046 15.4031 14.046 14.6667C14.046 13.9304 13.4911 13.3334 12.8067 13.3334C12.1222 13.3334 11.5673 13.9304 11.5673 14.6667C11.5673 15.4031 12.1222 16 12.8067 16Z" fill="currentColor"></path>
                    </svg>
                    <div class="indicator bg-primary-500"></div>
                </span>
                $9.10
                </a>
                <a href="" class="btn btn-burger ml-5" data-modal="#heared-mobile-not-log">
                <img src="@/assets/img/icons/setting-slider-horizontal.svg" alt="">
                </a>
            </div>
        </div>
    </div>
    </div>

    <div id="heared-mobile-not-log" class="modal modal-1">
    <div class="modal__dialog modal__dialog--443">
        <div class="modal__content">
            <div class="modal__header mb-0 pb-0">
                <a href="#" class="btn btn-close ml-auto" data-close-modal="">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.4771 2.94469C17.9223 2.51467 18.1009 1.87788 17.9441 1.27907C17.7874 0.68025 17.3197 0.212602 16.7209 0.0558617C16.1221 -0.100878 15.4853 0.0776826 15.0553 0.522914L8.99914 6.57736L2.94469 0.522914C2.51467 0.0776826 1.87788 -0.100878 1.27907 0.0558617C0.68025 0.212602 0.212602 0.68025 0.0558617 1.27907C-0.100878 1.87788 0.0776826 2.51467 0.522914 2.94469L6.57736 8.99914L0.522914 15.0553C0.0776826 15.4853 -0.100878 16.1221 0.0558617 16.7209C0.212602 17.3197 0.68025 17.7874 1.27907 17.9441C1.87788 18.1009 2.51467 17.9223 2.94469 17.4771L8.99914 11.4226L15.0553 17.4771C15.4853 17.9223 16.1221 18.1009 16.7209 17.9441C17.3197 17.7874 17.7874 17.3197 17.9441 16.7209C18.1009 16.1221 17.9223 15.4853 17.4771 15.0553L11.4226 8.99914L17.4771 2.94469Z" fill="#3A4C66"></path>
                </svg>
                </a>
            </div>
            <div class="modal__body px-0">
                <ul class="sidebar__list">
                <li>
                    <a href="" class="active">
                        <span class="sidebar-list-icon">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.7643 1H7.23571C3.79309 1.00425 1.00354 3.79437 1 7.237V12.7656C1.00496 16.2072 3.79409 18.9958 7.23571 19H12.7643C16.2069 18.9957 18.9965 16.2056 19 12.763V7.23443C18.995 3.7928 16.2059 1.00425 12.7643 1Z" fill="currentColor" stroke="currentColor" stroke-width="1.2"></path>
                            <path d="M13.546 11.2767H11.2767V13.546C11.2548 14.235 10.69 14.7822 10.0006 14.7822C9.31131 14.7822 8.74644 14.235 8.72457 13.546V11.2767H6.45529C5.98973 11.2915 5.55307 11.0516 5.31592 10.6507C5.07877 10.2498 5.07877 9.75153 5.31592 9.35063C5.55307 8.94973 5.98973 8.70979 6.45529 8.72457H8.72457V6.45529C8.70979 5.98973 8.94973 5.55307 9.35063 5.31592C9.75153 5.07877 10.2498 5.07877 10.6507 5.31592C11.0516 5.55307 11.2915 5.98973 11.2767 6.45529V8.72457H13.546C14.235 8.74644 14.7822 9.31131 14.7822 10.0006C14.7822 10.69 14.235 11.2548 13.546 11.2767Z" class="active-color" fill="#ffffff"></path>
                            </svg>
                        </span>
                        New order
                    </a>
                </li>
                <li>
                    <a href="">
                        <span class="sidebar-list-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
                            <g clip-path="url(#clip0_1266_63795)">
                                    <path d="M9.75 7C10.4404 7 11 6.44036 11 5.75C11 5.05964 10.4404 4.5 9.75 4.5C9.05964 4.5 8.5 5.05964 8.5 5.75C8.5 6.44036 9.05964 7 9.75 7Z" fill="currentColor"></path>
                                    <path d="M15.75 0H3.75C1.68 0 0 1.68 0 3.75V11.75C0 13.82 1.68 15.5 3.75 15.5H5.25C5.95 15.5 6.63 15.84 7.05 16.4L8.35 18.13C8.68 18.58 9.19 18.83 9.75 18.83C10.31 18.83 10.82 18.57 11.15 18.13L12.45 16.4C12.87 15.84 13.55 15.5 14.25 15.5H15.75C17.82 15.5 19.5 13.82 19.5 11.75V3.75C19.5 1.68 17.82 0 15.75 0ZM9.75 3C11.27 3 12.5 4.23 12.5 5.75C12.5 7.27 11.27 8.5 9.75 8.5C8.23 8.5 7 7.27 7 5.75C7 4.23 8.23 3 9.75 3ZM14.09 12.42C13.98 12.47 13.87 12.5 13.76 12.5C13.49 12.5 13.22 12.35 13.09 12.09L12.64 11.2C12.43 10.77 12 10.51 11.52 10.51H7.99C7.51 10.51 7.09 10.77 6.87 11.2L6.42 12.09C6.24 12.46 5.79 12.61 5.41 12.43C5.04 12.24 4.89 11.79 5.07 11.42L5.52 10.53C5.99 9.59 6.93 9.01 7.98 9.01H11.51C12.56 9.01 13.5 9.59 13.97 10.53L14.42 11.42C14.61 11.79 14.46 12.24 14.08 12.43L14.09 12.42Z" fill="currentColor"></path>
                            </g>
                            <defs>
                                    <clipPath id="clip0_1266_63795">
                                        <rect width="20" height="19" class="active-color" fill="#ffffff"></rect>
                                    </clipPath>
                            </defs>
                            </svg>
                        </span>
                        My orders
                    </a>
                </li>
                <li>
                    <a href="">
                        <span class="sidebar-list-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
                            <g clip-path="url(#clip0_1266_63804)">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.75 0.5C15.13 0.5 19.5 3.99 19.5 8.26C19.5 12.53 15.13 16.01 9.75 16.01C8.89 16.01 8.03 15.92 7.2 15.74L2.92 17.57C2.76 17.64 2.6 17.67 2.43 17.67C2.15 17.67 1.87 17.57 1.64 17.39C1.28 17.09 1.11 16.63 1.2 16.17L1.87 12.82C0.66 11.5 0 9.89 0 8.25C0 3.98 4.37 0.5 9.75 0.5ZM4.61111 7.16193C3.78268 7.16193 3.11111 7.8335 3.11111 8.66193C3.11111 9.49036 3.78268 10.1619 4.61111 10.1619C5.43954 10.1619 6.11111 9.49036 6.11111 8.66193C6.11111 7.8335 5.43954 7.16193 4.61111 7.16193ZM9.61111 7.16193C8.78268 7.16193 8.11111 7.8335 8.11111 8.66193C8.11111 9.49036 8.78268 10.1619 9.61111 10.1619C10.4395 10.1619 11.1111 9.49036 11.1111 8.66193C11.1111 7.8335 10.4395 7.16193 9.61111 7.16193ZM14.6111 7.16193C13.7827 7.16193 13.1111 7.8335 13.1111 8.66193C13.1111 9.49036 13.7827 10.1619 14.6111 10.1619C15.4395 10.1619 16.1111 9.49036 16.1111 8.66193C16.1111 7.8335 15.4395 7.16193 14.6111 7.16193Z" fill="currentColor"></path>
                            </g>
                            <defs>
                                    <clipPath id="clip0_1266_63804">
                                        <rect width="20" height="18" class="active-color" fill="#ffffff"></rect>
                                    </clipPath>
                            </defs>
                            </svg>
                        </span>
                        Message us
                    </a>
                </li>
                </ul>
            </div>
        </div>
    </div>
    </div>        
    <div class="pt-[24px] pb-[50px] text-center text-white-100 bg-black-100">
        <div class="container">
            <div class="max-w-[642px] mx-auto">
                <span class="block mb-[12px] sm:mb-[30px] font-[700] text-[18px] sm:text-[24px] leading-[24px] sm:leading-[32px]">Latest Post</span>
                <h2 class="font-[600] text-[24px] sm:text-[36px] leading-[32px] sm:leading-[48px] mb-[17px] sm:mb-[27px]">How to Fix the ‘Checkpoint Required’ Error on Instagram?</h2>
                <p class="mb-[17px] sm:mb-[27px] font-[600] text-[14px] sm:text-[16px] leading-[18px] text-secondary-500 max-w-[586px] mx-auto ff-fotnFamilyMontserrat">Instagram has become a major social media outlet for brands and individuals alike. There’s no shortage of ways to gain followers or increase likes, shares, and comments—but sometimes…</p>
                <a href="#" class="btn btn--primary px-[23px] py-[13px] rounded-[8px] w-[270px] text-[14px] ">
                    <span class="info">Read more</span>
                </a>
            </div>
        </div>
    </div>
        <section class="pb-[65px] pt-[18px] sm:bg-secondary-100">
            <div class="container">
                <div class="tabs blog-tabs tab-blog card-tabs " data-tabs="">
                    <div class="tabs-head mb-[30px]" tabs-nav="">
                        <ul class="tabs__list">
                            <li
                                v-for="itemFilter in filtersList" :key="itemFilter.id"
                            >
                                <a href="#twitter" class="tab-button" :class="{'active':filterActive === itemFilter.id}" @click="filterActive = itemFilter.id">
                                    <span class="tab-text">{{ itemFilter.name }}</span>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div class="tabs-body" tabs-body="tabs-page">
                        <div class="tabs-item" id="recent">
                            <div class="tabs-item__content">

                            </div>
                        </div>
                        <div class="tabs-item active" id="facebook">
                            <div class="tabs-item__content">
                                <div class="blog-wrap mb-[22px]">
                                    <ul class="blog-list">
                                        <li class="blog-item" v-for="(post, index) in postsData" :key="index">
                                            <router-link :to="`/blogs/blog-post/${index}`" class="blog-item__card">
                                                <div class="mb-[21px]">
                                                    <img :src="post.image">
                                                </div>
                                                <h6 class="font-[700] text-[18px] mb-[10px]">{{ post.title}}</h6>
                                                <span class="font-[600] block text-primary-500 text-[15px] mb-[11px]">{{ post.date }}</span>
                                                <p class="blog-item__text">
                                                    {{ post.text }}
                                                </p>
                                            </router-link>
                                        </li>
                                    </ul>
                                </div>
                                <ul class="pagination">
                                    <li><a href="#" class="btn">1</a></li>
                                    <li><a href="#" class="btn">2</a></li>
                                    <li><a href="#" class="btn">3</a></li>
                                    <li><a href="#" class="btn">4</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="tabs-item" id="instagram">
                            <div class="tabs-item__content">

                            </div>
                        </div>
                        <div class="tabs-item" id="twitter">
                            <div class="tabs-item__content">

                            </div>
                        </div>
                        <div class="tabs-item" id="youtube">
                            <div class="tabs-item__content">

                            </div>
                        </div>
                        <div class="tabs-item" id="tiktok">
                            <div class="tabs-item__content">

                            </div>
                        </div>
                        <div class="tabs-item" id="spotify">
                            <div class="tabs-item__content">

                            </div>
                        </div>
                        <div class="tabs-item" id="soundcloud">
                            <div class="tabs-item__content">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>

export default {
    data() {
        return {
            filtersList: [
                { name: "Recent", id: "recent" },
                { name: "Facebook", id: "facebook" },
                { name: "Instagram", id: "instagram" },
                { name: "Twitter", id: "twitter" },
                { name: "Spotify", id: "spotify" },
                { name: "TikTok", id: "tiktok" },
                { name: "SoundCloud", id: "soundcloud" },
            ],
            filterActive: "recent",
            postsData: [
                {
                    title: "How to find my Instagram URL?",
                    date: "11 September, 2022",
                    text: "Instagram is one of today’s most popular social websites, and since its creation, it has been going through various changes and improvements. One of these changes was when they introduced a different URL for each user account, post, or video.",
                    image: require('@/assets/img/blog-img-1.png'),
                },
                {
                    title: "How to find my Instagram URL?",
                    date: "11 September, 2022",
                    text: "Instagram is one of today’s most popular social websites, and since its creation, it has been going through various changes and improvements. One of these changes was when they introduced a different URL for each user account, post, or video.",
                    image: require('@/assets/img/blog-img-2.png'),
                },
                {
                    title: "How to find my Instagram URL?",
                    date: "11 September, 2022",
                    text: "Instagram is one of today’s most popular social websites, and since its creation, it has been going through various changes and improvements. One of these changes was when they introduced a different URL for each user account, post, or video.",
                    image: require('@/assets/img/blog-img-3.png'),
                },
                {
                    title: "How to find my Instagram URL?",
                    date: "11 September, 2022",
                    text: "Instagram is one of today’s most popular social websites, and since its creation, it has been going through various changes and improvements. One of these changes was when they introduced a different URL for each user account, post, or video.",
                    image: require('@/assets/img/blog-img-1.png'),
                },
                {
                    title: "How to find my Instagram URL?",
                    date: "11 September, 2022",
                    text: "Instagram is one of today’s most popular social websites, and since its creation, it has been going through various changes and improvements. One of these changes was when they introduced a different URL for each user account, post, or video.",
                    image: require('@/assets/img/blog-img-2.png'),
                },
                {
                    title: "How to find my Instagram URL?",
                    date: "11 September, 2022",
                    text: "Instagram is one of today’s most popular social websites, and since its creation, it has been going through various changes and improvements. One of these changes was when they introduced a different URL for each user account, post, or video.",
                    image: require('@/assets/img/blog-img-3.png'),
                },
                {
                    title: "How to find my Instagram URL?",
                    date: "11 September, 2022",
                    text: "Instagram is one of today’s most popular social websites, and since its creation, it has been going through various changes and improvements. One of these changes was when they introduced a different URL for each user account, post, or video.",
                    image: require('@/assets/img/blog-img-1.png'),
                },
                {
                    title: "How to find my Instagram URL?",
                    date: "11 September, 2022",
                    text: "Instagram is one of today’s most popular social websites, and since its creation, it has been going through various changes and improvements. One of these changes was when they introduced a different URL for each user account, post, or video.",
                    image: require('@/assets/img/blog-img-2.png'),
                },
                {
                    title: "How to find my Instagram URL?",
                    date: "11 September, 2022",
                    text: "Instagram is one of today’s most popular social websites, and since its creation, it has been going through various changes and improvements. One of these changes was when they introduced a different URL for each user account, post, or video.",
                    image: require('@/assets/img/blog-img-3.png'),
                },

            ],
        };
    },
}
</script>

<style>

</style>