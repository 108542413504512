<template>
    <main class="content">
<div class="header-dash-mobile lg:hidden ">
<div class="container">
    <div class="header-dash-mobile-body">
        <div class="header-dash-mobile-top">
            <a href="" class="mobile-dash-logo">
            <img src="@/assets/img/icons/tinkws-logo-mobile-dash.svg" alt="">
            </a>
            <a href="" class="btn btn-header-card font-semibold ml-auto">
            <span class="btn-icon relative mr-[12px]">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7646 3.55596C15.4742 3.01465 14.9409 2.67717 14.36 2.66711H3.78416L3.30495 0.658283C3.20525 0.258991 2.8628 -0.0136274 2.47871 0.000526357H0.826237C0.369919 0.000526357 0 0.398482 0 0.889386C0 1.38029 0.369919 1.77825 0.826237 1.77825H1.85077L4.13118 10.8979C4.23088 11.2972 4.57333 11.5699 4.95742 11.5557H12.3936C12.7047 11.5547 12.9889 11.3657 13.1289 11.0668L15.839 5.23591C16.0775 4.69793 16.0496 4.06712 15.7646 3.55596ZM11.8813 9.77798H5.58536L4.23859 4.44482H14.36L11.8813 9.77798ZM5.78366 14.6667C5.78366 15.4031 5.22878 16 4.5443 16C3.85983 16 3.30495 15.4031 3.30495 14.6667C3.30495 13.9304 3.85983 13.3334 4.5443 13.3334C5.22878 13.3334 5.78366 13.9304 5.78366 14.6667ZM12.8067 16C13.4911 16 14.046 15.4031 14.046 14.6667C14.046 13.9304 13.4911 13.3334 12.8067 13.3334C12.1222 13.3334 11.5673 13.9304 11.5673 14.6667C11.5673 15.4031 12.1222 16 12.8067 16Z" fill="currentColor"></path>
                </svg>
                <div class="indicator bg-primary-500"></div>
            </span>
            $9.10
            </a>
            <a href="" class="btn btn-burger ml-5" data-modal="#heared-mobile-not-log">
            <img src="@/assets/img/icons/setting-slider-horizontal.svg" alt="">
            </a>
        </div>
    </div>
</div>
</div>

<div id="heared-mobile-not-log" class="modal modal-1">
<div class="modal__dialog modal__dialog--443">
    <div class="modal__content">
        <div class="modal__header mb-0 pb-0">
            <a href="#" class="btn btn-close ml-auto" data-close-modal="">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.4771 2.94469C17.9223 2.51467 18.1009 1.87788 17.9441 1.27907C17.7874 0.68025 17.3197 0.212602 16.7209 0.0558617C16.1221 -0.100878 15.4853 0.0776826 15.0553 0.522914L8.99914 6.57736L2.94469 0.522914C2.51467 0.0776826 1.87788 -0.100878 1.27907 0.0558617C0.68025 0.212602 0.212602 0.68025 0.0558617 1.27907C-0.100878 1.87788 0.0776826 2.51467 0.522914 2.94469L6.57736 8.99914L0.522914 15.0553C0.0776826 15.4853 -0.100878 16.1221 0.0558617 16.7209C0.212602 17.3197 0.68025 17.7874 1.27907 17.9441C1.87788 18.1009 2.51467 17.9223 2.94469 17.4771L8.99914 11.4226L15.0553 17.4771C15.4853 17.9223 16.1221 18.1009 16.7209 17.9441C17.3197 17.7874 17.7874 17.3197 17.9441 16.7209C18.1009 16.1221 17.9223 15.4853 17.4771 15.0553L11.4226 8.99914L17.4771 2.94469Z" fill="#3A4C66"></path>
            </svg>
            </a>
        </div>
        <div class="modal__body px-0">
            <ul class="sidebar__list">
            <li>
                <a href="" class="active">
                    <span class="sidebar-list-icon">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.7643 1H7.23571C3.79309 1.00425 1.00354 3.79437 1 7.237V12.7656C1.00496 16.2072 3.79409 18.9958 7.23571 19H12.7643C16.2069 18.9957 18.9965 16.2056 19 12.763V7.23443C18.995 3.7928 16.2059 1.00425 12.7643 1Z" fill="currentColor" stroke="currentColor" stroke-width="1.2"></path>
                        <path d="M13.546 11.2767H11.2767V13.546C11.2548 14.235 10.69 14.7822 10.0006 14.7822C9.31131 14.7822 8.74644 14.235 8.72457 13.546V11.2767H6.45529C5.98973 11.2915 5.55307 11.0516 5.31592 10.6507C5.07877 10.2498 5.07877 9.75153 5.31592 9.35063C5.55307 8.94973 5.98973 8.70979 6.45529 8.72457H8.72457V6.45529C8.70979 5.98973 8.94973 5.55307 9.35063 5.31592C9.75153 5.07877 10.2498 5.07877 10.6507 5.31592C11.0516 5.55307 11.2915 5.98973 11.2767 6.45529V8.72457H13.546C14.235 8.74644 14.7822 9.31131 14.7822 10.0006C14.7822 10.69 14.235 11.2548 13.546 11.2767Z" class="active-color" fill="#ffffff"></path>
                        </svg>
                    </span>
                    New order
                </a>
            </li>
            <li>
                <a href="">
                    <span class="sidebar-list-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
                        <g clip-path="url(#clip0_1266_63795)">
                                <path d="M9.75 7C10.4404 7 11 6.44036 11 5.75C11 5.05964 10.4404 4.5 9.75 4.5C9.05964 4.5 8.5 5.05964 8.5 5.75C8.5 6.44036 9.05964 7 9.75 7Z" fill="currentColor"></path>
                                <path d="M15.75 0H3.75C1.68 0 0 1.68 0 3.75V11.75C0 13.82 1.68 15.5 3.75 15.5H5.25C5.95 15.5 6.63 15.84 7.05 16.4L8.35 18.13C8.68 18.58 9.19 18.83 9.75 18.83C10.31 18.83 10.82 18.57 11.15 18.13L12.45 16.4C12.87 15.84 13.55 15.5 14.25 15.5H15.75C17.82 15.5 19.5 13.82 19.5 11.75V3.75C19.5 1.68 17.82 0 15.75 0ZM9.75 3C11.27 3 12.5 4.23 12.5 5.75C12.5 7.27 11.27 8.5 9.75 8.5C8.23 8.5 7 7.27 7 5.75C7 4.23 8.23 3 9.75 3ZM14.09 12.42C13.98 12.47 13.87 12.5 13.76 12.5C13.49 12.5 13.22 12.35 13.09 12.09L12.64 11.2C12.43 10.77 12 10.51 11.52 10.51H7.99C7.51 10.51 7.09 10.77 6.87 11.2L6.42 12.09C6.24 12.46 5.79 12.61 5.41 12.43C5.04 12.24 4.89 11.79 5.07 11.42L5.52 10.53C5.99 9.59 6.93 9.01 7.98 9.01H11.51C12.56 9.01 13.5 9.59 13.97 10.53L14.42 11.42C14.61 11.79 14.46 12.24 14.08 12.43L14.09 12.42Z" fill="currentColor"></path>
                        </g>
                        <defs>
                                <clipPath id="clip0_1266_63795">
                                    <rect width="20" height="19" class="active-color" fill="#ffffff"></rect>
                                </clipPath>
                        </defs>
                        </svg>
                    </span>
                    My orders
                </a>
            </li>
            <li>
                <a href="">
                    <span class="sidebar-list-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
                        <g clip-path="url(#clip0_1266_63804)">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.75 0.5C15.13 0.5 19.5 3.99 19.5 8.26C19.5 12.53 15.13 16.01 9.75 16.01C8.89 16.01 8.03 15.92 7.2 15.74L2.92 17.57C2.76 17.64 2.6 17.67 2.43 17.67C2.15 17.67 1.87 17.57 1.64 17.39C1.28 17.09 1.11 16.63 1.2 16.17L1.87 12.82C0.66 11.5 0 9.89 0 8.25C0 3.98 4.37 0.5 9.75 0.5ZM4.61111 7.16193C3.78268 7.16193 3.11111 7.8335 3.11111 8.66193C3.11111 9.49036 3.78268 10.1619 4.61111 10.1619C5.43954 10.1619 6.11111 9.49036 6.11111 8.66193C6.11111 7.8335 5.43954 7.16193 4.61111 7.16193ZM9.61111 7.16193C8.78268 7.16193 8.11111 7.8335 8.11111 8.66193C8.11111 9.49036 8.78268 10.1619 9.61111 10.1619C10.4395 10.1619 11.1111 9.49036 11.1111 8.66193C11.1111 7.8335 10.4395 7.16193 9.61111 7.16193ZM14.6111 7.16193C13.7827 7.16193 13.1111 7.8335 13.1111 8.66193C13.1111 9.49036 13.7827 10.1619 14.6111 10.1619C15.4395 10.1619 16.1111 9.49036 16.1111 8.66193C16.1111 7.8335 15.4395 7.16193 14.6111 7.16193Z" fill="currentColor"></path>
                        </g>
                        <defs>
                                <clipPath id="clip0_1266_63804">
                                    <rect width="20" height="18" class="active-color" fill="#ffffff"></rect>
                                </clipPath>
                        </defs>
                        </svg>
                    </span>
                    Message us
                </a>
            </li>
            </ul>
        </div>
    </div>
</div>
</div>        <div class="pt-[24px] pb-[50px] text-center text-white-100 bg-black-100">
            <div class="container">
                <div class="max-w-[642px] mx-auto">
                    <span class="badge bg-primary-500 mb-[8px]">
                        <span class="info text-[12px] px-[14px]">Instagram</span>
                    </span>
                    <h2 class="font-[600] text-[24px] sm:text-[36px] leading-[32px] sm:leading-[48px] mb-[17px] sm:mb-[27px] text-primary-500">
                        How to Fix the ‘Checkpoint Required’ Error on Instagram?
                    </h2>
                    <p class="font-[600] text-[14px] sm:text-[16px] leading-[18px] text-secondary-500 max-w-[586px] mx-auto ff-fotnFamilyMontserrat">
                        <span class="text-primary-500">Blog </span> <span class="text-secondary-500"> &gt; </span> How to Fix the ‘Checkpoint Required’ Error on Instagram? 
                    </p>
                </div>
            </div>
        </div>
        <section class="lg:pb-[65px] pt-[52px]">
            <div class="container container-1190">
                <div class="blog-post-wrap flex-col lg:flex-row mb-[22px]">
                    <div class="blog-post lg:max-w-[690px] ff-fotnFamilyMontserrat lg:mr-[70px]">
                        <div class="mb-[52px] mx-[-15px] sm:mx-[0px]">
                            <img src="@/assets/img/blog-post.png">
                        </div>
                        <p>
                            Instagram has become a major social media outlet for brands and individuals alike. There’s no shortage of ways to gain followers or increase likes, shares, and comments—but sometimes, you run into trouble such as seeing the Checkpoint Required Error, because of their new changes and updates.<br><br>
    
                            Instagram recently made some API changes; some people who use third-party applications to automatically add hashtags to their Instagram accounts or try to log in from different geolocation aren’t able to because they’re missing these requests from Instagram. However, if your Instagram account hasn’t been active recently, you shouldn’t need to submit any requests, meaning that you won’t have this issue.<br><br>
    
                            However, if this issue persists and you’re still having trouble with this error, even on newer accounts on some older ones but which have been active, then read on. In this article, we will show you the proper way of <a href="#" class="text-primary-500 underline font-[500]">How to Fix the Checkpoint Required Error on Instagram</a>. But first, let’s try and understand what does that error mean.<br><br>
                        </p>
                        <div class="py-[42px] lg:hidden">
                            <img src="@/assets/img/blog-post-img-2.png">
                        </div>
                        <h3 class="text-[26px] leading-[30px] font-[600] mb-[20px]">Learning the Meaning of the “Checkpoint Required” Instagram Error</h3>
                        <p>
                            When the error appears on your phone or desktop screen, there are usually two main reasons why it happens. And even though it has the name error in it, it’s in fact, actually a security feature of Instagram. Yeah, that’s right, it’s just like the programming meme, it’s not a bug it’s a feature. Well, only half of that last statement is true. <br><br>
    
                            While this indeed is a security feature sometimes gets confused, and it displays that error message instead. Well, to learn how you can potentially avoid making that mistake in the future, continue reading down below.<br><br>
    
                            Reason 1: Your IP location is unusual.<br><br>
    
                            One of the reasons why this happens is because the location where you normally log in doesn’t match what your IP address shows when you’re attempting to log in. This means that simply you could have logged in to your account in the morning, and then in the afternoon, you’ve traveled to another city and once again try to log in to Instagram. 
                        </p>
                        <div class="sm:pt-[32px] pt-[25px] sm:mt-[32px] mt-[25px] border-top ">
                            <h4 class="text-[14px] leading-[17px] font-[600] mb-12 hidden sm:block">Tagged under</h4>
                            <ul class="list-tegs">
                                <li class="list-tegs__item flex items-center sm:hidden">
                                    <h4 class="text-[14px] leading-[17px] font-[600]">Tagged under</h4>
                                </li>
                                <li class="list-tegs__item">
                                    <span class="teg-item">#GOOGLEADS</span>
                                </li>
                                <li class="list-tegs__item">
                                    <span class="teg-item">#TIKTOKADS</span>
                                </li>
                                <li class="list-tegs__item">
                                    <span class="teg-item">#TIKTOKGOOGLEADS</span>
                                </li>
                                <li class="list-tegs__item">
                                    <span class="teg-item">#ADCOMPARISON</span>
                                </li>
                                <li class="list-tegs__item">
                                    <span class="teg-item">#GoogleAdsTikTokAdsComparison</span>
                                </li>
                                <li class="list-tegs__item">
                                    <span class="teg-item">#TIKTOKADS</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="blog-right-block pt-[32px] mt-[32px] lg:mt-[0] lg:pt-[0] max-lg-border-top">
                        <div class="blog-block mb-[24px] lg:px-[48px] pt-[32px] lg:pt-[42px] mt-[32px] lg:mt-[0px] max-lg-border-top lg:pb-[49px] lg:bg-secondary-100 order-2 lg:order-1 lg:rounded-[10px]">
                            <div class="mb-[22px]">
                                <img src="@/assets/img/tiktok-logo-4500.png">
                            </div>
                            <h6 class="leading-[24px] font-[700] mb-[12px]">Get social proof and increase your engagement on TikTok within minutes!</h6>
                            <span class="block leading-[20px] font-[600] mb-[18px]">From $2.97 only. Free trial available.</span>
                            <ul class="buy-tiktok-list">
                                <li class="buy-tiktok-item">
                                    <a href="#" class="btn">Buy TikTok Followers</a>
                                </li>
                                <li class="buy-tiktok-item">
                                    <a href="#" class="btn">Buy TikTok Likes</a>
                                </li>
                                <li class="buy-tiktok-item">
                                    <a href="#" class="btn">Buy TikTok Shares</a>
                                </li>
                                <li class="buy-tiktok-item">
                                    <a href="#" class="btn">Buy TikTok Views</a>
                                </li>
                                <li class="buy-tiktok-item">
                                    <a href="#" class="btn">Buy TikTok Comments</a>
                                </li>
                            </ul>
                        </div>

                        <div class="lg:px-[32px] pt-[32px] lg:pt-[32px] mt-[32px] lg:mt-[0px] max-lg-border-top pb-[49px] lg:bg-secondary-100 lg:rounded-[10px] lg:mb-[24px] order-3 lg:order-2">
                            <div class="logo-black-box-125x48 mb-[12px]">
                                <img src="@/assets/img/logo-tink-ws.png">
                            </div>
                            <h6 class="leading-[24px] font-[700] mb-[18px]">About TinkWs</h6>
                            <p class="text-secondary-500 leading-[18px] font-[600]">
                                TinkWs is the best place to go when you need instant engagement services on social media. Offering a wide variety of packages for Instagram, Youtube, Facebook, Twitter, Soundcloud &amp; Tiktok.
                            </p>
                        </div>

                        <div class="lg:px-[32px] lg:py-[35px] lg:bg-secondary-100 lg:rounded-[10px] order-1 lg:order-3">
                            <div class="flex justify-between mb-[11px]">
                                <h6 class="leading-[24px] font-[700]">Rate this article</h6>
                                <div class="rating-input pb-[5px] px-[4px]" style="pointer-events:none;">
                                    <input type="radio" checked="" name="rating" id="rating-5">
                                    <label for="rating-5"></label>
                                    <input type="radio" name="rating" id="rating-4">
                                    <label for="rating-4"></label>
                                    <input type="radio" name="rating" id="rating-3">
                                    <label for="rating-3"></label>
                                    <input type="radio" name="rating" id="rating-2">
                                    <label for="rating-2"></label>
                                    <input type="radio" name="rating" id="rating-1">
                                    <label for="rating-1"></label>
                                </div>
                            </div>
                            <span class="text-secondary-500 leading-[18px] font-[600]">
                                4.9/5 based on 4028 reviews
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
export default {

}
</script>

<style>

</style>