<template>
    <div class="search-input-container ">
        <div :class="[smallVariant ? 'max-w-[690px] mx-auto w-full full-width-override' : 'col-12' ]">
            <div class="row row-override" :class="{ 'items-center mb-[20px]' : smallVariant }">
                <div :class="[smallVariant ? 'col-lg-6 col-override' : 'col-md-6' ]">
                    <div class="form-group">
                        <label class="input-wraper input-wrapper-ico--left relative" :class="{ 'has-error':searchError }">
                            <div v-if="searchError" class="input-wrapper-error">{{ searchError }}</div>
                            <span class="input-icon text-primary-500" v-html="svgTemplate">
                            </span>
                            <input type="text" class="input font-semibold" :placeholder="placeholder" v-model="inputValue">
                        </label>
                    </div>
                </div>
                <div :class="[smallVariant ? 'col-lg-6 hidden lg:block col-override' : 'col-md-6 ' ]">
                    <a href="#" class="btn btn--primary pl-[56px] pr-[27px] py-[20px] icon-btn rounded-[8px] w-full h-full"
                        @click.prevent="submit">
                        <div class="icon-left">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2 11.5333C16.2 14.1107 14.1107 16.2 11.5333 16.2C8.956 16.2 6.86667 14.1107 6.86667 11.5333C6.86667 8.956 8.956 6.86667 11.5333 6.86667C14.1107 6.86667 16.2 8.956 16.2 11.5333ZM5 11.5333C5 15.1416 7.92507 18.0667 11.5333 18.0667C13.0008 18.0667 14.3554 17.5828 15.446 16.766L17.4067 18.7266C17.7712 19.0911 18.3621 19.0911 18.7266 18.7266C19.0911 18.3621 19.0911 17.7712 18.7266 17.4067L16.766 15.446C17.5828 14.3554 18.0667 13.0008 18.0667 11.5333C18.0667 7.92507 15.1416 5 11.5333 5C7.92507 5 5 7.92507 5 11.5333Z" fill="currentColor"></path>
                                <mask id="mask0_82_4320" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="5" y="5" width="14" height="14">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2 11.5333C16.2 14.1107 14.1107 16.2 11.5333 16.2C8.956 16.2 6.86667 14.1107 6.86667 11.5333C6.86667 8.956 8.956 6.86667 11.5333 6.86667C14.1107 6.86667 16.2 8.956 16.2 11.5333ZM5 11.5333C5 15.1416 7.92507 18.0667 11.5333 18.0667C13.0008 18.0667 14.3554 17.5828 15.446 16.766L17.4067 18.7266C17.7712 19.0911 18.3621 19.0911 18.7266 18.7266C19.0911 18.3621 19.0911 17.7712 18.7266 17.4067L16.766 15.446C17.5828 14.3554 18.0667 13.0008 18.0667 11.5333C18.0667 7.92507 15.1416 5 11.5333 5C7.92507 5 5 7.92507 5 11.5333Z" fill="white"></path>
                                </mask>
                                <g mask="url(#mask0_82_4320)">
                                </g>
                            </svg>

                        </div>
                        <span>Search</span>
                    </a>
                </div>
            </div>
        </div>

        <div v-if="smallVariant" class="mt-auto max-w-[690px] mx-auto w-full lg:hidden full-width-override">
            <a href="#" class="btn btn--primary pl-[56px] pr-[27px] py-[20px] icon-btn rounded-[8px] w-full"
                @click.prevent="submit">
                <div class="icon-left">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2 11.5333C16.2 14.1107 14.1107 16.2 11.5333 16.2C8.956 16.2 6.86667 14.1107 6.86667 11.5333C6.86667 8.956 8.956 6.86667 11.5333 6.86667C14.1107 6.86667 16.2 8.956 16.2 11.5333ZM5 11.5333C5 15.1416 7.92507 18.0667 11.5333 18.0667C13.0008 18.0667 14.3554 17.5828 15.446 16.766L17.4067 18.7266C17.7712 19.0911 18.3621 19.0911 18.7266 18.7266C19.0911 18.3621 19.0911 17.7712 18.7266 17.4067L16.766 15.446C17.5828 14.3554 18.0667 13.0008 18.0667 11.5333C18.0667 7.92507 15.1416 5 11.5333 5C7.92507 5 5 7.92507 5 11.5333Z" fill="currentColor"></path>
                        <mask id="mask0_82_4320" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="5" y="5" width="14" height="14">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2 11.5333C16.2 14.1107 14.1107 16.2 11.5333 16.2C8.956 16.2 6.86667 14.1107 6.86667 11.5333C6.86667 8.956 8.956 6.86667 11.5333 6.86667C14.1107 6.86667 16.2 8.956 16.2 11.5333ZM5 11.5333C5 15.1416 7.92507 18.0667 11.5333 18.0667C13.0008 18.0667 14.3554 17.5828 15.446 16.766L17.4067 18.7266C17.7712 19.0911 18.3621 19.0911 18.7266 18.7266C19.0911 18.3621 19.0911 17.7712 18.7266 17.4067L16.766 15.446C17.5828 14.3554 18.0667 13.0008 18.0667 11.5333C18.0667 7.92507 15.1416 5 11.5333 5C7.92507 5 5 7.92507 5 11.5333Z" fill="white"></path>
                        </mask>
                        <g mask="url(#mask0_82_4320)">
                        </g>
                    </svg>

                </div>
                <span>Search</span>
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            smallVariant: {
                type: Boolean,
                default: false
            },
            placeholder: {
                type: String,
                default: 'placeholder'
            },
            svgName: {
                type: String,
                default: "instagram"
            },
            callBack: {
                type: Function,
                default: () => {}
            }
        },
        data() {
            return {
                inputValue: '',
                searchError: false,
            }
        },
        methods: {
            submit() {
                if(!this.inputValue.length) {
                    this.searchError = "Field can not be empty";
                    return;
                }
                console.log(this.inputValue)
                this.callBack(this.inputValue)
            }
        },
        computed: {
            svgTemplate() {
                if (this.svgName === 'instagram') {
                    return `<svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2102 5.64967H18.7554C21.8251 5.65276 24.313 8.14021 24.3166 11.2099V18.7551C24.3135 21.8249 21.826 24.3127 18.7563 24.3163H11.2111C8.14138 24.3132 5.65351 21.8258 5.6499 18.7561V11.2109C5.65299 8.14115 8.14045 5.65327 11.2102 5.64967ZM21.3602 21.36C22.051 20.6691 22.4391 19.7321 22.4391 18.7551V11.2109C22.4391 9.17673 20.7904 7.5276 18.7563 7.52708H11.2111C9.17697 7.52708 7.52784 9.1758 7.52732 11.2099V18.7551C7.52732 20.7893 9.17604 22.4384 11.2102 22.4389H18.7554C19.7324 22.4389 20.6694 22.0508 21.3602 21.36Z" fill="currentColor"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1562 14.9825C10.1599 12.3184 12.3184 10.1594 14.9826 10.1553V10.1562C17.6474 10.1588 19.8076 12.3176 19.8117 14.9825C19.8086 17.6478 17.6487 19.8076 14.9835 19.8107C12.3186 19.8071 10.1593 17.6474 10.1562 14.9825ZM12.0327 14.9835C12.0327 16.6126 13.3534 17.9333 14.9826 17.9333C16.6117 17.9333 17.9324 16.6126 17.9324 14.9835C17.9324 13.3543 16.6117 12.0336 14.9826 12.0336C13.3534 12.0336 12.0327 13.3543 12.0327 14.9835Z" fill="currentColor"></path>
                                <circle cx="19.8201" cy="10.1917" r="1.15656" fill="currentColor"></circle>
                            </svg>`
                }
                if (this.svgName === 'tikTok') {
                    return `<svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.4795 13.9554C20.3829 13.9643 20.2857 13.9702 20.1877 13.9702C19.0778 13.9702 18.1022 13.4 17.5343 12.5366C17.5343 14.8002 17.5343 17.3765 17.5343 17.4195C17.5343 19.4129 15.918 21.0284 13.9253 21.0284C11.9327 21.0277 10.3164 19.4114 10.3164 17.4188C10.3164 15.4254 11.9327 13.8099 13.9253 13.8099C14.0011 13.8099 14.0746 13.8165 14.1481 13.821V15.5998C14.0738 15.5909 14.0018 15.5776 13.9253 15.5776C12.9082 15.5776 12.0834 16.4024 12.0834 17.4195C12.0834 18.4366 12.9075 19.2615 13.9253 19.2615C14.9432 19.2615 15.8415 18.4597 15.8415 17.4425C15.8415 17.4017 15.8593 9.14966 15.8593 9.14966H17.5588C17.7184 10.6694 18.9456 11.8692 20.4795 11.979V13.9554Z" fill="currentColor"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5837 5.64966H9.38372C7.32186 5.64966 5.65039 7.32113 5.65039 9.38299V20.583C5.65039 22.6449 7.32186 24.3163 9.38372 24.3163H20.5837C22.6456 24.3163 24.3171 22.6449 24.3171 20.583V9.38299C24.3171 7.32113 22.6456 5.64966 20.5837 5.64966ZM7.51706 9.38299C7.51706 8.35206 8.35279 7.51632 9.38372 7.51632H20.5837C21.6147 7.51632 22.4504 8.35206 22.4504 9.38299V20.583C22.4504 21.6139 21.6147 22.4497 20.5837 22.4497H9.38372C8.35279 22.4497 7.51706 21.6139 7.51706 20.583V9.38299Z" fill="currentColor"></path>
                            </svg>`
                }
                if (this.svgName === 'youTube') {
                    return `<svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.65039 14.983C5.65039 20.1378 9.82892 24.3163 14.9837 24.3163C20.1385 24.3163 24.3171 20.1378 24.3171 14.983C24.3171 9.82819 20.1385 5.64966 14.9837 5.64966C9.82892 5.64966 5.65039 9.82819 5.65039 14.983ZM22.4504 14.983C22.4504 19.1067 19.1075 22.4497 14.9837 22.4497C10.86 22.4497 7.51706 19.1067 7.51706 14.983C7.51706 10.8593 10.86 7.51632 14.9837 7.51632C19.1075 7.51632 22.4504 10.8593 22.4504 14.983Z" fill="currentColor"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2549 11.7406H17.7112C18.7817 11.7406 19.6497 12.5983 19.6497 13.6577V16.355C19.6497 17.4134 18.7817 18.2721 17.7112 18.2721H12.2549C11.1844 18.2721 10.3164 17.4143 10.3164 16.355V13.6577C10.3164 12.5993 11.1844 11.7406 12.2549 11.7406ZM13.8481 16.3419L16.4008 15.1379C16.4773 15.1015 16.4783 14.9942 16.4027 14.955L13.8509 13.6763C13.8193 13.6602 13.7815 13.6615 13.751 13.68C13.7206 13.6984 13.7019 13.7313 13.7016 13.7669V16.2495C13.7018 16.2846 13.7198 16.3172 13.7495 16.336C13.7792 16.3547 13.8164 16.3569 13.8481 16.3419Z" fill="currentColor"></path>
                            </svg>`
                }
            }
        },
    }
</script>

<style>
    .search-input-container {
        flex: auto;
        display: flex;
        flex-direction: column;
    }

    .input-wrapper-error {
    position: absolute;
    left: 0;
    top: -22px;
    color: red;
    }

    .input-wraper.has-error {
        border-color: red;
    }

    .small-with-width-500 {
        max-width: 500px;
    }

    .small-with-full-size .full-width-override {
        max-width: unset;
    }

    .small-with-width-500 .row-override, .small-with-full-size .row-override {
        flex-direction: column;
    }

    .small-with-full-size .row-override {
        margin-bottom: 0;
    }

    .small-with-width-500 .col-override, .small-with-full-size .col-override {
        width: 100%;
    }

    .small-with-width-500 .form-group, .small-with-full-size .form-group {
        margin-bottom: 15px;
    }
</style>

