<template>
    <div id="remove-item" class="modal modal-5 modal--show">
        <div class="modal__dialog modal__dialog--522">
            <div class="modal__content" v-click-outside="closePopup">
                <div class="modal__header justify-center pt-[67px] pb-[30px]">
                    <h4 class="font-bold text-[24px] leading-[32px] max-w-[323px] text-center">{{ payload?.message ? payload.message : 'Do you really want to remove this item?'}}</h4>
                </div>
                <div class="modal__body items-center pb-[83px]">
                    <a href="#" class="btn btn--primary px-[23px] py-[13px] rounded-[8px] mb-[12px] font-[700] text-[14px] leading-[19px] max-w-[281px] w-[100%]" data-close-modal="" @click.prevent="cancel">No, go back</a>
                    <a href="#" class="btn without--border px-[23px] py-[13px] rounded-[8px] font-[700] text-[14px] leading-[19px] max-w-[281px] w-[100%]" data-close-modal="" @click.prevent="confirm">
                        <span class="info">Yes, remove</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    props: ['payload'],
    computed: {},
    methods: {
        closePopup() {
            this.$emit('confirmRemove', false);
            this.$emit('close');
        },
        cancel() {
            this.payload?.onCancel && this.payload.onCancel()
            this.closePopup()
        },
        confirm() {
            this.payload?.onConfirm && this.payload.onConfirm()
            this.closePopup()
        }
    },
};
</script>