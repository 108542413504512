<template>
    <div>
        <div class="container mx-0 container-dash flex mt-[14px] d-none-1024  " style="display: none">
            <a href="#" class="btn w-[40px] h-[40px] bg-primary-100 rounded-[11px]">
                <!-- <img src="./img/icons/left-arrow-long.svg" alt="" /> -->
            </a>
        </div>
        <section class="py-[60px] bg-white-100 section flex flex-auto">
            <div class="container mx-0 container-dash">
                <div
                    class="heading-block items-center text-center max-w-[520px] mx-auto mw-full-576"
                >
                    <div class="flex items-center">
                        <h2 class="heading heading-2 font-bold">
                            It seems that you have already used a free trial before.
                        </h2>
                    </div>
                    <div class="mt-[30px]">
                        <p
                            class="def-text--2 font-semibold ff-fotnFamilyMontserrat text-secondary-500"
                        >
                            Would you like to buy TikTok likes instead?
                        </p>
                    </div>
                </div>
                <div class="mt-[30px] max-w-[330px] mw-full-576 mx-auto">
                    <router-link
                        :to="getPaidLink"
                        class="btn btn--lg2 btn--primary rounded-[8px] w-full justify-between mt-auto"
                    >
                        <span class="info text-[14px] font-bold">
                            Buy TikTok {{ productGroup.name }}
                        </span>
                        <span class="ico ico-24 ml-[10px]">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M3.31818 11.6361L21 11.6361"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                ></path>
                                <path
                                    d="M13.3636 19.2727L21 11.6364L13.3636 4"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                ></path>
                            </svg>
                        </span>
                    </router-link>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    props: ['productGroup'],
    computed: {
        getPaidLink() {
            const identifier = this.productGroup.identifier

            const obj = {
                'tiktok.likes': '/service-page/likes',
                'tiktok.followers': '/service-page/followers',
                'tiktok.views': '/service-page/views',
            }

            return obj[identifier]

        }
    }
}

</script>
