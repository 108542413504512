<template>
    <div class="accordeons flex flex-col">
        <div class="accordeons-container flex flex-col">
            <AccordeonItem
                v-for="(item, index) in faqMore" :key="index"
                :title="item.question"
                :content="`<p class='font-semibold def-text--1'>`+item.answer+'</p>'"
            />
        </div>
        
        <div class="accordeon-footer max-w-max mx-auto flex flex-col align-center" v-if="showMore && this.faqs.length > 4">
            <a href="#" class="btn flex-col text-center def-text--1 text-primary-500" @click.prevent="onShowMore">
                <span class="font-semibold">
                    {{ showMoreState ? 'Show less' : 'See more' }} 
                </span>
                <span class="ico ico-20 mt-[4px]" :class="showMoreState && 'rotated'">
                    <img src="@/assets/img/icons/ico-arrowdown2.svg" alt="arrow">
                </span>
            </a>
        </div>
    </div>
</template>
    
<script>
import { ordersApi } from '@/api/ordersApi';
import AccordeonItem from '@/components/Base/AccordeonItem.vue';
export default {
    components:{
        AccordeonItem
    },
    props: {
        params: {
            type: Object,
            default: {}
        },
        dataprops: {
            type: Object,
            default: function () {
                return null
            }
        },
        className: {
            type: String,
            default: null
        },
        showMore: {
            type: Boolean,
            default: true
        },
        faqDropdownWhite: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            showMoreState: false,
            faqs: []
        };
    },
    computed:{
        faqMore() {
            return this.showMoreState ? this.faqs : this.faqs.slice(0, 4)
        }
    },
    methods: {
        onShowMore(ev) {
            this.showMoreState = !this.showMoreState;
        },
        openAcordeon(item){
            item.isActive = !item.isActive;
        },
        openModal(name){
            this.$bus.$emit('modal',{
                name: name,
                overlay: true,
                payload: {},
            })
        },
        async fetchFaqs(params) {
            const response = await ordersApi.getFaqs(params);
            this.faqs = response
            console.log(response);
        }
    },
    mounted() {
        // this.$store.dispatch('FETCH_FAQ');
        this.fetchFaqs(this.params)
    },
    watch: {
        params(newValue, oldValue) {
            console.log('NEW VALUE', newValue, oldValue)
            if(newValue.type === oldValue.type && newValue.slug === oldValue.slug) {
                return
            }
            this.fetchFaqs(newValue)
        }
    }
};
</script>

<style scoped>
.rotated {
    transform: rotate(180deg);
    transform-origin: center;
}
</style>
<style>
/* .faq-dropdown.bg--white+.faq-content {
    padding-top: 30px;
} */
</style>
    