<template>
    <div id="get-like-supply" class="modal modal-24 modal--show">
        <div class="modal__dialog modal__dialog--818">
            <div class="modal__content" v-click-outside="closeModal">
                <div class="modal__body pt-[42px] pb-[0px] px-[20px] sm:px-[56px]">
                    <a href="#" class="btn btn-close justify-end" data-close-modal="" @click.prevent="closeModal()">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.4771 2.94469C17.9223 2.51467 18.1009 1.87788 17.9441 1.27907C17.7874 0.68025 17.3197 0.212602 16.7209 0.0558617C16.1221 -0.100878 15.4853 0.0776826 15.0553 0.522914L8.99914 6.57736L2.94469 0.522914C2.51467 0.0776826 1.87788 -0.100878 1.27907 0.0558617C0.68025 0.212602 0.212602 0.68025 0.0558617 1.27907C-0.100878 1.87788 0.0776826 2.51467 0.522914 2.94469L6.57736 8.99914L0.522914 15.0553C0.0776826 15.4853 -0.100878 16.1221 0.0558617 16.7209C0.212602 17.3197 0.68025 17.7874 1.27907 17.9441C1.87788 18.1009 2.51467 17.9223 2.94469 17.4771L8.99914 11.4226L15.0553 17.4771C15.4853 17.9223 16.1221 18.1009 16.7209 17.9441C17.3197 17.7874 17.7874 17.3197 17.9441 16.7209C18.1009 16.1221 17.9223 15.4853 17.4771 15.0553L11.4226 8.99914L17.4771 2.94469Z" fill="#3A4C66"></path>
                        </svg>
                    </a>
                    <div class="get-likeSupply">
                        <div class="get-likeSupply__text-block px-[0px] sm:px-[56px]">
                            <h2 class="mb-[18px] text-[24px] ms:text-[32px] leading-[32px] ms:leading-[40px]">Get TinkWs App
                            </h2>
                            <p class="mb-[60px] text-[16px] leading-[18px] font-[600] w-[278px] text-secondary-500">One stop
                                application for all your social media engagement needs.</p>
                            <a href="#" class="btn btn--primary pl-[56px] px-[27px] py-[20px] icon-btn rounded-[8px]">
                                <div class="icon-left">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.4927 10.521L13.326 11.641V6C13.326 5.44772 12.8601 5 12.2853 5C11.7105 5 11.2445 5.44772 11.2445 6V11.641L10.0789 10.52C9.81599 10.2671 9.43264 10.1681 9.07321 10.2604C8.71379 10.3526 8.4329 10.6221 8.33636 10.9674C8.23982 11.3126 8.34229 11.6811 8.60517 11.934L11.5495 14.763C11.7442 14.9512 12.009 15.057 12.2853 15.057C12.5615 15.057 12.8263 14.9512 13.0211 14.763L15.9654 11.934C16.3595 11.5414 16.3534 10.9176 15.9517 10.5322C15.5499 10.1467 14.9008 10.1417 14.4927 10.521ZM19.5705 18C19.5705 17.4477 19.1046 17 18.5298 17H6.04075C5.46596 17 5 17.4477 5 18C5 18.5523 5.46596 19 6.04075 19H18.5298C19.1046 19 19.5705 18.5523 19.5705 18Z" fill="currentColor"></path>
                                    </svg>
                                </div>
                                <span>Download Now</span>
                            </a>
                        </div>
                        <div class="flex justify-center">
                            <img class="d-none-mw-1024 " src="@/assets/img/decor-img.png">
                            <img class="d-none-min-1024" src="@/assets/img/mobile.png">
                        </div>
                        <div class="px-[70px] sm:px-[56px] d-none-min-1024">
                            <a href="#" class="btn btn--primary pl-[56px] px-[27px] py-[20px] icon-btn rounded-[8px] mt-[60px] whitespace-nowrap">
                                <div class="icon-left">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.4927 10.521L13.326 11.641V6C13.326 5.44772 12.8601 5 12.2853 5C11.7105 5 11.2445 5.44772 11.2445 6V11.641L10.0789 10.52C9.81599 10.2671 9.43264 10.1681 9.07321 10.2604C8.71379 10.3526 8.4329 10.6221 8.33636 10.9674C8.23982 11.3126 8.34229 11.6811 8.60517 11.934L11.5495 14.763C11.7442 14.9512 12.009 15.057 12.2853 15.057C12.5615 15.057 12.8263 14.9512 13.0211 14.763L15.9654 11.934C16.3595 11.5414 16.3534 10.9176 15.9517 10.5322C15.5499 10.1467 14.9008 10.1417 14.4927 10.521ZM19.5705 18C19.5705 17.4477 19.1046 17 18.5298 17H6.04075C5.46596 17 5 17.4477 5 18C5 18.5523 5.46596 19 6.04075 19H18.5298C19.1046 19 19.5705 18.5523 19.5705 18Z" fill="currentColor"></path>
                                    </svg>
                                </div>
                                <span>Download Now</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    computed: {},
    methods: {
        closeModal() {
            this.$bus.$emit("modal", {
                name: "",
                payload: {},
            });
        },
    },
};
</script>

<style>

</style>