import axios from 'axios';
import { translate } from '../helpers/functions.js';

export default class API {
    constructor() {
        this.notifications = [];
        this.balanceHistory = [];
        this.orderHistory = [];
        this.discounts = [];

        this.notificationsLoaded = false;
        this.balanceHistoryLoaded = false;
        this.orderHistoryLoaded = false;
        this.discountsLoaded = false;

        this.notificationsPage = 2;
        this.balanceHistoryPage = 2;
        this.orderHistoryPage = 2;
        this.discountsPage = 2;
    };

    // Notifications
    async loadNotifications() {
        if (this.notificationsLoaded) return {
            success: true,
            data: this.notifications
        }

        try {
            let result = (await axios.get('/notifications/more', {
                params: {
                    page: this.notificationsPage
                }
            })).data;

            this.notifications.push(...result.data.items);
            this.notificationsLoaded = result.data.is_last;
            this.notificationsPage += 1;
        } catch (error) {
            console.error('Notifications request error:', error);

            return {
                success: false,  
                message: error.response?.data?.message || 'Server error'
            };
        }

        return this.notifications;
    };

    setNotifications(payload) {
        this.notifications = payload;

        return this.notifications;
    };

    getNotifications() {
        return this.notifications;
    };

    // Balance history
    async loadBalanceHistory() {
        if (this.balanceHistoryLoaded) return {
            success: true,
            data: this.balanceHistory
        }

        try {
            let result = (await axios.get('/profile/more-balance-history', {
                params: {
                    page: this.balanceHistoryPage
                }
            })).data;

            this.balanceHistory.push(...result.data.items);

            this.balanceHistoryLoaded = result.data.is_last;
            this.balanceHistoryPage += 1;
        } catch (error) {
            console.error('Balance history request error:', error);

            return {
                success: false,
                message: error.response?.data?.message || 'Server error'
            };
        }

        return {
            success: true,
            data: this.balanceHistory
        };
    };

    setBalanceHistory(payload) {
        this.balanceHistory = payload;

        return this.balanceHistory;
    };

    getBalanceHistory() {
        return this.balanceHistory;
    };

    async loadOrders() {
        let result = false;

        if (window.orders_is_last) return { is_last: true }

        if (!window.lastOrdersPage) {
            window.lastOrdersPage = 2;
        }

        try {
            result = (await axios.get('/profile/more-orders', {
                params: {
                    page: window.lastOrdersPage
                }
            })).data;

            window.orders_is_last = result.data.is_last;
            window.lastOrdersPage += 1;
        } catch (error) {
            console.error('Orders request error:', error);
            result = {
                success: false,
                message: error.response?.data?.message || 'Server error'
            }
        }

        return result;
    };

    async loadMoreDiscounts() {
        let result = false;

        if (window.discounts_is_last) return { is_last: true }

        if (!window.lastDiscountsPage) {
            window.lastDiscountsPage = 2;
        }

        try {
            result = (await axios.get('/profile/more-discounts', {
                params: {
                    page: window.lastDiscountsPage
                }
            })).data;

            window.discounts_is_last = result.data.is_last;
            window.lastDiscountsPage += 1;
        } catch (error) {
            console.error('Discounts request error:', error);
            result = {
                success: false,
                message: error.response?.data?.message || 'Server error'
            }
        }

        return result;
    };

    async loadTransactions() {
        let result = false;

        if (window.transactions_is_last) return { is_last: true }

        if (!window.lastTransactionsPage) {
            window.lastTransactionsPage = 2;
        }

        try {
            result = (await axios.get(`/profile/more-transactions`, {
                params: {
                    page: window.lastTransactionsPage
                }
            })).data;

            window.transactions_is_last = result.data.is_last;
            window.lastTransactionsPage += 1;
        } catch (error) {
            console.error('Transactions request error:', error);
            result = {
                success: false,  
                message: error.response?.data?.message || 'Server error'
            }
        }

        return result;
    };

    async loadOrderById(id) {
        let result = false;

        try {
            result = (await axios.get(`/order-fetch/${id}`)).data;
        } catch (error) {
            console.error('Orders request error:', error);
            result = {
                success: false,
                message: error.response?.data?.message || 'Server error'
            }
        }

        return result;
    };

    async updateName({ firstName, lastName }) {
        let result = false;

        try {
            result = (await axios.put('/profile', {
                first_name: firstName,
                last_name: lastName
            })).data;
        } catch (error) {
            let response = error.response.data;

            console.error('Update name error:', error);
            result = {
                success: false,
                message: response.message || 'Server error'
            }
        }

        return result;
    };

    async sendConfirmationCode({ email }) {
        let result = false;

        try {
            result = (await axios.post('/profile/send-confirm-code', {
                ...(email ? { email } : {})
            })).data;
        } catch (error) {
            let response = error.response.data;

            console.error('Send confirm code error:', error);
            result = {
                success: false,
                message: response.message || response.email?.[0] || 'Server error'
            }
        }

        return result;
    }

    async updateEmail({ email, code, publicCode }) {
        let result = false;

        try {
            result = (await axios.put('/profile/email', {
                email, code, publicCode
            })).data;
        } catch (error) {
            let response = error.response.data;

            console.error('Update email error:', error);
            result = {
                success: false,
                message: response.message || response.email?.[0] || 'Server error'
            }
        }

        return result;
    };

    async changePassword({ password, newPassword, confirmPassword }) {
        let result = false;

        try {
            result = (await axios.put('/profile/password', {
                password: password,
                new_password: newPassword,
                new_password_confirmation: confirmPassword
            })).data;
        } catch (error) {
            console.error('Update password error:', error);
            result = {
                success: false,
                message: error.response?.data?.message || 'Server error'
            }
        }

        return result;
    };
    
    async updateProfileValue(payload) { // this method usually calling only with one argument from the list below
        /*
            notify_discount_expire_days
            Notify_discount_expire (0\1)
            notify_deals_and_discounts (0\1)
            notify_giftbox_ready (0\1)
            notify_cashback (0\1)
            tfa_login (0\1)
            is_personal (0\1)
        */ 

        let result = false;
        let paramName = Object.keys(payload)[0];

        try {
            result = (await axios.put('/profile/value', {
                ...payload
            })).data;
        } catch (error) {
            let response = error.response.data;

            console.error('Update profile value error:', error);
            result = {
                success: false,
                message: response.message || response[paramName]?.[0] || 'Server error'
            }
        }

        return result;
    };

    async updateBillingInfo(payload) {
        let result = false;

        try {
            let { first_name, last_name, country, city, address, company_name, vat_number, vat_registered_business } = payload;

            result = (await axios.put('/profile/address', {
                vat_registered_business,
                first_name,
                last_name,
                country,
                city,
                address,
                ...(company_name ? { company_name } : {}), // if it's business acc
                ...(vat_number ? { vat_number } : {}), // if it's business acc
            })).data;
        } catch (error) {
            let response = error.response.data;
            let message = response.message
                ? response.message
                : Object.keys(response).length ? Object.values(response)[0][0] : 'Server error'

            console.error('Update billing info error:', error);
            result = {
                success: false,
                message: message
            }
        }

        return result;
    };

    async createAffiliator(payload) {
        let { traffic_source, target_audience_region, promotion_services, is_company, company_info } = payload;
        let result = false;

        try {
            result = (await axios.post('/affiliators', {
                traffic_source,
                target_audience_region,
                promotion_services,
                is_company,
                company_info
            })).data;
        } catch (error) {
            console.error('Create affiliator error:', error);
            result = {
                success: false,
                message: 'Server error'
            }
        }

        return result;
    };

    async affiliatorPayout({ affiliator_payout_method_id, amount, agreement }) {
        let result = false;

        try {
            result = (await axios.post('/affiliators/payout', {
                affiliator_payout_method_id,
                amount,
                agreement
            })).data;
        } catch (error) {
            let response = error.response.data;

            console.error('Affiliator payout error:', error);
            result = {
                success: false,
                message: response?.amount?.[0] || response?.agreement?.[0] || response.message || 'Server error'
            }
       }

       return result;
    };

    async updateAffiliatorLink({ tag }) {
        let result = false;

        try {
            result = (await axios.put('/affiliators/link', {
                tag
            })).data;
        } catch (error) {
            let response = error.response.data;

            console.error('Update affiliator link error:', error);
            result = {
                success: false,
                message: response?.link?.[0] || response.message || 'Server error'
            }
       }

       return result;
    };
    
    async addPayoutMethod({ type_id, data }) {
        let result = false;

        try {
            result = (await axios.post('/affiliator-payout-methods', {
                type_id, data
            })).data;
        } catch (error) {
            let response = error.response.data;

            console.error('Add payment method error:', error);
            result = {
                success: false,
                message: response.message || response || 'Server error'
            }
       }

       return result;
    };
    
    async deletePayoutMethod({ id }) {
        let result = false;

        try {
            result = (await axios.delete(`/affiliator-payout-methods/${id}`)).data;
        } catch (error) {
            let response = error.response.data;

            console.error('Delete payment method error:', error);
            result = {
                success: false,
                message: response.message || 'Server error'
            }
       }

       return result;
    };
    
    async addUserSocialMedia({ platformId, name, image }) {
        let result = false;

        try {
            result = (await axios.post('/profile/value', {
                params: {
                    platform_id: platformId,
                    name: name,
                    image: image
                }
            })).data;
        } catch (error) {
            console.error('Create user social media error:', error);
            result = {
                success: false,
                message: error.response?.data?.message || 'Server error'
            }
        }

        return result;
    };

    async updateUserSocialmedia({ id, toDelete=false }) {
        let result = false;

        try {
            result = (await axios.post(`/user-social-medias/${id}`, {
                params: {
                    is_active: toDelete ? 0 : 1
                }
            })).data;
        } catch (error) {
            console.error('Update user social media error:', error);
            result = {
                success: false,
                message: error.response?.data?.message || 'Server error'
            }
        }

        return result;
    };

    async loadAffiliatorOrders() {
        let result = false;

        if (window.is_affiliator_orders_last) return { is_last: true }

        if (!window.affiliatorsOrderslastPage) {
            window.affiliatorsOrderslastPage = 2;
        }

        try {
            result = (await axios.get('/affiliators/more-orders', {
                params: {
                    page: window.affiliatorsOrderslastPage
                }
            })).data;

            window.is_affiliator_orders_last = result.is_last;
            window.affiliatorsOrderslastPage += 1;
        } catch (error) {
            console.error('Affiliator orders request error:', error);
            result = {
                success: false,
                message: error.response?.data?.message || 'Server error'
            }
        }

        return result;
    };

    async openGiftBox() {
        let result = false;

        try {
            result = (await axios.put('/giftboxes/open')).data;
        } catch (error) {
            let response = error.response.data;

            console.error('Update user social media error:', error);
            result = {
                success: false,
                message: response.message || 'Server error'
            }
        }

        return result;
    };
    
    async requestFeature({ title, body }) {
        let result = false;

        try {
            result = (await axios.post('/request-features', {
                title,
                body
            })).data;
        } catch (error) {
            let response = error.response.data;

            console.error('Request a feature error:', error);
            result = {
                success: false,
                message: response.message || 'Server error'
            }
        }

        return result;
    };

    async sendReview(payload) {
        let result = false;
        let { url_hash, items } = payload;

        try {
            result = (await axios.post(`/orders/${url_hash}/review`, {
                name: null,
                items: items
            })).data;
        } catch (error) {
            let response = error.response.data;

            console.error('Send review error:', error);
            result = {
                success: false,
                message: response.message || 'Server error'
            }
        }

        return result;
    }
    
    async checkEmail(email) {
        let result = false;

        try {
            result = (await axios.post(`/email/exists`, {
                email
            })).data;
        } catch (error) {
            console.error('Send confirmation code error:', error);
            let response = error.response.data;

            result = {
                success: false,
                message: response.message || response.email?.[0] || 'Server error'
            }
        }

        return result
    };

    async resendEmailConfirmation() {
        let result = false;

        try {
            result = (await axios.post(`/email/resend`)).data;
        } catch (error) {
            console.error('Send confirmation code error:', error);
            result = {
                success: false,
                message: response.message || 'Server error'
            }
        }

        return result
    };

    async sendVerifyCode() {
        let result = false;

        try {
            result = (await axios.post(`/profile/send-confirm-code`)).data;
        } catch (error) {
            console.error('Send verify code error:', error);
            result = {
                success: false,
                message: response.message || 'Server error'
            }
        }

        return result
    }

    async verifyCode(payload) {
        let result = false;
        let { token, code } = payload;

        try {
            result = (await axios.post(`/profile/verify-confirm-code`, {
                publicToken: token,
                code
            })).data;
        } catch (error) {
            let response = error.response.data;

            console.error('Send review error:', error);
            result = {
                success: false,
                message: response.message || 'Server error'
            }
        }

        return result;
    }
    
    async cancelAffiliatorPayout(affiliatorPayoutId) {
        let result = false;

        try {
            result = (await axios.put(`/affiliators/payouts/${affiliatorPayoutId}/cancel`,)).data;
        } catch (error) {
            let response = error.response.data;

            console.error('Cancel payout error:', error);
            result = {
                success: false,
                message: response.message || 'Server error'
            }
        }

        return result;
    }
    
    async userLogin({ email, password, tfaCode, code }) {
        let result = false;

        try {
            result = (await axios.post(`/login`, {
                email: email,
                password: password,
                '2fa_code': tfaCode,
                code: code,
            })).data;
        } catch (error) {
            let response = error.response.data;

            console.error('User login error:', error);
            result = {
                success: false,
                message: response.message || response.email || response.password || 'Server error'
            }
        }

        return result;
    }
}