<template>
    <section class="section pt-[25px] flex-auto flex lg:block">
        <div class="container mx-0 container-dash flex flex-col flex-auto">
            <h4 class="text-center lg:text-[40px] mb-[20px]">
                <div>Instagram</div>
                <span class="lg:text-primary-500">
                    Line Break Generator
                </span>
            </h4>
            <p class="def-text--2 font-[600] text-secondary-500 text-center mb-[40px]">
                Just enter desired data with line breaks 
                and hit the copy button.
            </p>
            <div class="max-w-[530px] mx-auto w-full">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group flex flex-col">
                            <label class="w-full input-wraper--label input-wraper h-[215px]">
                                <textarea v-model="textValue" ref="textAreaMain" class="input h-full px-[15px] py-[20px] font-semibold" placeholder="Your caption here"></textarea>
                                <div class="label-input">
                                    <span>Your caption here</span>
                                </div>
                            </label>
                            <a href="#"  @click.prevent="copyToClipboard" class="mt-[27px] w-full icon-btn px-[23px] btn btn--lg btn--primary rounded-[8px]">
                                <span v-if="!copied" class="ico-24 icon-left mr-[20px] flex">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_157_7203)">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.6192 11.5238V7.7143C19.6192 5.87335 18.1268 4.38097 16.2859 4.38097H7.71443C5.87348 4.38097 4.38109 5.87335 4.38109 7.7143V16.2857C4.38109 18.1267 5.87348 19.6191 7.71443 19.6191H12.4763C13.2653 19.6191 13.9049 18.9795 13.9049 18.1905V16.2857C13.9049 14.4448 15.3973 12.9524 17.2382 12.9524H18.1906C18.9796 12.9524 19.6192 12.3128 19.6192 11.5238ZM19.5493 13.4793C19.164 13.7475 18.6957 13.9048 18.1906 13.9048H17.2382C15.9233 13.9048 14.8573 14.9708 14.8573 16.2857V18.1905C14.8573 18.5927 14.7576 18.9716 14.5815 19.3038C17.1897 18.5005 19.1608 16.2439 19.5493 13.4793V13.4793ZM7.71442 3.42859H16.2859C18.6528 3.42859 20.5716 5.34737 20.5716 7.7143V12.4762C20.5716 16.9471 16.9472 20.5714 12.4763 20.5714H7.71442C5.34749 20.5714 3.42871 18.6527 3.42871 16.2857V7.7143C3.42871 5.34737 5.34749 3.42859 7.71442 3.42859Z" fill="currentColor"></path>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_157_7203">
                                                <rect width="24" height="24" fill="currentColor"></rect>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </span>
                                <span class="info text-[14px] font-[800]" >
                                    {{copied ? 'Copied' : 'Copy to clipboard' }}
                                </span>
                            </a>
                        </div>
                        <div class="max-w-[310px] mx-auto mt-[27px]">
                            <div class="w-full">
                                <h6 class="heading heading-6 font-[700]">
                                    Here are some emoji’s to make your 
                                    IG bio and captions attractive.
                                </h6>
                            </div>
                        </div>
                        
                        <div class="mt-[35px]">
                            <div class="form-group flex flex-col">
                                <!-- <label class="w-full input-wraper h-[215px]">
                                    <textarea class="input h-full p-[24px] font-semibold" readonly>ღ • ⁂ € ™ ↑ → ↓ ⇝ √ ∞ ░ ▲ ▶ ◀ ● ☀ ☁ ☂ ☃ ☄ ★ ☆ ☉ ☐ ☑ ☎ ☚ ☛ ☜ ☝ ☞ ☟ ☠ ☢ ☣ ☪ ☮ ☯ ☸ ☹ ☺ ☻ ☼ ☽ ☾ ♔ ♕ ♖ ♗ ♘ ♚ ♛ ♜ ♝ ♞ ♡ ♨ ♩ ♪ ♫ ♬ ✈ ✉ ✍ ✎ ✓ ✔ ✘ ✚ ✝ ✞ ✟ ✠ ✡ ✦ ✧ ✩ ✪ ✮ ✯ ✹ ✿ ❀ ❁ ❂ ❄ ❅ ❆ ❝ ❞ ❣ ❤ ❥ ❦ ❧ ➤ ツ ㋡ 卍 웃 Ⓐ Ⓑ Ⓒ Ⓓ Ⓔ Ⓕ Ⓖ Ⓗ Ⓘ Ⓙ Ⓚ Ⓛ Ⓜ Ⓝ Ⓞ Ⓟ Ⓠ Ⓡ Ⓢ Ⓣ Ⓤ Ⓥ Ⓦ Ⓧ Ⓨ Ⓩ ❦ ❣ ← ↞ ⇠ ⇐ ◄ ➞ ➜ ➠ » ⫸ ➼ ➺ ➤ ► ➥ ⇢ ➳ ↳ ✱ ⁕ ❋ ✻ ✽ ❃ ✿ ❊ ✓ ✔ ☑ ♥ ❤ ♡ ღ ★ ☆ ✦ ✧ ✩ ✶</textarea>
                                </label> -->
                                <div class="keyboard-imitation">
                                    <template v-for="(symbol, index) in symbols.split(' ')">
                                        <div :key="index" class="keyboard-imitation-button" @click="insertText(symbol)">{{symbol}}</div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { toolsApi } from "@/api/toolsApi";
import Loader from "@/components/Base/Loader";

export default {
    data(){
        return {
            textValue:null,
            copied:false,
            symbols:'ღ • ⁂ € ™ ↑ → ↓ ⇝ √ ∞ ░ ▲ ▶ ◀ ● ☀ ☁ ☂ ☃ ☄ ★ ☆ ☉ ☐ ☑ ☎ ☚ ☛ ☜ ☝ ☞ ☟ ☠ ☢ ☣ ☪ ☮ ☯ ☸ ☹ ☺ ☻ ☼ ☽ ☾ ♔ ♕ ♖ ♗ ♘ ♚ ♛ ♜ ♝ ♞ ♡ ♨ ♩ ♪ ♫ ♬ ✈ ✉ ✍ ✎ ✓ ✔ ✘ ✚ ✝ ✞ ✟ ✠ ✡ ✦ ✧ ✩ ✪ ✮ ✯ ✹ ✿ ❀ ❁ ❂ ❄ ❅ ❆ ❝ ❞ ❣ ❤ ❥ ❦ ❧ ➤ ツ ㋡ 卍 웃 Ⓐ Ⓑ Ⓒ Ⓓ Ⓔ Ⓕ Ⓖ Ⓗ Ⓘ Ⓙ Ⓚ Ⓛ Ⓜ Ⓝ Ⓞ Ⓟ Ⓠ Ⓡ Ⓢ Ⓣ Ⓤ Ⓥ Ⓦ Ⓧ Ⓨ Ⓩ ❦ ❣ ← ↞ ⇠ ⇐ ◄ ➞ ➜ ➠ » ⫸ ➼ ➺ ➤ ► ➥ ⇢ ➳ ↳ ✱ ⁕ ❋ ✻ ✽ ❃ ✿ ❊ ✓ ✔ ☑ ♥ ❤ ♡ ღ ★ ☆ ✦ ✧ ✩ ✶',
            loading: false,
            errorMessage: null,
        }
    },
    methods:{
        insertText( text ) {
            let txtarea = this.$refs.textAreaMain;
            let start = txtarea.selectionStart;
            let end = txtarea.selectionEnd;
            let finText = txtarea.value.substring(0, start) + text + txtarea.value.substring(end);
            txtarea.value = finText;
            this.textValue=finText
            txtarea.focus();
            txtarea.selectionEnd = ( start == end )? (end + text.length) : end ;
        },
        async copyToClipboard(){
            if(!this.textValue) return;

            try {
                this.errorMessage = null;
                this.loading = true;

                const res = await toolsApi.instagramLineBreakGenerator(this.textValue);
                
                if(res?.text){
                    navigator.clipboard.writeText(res.text).then(() => {
                        this.copied=true;
                        setTimeout(()=>{
                            this.copied=false
                        },1000)
                    })
                }
            } catch (error) {
                console.log('error:', error);
            } finally {
                this.loading = false;
            }

            
        }
    }
}
</script>
<style scoped>
    .keyboard-imitation{
        color: rgba(0,0,0,0.3);
        padding: 22px;
        font-weight: 600;
        border: 1.5px solid rgba(200,206,218, 0.375082);
        background-color: var(--white);
        display: flex;
        flex-wrap: wrap;
        -moz-user-select: none; 
        -khtml-user-select: none; 
        user-select: none; 
    }
    .keyboard-imitation .keyboard-imitation-button{
        cursor: pointer;
        margin: 2px 2px;
        
    }
</style>
<style >
    /* .input-wraper.input-wraper--label.fill-input:not(:has(label)) .input{
        padding-top: 0;
    } */
    .input-wraper.input-wraper--label:has( .label-input ) .input:not(:placeholder-shown) {
        padding-top: 35px;
    }
    .input-wraper.input-wraper--label:has( .label-input ):has(.input:not(:placeholder-shown)) .label-input {
        opacity: 1;
    }
</style>