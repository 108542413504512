<template>
    <div class="pt-[30px] swiper-reviews mx-container-576">
        <swiper class="swiper" :options="swiperOption">
        <swiper-slide class="swiper-slide pt-[17.5px] currentcolor">
            <div class="review">
                <div class="review--head">
                    <div class="rating">
                        <label for="star-1" class="rating-star ico-35">
                            <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                            </svg>
                            <input type="radio" name="star-1" id="star-1" class="rating-input">
                        </label>
                        <label for="star-2" class="rating-star ico-35">
                            <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                            </svg>
                            <input type="radio" name="star-2" id="star-2" class="rating-input">
                        </label>
                        <label for="star-3" class="rating-star ico-35">
                            <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                            </svg>
                            <input type="radio" name="star-3" id="star-3" class="rating-input">
                        </label>
                        <label for="star-3" class="rating-star ico-35">
                            <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                            </svg>
                            <input type="radio" name="star-3" id="star-3" class="rating-input">
                        </label>
                        <label for="star-3" class="rating-star ico-35">
                            <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                            </svg>
                            <input type="radio" name="star-3" id="star-3" class="rating-input">
                        </label>
                    </div>
                </div>
                <div class="review--body">
                    <div class="review--body--item">
                        <div class="ico ico-30 mr-[14px] d-none-360"><img src="@/assets/img/ico-quotes.svg" alt="ico-quotes"></div>
                        <div class="review-text font-semibold">
                            Not only is it easy to use, but it’s also very powerful. 
                            I’ve been consistently hitting the explore page for 
                            the past few weeks.
                        </div>
                    </div>
                    <div class="review--body--item">
                        <div class="review-image--container after-shape">
                            <div class="wrapper-image rounded-[10px] 
                            review-image flex flex-auto">
                                <img src="@/assets/img/photo-user-2.png" alt="photo-user-2">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </swiper-slide>
        <swiper-slide class="swiper-slide pt-[17.5px] currentcolor">
            <div class="review">
                <div class="review--head">
                    <div class="rating">
                        <label for="star-1" class="rating-star ico-35">
                            <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                            </svg>
                            <input type="radio" name="star-1" id="star-1" class="rating-input">
                        </label>
                        <label for="star-2" class="rating-star ico-35">
                            <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                            </svg>
                            <input type="radio" name="star-2" id="star-2" class="rating-input">
                        </label>
                        <label for="star-3" class="rating-star ico-35">
                            <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                            </svg>
                            <input type="radio" name="star-3" id="star-3" class="rating-input">
                        </label>
                        <label for="star-3" class="rating-star ico-35">
                            <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                            </svg>
                            <input type="radio" name="star-3" id="star-3" class="rating-input">
                        </label>
                        <label for="star-3" class="rating-star ico-35">
                            <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                            </svg>
                            <input type="radio" name="star-3" id="star-3" class="rating-input">
                        </label>
                    </div>
                </div>
                <div class="review--body">
                    <div class="review--body--item">
                        <div class="ico ico-30 mr-[14px] d-none-360"><img src="@/assets/img/ico-quotes.svg" alt="ico-quotes"></div>
                        <div class="review-text font-semibold">
                            Not only is it easy to use, but it’s also very powerful. 
                            I’ve been consistently hitting the explore page for 
                            the past few weeks.
                        </div>
                    </div>
                    <div class="review--body--item">
                        <div class="review-image--container after-shape">
                            <div class="wrapper-image rounded-[10px] 
                            review-image flex flex-auto">
                                <img src="@/assets/img/photo-user.png" alt="photo-user">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </swiper-slide>
        <swiper-slide class="swiper-slide pt-[17.5px] currentcolor">
            <div class="review">
                <div class="review--head">
                    <div class="rating">
                        <label for="star-1" class="rating-star ico-35">
                            <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                            </svg>
                            <input type="radio" name="star-1" id="star-1" class="rating-input">
                        </label>
                        <label for="star-2" class="rating-star ico-35">
                            <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                            </svg>
                            <input type="radio" name="star-2" id="star-2" class="rating-input">
                        </label>
                        <label for="star-3" class="rating-star ico-35">
                            <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                            </svg>
                            <input type="radio" name="star-3" id="star-3" class="rating-input">
                        </label>
                        <label for="star-3" class="rating-star ico-35">
                            <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                            </svg>
                            <input type="radio" name="star-3" id="star-3" class="rating-input">
                        </label>
                        <label for="star-3" class="rating-star ico-35">
                            <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                            </svg>
                            <input type="radio" name="star-3" id="star-3" class="rating-input">
                        </label>
                    </div>
                </div>
                <div class="review--body">
                    <div class="review--body--item">
                        <div class="ico ico-30 mr-[14px] d-none-360"><img src="@/assets/img/ico-quotes.svg" alt="ico-quotes"></div>
                        <div class="review-text font-semibold">
                            Not only is it easy to use, but it’s also very powerful. 
                            I’ve been consistently hitting the explore page for 
                            the past few weeks.
                        </div>
                    </div>
                    <div class="review--body--item">
                        <div class="review-image--container after-shape">
                            <div class="wrapper-image rounded-[10px] 
                            review-image flex flex-auto">
                                <img src="@/assets/img/photo-user-3.png" alt="photo-user-3">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </swiper-slide>
        </swiper>

        <div class="swiper-pagination  mt-[30px] swiper-pagination-bullets swiper-pagination-horizontal style-pagination" slot="pagination">
            <span class="swiper-pagination-bullet swiper-pagination-bullet-active" aria-current="true"></span>
            <span class="swiper-pagination-bullet"></span>
            <span class="swiper-pagination-bullet"></span>
        </div>  
    </div>
  </template>
  
  <script>
    import { swiper, swiperSlide } from 'vue-awesome-swiper'
    require('swiper/dist/css/swiper.css')
  
    export default {
      components: {
        swiper,
        swiperSlide
      },
      data() {
        return {
          swiperOption: {
            loop: true,
            infinity: true,
            slidesPerView: 'auto',
            spaceBetween: 40,
            centeredSlides: true,
            pagination: '.swiper-pagination',
            paginationClickable: true,
          }
        }
      }
    }
  </script>
  
  <style scoped>


    .swiper-slide {
      max-width: 600px;
      width: 70%;
    }

    .style-pagination {
            position: relative;
        }

    @media (max-width: 575.98px) {
        .swiper-slide {
            max-width: 100%;
            width: 100%;
        }
    }
    
  </style>