<template>
    <section class="py-5">
        <div class="container-px--lg">
            <div class="row gx-60 gy-20">
                <div class="col-lg-7 order-1 lg:order-none">
                    <div class="border-bottom  hidden lg:block pb-[30px] mb-[30px]">
                        <h1 class="mb-[16px] mt-[11px] leading-[60px]">Instagram <br><span class="text-primary-500">Username Generator</span></h1>
                        <p class="text-[#737276] font-[600]">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.</p>
                    </div>
                    <template v-if="usernamesList.length">
                        <h6 class="mb-8">{{ usernamesList.length }} names generated. Click Spin for more.</h6>
                        <div>
                            <ul class="columns-2 sm:columns-3 text-primary-500 font-semibold text-[15px] leading-[24px]">
                                <li v-for="(nickname, index) in usernamesList" :key="index + '-nickname'">{{ nickname }}</li>
                            </ul>
                        </div>
                    </template>
                    <div class="flex justify-center mt-[15px]" v-if="loading">
                        <Loader />
                    </div>
                </div>
                <div class="col-lg-5">
                    <h1 class="mb-[16px] text-center lg:hidden">Instagram <br>Username Generator</h1>
                    <p class="text-center lg:hidden mb-10">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.</p>
                    <h6 class="mb-[15px] hidden lg:block">Enter your details</h6>
                    <div class="row gy-13">
                        <div class="col-12">
                            <label class="input-wraper input-wraper--label">
                                <input type="text" class="input font-semibold" placeholder="Name or Nick Name" v-model="nicknameProps.name">
                            </label>
                        </div>
                        <div class="col-12">
                            <label class="input-wraper input-wraper--label">
                                <input type="text" class="input font-semibold" placeholder="What are you like?" v-model="nicknameProps.likes">
                            </label>
                        </div>
                        <div class="col-12">
                            <label class="input-wraper input-wraper--label">
                                <input type="text" class="input font-semibold" placeholder="Hobbies?" v-model="nicknameProps.hobbies">
                            </label>
                        </div>
                        <div class="col-12">
                            <label class="input-wraper input-wraper--label">
                                <input type="text" class="input font-semibold" placeholder="Things you like" v-model="nicknameProps.likes2">
                            </label>
                        </div>
                        <div class="col-12">
                            <label class="input-wraper input-wraper--label">
                                <input type="text" class="input font-semibold" placeholder="Important Words" v-model="nicknameProps.words">
                            </label>
                        </div>
                        <div class="col-12">
                            <a href="#" class="btn btn--primary px-[23px] py-[20px] rounded-[8px] w-full" @click.prevent="generateNickNames">
                                <span class="info">Spin</span>
                            </a>
                            <p class="mt-[5px] text-[red]" v-if="errorMessage">{{ errorMessage }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { toolsApi } from "@/api/toolsApi";
import Loader from "@/components/Base/Loader";

export default {
    name: "UsernameGenerator",
    components: {
        Loader,
    },
    data(){
        return{
            usernamesList: [],
            nicknameProps:{
                name: '',
                likes: '',
                hobbies: '',
                likes2: '',
                words: '',
            },
            loading: false,
            errorMessage: null,
        }
    },
    methods: {
        async generateNickNames(){
            try {
                this.errorMessage = null;
                this.loading = true;

                const res = await toolsApi.instagramUserNameGenerator(this.nicknameProps);

                if(res?.words?.length){
                    this.usernamesList = [...this.usernamesList, ...res?.words];
                } else if(res?.error?.message){
                    this.errorMessage = res?.error?.message;
                }
            } catch (error) {
                console.log('error:', error);
            } finally {
                this.loading = false;
            }
        }
    }
}
</script>