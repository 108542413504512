<template>
    <section class="pb-[25px] pt-[25px]">
        <div class="container mx-0 container-dash">
            <div class="flex items-center justify-center relative mb-[50px] mt-[14px] lg:hidden">
                <router-link to="/affiliate" class="btn w-[40px] h-[40px] bg-primary-100 rounded-[11px] left-0 absolute">
                    <img src="@/assets/img/left-arrow-long.svg" alt="">
                </router-link>
                <span class="def-text--1 font-semibold">Order details</span>
            </div>
            <div class="flex items-center justify-between mb-[25px]">
                <div class="flex items-center">
                    <router-link to="/affiliate" class="btn w-[40px] h-[40px] bg-primary-100 rounded-[11px] mr-[20px] hidden lg:flex">
                        <img src="@/assets/img/left-arrow-long.svg" alt="">
                    </router-link>
                    <h6>Withdraw funds</h6>
                </div>
            </div>
            <div class="row pb-[30px] mb-[20px]">
                <div class="col-lg-8">
					<div class="card mb-[10px]">
                            <h6 class="text-[12px] font-[600] mb-[17px]">TinkWs account balance</h6>
                            <span class="block underline text-primary-500 mb-[5px]">{{ dataprops.user.email }}</span>
                            <span class="block text-[12px] text-text font-[600]">
                                <span class="font-[700]">Processed:</span>
                                Instantly
                            </span>
                            
                        </div>
                    <template v-for="item in payoutMethods">
                        <div class="card mb-[10px]" :class="{'card-active': payoutMethod === item.id}" v-if="item.type_id == 1" :key="item.id + item.created_at" @click="payoutMethod = item.id">
                            <h6 class="text-[12px] font-[600] mb-[17px]">TinkWs account balance</h6>
                            <span class="block underline text-primary-500 mb-[5px]">{{ dataprops.user.email }}</span>
                            <span class="block text-[12px] text-text font-[600]">
                                <span class="font-[700]">Processed:</span>
                                Instantly
                            </span>
                            <span class="block text-[12px] text-text font-[600]" v-if="item.minValue">
                                <span class="font-[700]">Min:</span>
                                ${{ item.minValue | floatNumberWithSpaces }}
                            </span>
                        </div>

                        <div class="card mb-[10px]" :class="{'card-active': payoutMethod === item.id}" v-if="item.type_id == 2" :key="item.id + item.created_at" @click="payoutMethod = item.id">
                            <h6 class="text-[12px] font-[600] mb-[17px]">Balance automatic</h6>
                            <span class="block underline text-primary-500 mb-[5px]">{{ dataprops.user.email }}</span>
                            <span class="block text-[12px] text-text font-[600]" v-if="item.minValue">
                                <span class="font-[700]">Min:</span>
                                ${{ item.minValue | floatNumberWithSpaces }}
                            </span>
                        </div>

                        <div class="card mb-[10px]" :class="{'card-active': payoutMethod === item.id}" v-if="item.type_id == 6" :key="item.id + item.created_at" @click="payoutMethod = item.id">
                            <h6 class="text-[12px] font-[600] mb-[17px]">Bank Account</h6>
                            <span class="block text-primary-500 mb-[5px]">{{ item.data.iban }}</span>
                            <span class="block text-[12px] text-text font-[600] mb-[5px]">{{ item.data.full_name }}</span>
                            <span class="block text-[12px] text-text font-[600]">
                                <span class="font-[700]">Processed:</span>
                                Business days
                            </span>
                            <span class="block text-[12px] text-text font-[600]">
                               <div :class="{'d-flex w-full justify-content-between align-items-end': item.minValue}">
                                    <button
                                        type="button"
                                        class="btn btn--sm btn--outline grey-btn mt-20"
                                        :style="item.id == payoutMethod ? 'background: rgba(48, 75, 101, 0.7);' : ''"
                                        @click="methodToRemove = item.id; showConfirmDelete = true;"
                                    >
                                        <span class="ico mr-10" v-html="icons['trash-icon.svg']"></span>
                                        <span class="translate-wrapper">Delete</span>
                                    </button>

                                    <span class="fw-bold opacity-07" v-if="item.minValue">
                                        <span class="translate-wrapper">Min</span>: ${{ item.minValue | floatNumberWithSpaces }}
                                    </span>
                                </div>
                            </span>
                        </div>

                        <div class="card mb-[10px]" :class="{'card-active': payoutMethod === item.id}" v-if="item.type_id == 4" :key="item.id + item.created_at" @click="payoutMethod = item.id">
                            <h6 class="text-[12px] font-[600] mb-[17px]">PayPal</h6>
                            <span class="block underline text-primary-500 mb-[5px]">{{ item.data.email }}</span>
                            <span class="block text-[12px] text-text font-[600]">
                                <span class="font-[700]">Processed:</span> Business days
                            </span>
                            <span class="block text-[12px] text-text font-[600]">
                                <div :class="{'d-flex w-full justify-content-between align-items-end': item.minValue}">
                                    <button
                                        type="button"
                                        class="btn btn--sm btn--outline grey-btn mt-20"
                                        :style="item.id == payoutMethod ? 'background: rgba(48, 75, 101, 0.7);' : ''"
                                        @click="methodToRemove = item.id; showConfirmDelete = true;"
                                    >
                                        <span class="ico mr-10" v-html="icons['trash-icon.svg']"></span>
                                        <span class="translate-wrapper">Delete</span>
                                    </button>

                                    <span class="fw-bold opacity-07" v-if="item.minValue">
                                        <span class="translate-wrapper">Min</span>: ${{ item.minValue | floatNumberWithSpaces }}
                                    </span>
                                </div>
                            </span>
                        </div>

                        <div class="card mb-[10px]" :class="{'card-active': payoutMethod === item.id}" v-if="item.type_id == 5" :key="item.id + item.created_at" @click="payoutMethod = item.id">
                            <h6 class="text-[12px] font-[600] mb-[17px]">Crypto</h6>
                            <!-- <span class="block underline text-primary-500 mb-[5px]">lilahloselev@mail.com</span> -->
                            <span class="block text-[12px] text-text font-[600]">
                                <span class="font-[700]">Street address:</span> {{ item.data.address }}
                            </span>
                            <span class="block text-[12px] text-text font-[600]">
                                <span class="font-[700]">Currency:</span> {{ item.data.coin.toUpperCase() }}
                            </span>
                            <span class="block text-[12px] text-text font-[600]">
                                <span class="font-[700]">Processed:</span> Business days
                            </span>
                            <span class="block text-[12px] text-text font-[600]">
                                <div :class="{'d-flex w-full justify-content-between align-items-end': item.minValue}">
                                    <button
                                        type="button"
                                        class="btn btn--sm btn--outline grey-btn mt-20"
                                        :style="item.id == payoutMethod ? 'background: rgba(48, 75, 101, 0.7);' : ''"
                                        @click="methodToRemove = item.id; showConfirmDelete = true;"
                                    >
                                        <span class="ico mr-10" v-html="icons['trash-icon.svg']"></span>
                                        <span class="translate-wrapper">Delete</span>
                                    </button>

                                    <span class="fw-bold opacity-07" v-if="item.minValue">
                                        <span class="translate-wrapper">Min</span>: ${{ item.minValue | floatNumberWithSpaces }}
                                    </span>
                                </div>
                            </span>
                        </div>
                    </template>
                </div>
                <div class="col-lg-4">
                    <div class="card">
                        <h6 class="mb-[17px]">Amount</h6>
                        <label class="input-wraper input-wrapper-ico--left mb-[20px]">
                            <span class="input-icon text-primary-500 justify-center font-[600]">$</span>
                            <input type="text" class="input font-semibold pl-[0]" style="padding-left: 0;" placeholder="" v-model="selectedAmount">
                            <span class="max-amount">Max ${{ maxAmount | floatNumberWithSpaces }}</span>
                        </label>
                        <span class="block text-center text-[14px] text-text font-[600] mb-[20px]">*Transaction processing fees will be deducted from this amount.</span>
                        <a href="#" class="btn btn--primary px-[20px] py-[20px] icon-btn rounded-[8px] w-full" @click="payout">
                            <span>Withdraw</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { icons } from '@/helpers/icons.js';
import { translate } from '@/helpers/functions.js';
import { floatNumberWithSpaces } from '@/helpers/filters.js';

// import Dropdown from '../Base/Dropdown.vue';
// import ConfirmCloseModal from '../../promotion-service-panels/ConfirmCloseModal.vue';

export default {
    name: "WithdrawFundsModal",
	props: {
		dataprops: {
			type: Object,
			default: () => ({})
		},
	},
	components: {
		// Dropdown,
        // ConfirmCloseModal,
	},
	filters: {
		floatNumberWithSpaces
	},
    data: () => ({
		currencies: [{id: '1', title: 'BTC', value: 'btc'}, {id: '2', title: 'ETH', value: 'eth'}, {id: '3', title: 'USDT', value: 'usdt'}],
        icons,
		selectedAmount: '',
		addPaymentActive: false,
		paymentOption: '',
		iban: '',
		swiftCode: '',
		fullName: '',
		address: '',
		email: '',
		payoutMethod: null,
		isAgree: true,
		showConfirmDelete: false,
		methodToRemove: false,
		currency: '',
		receiverType: '',
		paymentData: {},
		errors: {},
		coin: '',
    }),
	computed: {
        payload(){
			console.log('props', this.dataprops);
            return {
                customPaymentOptions: this.dataprops?.general?.affiliatorPayoutMethodTypes.filter(el => !Boolean(el.is_default)).map(el => ({ id: el.id, name: el.name })),
                defaultPaymentOptions: this.dataprops?.general?.affiliatorPayoutMethodTypes.filter(el => el.is_default).map(el => ({ id: el.id, name: el.name })),
                affiliator: this.dataprops.affiliator
            }
        },
		customPaymentOptions: function() {
			return this.payload.customPaymentOptions.map((item) => ({ title: item.name, value: item.id }));
		},
		defaultPaymentOptions: function() {
			console.log('defaultPaymentOptions:', this.payload.defaultPaymentOptions);
			return this.payload.defaultPaymentOptions;
		},
		maxAmount: function() {
			return this.payload.affiliator?.available_earnings;
		},
		payoutMethods: function() {
			console.log('AFFILIATOR', this?.payload?.affiliator)
			return this.payload?.affiliator?.payout_methods.map((el) => ({
				...el,
				minValue: this.dataprops?.general?.affiliatorPayoutMethodTypes.find(itm => itm.id == el.type_id)?.min_withdrawal || false
			}));
		},
		isBalanceAutomaticSelected() {
			return this.payoutMethods && this.payoutMethod && this.payoutMethods.find(el => el.id == this.payoutMethod)?.type_id == 2;
		},
		payoutMethodTypeSelected() {
			this.payoutMethods && this.payoutMethod && this.payoutMethods.find(el => el.id == this.payoutMethod)?.type_id;
		},
		activePayoutMethod() {
			return this.payoutMethods.find(el => el.id == this.payoutMethod);
		},
		disableSubmitBtn() {
			return !this.payoutMethod || (this.isBalanceAutomaticSelected ? false : !this.selectedAmount) || this.disablePayout
		},
		errorsFormatted() {
			return Object.fromEntries(
				Object.entries(this.errors)
				.filter(([key, errors]) => errors)
				.map(([key, errors]) => {
					const ecxeptedErrors = ['data.email', 'data.address', 'data.full_address', 'data.full_name', 'data.iban', 'data.swift_code'];

					if (ecxeptedErrors.includes(key)) {
						return [key, errors.map(error => error.replace(key, key.split('.')[1])).join('<br/>')]
					} else {
						false
					}
				})
				.filter(el => el)
			);
		},
		disablePayout() {
			return (this.selectedAmount && this.activePayoutMethod.minValue && this.selectedAmount < parseFloat(this.activePayoutMethod.minValue));
		}
	},
	methods: {
		translate,
		resetPaymentData(event) {
			this.paymentData = {};
			this.errors = {};


			if (event.id == '5') {
				this.currency = this.currencies[0];
				this.paymentData = { coin: this.currency.value }
			}
		},
		async addPaymentMethod() {
			if (this.paymentOption.id == '5' && window.WAValidator) {
				let isValid;
				if (['btc', 'eth'].includes(this.paymentData.coin)) {
					isValid = window.WAValidator.validate(this.paymentData.address, this.paymentData.coin.toUpperCase());
				} else if (this.paymentData.coin == 'usdt') {
					isValid = [
						window.WAValidator.validate(this.paymentData.address, 'BTC'),
						window.WAValidator.validate(this.paymentData.address, 'ETH'),
					].some(el => el);
				}

				if (!isValid) {
					this.$set(this.errors, 'data.address', ['The address field is invalid']);
					return;
				}
			}

			const response = await this.$api.addPayoutMethod({
				type_id: this.paymentOption.id,
				data: this.paymentData
			});

			if (response.success) {
				let affiliatorObject = {
					...this.payload.affiliator,
					payout_methods: [...this.payload.affiliator.payout_methods, response.data]
				};

				this.$set(this.payload, 'affiliator', affiliatorObject);
				this.$set(this.dataprops, 'affiliator', affiliatorObject);
				this.resetAddPayment();

				setTimeout(() => {
					this.$bus.$emit('modal', { name: 'changes-saved', payload: { success: true, overlay: true, message: response.message } });
				}, 500);
			} else if (response.success === false) {
				this.errors = response.message;
			}
		},
		resetAddPayment() {
			this.addPaymentActive = false;
			this.resetPaymentData({});
		},
		async payout() {            
			const response = await this.$api.affiliatorPayout({
				affiliator_payout_method_id: this.payoutMethod,
                amount: this.selectedAmount,
                agreement: this.isAgree ? 1 : 0
			});

			let popupPayload = {
				name: 'changes-saved',
				payload: {
					success: Boolean(response.success),
					message: response.message,
					...(response.success ? { title: 'Withdraw confirmed' } : {})
				}
			};

			if (response.success && this.payoutMethodTypeSelected == 1) {
				this.$set(this.dataprops, 'user', response.data.user);
			}

			if (response.success && this.payoutMethodTypeSelected == 2) {
				let method = this.dataprops.affiliator.payout_methods.find(el => el.type_id == 2);

				method && this.$set(method, 'is_active', 1);
			}

			if (response?.data?.affiliator_available_earnings) {
				this.$set(this.dataprops, 'affiliator', { ...this.dataprops.affiliator, available_earnings: parseFloat(response.data.affiliator_available_earnings).toFixed(2) });
			}

			if (response?.data?.balanceHistoryEntry) {
				this.$set(this.dataprops, 'balanceHistory', [response.data.balanceHistoryEntry, ...this.dataprops.balanceHistory]);
			}

			this.$bus.$emit('modal', popupPayload);
		},
		async deletePayoutMethod(id) {
			const response = await this.$api.deletePayoutMethod({ id });

			if (response.success) {
				let methods = this.payload.affiliator.payout_methods.slice(),
					deletedIndex = this.payload.affiliator.payout_methods.findIndex((el) => el.id == id);
				
				methods.splice(deletedIndex, 1);

				let affiliatorObject = {
					...this.payload.affiliator,
					payout_methods: [...methods]
				};

				this.$set(this.payload, 'affiliator', affiliatorObject);
				this.$set(this.dataprops, 'affiliator', affiliatorObject);
			}

			setTimeout(() => {
				this.$bus.$emit('modal', { name: 'changes-saved', payload: {
					success: Boolean(response.success),
					overlay: true,
					message: response.message
				} });
			}, 500);

			this.methodToRemove = false;
			this.showConfirmDelete = false;
		},
	},
	mounted() {
		console.log('payout', this.dataprops);
		let balanceAutomatic = this.payoutMethods.find(el => el.type_id == 2);
		if (balanceAutomatic && balanceAutomatic.is_active) {
			this.payoutMethod = balanceAutomatic?.id;
		} else {
			this.payoutMethod = this.payoutMethods[0]?.id;
		}

		this.paymentOption = this.customPaymentOptions[0].value;
	}
}
</script>

<style scoped>
.card-active {
    border: 1px solid #FE2C55;
}
</style>