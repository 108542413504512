export default {
    data: () => ({
        formValidators: {
            required: val => !!val,
            minLength: (val, length) => val.length >= length,
            maxlength: (val, length) => val.length <= length,
            nameString: (val) => /^[А-Яа-яa-zA-Z\s]+$/.test(val),
            cardDate: ([month, year]) =>  {
                const curYear = (new Date()).getFullYear().toString().substring(2, 4);
                const curMonth = (new Date()).getMonth() + 1;

                if ((!year || year <=0) || (!month || month > 12)) return false;

                if (year == curYear) {
                    return curMonth <= month
                } else if (year > curYear) {
                    return true
                } else if (year < curYear) {
                    return false;
                }
            }
        }
    }),
    methods: {
        // filed object includes such fields as { validators: [], value: '', required: true }
        createForm(config) {
            return Object.fromEntries(
                Object.entries(config)
                    .map(([fieldName, fieldConf]) => [
                        fieldName,
                        {
                            ...fieldConf,
                            ...(fieldConf.required === false ? {} : { required: true } ),
                            validators: [
                                ...(fieldConf.validators ? fieldConf.validators : []),
                                ...(fieldConf.required === false ? [] : [this.formValidators.required])
                            ],
                            disabled: false,
                            isValid: true,
                            touched: false
                        }
                    ])
            )
        },
        getValues(form) {
            return Object.fromEntries(
                Object.entries(form)
                    .map(([fieldName, fieldData]) => [fieldName, fieldData.value])
            )
        },
        setValue(form, field, value, forceValidate=true) {
            this.$set(form, field, {
                ...form[field],
                value: value
            });

            if (forceValidate) this.validate(form, [field])
        },
        validate(form, fields = []) {
            let validateRes = [];

            if (fields && fields.length) {
                for (let [index, fieldName] of fields.entries()) {
                    const field = form[fieldName];

                    if (field.disabled) continue;

                    let isValid = field.validators.length
                        ? field.validators.map(validator => validator(field.value)).every(validatorRes => validatorRes)
                        : true;

                    validateRes.push(isValid);

                    if (!isValid) {
                        this.$set(form, fieldName, {
                            ...form[fieldName],
                            touched: true,
                            isValid: false
                        });
                    }
                }
            } else {
                for (let [fieldName, fieldData] of Object.entries(form)) {
                    if (fieldData.disabled) continue;

                    let isValid = fieldData.validators.length
                        ? fieldData.validators.map(validator => validator(fieldData.value)).every(validatorRes => validatorRes)
                        : true;
                    validateRes.push(isValid);

                    if (!isValid) {
                        this.$set(form, fieldName, {
                            ...form[fieldName],
                            touched: true,
                            isValid: false
                        });
                    }
                }
            }
            
            const isValid = validateRes.every(el => el);
            this.showErrors = !isValid;

            return isValid;
        },
        onFieldBlur(form, fields=[]) {
            if (!fields || !fields.length) return;

            fields.forEach(fieldName => {
                let fieldData = form[fieldName];

                this.$set(form, fieldName, {
                    ...fieldData,
                    touched: true,
                    isValid: fieldData.validators.length
                        ? fieldData.validators.map(validator => validator(fieldData.value)).every(validatorRes => validatorRes)
                        : true
                })
            })
        },
        disableField(form, fields=[]) {
            if (!fields || !fields.length) return;

            fields.forEach(fieldName => {
                this.$set(form, fieldName, {
                    ...form[fieldName],
                    disabled: true
                });
            });
        },
        enableField(form, fields=[]) {
            if (!fields || !fields.length) return;

            fields.forEach(fieldName => {
                this.$set(form, fieldName, {
                    ...form[fieldName],
                    disabled: false
                });
            })
        },
    }
}