<template>
    <section class="py-[50px]">
        <div class="container-px--lg">
            <div class="row gy-20 mb-[30px] pb-[30px] border-bottom">
                <div class="col-lg-8">
                    <div class="text-center sm:text-start mx-auto sm:mx-0">
                        <h1 class="mb-[16px] text-[24px] leading-[32px] sm:text-[40px] sm:leading-[60px]">Instagram <br><span class="text-primary-500">Caption Generator</span></h1>
                        <p class="text-text font-semibold">Collection of 10000+ Catchy Captions</p>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group mb-4">
                        <label class="input-wraper fill-input input-wraper--label">
                            <input type="text" class="input font-semibold" placeholder="Description" v-model="description">
                            <div class="label-input">
                                <span>Type what you do or who you are</span>
                            </div>
                        </label>
                    </div>
                    <div class="form-group mb-4">
                        <div class="select-multiple filled select-multiple--label not--lefticon" style="z-index:5;">
                            <div class="select-search">
                                <div class="label-input">
                                    <span>Select your vibes or topics</span>
                                </div>
                                <SelectBox
                                    key="select-1"
                                    identifier='select-default'
                                    :options="categories"
                                    :value="category"
                                    @change="(value)=>{category = value;}"
                                />
                            </div>
                            <div class="select-arrow">
                                <span class="ico">
                                    <img src="@/assets/img/icons/ico-arrowdown.svg">
                                </span>
                            </div>
                        </div>
                    </div>
                    <a href="#" @click.prevent="generate" class="btn btn--primary px-[27px] py-[20px] icon-btn rounded-[8px] w-full">
                        <span>Generate Captions</span>
                    </a>
                    <p class="mt-[15px] text-[red]" v-if="errorMessage">{{ errorMessage }}</p>
                </div>
            </div>
            <div class="row gy-20" v-if="result.length">
                <div class="col-lg-4 col-sm-6" v-for="(item, index) in result" :key="index">
                    <div class="p-10 bg-[#FAFCFF] border rounded-[7px] flex flex-col">
                        <span class="block font-semibold mb-10" v-html="item"></span>
                        <a href="#" @click.prevent="copyToClipboard(item, index)" class="btn btn--primary pl-[56px] pr-[27px] py-[13px] icon-btn rounded-[8px] max-w-[185px] w-full">
                            <div class="icon-left">
                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.2188 11.5238V7.7143C20.2188 5.87335 18.7264 4.38097 16.8855 4.38097H8.31403C6.47309 4.38097 4.9807 5.87335 4.9807 7.7143V16.2857C4.9807 18.1267 6.47309 19.6191 8.31403 19.6191H13.0759C13.8649 19.6191 14.5045 18.9795 14.5045 18.1905V16.2857C14.5045 14.4448 15.9969 12.9524 17.8378 12.9524H18.7902C19.5792 12.9524 20.2188 12.3128 20.2188 11.5238ZM20.1489 13.4793C19.7636 13.7475 19.2953 13.9048 18.7902 13.9048H17.8378C16.5229 13.9048 15.4569 14.9708 15.4569 16.2857V18.1905C15.4569 18.5927 15.3572 18.9716 15.1811 19.3038C17.7893 18.5005 19.7604 16.2439 20.1489 13.4793ZM8.31403 3.42859H16.8855C19.2524 3.42859 21.1712 5.34737 21.1712 7.7143V12.4762C21.1712 16.9471 17.5468 20.5714 13.0759 20.5714H8.31403C5.9471 20.5714 4.02832 18.6527 4.02832 16.2857V7.7143C4.02832 5.34737 5.9471 3.42859 8.31403 3.42859Z" fill="currentColor"></path>
                                </svg>
                            </div>
                            <span>{{ copiedIndex === index ? 'Copied' : 'Copy' }}</span>
                        </a>
                    </div>
                </div>

                <div class="col-12">
                    <div class="flex justify-center">
                        <a href="#" @click.prevent="generate" class="btn flex-col text-center def-text--1 text-primary-500">
                            <span class="font-semibold" >
                                See more
                            </span>
                            <span class="ico ico-20 mt-[4px]">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5265 15.2603C11.4578 15.2322 11.3954 15.1906 11.343 15.138L5 8.792L5.792 8L11.743 13.951L17.694 8L18.486 8.792L12.139 15.138C12.0871 15.1901 12.0253 15.2314 11.9574 15.2595C11.8894 15.2877 11.8166 15.3021 11.743 15.302C11.6688 15.3026 11.5952 15.2885 11.5265 15.2603Z" fill="currentColor"></path>
                                </svg>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="row" v-if="loading">
                <div class="flex justify-center pt-[30px] pb-[30px]">
                    <Loader />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import SelectBox from '@/components/Base/SelectBox.vue';
import { toolsApi } from "@/api/toolsApi";
import Loader from "@/components/Base/Loader";

export default {
    components:{
        SelectBox,
        Loader,
    },
    data(){
        return {
            selectOptions: [
                {
                    value: "1",
                    label: "Caption Category",
                },
                {
                    value: "2",
                    label: "Friend 1",
                },
                {
                    value: "3",
                    label: "Friend 2",
                },
                {
                    value: "4",
                    label: "Friend 3",
                },
            ],
            captionsTemplate:[
                {
                    text:'A journey of thousand miles starts with a single step! 🥰'
                },
                {
                    text:'A journey of thousand miles starts with a single step! 🥰'
                },
                {
                    text:'A journey of thousand miles starts with a single step! 🥰'
                },
                {
                    text:'A journey of thousand miles starts with a single step! 🥰'
                },
                {
                    text:'A journey of thousand miles starts with a single step! 🥰'
                },
                {
                    text:'A journey of thousand miles starts with a single step! 🥰'
                },
            ],
            captions:[],

            categories: [
                {
                    value: "",
                    label: "Select",
                    selected: true,
                },
                {
                    value: "General",
                    label: "General"
                },
                {
                    value: "Friends",
                    label: "Friends"
                },
                {
                    value: "Education",
                    label: "Education"
                },
                {
                    value: "Sport",
                    label: "Sport"
                },
                {
                    value: "Question",
                    label: "Question"
                },
                {
                    value: "Call to Action",
                    label: "Call to Action"
                },
                {
                    value: "Provoking",
                    label: "Provoking"
                },
                {
                    value: "Story Time",
                    label: "Story Time"
                },
                {
                    value: "For Girls",
                    label: "For Girls"
                },
                {
                    value: "For Men",
                    label: "For Men"
                },
            ],
            description: "",
            category: "",
            loading: false,
            result: [],
            errorMessage: null,
            copiedIndex: null,
        }
    },
    methods:{
        copyToClipboard(textValue, index){
            let self = this;

            if(!textValue) return;
            
            self.copiedIndex = index;

            navigator.clipboard.writeText(textValue)
            .then(() => {})

            setTimeout(() => {
               self.copiedIndex = null; 
            }, 1000);
        },
        seeMore(){
            this.captions=[...this.captions, ...this.captionsTemplate]
        },
        generateCaptions(){
            this.captions=[...this.captionsTemplate]
        },
        async generate(){
            this.errorMessage = null;
            this.loading = true;

            try {
                const res = await toolsApi.instagramCaptionGenerator({
                    description: this.description,
                    category: this.category,
                });

                console.log('res:', res);

                if(res?.result){
                    this.result = [...this.result, ...res.result];
                } else if(res?.error?.message){
                    this.errorMessage = res.error.message;
                }
            } catch (error) {
                console.log('error:', error);
            } finally {
                this.loading = false;
            }
        },
    }
    
}
</script>