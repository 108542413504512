<template>
    <section class="section pt-[14px] lg:pt-0 flex-auto flex lg:block">
        <div class="container mx-0 container-dash flex flex-col flex-auto" v-if="generateStep === 1">
            <h4 class="lg:text-primary-500 text-center lg:text-[40px] mb-[20px]">Hashtag Generator</h4>
            <p class="text-secondary-500 text-center mb-[40px]">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.
            </p>
            <div class="max-w-[330px] mx-auto flex-auto w-full">
                <div class="form-group mb-[20px]">
                    <label class="input-wraper">
                        <input type="text" class="input font-semibold" placeholder="Insert word" v-model="hashtag">
                    </label>
                </div>
                <div class="generate-buttons mb-[20px]">
                    <a href="#" 
                        class="btn rounded-[8px]" 
                        :class="{'active': activeButton === button.name}" 
                        v-for="button in categoryButtons" :key="button.name"
                        @click.prevent="activeButton = button.name"
                    >
                        <span class="info">{{button.name}}</span>
                    </a>
                </div>
            </div>
            <div class="max-w-[330px] mx-auto w-full">
                <a href="#" class="btn btn--primary p-[24px] rounded-[8px] w-full"
                    @click.prevent="generate"
                >
                    <span class="info">Generate</span>
                </a>
            </div>
            <div class="max-w-[500px] mt-10 mx-auto" v-if="loading">
                <div class="flex justify-center">
                    <Loader style="zoom: 0.6;" />
                </div>
            </div>
        </div>
        <div class="container mx-0 container-dash" v-if="generateStep === 2">
            <div class="flex items-center justify-center relative mb-[50px] mt-[14px] lg:hidden">
                <a href="#" class="btn w-[40px] h-[40px] bg-primary-100 rounded-[11px] left-0 absolute"
                @click.prevent="generateStep = 1"
                >
                    <img src="@/assets/img/icons/left-arrow-long.svg" alt="">
                </a>
                <span class="def-text--1 font-semibold">Hashtag Generator</span>
            </div>
            <div class="lg:flex items-center justify-between mb-[25px] hidden ">
                <div class="flex items-center">
                    <a href="#" class="btn w-[40px] h-[40px] bg-primary-100 rounded-[11px] mr-[20px] hidden lg:flex"
                    @click.prevent="generateStep = 1"
                    >
                        <img src="@/assets/img/icons/left-arrow-long.svg" alt="">
                    </a>
                    <h4 class="text-[24px]">Hashtag Generator</h4>
                </div>
            </div>
            <div class="flex max-w-[890px] flex-col lg:flex-row w-full xl:px-[60px] items-center justify-center mx-auto">
                <div class="max-w-[330px] mb-[30px] lg:mr-[20px]">
                    <h4 class="text-[24px] text-center mb-[15px]">Best {{ hashtags.length }} Hashtags
                        <span class="text-primary-500 block">#{{hashtag}}</span>
                    </h4>
                    <p class="text-center text-secondary-500 mb-[20px]">Here you can find the {{ hashtags.length }} relevangt hashtags based on your searching key. Instagram allows max 30 Hashtags/Post.</p>
                    <div class="hash-wrapper mb-[40px]">
                        <div class="flex font-semibold items-center justify-between mb-[20px]">
                            <span>Best top hashtags</span>
                            <span>{{ hashtags.length }} #Hashtags</span>
                        </div>
                        <div class="text-secondary-500">
                            {{ hashtags.join(' ') }}
                        </div>
                    </div>
                    <button @click="copy" class="btn btn--primary pl-[56px] pr-[27px] py-[20px] icon-btn rounded-[8px] w-full">
                        <div class="icon-left">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.6189 11.5238V7.7143C19.6189 5.87335 18.1266 4.38097 16.2856 4.38097H7.71418C5.87323 4.38097 4.38085 5.87335 4.38085 7.7143V16.2857C4.38085 18.1267 5.87323 19.6191 7.71418 19.6191H12.4761C13.2651 19.6191 13.9047 18.9795 13.9047 18.1905V16.2857C13.9047 14.4448 15.397 12.9524 17.238 12.9524H18.1904C18.9793 12.9524 19.6189 12.3128 19.6189 11.5238ZM19.5491 13.4793C19.1638 13.7475 18.6954 13.9048 18.1904 13.9048H17.238C15.923 13.9048 14.857 14.9708 14.857 16.2857V18.1905C14.857 18.5927 14.7573 18.9716 14.5813 19.3038C17.1894 18.5005 19.1605 16.2439 19.5491 13.4793ZM7.71418 3.42859H16.2856C18.6525 3.42859 20.5713 5.34737 20.5713 7.7143V12.4762C20.5713 16.9471 16.947 20.5714 12.4761 20.5714H7.71418C5.34725 20.5714 3.42847 18.6527 3.42847 16.2857V7.7143C3.42847 5.34737 5.34725 3.42859 7.71418 3.42859Z" fill="currentColor"></path>
                            </svg>
                        </div>
                        <span>{{ isCopied ? 'Copied!' : 'Copy Hashtags' }}</span>
                    </button>
                </div>
                <!-- <div class="max-w-[330px] mb-[30px]">
                    <h4 class="text-[24px] text-center mb-[15px]">More top Hashtags
                        <span class="text-primary-500 block">#{{hashtag}}</span>
                    </h4>
                    <p class="text-center text-secondary-500 mb-[20px]">There are more hashtags we found. All hashtags are based on your searching key. Sorting, filter and order by your filter you have chosen.</p>
                    <div class="hash-acordion mb-[20px]" v-for="(item, index) in hashtagsLists" :key="index + 'hashtag-item'">
                        <a href="" @click.prevent="toggleAccordeon(item)" class="flex items-center spoler-btn justify-between py-[22px] px-[17px] font-semibold" data-spoller="">
                            <span class="mr-[10px]">1: More top hashtags</span>
                            <span class="mr-[10px]">30 #Hashtags</span>
                            <span class="btn-icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5265 15.2603C11.4578 15.2322 11.3954 15.1906 11.343 15.138L5 8.792L5.792 8L11.743 13.951L17.694 8L18.486 8.792L12.139 15.138C12.0871 15.1901 12.0253 15.2314 11.9574 15.2595C11.8894 15.2877 11.8166 15.3021 11.743 15.302C11.6688 15.3026 11.5952 15.2885 11.5265 15.2603Z" fill="#2E3A59"></path>
                                </svg>
                            </span>
                        </a>
                        <div style="box-sizing: border-box" :style="item.isOpen ? 'dispaly: block' : 'display:none'">
                            <div class="text-secondary-500 px-[17px] pb-[24px]">
                                {{item.hashtags}}
                            </div>
                        </div>
                    </div>
                    <a href="#" @click.prevent="" class="btn btn--primary px-[23px] py-[20px] rounded-[8px] w-full">
                        <span class="info">Enter another term</span>
                    </a>
                </div> -->
            </div>
        </div>
    </section>
</template>

<script>
import { toolsApi } from "@/api/toolsApi";
import Loader from "@/components/Base/Loader";
export default {
    name: "HashtagGenerator",
    components: {
        Loader
    },
    data(){
        return{
            categoryButtons: 
            [
                {
                    name: 'Top',
                },
                {
                    name: 'Random',
                },
                {
                    name: 'Live',
                },
            ],
            hashtagsLists: [
                {isOpen: false, name: 'More top Hashtags', hashtags: '#srilanka #srilankan #srilankatravel #srilankadaily #srilankanfood #srilankanstyle #srilankatrip #srilankangirl #srilankans #srilanka2016 #srilankatoday #srilankatourism #srilanka2017 #srilankawedding #srilankanart #srilankanbride #srilankanbeauty #srilankanwedding #srilanka2015 #srilankancuisine #srilankanfashion #SriLankaBalloon #srilankabeach #srilankamakeup #srilankamakeupartist #srilankanstreetstyle #SriLankaStyle #srilankabeauty #srilankanairlines #srilankandesigner'},
                {isOpen: false, name: 'More top Hashtags', hashtags: '#srilanka #srilankan #srilankatravel #srilankadaily #srilankanfood #srilankanstyle #srilankatrip #srilankangirl #srilankans #srilanka2016 #srilankatoday #srilankatourism #srilanka2017 #srilankawedding #srilankanart #srilankanbride #srilankanbeauty #srilankanwedding #srilanka2015 #srilankancuisine #srilankanfashion #SriLankaBalloon #srilankabeach #srilankamakeup #srilankamakeupartist #srilankanstreetstyle #SriLankaStyle #srilankabeauty #srilankanairlines #srilankandesigner'},
                {isOpen: false, name: 'More top Hashtags', hashtags: '#srilanka #srilankan #srilankatravel #srilankadaily #srilankanfood #srilankanstyle #srilankatrip #srilankangirl #srilankans #srilanka2016 #srilankatoday #srilankatourism #srilanka2017 #srilankawedding #srilankanart #srilankanbride #srilankanbeauty #srilankanwedding #srilanka2015 #srilankancuisine #srilankanfashion #SriLankaBalloon #srilankabeach #srilankamakeup #srilankamakeupartist #srilankanstreetstyle #SriLankaStyle #srilankabeauty #srilankanairlines #srilankandesigner'},
                {isOpen: false, name: 'More top Hashtags', hashtags: '#srilanka #srilankan #srilankatravel #srilankadaily #srilankanfood #srilankanstyle #srilankatrip #srilankangirl #srilankans #srilanka2016 #srilankatoday #srilankatourism #srilanka2017 #srilankawedding #srilankanart #srilankanbride #srilankanbeauty #srilankanwedding #srilanka2015 #srilankancuisine #srilankanfashion #SriLankaBalloon #srilankabeach #srilankamakeup #srilankamakeupartist #srilankanstreetstyle #SriLankaStyle #srilankabeauty #srilankanairlines #srilankandesigner'},
                {isOpen: false, name: 'More top Hashtags', hashtags: '#srilanka #srilankan #srilankatravel #srilankadaily #srilankanfood #srilankanstyle #srilankatrip #srilankangirl #srilankans #srilanka2016 #srilankatoday #srilankatourism #srilanka2017 #srilankawedding #srilankanart #srilankanbride #srilankanbeauty #srilankanwedding #srilanka2015 #srilankancuisine #srilankanfashion #SriLankaBalloon #srilankabeach #srilankamakeup #srilankamakeupartist #srilankanstreetstyle #SriLankaStyle #srilankabeauty #srilankanairlines #srilankandesigner'},
            ],
            activeButton: 'Top',
            hashtag: '',
            generateStep: 1,
            hashtags: null,
            isCopied: false,
            loading: false
        }
    },
    methods: {
        toggleAccordeon(item){
            item.isOpen = !item.isOpen;
        },
        async generate() {
            this.loading = true;
            this.hashtags = null;
            const response = await toolsApi.instagramHashtagsGenerator(this.hashtag);
            this.loading = false
            if(response.hasError) {
                return
            }
            this.hashtags = Object.keys(response.batches);
            this.generateStep = 2
            console.log(response)
        },
        copy() {
            console.log('hello')
            navigator.clipboard.writeText(this.hashtags.join(' '));
            this.isCopied = true;
            setTimeout(() => {
                this.isCopied = false
            }, 1000)
        }
    }
    

}
</script>

<style>

</style>