import RequestDeletionModal from "./RequestDeletionModal.vue"
import ChangePasswordModal from "./ChangePasswordModal.vue"
import AddNewCardModal from "./AddNewCardModal.vue"
import BillingInformation from "./BillingInformation.vue"
import MessageSent from "./MessageSent.vue"
import LoginModal from "./LoginModal.vue"
import RegisterModal from "./RegisterModal.vue"
import RegisterTwoModal from "./RegisterTwoModal.vue"
import ForgotPassword from "./ForgotPassword.vue"
import ForgotPasswordSent from "./ForgotPasswordSent.vue"
import VerifyEmailModal from "./VerifyEmailModal.vue"
import DowmloadAppModal from "./DowmloadAppModal.vue"
import QuickOrder from "./QuickOrder.vue"
import RemoveItem from "./RemoveItem.vue"
import CuponSuccesModal from "./CuponSuccesModal.vue"
import CuponErrorModal from "./CuponErrorModal.vue"
import WriteReviewModal from "./WriteReviewModal.vue"
import HeaderMobileModal from "./HeaderMobileModal.vue"
import WriteOrderReviewModal from "./WriteOrderReviewModal.vue"
import SeeOrderReviewModal from "./SeeOrderReviewModal.vue"
import MessageUsModal from "./MessageUsModal.vue"
import ChangeEmailModal from "./ChangeEmailModal.vue"
import AddFoundsCheckoutModal from "./AddFoundsCheckoutModal.vue"
import CartDetailsModal from "./CartDetailsModal.vue"
import ChangesSavedModal from "./ChangesSavedModal.vue"
import ConfirmEmailModal from "./ConfirmEmailModal.vue"
import PayNow2PopUp from "./PayNow2PopUp.vue"

export default {
    'request-deletion': RequestDeletionModal,
    'change-password': ChangePasswordModal,
    'add-new-card': AddNewCardModal,
    'billing-information': BillingInformation,
    'message-sent': MessageSent,
    'login': LoginModal,
    'register': RegisterModal,
    'register-two': RegisterTwoModal,
    'forgot-password': ForgotPassword,
    'forgot-password-sent': ForgotPasswordSent,
    'verify-email': VerifyEmailModal,
    'download-app': DowmloadAppModal,
    'quick-order': QuickOrder,
    'remove-item': RemoveItem,
    'cupon-succes': CuponSuccesModal,
    'cupon-error': CuponErrorModal,
    'write-review': WriteReviewModal,
    'header-mobile-menu': HeaderMobileModal,
    'write-order-review': WriteOrderReviewModal,
    'see-order-review': SeeOrderReviewModal,
    'message-us': MessageUsModal,
    'change-email': ChangeEmailModal,
    'confirm-email': ConfirmEmailModal,
    'add-funds-checkout': AddFoundsCheckoutModal,
    'cart-details': CartDetailsModal,
    'changes-saved': ChangesSavedModal,
    'pay-now-2': PayNow2PopUp,
}