<template>
    <div id="change-password" class="modal modal-2 modal--show">
        <div class="modal__dialog modal__dialog--443">
            <div class="modal__content" v-click-outside="closeModal">
                <div class="modal__header header-center px-[28px] md:px-[40px]">
                    <a href="#"
                        class="btn btn-close btn-back md-hidden mobail-absolute"
                        data-close-modal=""
                        @click.prevent="closeModal()" >
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg" >
                            <path
                                d="M20.6818 11.6366L3 11.6366"
                                stroke="#222B45"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            ></path>
                            <path
                                d="M10.6364 19.2727L3 11.6364L10.6364 4"
                                stroke="#222B45"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            ></path>
                        </svg>
                    </a>
                    <h6 class="font-bold text-[14px] leading-[24px] sm:text[18px]" >
                        Change password
                    </h6>
                    <a href="#"
                        class="btn btn-close max-md-visible"
                        data-close-modal=""
                        @click.prevent="closeModal()" >
                        <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M17.4771 2.94469C17.9223 2.51467 18.1009 1.87788 17.9441 1.27907C17.7874 0.68025 17.3197 0.212602 16.7209 0.0558617C16.1221 -0.100878 15.4853 0.0776826 15.0553 0.522914L8.99914 6.57736L2.94469 0.522914C2.51467 0.0776826 1.87788 -0.100878 1.27907 0.0558617C0.68025 0.212602 0.212602 0.68025 0.0558617 1.27907C-0.100878 1.87788 0.0776826 2.51467 0.522914 2.94469L6.57736 8.99914L0.522914 15.0553C0.0776826 15.4853 -0.100878 16.1221 0.0558617 16.7209C0.212602 17.3197 0.68025 17.7874 1.27907 17.9441C1.87788 18.1009 2.51467 17.9223 2.94469 17.4771L8.99914 11.4226L15.0553 17.4771C15.4853 17.9223 16.1221 18.1009 16.7209 17.9441C17.3197 17.7874 17.7874 17.3197 17.9441 16.7209C18.1009 16.1221 17.9223 15.4853 17.4771 15.0553L11.4226 8.99914L17.4771 2.94469Z"
                                fill="#3A4C66"
                            ></path>
                        </svg>
                    </a>
                </div>
                <div class="modal__body pb-[37px] md:pb-[65px] px-[28px] md:px-[50px]" >
                    <div class="form-group mb-[18px] relative">
                        <!-- <input
                            type="text"
                            class="input h-[68px] input-default"
                            placeholder="Current password"
                        /> -->
                        <input 
                            class="input h-[68px] input-default"
                            type="text" 
                            id="password"
                            placeholder="Current password"
                            v-model="userPasswordInfo.password.value"
                            :disabled="userPasswordInfo.password.disabled"
                            @input="isDisabled.pas = true"
                            @blur="onFieldBlur(userPasswordInfo, ['password'])"
                            :class="{ 'invalid': userPasswordInfo.password.touched && !userPasswordInfo.password.isValid }"
                        >
                        <span class="input-error">{{ errors?.password?.[0] }}</span>
                    </div>
                    <div class="form-group mb-[18px] relative">
                        <!-- <input
                            type="text"
                            class="input h-[68px] input-default"
                            placeholder="New password"
                        /> -->
                        <input 
                            type="text" 
                            id="new_password" 
                            class="input h-[68px] input-default"
                            placeholder="New password"
                            v-model="userPasswordInfo.newPassword.value"
                            :disabled="userPasswordInfo.newPassword.disabled"
                            @input="isDisabled.new = true"
                            @blur="onFieldBlur(userPasswordInfo, ['newPassword'])"
                            :class="{ 'invalid': userPasswordInfo.newPassword.touched && !userPasswordInfo.newPassword.isValid }"
                        >
                        <span class="input-error">{{ errors?.new_password?.[0] }}</span>
                    </div>
                    <div class="form-group mb-[18px]">
                        <!-- <input
                            type="text"
                            class="input h-[68px] input-default"
                            placeholder="Repeat new password"
                        /> -->
                        <input 
                            type="text" 
                            id="confirm_password" 
                            class="input h-[68px] input-default"
                            placeholder="Repeat new password"
                            v-model="userPasswordInfo.confirmPassword.value"
                            :disabled="userPasswordInfo.confirmPassword.disabled"
                            @input="isDisabled.con = true"
                            @blur="onFieldBlur(userPasswordInfo, ['confirmPassword'])"
                            :class="{ 'invalid': userPasswordInfo.confirmPassword.touched && !userPasswordInfo.confirmPassword.isValid }"
                        >
                    </div>
                    <div class="flex flex-col mt-auto md:mt-[0px]">
                        <button 
                            class="btn btn--primary px-[23px] py-[19px] rounded-[8px] mb-[18px] font-[700] text-[14px] leading-[19px]"
                            :class="{'disabled': !isDisabled.pas || !isDisabled.new || !isDisabled.con}"
                            data-close-modal=""
                            @click="updatePassword"
                            >Save changes</button>
                        <a href="#"
                            class="btn btn--primary-outline px-[23px] py-[19px] rounded-[8px] font-[700] text-[14px] leading-[19px]"
                            data-close-modal=""
                            @click.prevent="closeModal()">
                            <span class="info">Cancel</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { profileApi } from '@/api/profileApi';
import formValidation from '@/mixins/formValidation';
export default {
    data: () => ({
        userPasswordInfo: false,
        isDisabled: {
            pas: false,
            new: false,
            con: false
        },
        errors: {
            new_password: [],
            password: []
        }
    }),
    mixins: [formValidation],
    created() {

        this.userPasswordInfo = this.createForm({
            password: {
                value: '',
                required: true
            },
            newPassword: {
                value: '',
                required: true
            },
            confirmPassword: {
                value: '',
                required: true
            },
        });  
    },
    methods: {
        closeModal() {
            this.$bus.$emit("modal", {
                name: "",
                payload: {},
            });
        },
        async updatePassword() {
            const isValidate = this.validate(this.userPasswordInfo)

            if(!isValidate) return

            console.log({...this.getValues(this.userPasswordInfo)})

            this.disableField(this.userPasswordInfo, ['password', 'newPassword', 'confirmPassword'])

            // const response = await this.$api.changePassword({...this.getValues(this.userPasswordInfo)})
            const response = await profileApi.updatePassword({...this.getValues(this.userPasswordInfo)})
            this.enableField(this.userPasswordInfo, ['password', 'newPassword', 'confirmPassword'])
            console.log('response',  response)
            if(response.hasError) {
                this.errors = response.error
                return
            }
            this.closeModal()

        }
    },
};
</script>

<style scoped>
    .input-error {
        position: absolute;
        left: 0;
        bottom: -15px;
        color: #EF6057;
        font-weight: 400;
        font-size: 10px;
        line-height: 140%;
    }

    .invalid {
        border: 1px solid red;
    }

</style>
