export const affiliateOrderStatuses = {
    'pending': 'Pending',
    'approved': 'Approved',
    'declined': 'Declined'
};

export const affiliateOrderClassByStatus = {
    'pending': '',
    'approved': 'approved',
    'declined': 'declined'
};

export const affiliatorPayoutsStatuses = {
    'completed': 'Completed',
    'in_progress': 'In Progress',
};

export const userOrderStatuses = {
    'awaiting_payment': 'Awaiting payment',
    'awaiting_confirmation': 'Awaiting confirmation',
    'done': 'Done'
};

export const platformsConfig = [
    {
        title: "Spotify",
        identifier: "spotify",
        quickOrderImg: `/img/dashboard/Spotify.svg`,
        identifiersWithoutMedia:['spotify.playlistFollowers', 'spotify.artistFollowers', 'spotify.monthlyListeners']
    },
    {
        title: "Soundcloud",
        identifier: "soundcloud",
        quickOrderImg: `/img/dashboard/soudcloud.svg`,
        identifiersWithoutMedia:['soundcloud.followers']
    },
    {
        title: "YouTube",
        identifier: "youtube",
        quickOrderImg: `/img/dashboard/youtybe.svg`,
        identifiersWithoutMedia:['youtube.subscribers']
    },
    {
        title: "Twitch",
        identifier: "twitch",
        quickOrderImg: `/img/dashboard/twitch.svg`,
        identifiersWithoutMedia:['twitch.followers', 'twitch.viewers', 'twitch.viewersMonthly']

    },
    {
        title: "TikTok",
        identifier: "tiktok",
        quickOrderImg: `/img/dashboard/tictok.svg`,
        identifiersWithoutMedia:['tiktok.followers']

    },
]
export const selectedProductForInit = {
    "tiktok":{
        "tiktok.followers":1,
        "tiktok.likes":2,
        "tiktok.views":3,
        "tiktok.realComments":1,
         
        // "shares" : 2
    },
    "youtube":{
        "youtube.views":3,
        "youtube.likes":1,
        "youtube.subscribers":2,
        "youtube.randomComments":1
        // youtube.watchTime - ?	
    },
    "soundcloud":{
        "soundcloud.followers":2,
        "soundcloud.plays":4,
        "soundcloud.likes":3,
        "soundcloud.comments":1,
        "soundcloud.reposts":2,
        // soundcloud.downloads - 
    },
    "spotify":{
        "spotify.plays"	: 3,
        "spotify.artistFollowers" : 2,
        // "spotify.playlistFollowers" : 0,
        "spotify.monthlyListeners" : 2,
    },
    "twitch":{
        "twitch.followers":1,
        "twitch.videoViews":2,
        "twitch.clipViews":3,
        // Viewers	12 hours	default package:1
        //     7 days	
        //     30 days	
        // Channel total views		default package: 2
    },

}