import restInstance from "@/lib/rest"

const rest = restInstance

export const toolsApi = {

    async instagramPhoto(payload) {
        const data = {
            search: payload, 
            // _token: localStorage.getItem('token'),
        };

        const formData = new FormData();
        formData.append("search", payload);

        return await rest.request('/tools/instagram-profile-photo-downloader', 'POST', formData, 'instagramPhoto')
    },
    
    async instagramVideo(payload)  {
        const data = {
            search: payload, 
            _token: "",
        }
        return await rest.request('/tools/instagram-video-downloader', 'POST', data, 'instagramVideo')
    },
    
    async tikTokVideoDownload(payload)  {
        const data = {
            search: payload, 
            _token: "",
        }
        return await rest.request('/tools/tiktok-video-downloader', 'POST', data, 'tikTokVideo', 30000)
    },
    
    async instagramEngagementCalculator(payload)  {
        const data = {
            username: payload, 
            _token: localStorage.getItem('token'),
        }

        return await rest.request('/tools/instagram-engagement-calculator', 'POST', data, 'instagramEngagementCalculator')
    },
    
    async youtubeChanel(payload)  {
        const data = {
            username: payload, 
            // _token: "",
        }
        return await rest.request('/tools/youtube-find-channel-id', 'POST', data, 'youtubeChanel')
    },
    
    async instagramUser(payload)  {
        const data = {
            username: payload, 
            _token: "",
        }
        return await rest.request('/tools/instagram-find-user-id', 'POST', data, 'instagramUser', null)
    },

    async instagramUserNameGenerator(payload)  {
        const data = payload || {
            name: "eminem",
            likes: "1111",
            hobbies: "sail",
            likes2: "22222",
            words: "any",
    }
        return await rest.request('/tools/instagram-username-generator', 'POST', data, 'instagramUser', 30000)
    },

    async instagramLineBreakGenerator(payload)  {
        const data = {
            text: payload
        }
        
        return await rest.request('/tools/instagram-line-break-generator', 'POST', data, 'instagramUser', 30000)
    },
   
    async youtubeChanelThumbanail(payload)  {
        const data = {
            search: payload,
            // username: 'eminem',
            // _token: "",
        }
        return await rest.request('/tools/youtube-thumbnail-downloader', 'POST', data, 'youtubeChanelThumbanail')
    },
    
    async instagramProfileViewer(payload)  {
        const data = {
            search: payload, 
            _token: "",
        }
        return await rest.request('/tools/instagram-profile-viewer', 'POST', data, 'instagramProfileViewer', 120000)
    },
    
    async instagramHashtagsGenerator(payload)  {
        const data =  {
            tags: "",
            tag: payload,
            _token: "",
        }
        return await rest.request('/tools/instagram-hashtags-generator', 'POST', data, 'instagramHashtagsGenerator', null)
    },
    
    async instagramUsernameGenerator(payload)  {
        const data = {
            description: "", 
            category: "",
            _token: "",
        }
        return await rest.request('/tools/instagram-hashtags-generator', 'POST', data, 'instagramUsernameGenerator')
    },
    
    async instagramBioMaker(payload)  {
        const data = payload || {
            name: 'eminem',
            likes: '1111',
            hobbies: 'jump',
            likes2: '11111',
            words: 'any',
            description: 'rest with friends',
            category: 'friends',
            _token: '', 
        }
        return await rest.request('/tools/instagram-bio-maker', 'POST', data, 'instagramBioMaker', 30000)
    },
    
    async instagramCaptionGenerator(payload)  {
        const data = {
            description: payload.description,
            category: payload.category,
            _token: "", 
        }
        return await rest.request('/tools/instagram-caption-generator', 'POST', data, 'instagramCaptionGenerator', 30000)
    },

}