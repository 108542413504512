<template>
	<div style="height: 100%;">
        <template>
            <Sidebar v-if="showSidebar"></Sidebar>
            
            <div class="wrapper" :class="{'sidebar-gutters': showSidebar }"> 
                <main class="content">
                    <Header v-if="showHeader" :isSideBar="showSideBarInHeader" :dataprops="dataprops"></Header>
                    <AlternativeHeader v-else></AlternativeHeader>
                    <!-- v-if="showSmallMobileHeader" -->
                    <SmallMobileHeader  :dataprops="dataprops" />

                    <!-- <div :ref="globalStatus" class="global-status-bar">
                        <p>This is a global status bar used for alerts or big discounts.</p>
                    </div> -->

                    <router-view :dataprops="dataprops" />

                </main>
                
                <a href="" class="scroll_to_top"></a>
                <Footer v-if="showFooter" v-bind:class="{ 'layout__hide-footer-mobile' : hideFooterMobile}"></Footer>
            </div>
        </template>
        <Popup :dataprops="dataprops" />
	</div>
</template>

<script>
import Sidebar from '@/components/Parts/Sidebar.vue'
import Header from '@/components/Parts/Header.vue'
import Footer from '@/components/Parts/Footer.vue'
import SmallMobileHeader from '@/components/Parts/SmallMobileHeader.vue'
import Popup from '@/components/Modals/popup.vue'
import AlternativeHeader from '@/components/Parts/AlternativeHeader.vue'

import { authApi } from '@/api/authApi'

export default {
    // data(){
    //     return{
    //         hideFooter: this.$route.meta.hideFooter,
    //     }
    // },
    components:{
        Sidebar,
        Header,
        Footer,
        Popup,
        AlternativeHeader,
        SmallMobileHeader
    },
    props: ['dataprops'],
	computed:{
        showFooter() {
            return this.$route.meta.hideFooter ? false : true
        },
        showHeader(){
            return this.$route.meta.hideHeader ? false : true
        },
        showSidebar(){
            return this.$route.meta.hideSidebar ? false : true
        },
        showSmallMobileHeader(){
            return this.$route.meta.showSmallMobileHeader ? true : false
        },
        hideFooterMobile(){
            return this.$route.meta.hideFooterMobile ? true : false
        },
        showSideBarInHeader() {
            return this.$route.meta.showSideBarInHeader ? true : false
        }
    },
    created() {
        // this.$bus.setPropetry('dataprops', this.data);
    },
    mounted () {
        // this.countHeight()
    },
    // countHeight () {
    //     let height = this.$refs.globalStatus.clientHeight;
    // }
}
</script>

<style>
    @media (min-width: 1024px) {
        .layout__hide-footer-mobile {
        display: none;
        }
    }
</style>