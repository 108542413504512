<template>
    <select v-if="!destroyed" :key="additionalRefId" @change="$event.preventDefault();onSelectMethod($event)" :ref="refId" data-text="Add account" data-modal="#add-account" select-one :data-image="dataImage">
        <option 
            v-for="(item, index) in (options ? options : optionsComputed)"
            :selected="item.selected || String(item.value) === String(value)"  
            :value="item.value" 
            :key="''+index+additionalRefId+item.value" 
            :data-custom-properties="item['data-custom-properties'] ? item['data-custom-properties'] : JSON.stringify(item)"
        >
            {{item.label}}
        </option>
        <!-- <option value="val1" data-custom-properties='{"icon": "@/assets/img/images/user-1.png",
        "socialIcon": "@/assets/img/socila-icons/instagram-primary.png"}'>
            @jessicaj18</option>
        <option value="val2" data-custom-properties='{"icon": "@/assets/img/images/user-2.png",
        "socialIcon": "@/assets/img/socila-icons/instagram-primary.png"}'>
            @jessicaj18</option> -->
    </select>
</template>
<script>
// import * as Choices from 'choices.js';

import * as Choices from '@/lib/Choices.js';



export default {
props: [
    'dataprops', 'identifier', 'options', 'value', 'onAddButtonClick', 'dataImage', 'additionalRefId', 'additionalClass'
],
data(){
    return {
        choices:null,
        keyUpdate: 0,
        destroyed:false 
    }
},
watch:{
    // choices(){
    //     if(!this.choices){
    //         this.init()
    //     }
    // },
    options: {
        handler(newOptions, oldOptions) {
            if(!(JSON.stringify(newOptions)===JSON.stringify(oldOptions))){
                this.$nextTick(()=>{
                    this.choices.clearStore();
                    this.choices.setValue(newOptions);
                    this.choices.setChoiceByValue(newOptions[0].value);
                    this.onSelectMethod({target:{event:newOptions[0].value}})
                })
        
            }
    
        },
    },
    value: {
        handler(newValue) {
            console.log('update select')
            this.$nextTick(()=>{
                this.choices.setChoiceByValue(String(newValue));
            })

        },
    },
    //need to full reload select
    additionalRefId:{
        handler(newValue, oldValue){
            // console.log('error', newValue, oldValue);
            this.choices.destroy()
            this.$nextTick(() => {
                this.init()
            })
        }
    }
},
methods:{
    onSelectMethod(event){
        // console.log('select on change', event.target.value);
        if(typeof this.options[0].value === 'number'){
            this.$emit('change', +event.target.value);
        }else{
            this.$emit('change', event.target.value);
        }
        console.log('select box onSelectMethod', this.value);
        //need to full control select from vue state
        this.choices.setChoiceByValue(String(this.value));

    },
    init(){
        let $this=this
        function addCustombtn (obj) {
            const containerBtn = document.createElement(`div`);
            let dropdown = obj.dropdown.element
            let select = obj.passedElement.element
            let text = select.dataset.text
            let modal = select.dataset.modal
            containerBtn.classList.add('select-btn-wrapper');
            containerBtn.innerHTML = `<a href="#" class="btn primary-outline btn-md radius-100 test-click" data-modal="${modal}">
                            <span class="info">${text}</span>
                        </a>`

            const element = containerBtn.firstElementChild;
            let eventCallback = ()=>{
                if($this.onAddButtonClick) {
                    $this.onAddButtonClick();
                } else {
                    console.log('add button click');
                }
            }
            element.addEventListener('click',eventCallback);
        
            dropdown.append(containerBtn)
        }
        if(this.identifier==='select-toicon'){
            this.choices = new Choices(this.$refs[this.refId], {
                searchChoices: false,
                searchEnabled: false,
                allowHTML: true,
                shouldSort: false,
                classNames: {
                    containerOuter: 'choices select-toicon',
                    selectedState: 'is-selected',
                },
                items: $this.optionsComputed, 
                callbackOnInit: function() {
                    addCustombtn(this)
                },
                callbackOnCreateTemplates: function (template) {
                    let itemSelectText = this.config.itemSelectText;
                    return {
                        item: function ({ classNames }, data) {
                            return template(`
                            <div
                                class="${String(classNames.item)} ${String(data.highlighted ? classNames.highlightedState : classNames.itemSelectable)} "
                                data-item
                                data-id="${String(data.id)}"
                                data-value="${String(data.value)}"
                                ${String(data.active ? 'aria-selected="true"' : '')} 
                                ${String(data.disabled ? 'aria-disabled="true"' : '')}>
                                <div class="select-user-icon me-3">
                                    <img src="${String(data.customProperties.icon)}" />
                                </div>
                                <span class="select-social-icon me-4">
                                    <img src="${String(data.customProperties.socialIcon)}" />
                                </span>
                                ${String(data.label)} 
                            </div>
                            `);
                        },
                        choice: function ({ classNames }, data) {
                            
                            return template(`
                                <div 
                                    class="${String(classNames.item)} ${String(classNames.itemChoice)} ${String(data.disabled ? classNames.itemDisabled : classNames.itemSelectable)} ${String(data.selected ? classNames.selectedState : '')} d-flex align-items-center"
                                    data-select-text="${String(itemSelectText)}"
                                    data-choice ${String(data.disabled ? 'data-choice-disabled aria-disabled=true' : 'data-choice-selectable')}
                                    data-id="${String(data.id)}"
                                    data-value="${String(data.value)}"
                                    data-label="${String(data.label)}"
                                    ${String(data.groupId > 0 ? 'role="treeitem"' : 'role="option"')}>     
                                    <span class="select-user-icon me-3">
                                        <img src="${String(data.customProperties.icon)}" />
                                    </span>
                                    <span class="select-social-icon me-4">
                                        <img src="${String(data.customProperties.socialIcon)}" />
                                    </span>
                                    ${String(data.label)}                                
                                </div>`)
                        }
                    }
                }
            });
            // setInterval(() => {
            //     choices.setChoiceByValue('val-2');
            //     console.log("setChoiceByValue('val-2');");
            // }, 10000);
        }
        if(this.identifier === 'select-checkbox'){
            this.choices = new Choices(this.$refs[this.refId], {
                searchChoices: false,
                searchEnabled: false,
                allowHTML: true,
                shouldSort: false,
                classNames: {
                    containerOuter: 'choices select-icon select-checkbox-box',
                    selectedState: 'is-selected',
                },
                items: $this.options,
                callbackOnCreateTemplates: function (template) {
                    let itemSelectText = this.config.itemSelectText;
                    return {
                        item: function ({ classNames }, data) {
                            return template(`
                            <div
                                class="${String(classNames.item)} ${String(data.highlighted ? classNames.highlightedState : classNames.itemSelectable)} "
                                data-item
                                data-id="${String(data.id)}"
                                data-value="${String(data.value)}"
                                ${String(data.active ? 'aria-selected="true"' : '')} 
                                ${String(data.disabled ? 'aria-disabled="true"' : '')}>
                                ${String(data.label)} 
                            </div>
                            `);
                        },
                        choice: function ({ classNames }, data) {
                            return template(`
                                <div 
                                    class="${String(classNames.item)} ${String(classNames.itemChoice)} ${String(data.disabled ? classNames.itemDisabled : classNames.itemSelectable)} ${String(data.selected ? classNames.selectedState : '')} d-flex align-items-center"
                                    data-select-text="${String(itemSelectText)}"
                                    data-choice ${String(data.disabled ? 'data-choice-disabled aria-disabled=true' : 'data-choice-selectable')}
                                    data-id="${String(data.id)}"
                                    data-value="${String(data.value)}"
                                    data-label="${String(data.label)}"
                                    ${String(data.groupId > 0 ? 'role="treeitem"' : 'role="option"')}>                                        
                                    <label class="custom-checkbox me-2">
                                        <input type="checkbox" class="custom-checkbox__input" ${String(data.selected ? 'checked' : '')}>
                                        <span class="custom-checkbox__input-fake">
                                        </span>
                                    </label>
                                    ${String(data.label)}  
                                                                
                                    <span class='text-data-price'><span>${String(data.customProperties.date)} </span>${String(data.customProperties.price)}</span>
                                </div>`)
                        }
                    }
                }
            });
        }
        if(this.identifier === 'select-default'){
            this.choices = new Choices(this.$refs[this.refId], {
                searchChoices: false,
                searchEnabled: false,
                allowHTML: true,
                shouldSort: false,
                classNames: {
                    containerOuter: 'choices select-default'+($this.additionalClass?' '+$this.additionalClass:''),
                    selectedState: 'is-selected',
                },
                items: $this.options,
                // choices: $this.options.map(item => {
                //     return ''+$this.value === ''+item.value ? {
                //         ...item,
                //         selected: true,
                //     } : item
                // })
            });
        }
        if(this.identifier === 'chose-social'){
            this.choices = new Choices(this.$refs[this.refId], {
                searchChoices: false,
                searchEnabled: false,
                allowHTML: true,
                shouldSort: false,
                classNames: {
                    containerOuter: 'choices chose-solial',
                    selectedState: 'is-selected',
                },
                items: $this.options,
                callbackOnCreateTemplates: function (template) {
                    let itemSelectText = this.config.itemSelectText;
                    return {
                        item: function ({ classNames }, data) {
                            return template(`
                            <div
                                class="${String(classNames.item)} ${String(data.highlighted ? classNames.highlightedState : classNames.itemSelectable)} "
                                data-item
                                data-id="${String(data.id)}"
                                data-value="${String(data.value)}"
                                ${String(data.active ? 'aria-selected="true"' : '')} 
                                ${String(data.disabled ? 'aria-disabled="true"' : '')}>
                                <img src='${String(data.customProperties.icon)}' />
                                ${String(data.label)} 
                            </div>
                            `);
                        },
                        choice: function ({ classNames }, data) {
                            return template(`
                                <div 
                                    class="${String(classNames.item)} ${String(classNames.itemChoice)} ${String(data.disabled ? classNames.itemDisabled : classNames.itemSelectable)} ${String(data.selected ? classNames.selectedState : '')} d-flex align-items-center"
                                    data-select-text="${String(itemSelectText)}"
                                    data-choice ${String(data.disabled ? 'data-choice-disabled aria-disabled=true' : 'data-choice-selectable')}
                                    data-id="${String(data.id)}"
                                    data-value="${String(data.value)}"
                                    data-label="${String(data.label)}"
                                    ${String(data.groupId > 0 ? 'role="treeitem"' : 'role="option"')}>                                        
                                    <img src='${String(data.customProperties.icon)}' />
                                    ${String(data.label)}    
                                </div>`)
                        }
                    }
                }
            });
        }
        if(this.identifier === 'select-icon-2'){
            this.choices = new Choices(this.$refs[this.refId], {
                searchChoices: false,
                searchEnabled: false,
                allowHTML: true,
                shouldSort: false,
                items: $this.options,
                classNames: {
                    containerOuter: 'choices select-icon-2',
                    selectedState: 'is-selected',
                },
                customProperties: {
                    description: 'Custom description about Option 2',
                    random: 'Another random custom property'
                },
                callbackOnCreateTemplates: function (template) {
                    let itemSelectText = this.config.itemSelectText;
                    return {
                        item: function ({ classNames }, data) {
                            let { element } = this.passedElement

                            return template(`
                            <div
                                class="${String(classNames.item)} ${String(data.highlighted ? classNames.highlightedState : classNames.itemSelectable)} "
                                data-item
                                data-id="${String(data.id)}"
                                data-value="${String(data.value)}"
                                ${String(data.active ? 'aria-selected="true"' : '')} 
                                ${String(data.disabled ? 'aria-disabled="true"' : '')}>
                                <div class="select--item flex-auto ovh">
                                
                                    <span class="select-icon"><img src="${element.dataset.image}" /></span>
                                    <span class="select-text textable">${String(data.label)}</span>
                                    <span class="select-arrow ml-8 ico ico-15">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="width: 15px;">
                                            <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/>
                                        </svg>
                                    </span>  
                                </div>
                                <div class="select--item">
                                    <span class="select-text --primary-color">
                                        ${data.customProperties.text ? String(data.customProperties.text) : ''}
                                    </span>
                                </div>
                            </div>
                            `);
                        },
                        choice: function ({ classNames }, data) {
                            return template(`
                                <div 
                                    class="${String(classNames.item)} ${String(classNames.itemChoice)} ${String(data.disabled ? classNames.itemDisabled : classNames.itemSelectable)} ${String(data.selected ? classNames.selectedState : '')}"
                                    data-select-text="${String(itemSelectText)}"
                                    data-choice ${String(data.disabled ? 'data-choice-disabled aria-disabled=true' : 'data-choice-selectable')}
                                    data-id="${String(data.id)}"
                                    data-value="${String(data.value)}"
                                    data-label="${String(data.label)}"
                                    ${String(data.groupId > 0 ? 'role="treeitem"' : 'role="option"')}>                                        
                                    ${String(data.label)}  ${data.customProperties.text ?  " - " + String(data.customProperties.text) : ""}
                                </div>`)
                        }
                    }
                }

            });
        }
        if(this.identifier === 'select-image'){
            this.choices = new Choices(this.$refs[this.refId], {
                searchChoices: false,
                searchEnabled: false,
                allowHTML: true,
                shouldSort: false,
                classNames: {
                    containerOuter: 'choices select-toicon select-image',
                    selectedState: 'is-selected',
                },
                callbackOnInit: function() {
                    addCustombtn(this)
                },
                callbackOnCreateTemplates: function (template) {
                    let itemSelectText = this.config.itemSelectText;
                    return {
                        item: function ({ classNames }, data) {
                            return template(`
                            <div
                                class="${String(classNames.item)} ${String(data.highlighted ? classNames.highlightedState : classNames.itemSelectable)} "
                                data-item
                                data-id="${String(data.id)}"
                                data-value="${String(data.value)}"
                                ${String(data.active ? 'aria-selected="true"' : '')} 
                                ${String(data.disabled ? 'aria-disabled="true"' : '')}>
                                <div class="select-user-icon me-4">
                                    <img src="${String(data.customProperties.icon)}" />
                                </div>
                                ${String(data.label)} 
                            </div>
                            `);
                        },
                        choice: function ({ classNames }, data) {
                            
                            return template(`
                                <div 
                                    class="${String(classNames.item)} ${String(classNames.itemChoice)} ${String(data.disabled ? classNames.itemDisabled : classNames.itemSelectable)} ${String(data.selected ? classNames.selectedState : '')} d-flex align-items-center"
                                    data-select-text="${String(itemSelectText)}"
                                    data-choice ${String(data.disabled ? 'data-choice-disabled aria-disabled=true' : 'data-choice-selectable')}
                                    data-id="${String(data.id)}"
                                    data-value="${String(data.value)}"
                                    data-label="${String(data.label)}"
                                    ${String(data.groupId > 0 ? 'role="treeitem"' : 'role="option"')}>     
                                    <span class="select-user-icon me-4">
                                        <img src="${String(data.customProperties.icon)}" />
                                    </span>
                                    ${String(data.label)}                                
                                </div>`)
                        }
                    }
                }

            });
        }
        if(this.identifier === 'select-icon-3'){
            this.choices = new Choices(this.$refs[this.refId], {
                searchChoices: false,
                searchEnabled: false,
                allowHTML: true,
                shouldSort: false,
                items: $this.options,
                classNames: {
                    containerOuter: 'choices select-lang',
                    selectedState: 'is-selected',
                },
                customProperties: {
                    description: 'Custom description about Option 2',
                    random: 'Another random custom property'
                },
                callbackOnCreateTemplates: function (template) {
                    let itemSelectText = this.config.itemSelectText;
                    return {
                        item: function ({ classNames }, data) {
                            let { element } = this.passedElement

                            console.log(data);
                            return template(`
                            <div
                                class="${String(classNames.item)} ${String(data.highlighted ? classNames.highlightedState : classNames.itemSelectable)} "
                                data-item
                                data-id="${String(data.id)}"
                                data-value="${String(data.value)}"
                                ${String(data.active ? 'aria-selected="true"' : '')} 
                                ${String(data.disabled ? 'aria-disabled="true"' : '')}>
                                <div class="choices__item choices__item--selectable">
                                    <span class="fi ico-flag fi-us"></span>
                                    <span class="fi-text">
                                        ${String(data.label)} 
                                    </span>
                                    <span class="ico ico-arrow ml-auto ico-20">
                                        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 1.00015L5.76444 5.76415C5.82689 5.82668 5.91163 5.86182 6 5.86182C6.08837 
                                            5.86182 6.17311 5.82668 6.23556 5.76415L11 1.00015" stroke="white" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round"/>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            `);
                        },
                        choice: function ({ classNames }, data) {

                            console.log(data);
                            return template(`
                                <div 
                                    class="${String(classNames.item)} ${String(classNames.itemChoice)} ${String(data.disabled ? classNames.itemDisabled : classNames.itemSelectable)} ${String(data.selected ? classNames.selectedState : '')}"
                                    data-select-text="${String(itemSelectText)}"
                                    data-choice ${String(data.disabled ? 'data-choice-disabled aria-disabled=true' : 'data-choice-selectable')}
                                    data-id="${String(data.id)}"
                                    data-value="${String(data.value)}"
                                    data-label="${String(data.label)}"
                                    ${String(data.groupId > 0 ? 'role="treeitem"' : 'role="option"')}>                                        
                                    <span class='fi ico-flag fi-fr'>
                                        
                                    </span>
                                    <span class="fi-text">${String(data.label)}</span>
                                </div>`)
                        }
                    }
                }

            });
        }
        if(this.identifier === 'select-icon'){
            this.choices = new Choices(this.$refs[this.refId], {
                searchChoices: false,
                searchEnabled: false,
                allowHTML: true,
                shouldSort: false,
                items: $this.options,
                classNames: {
                    containerOuter: 'choices select-icon',
                    selectedState: 'is-selected',
                },
                customProperties: {
                    description: 'Custom description about Option 2',
                    random: 'Another random custom property'
                },
                callbackOnCreateTemplates: function (template) {
                    let itemSelectText = this.config.itemSelectText;
                    return {
                        item: function ({ classNames }, data) {
                            let { element } = this.passedElement

                            return template(`
                            <div
                                class="${String(classNames.item)} ${String(data.highlighted ? classNames.highlightedState : classNames.itemSelectable)} "
                                data-item
                                data-id="${String(data.id)}"
                                data-value="${String(data.value)}"
                                ${String(data.active ? 'aria-selected="true"' : '')} 
                                ${String(data.disabled ? 'aria-disabled="true"' : '')}>
                                <span class="select-icon"><img src="${element.dataset.image}" /></span> ${String(data.label)} 

                            </div>
                            `);
                        }, // assets/img/socila-icons/
                        choice: function ({ classNames }, data) {
                            return template(`
                                <div 
                                    class="${String(classNames.item)} ${String(classNames.itemChoice)} ${String(data.disabled ? classNames.itemDisabled : classNames.itemSelectable)} ${String(data.selected ? classNames.selectedState : '')}"
                                    data-select-text="${String(itemSelectText)}"
                                    data-choice ${String(data.disabled ? 'data-choice-disabled aria-disabled=true' : 'data-choice-selectable')}
                                    data-id="${String(data.id)}"
                                    data-value="${String(data.value)}"
                                    data-label="${String(data.label)}"
                                    ${String(data.groupId > 0 ? 'role="treeitem"' : 'role="option"')}>                                        
                                    ${String(data.label)}
                                </div>`)
                        }
                    }
                }

            });
        }
        if(this.identifier === 'select-price'){
            this.choices = new Choices(this.$refs[this.refId], {
                searchChoices: false,
                searchEnabled: false,
                allowHTML: true,
                shouldSort: false,
                classNames: {
                    containerOuter: 'choices select-price'+($this.additionalClass?' '+$this.additionalClass:''),
                    selectedState: 'is-selected',
                },
                customProperties: {
                    description: 'Custom description about Option 2',
                    random: 'Another random custom property'
                },
                items: $this.options,
                callbackOnCreateTemplates: function (template) {
                    let itemSelectText = this.config.itemSelectText;
                    return {
                        item: function ({ classNames }, data) {
                            let { element } = this.passedElement

                            return template(`
                            <div
                                class="${String(classNames.item)} ${String(data.highlighted ? classNames.highlightedState : classNames.itemSelectable)} "
                                data-item
                                data-id="${String(data.id)}"
                                data-value="${String(data.value)}"
                                ${String(data.active ? 'aria-selected="true"' : '')} 
                                ${String(data.disabled ? 'aria-disabled="true"' : '')}>
                                <div class="d-flex w-full flex justify-between">
                                    <div class="flex">
                                        <span>${String(data.label)} </span>

                                        ${data.customProperties.desk ?  `<p class="desk text-gray-500 ml-10">${String(data.customProperties.desk)}</p>`  : ""}
                                    </div>
                                    

                                    <span class="price float-right">
                                        <span class="price-old line-through mr-[4px] text-[#A2A8B0] subber">${data.customProperties.price_old ?  " " + String(data.customProperties.price_old) : ""}</span>
                                        <span class="price-new info text-primary-500 font-semibold">${data.customProperties.price ?  " " + String(data.customProperties.price) : ""}</span>
                                    </span>
                                </div>
                                
                            </div>
                            `);
                        },
                        choice: function ({ classNames }, data) {
                            return template(`
                                <div 
                                    class="${String(classNames.item)} ${String(classNames.itemChoice)} ${String(data.disabled ? classNames.itemDisabled : classNames.itemSelectable)} ${String(data.selected ? classNames.selectedState : '')}"
                                    data-select-text="${String(itemSelectText)}"
                                    data-choice ${String(data.disabled ? 'data-choice-disabled aria-disabled=true' : 'data-choice-selectable')}
                                    data-id="${String(data.id)}"
                                    data-value="${String(data.value)}"
                                    data-label="${String(data.label)}"
                                    ${String(data.groupId > 0 ? 'role="treeitem"' : 'role="option"')}>                                        
                                    <div class="d-flex w-full flex justify-between">
                                        
                                        <div class="flex">
                                            <span>${String(data.label)} </span>

                                            ${data.customProperties.desk ?  `<p class="desk text-gray-500 ml-10">${String(data.customProperties.desk)}</p>`  : ""}
                                        </div>

                                        <span class="price float-right">
                                            <span class="price-old line-through mr-[4px] text-[#A2A8B0] subber">${data.customProperties.price_old ?  " " + String(data.customProperties.price_old) : ""}</span>
                                            <span class="price-new info text-primary-500 font-semibold">${data.customProperties.price ?  " " + String(data.customProperties.price) : ""}</span>
                                        </span>
                                    </div>
                                    
                                </div>`)
                                // <span class="save">
                                //         ${data.customProperties.save ?  " " + String(data.customProperties.save) : ""}
                                //     </span>
                        }
                    }
                }
            });
        }
        this.$nextTick(()=>{
            this.choices.setChoiceByValue(this.options[0].value);

        })
    }
},
computed:{
    refId(){
        
        return this.additionalRefId ? this.identifier+this.additionalRefId : this.identifier
    },
},
beforeDestroy(){
    this.choices.destroy();
    this.destroyed=true;
    this.$refs[this.refId].remove();
    // this.$nextTick(()=>{
    //     this.$destroy()
    // })
},

created(){
    this.choices?.setChoiceByValue(String(this.value));
},
mounted(){
    // this.choices.setChoiceByValue(String(this.value));
    this.init()
}

}

</script>
<style>

</style>