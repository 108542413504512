<template>
    <section class="section pt-[14px] lg:pt-0 flex-auto flex lg:block">
        <div class="container mx-0 container-dash flex flex-col flex-auto">
            <h4 class="text-center lg:text-[40px] mb-[20px]">Instagram <span class="lg:text-primary-500">Profile Viewer</span></h4>
            <p class="text-secondary-500 text-center mb-[40px]">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.
            </p>
            <div class="max-w-[690px] mx-auto w-full">
                <div class="row items-center mb-[20px]">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label class="input-wraper input-wrapper-ico--left">
                                <span class="input-icon text-primary-500">
                                    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2102 5.64967H18.7554C21.8251 5.65276 24.313 8.14021 24.3166 11.2099V18.7551C24.3135 21.8249 21.826 24.3127 18.7563 24.3163H11.2111C8.14138 24.3132 5.65351 21.8258 5.6499 18.7561V11.2109C5.65299 8.14115 8.14045 5.65327 11.2102 5.64967ZM21.3602 21.36C22.051 20.6691 22.4391 19.7321 22.4391 18.7551V11.2109C22.4391 9.17673 20.7904 7.5276 18.7563 7.52708H11.2111C9.17697 7.52708 7.52784 9.1758 7.52732 11.2099V18.7551C7.52732 20.7893 9.17604 22.4384 11.2102 22.4389H18.7554C19.7324 22.4389 20.6694 22.0508 21.3602 21.36Z" fill="currentColor"></path>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1562 14.9825C10.1599 12.3184 12.3184 10.1594 14.9826 10.1553V10.1562C17.6474 10.1588 19.8076 12.3176 19.8117 14.9825C19.8086 17.6478 17.6487 19.8076 14.9835 19.8107C12.3186 19.8071 10.1593 17.6474 10.1562 14.9825ZM12.0327 14.9835C12.0327 16.6126 13.3534 17.9333 14.9826 17.9333C16.6117 17.9333 17.9324 16.6126 17.9324 14.9835C17.9324 13.3543 16.6117 12.0336 14.9826 12.0336C13.3534 12.0336 12.0327 13.3543 12.0327 14.9835Z" fill="currentColor"></path>
                                        <circle cx="19.8201" cy="10.1917" r="1.15656" fill="currentColor"></circle>
                                    </svg>
                                </span>
                                <input type="text" class="input font-semibold" placeholder="Instagram username">
                            </label>
                        </div>
                    </div>
                    <div class="col-lg-6 hidden lg:block">
                        <a href="#" class="btn btn--primary pl-[56px] pr-[27px] py-[20px] icon-btn rounded-[8px] w-full">
                            <div class="icon-left">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2 11.5333C16.2 14.1107 14.1107 16.2 11.5333 16.2C8.956 16.2 6.86667 14.1107 6.86667 11.5333C6.86667 8.956 8.956 6.86667 11.5333 6.86667C14.1107 6.86667 16.2 8.956 16.2 11.5333ZM5 11.5333C5 15.1416 7.92507 18.0667 11.5333 18.0667C13.0008 18.0667 14.3554 17.5828 15.446 16.766L17.4067 18.7266C17.7712 19.0911 18.3621 19.0911 18.7266 18.7266C19.0911 18.3621 19.0911 17.7712 18.7266 17.4067L16.766 15.446C17.5828 14.3554 18.0667 13.0008 18.0667 11.5333C18.0667 7.92507 15.1416 5 11.5333 5C7.92507 5 5 7.92507 5 11.5333Z" fill="currentColor"></path>
                                    <mask id="mask0_82_4320" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="5" y="5" width="14" height="14">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2 11.5333C16.2 14.1107 14.1107 16.2 11.5333 16.2C8.956 16.2 6.86667 14.1107 6.86667 11.5333C6.86667 8.956 8.956 6.86667 11.5333 6.86667C14.1107 6.86667 16.2 8.956 16.2 11.5333ZM5 11.5333C5 15.1416 7.92507 18.0667 11.5333 18.0667C13.0008 18.0667 14.3554 17.5828 15.446 16.766L17.4067 18.7266C17.7712 19.0911 18.3621 19.0911 18.7266 18.7266C19.0911 18.3621 19.0911 17.7712 18.7266 17.4067L16.766 15.446C17.5828 14.3554 18.0667 13.0008 18.0667 11.5333C18.0667 7.92507 15.1416 5 11.5333 5C7.92507 5 5 7.92507 5 11.5333Z" fill="white"></path>
                                    </mask>
                                    <g mask="url(#mask0_82_4320)">
                                    </g>
                                </svg>

                            </div>
                            <span>Search</span>
                        </a>
                    </div>
                </div>

                
            </div>
            <div class="mt-auto max-w-[690px] mx-auto w-full lg:hidden">
                <a href="#" class="btn btn--primary pl-[56px] pr-[27px] py-[20px] icon-btn rounded-[8px] w-full">
                    <div class="icon-left">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2 11.5333C16.2 14.1107 14.1107 16.2 11.5333 16.2C8.956 16.2 6.86667 14.1107 6.86667 11.5333C6.86667 8.956 8.956 6.86667 11.5333 6.86667C14.1107 6.86667 16.2 8.956 16.2 11.5333ZM5 11.5333C5 15.1416 7.92507 18.0667 11.5333 18.0667C13.0008 18.0667 14.3554 17.5828 15.446 16.766L17.4067 18.7266C17.7712 19.0911 18.3621 19.0911 18.7266 18.7266C19.0911 18.3621 19.0911 17.7712 18.7266 17.4067L16.766 15.446C17.5828 14.3554 18.0667 13.0008 18.0667 11.5333C18.0667 7.92507 15.1416 5 11.5333 5C7.92507 5 5 7.92507 5 11.5333Z" fill="currentColor"></path>
                            <mask id="mask0_82_4320" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="5" y="5" width="14" height="14">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2 11.5333C16.2 14.1107 14.1107 16.2 11.5333 16.2C8.956 16.2 6.86667 14.1107 6.86667 11.5333C6.86667 8.956 8.956 6.86667 11.5333 6.86667C14.1107 6.86667 16.2 8.956 16.2 11.5333ZM5 11.5333C5 15.1416 7.92507 18.0667 11.5333 18.0667C13.0008 18.0667 14.3554 17.5828 15.446 16.766L17.4067 18.7266C17.7712 19.0911 18.3621 19.0911 18.7266 18.7266C19.0911 18.3621 19.0911 17.7712 18.7266 17.4067L16.766 15.446C17.5828 14.3554 18.0667 13.0008 18.0667 11.5333C18.0667 7.92507 15.1416 5 11.5333 5C7.92507 5 5 7.92507 5 11.5333Z" fill="white"></path>
                            </mask>
                            <g mask="url(#mask0_82_4320)">
                            </g>
                        </svg>

                    </div>
                    <span>Search</span>
                </a>
            </div>
        </div>
    </section>
    
</template>

<script>
export default {

}
</script>

<style>

</style>