<template>
    <div id="sign-up-step-2" class="modal modal-20 modal-login modal--show">
        <div class="modal__dialog modal__dialog--587">
            <div class="modal__content">
                <div class="modal__header header-center px-[27px] sm:px-[125px]">
                    <a href="#" class="btn btn-close" data-close-modal="" @click.prevent="goStepOne">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.6818 11.6366L3 11.6366" stroke="#222B45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M10.6364 19.2727L3 11.6364L10.6364 4" stroke="#222B45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </a>
                    <h6 class="font-[600] text-[14px] leading-[24px] sm:hidden">Sign up</h6>
                </div>
                <div class="modal__body pb-[57px] px-[27px] sm:px-[125px]">
                    <div class="form-group mb-[19px]">
                        <label class="input-wraper fill-input input-wraper--label" :class="{ 'error': registerForm.name.touched && !registerForm.name.isValid }">
                            <!-- <input type="text" class="input font-semibold" placeholder="Full name" value="@kyliejenner"> -->
                            <input 
                                type="text" 
                                id="name" 
                                class="input font-semibold" 
                                v-model="registerForm.name.value"
                                @blur="onFieldBlur(registerForm, ['name'])"
                                placeholder="full name"
                                :disabled="registerForm.name.disabled"
                            >
                            <div class="label-input">
                                <span>Full name</span>
                            </div>
                        </label>
                    </div>

                    <div class="form-group mb-[19px]">
                        <label class="input-wraper fill-input input-wraper--label" :class="{ 'error': registerForm.password.touched && !registerForm.password.isValid }">
                            <!-- <input type="password" class="input font-semibold" placeholder="Password" value="@kyliejenner"> -->
                            <input 
                                type="password" 
                                id="password" 
                                class="input font-semibold" 
                                v-model="registerForm.password.value"
                                @blur="onFieldBlur(registerForm, ['password'])"
                                placeholder="password"
                                :disabled="registerForm.password.disabled"
                            >
                            <div class="label-input">
                                <span>Password</span>
                            </div>
                        </label>
                    </div>

                    <div class="form-group mb-[19px]">
                        <label class="input-wraper fill-input input-wraper--label" :class="{ 'error': registerForm.password_confirmation.touched && !registerForm.password_confirmation.isValid }">
                            <!-- <input type="text" class="input font-semibold" placeholder="Confirm password" value="@kyliejenner"> -->
                            <input 
                                type="password" 
                                id="password_confirmation" 
                                class="input font-semibold" 
                                v-model="registerForm.password_confirmation.value"
                                @blur="onFieldBlur(registerForm, ['password_confirmation'])"
                                placeholder="confirm password"
                                @input="confirmError = null"
                                :disabled="registerForm.password_confirmation.disabled"
                            >
                            <div class="label-input">
                                <span>Confirm password</span>
                            </div>
                            <div v-if="confirmError === false" class="confirm-decor">
                                <img src="@/assets/img/icon-confirm-password.svg">
                            </div>
                        </label>
                        <div class="confirmError" v-if="confirmError">{{ confirmError }}</div>
                    </div>

                    <a @click.prevent="register" href="#" class="btn btn--primary px-[27px] py-[19px] icon-btn rounded-[8px] font-[700] text-[14px] leading-[19px] mb-[19px]">
                        <span>Sign up</span>
                    </a>

                    <div class="or-decor-wrap mb-[20px]">
                        <span class="or-decor"></span>
                        <span class="px-[16px]">or</span>
                        <span class="or-decor"></span>
                    </div>

                    <a href="#" class="btn border text-text px-[27px] py-[18px] icon-btn rounded-[8px] font-[600] text-[14px] leading-[17px] mb-[18px]">
                        <div class="mr-[15px] flex">
                            <img src="@/assets/img/google.png">
                        </div>
                        <span>Continue with Google</span>
                    </a>
                    <span class="block text-center text-[12px] leading-[24px]">Have an account ?
                        <a @click.prevent="openModal('login')" href="#" class="btn font-[600] text-primary-500">Sign in</a>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import formValidation from '@/mixins/formValidation';
import { authApi } from '@/api/authApi';
export default {
    props: ['payload'],
    data() {
        return {
            email: null,
            registerForm: {},
            confirmError: null
        };
    },
    mixins: [formValidation],
    computed: {},
    methods: {
        closeModal() {
            this.$bus.$emit("modal", {
                name: "",
                payload: {},
            });
        },
        openModal(name, payload){
            this.$bus.$emit('modal',{
                name: name,
                overlay: true,
                payload,
            })
        },
        goStepOne() {
            this.openModal('register', {email: this.email})
        },
        async register() {
            const isValid = this.validate(this.registerForm)

            if (!isValid) {
                return
            }

            const data = {...this.getValues(this.registerForm), email: this.email}
            
            if (data.password !== data.password_confirmation) {
                this.registerForm.password_confirmation.isValid = false
                this.confirmError = "passwords does't match"
                return
            }

            this.disableField(this.registerForm, ['name', 'password', 'password_confirmation'])

            console.log(data, 'data');

            const res = await authApi.register(data)

            console.log(res, 'res register');

            if (res.hasError) {
                console.log(res.error);
            } else {
                
            }

            this.enableField(this.registerForm, ['name', 'password', 'password_confirmation'])
        }
    },
    created() {
        this.registerForm = this.createForm({
            name: {
                value: '',
                require: true,
            },
            password: {
                value: '',
                require: true,
            },
            password_confirmation: {
                value: '',
                require: true,
            },

        })
    },
    mounted() {
        console.log(this.payload, 'payload');
        this.email = this.payload?.email || null
        
    }
}
</script>

<style>
.confirmError {
    padding: 5px;
    color: red;
}
</style>