import restInstance from "@/lib/rest"

const rest = restInstance

export const dashboardApi = {

    async general()  {
        return await rest.request('/dashboard', 'GET', null, 'dashboard')
    },

    async getUserData() {
        return await rest.request('/dashboard/user', 'GET', null, 'user')
    },
    
    async getTrialUserData() {
        return await rest.request('/dashboard/trial-used', 'GET', _, 'trialUser')
    },

    async getAllAutofills() {
        return await rest.request('/autofills', 'GET', null, 'autofills')
    },
    
    async addAutofillsData(payload) {

        const data = {
            platform_id: payload.platform_id,  // айди платформы
            name: payload.name,  // имя пользователя
            image: payload.image,  // аватар со скрапера
        }

        return await rest.request('/autofills', 'POST', data, 'addAutofill')
    },

    async removeAutofillsData(id) {
        return await rest.request(`/autofills/${id}`, 'DELETE', null, 'removeAutofill')
    },
    
    async getSuggestionsData() {
        return await rest.request('/dashboard/suggestions', 'GET', _, 'suggestions')
    },
    
    async getRecommendedData() {
        return await rest.request('/dashboard/recommended', 'GET', _, 'recommended')
    },

    

}
