<template>
    <div id="add-new-card" class="modal modal-13 modal--show">
        <div class="modal__dialog modal__dialog--797">
            <div class="modal__content max-sm-radius-0" v-click-outside="closeModal">
                <div class="modal__header pb-[33px] px-[28px] sm:px-[59px]">
                    <div class="flex items-center justify-center lg:justify-start relative w-full">
                        <a href="#" class="btn w-[40px] h-[40px] bg-primary-100 rounded-[11px] lg:hidden left-0 absolute">
                            <img src="@/assets/img/icons/left-arrow-long.svg" alt="">
                        </a>
                        <span class="def-text--1 font-semibold lg:text-[18px] lg:font-bold">{{payload?.cardPayload ? 'Update card information' : 'Add new card'}}</span>
                    </div>
                    <a href="#" class="btn btn-close mobail-11x11 hidden sm:flex" data-close-modal=""
                        @click.prevent="closeModal()"
                    >
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.4771 2.94469C17.9223 2.51467 18.1009 1.87788 17.9441 1.27907C17.7874 0.68025 17.3197 0.212602 16.7209 0.0558617C16.1221 -0.100878 15.4853 0.0776826 15.0553 0.522914L8.99914 6.57736L2.94469 0.522914C2.51467 0.0776826 1.87788 -0.100878 1.27907 0.0558617C0.68025 0.212602 0.212602 0.68025 0.0558617 1.27907C-0.100878 1.87788 0.0776826 2.51467 0.522914 2.94469L6.57736 8.99914L0.522914 15.0553C0.0776826 15.4853 -0.100878 16.1221 0.0558617 16.7209C0.212602 17.3197 0.68025 17.7874 1.27907 17.9441C1.87788 18.1009 2.51467 17.9223 2.94469 17.4771L8.99914 11.4226L15.0553 17.4771C15.4853 17.9223 16.1221 18.1009 16.7209 17.9441C17.3197 17.7874 17.7874 17.3197 17.9441 16.7209C18.1009 16.1221 17.9223 15.4853 17.4771 15.0553L11.4226 8.99914L17.4771 2.94469Z" fill="#3A4C66"></path>
                        </svg>
                    </a>
                </div>
                <div class="modal__body pb-[47px] px-[28px] sm:px-[59px]">
                    <div class="row">
                        <div class="col-12 sm:hidden">
                            <div class="credit-card__template mb-5">
                                <div class="credit-card__template-name">
                                    <span class="credit-card__name">Name on card:</span>
                                    <span class="credit-card__name text-text">{{ cardInfo.card_name.value || "Cardholder" }}</span>
                                </div>
                                <span class="credit-card__template-number">{{ cardNumber.replace(/\-/g, " ") || "XXXX-XXXX-XXXX-XXXX" }}</span>
                                <div class="credit-card__template-data">
                                    <div class="flex items-center">
                                        <div class="flex flex-col mr-6">
                                            <span class="text-white-100 font-semibold">Expires on:</span>
                                            <span class="text-text">{{ cardInfo.date.value || 'MM/YY' }}</span>
                                        </div>
                                        <div class="flex flex-col">
                                            <span class="text-white-100 font-semibold">CVV:</span>
                                            <span class="text-text">{{ cardInfo.cvv.value || '***' }}</span>
                                        </div>
                                    </div>
                                    <div class="credit-card__logo">
                                        <img src="@/assets/img/icons/visa-white.svg" alt="Visa">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group mb-5">
                                <label class="input-wraper input-wraper--label fill-input"
                                        :class="{ 'invalid': cardInfo.card_name.touched && !cardInfo.card_name.isValid }">
                                    <input 
                                        type="text" 
                                        id="card_name" 
                                        class="input font-semibold" 
                                        placeholder="Cardholder"
                                        v-model="cardInfo.card_name.value"
                                        :disabled="cardInfo.card_name.disabled"
                                        @blur="onFieldBlur(cardInfo, ['card_name'])"
                                    >
                                    <div class="label-input">
                                        <span>Name on the card</span>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group mb-5">
                                <label class="input-wraper input-wraper--label fill-input"
                                        :class="{ 'invalid': cardInfo.card_number.touched && !cardInfo.card_number.isValid }">
                                    <input 
                                        type="text" 
                                        id="card_number" 
                                        class="input font-semibold"
                                        maxlength="19"
                                        placeholder="XXXX XXXX XXXX XXXX"
                                        :value="cardNumber"
                                        @input="setCardNumber"
                                        :disabled="cardInfo.card_number.disabled"
                                        @blur="onFieldBlur(cardInfo, ['card_number'])"
                                    >
                                    <div class="label-input">
                                        <span>Card Number</span>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group mb-5">
                                <label class="input-wraper input-wraper--label fill-input"
                                        :class="{ 'invalid': cardInfo.date.touched && !cardInfo.date.isValid }">
                                    <input 
                                        type="text" 
                                        id="date" 
                                        class="input font-semibold" 
                                        placeholder="(MM/YY)"
                                        maxlength="5"
                                        :value="cardInfo.date.value"
                                        @input="setCardDate"
                                        :disabled="cardInfo.date.disabled"
                                        @blur="onFieldBlur(cardInfo, ['date'])"
                                    >
                                    <div class="label-input">
                                        <span>Expiration date</span>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group mb-5">
                                <label class="input-wraper input-wraper--label fill-input"
                                :class="{ 'invalid': cardInfo.cvv.touched && !cardInfo.cvv.isValid }">
                                    <input 
                                        type="text" 
                                        id="cvv" 
                                        class="input font-semibold" 
                                        placeholder="CVV"
                                        maxlength="3"
                                        :value="cardInfo.cvv.value"
                                        @input="setCVV"
                                        :disabled="cardInfo.cvv.disabled"
                                        @blur="onFieldBlur(cardInfo, ['cvv'])" 
                                    >
                                    <div class="label-input">
                                        <span>CVV</span>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group mb-5">
                                <!-- <div class="form-group mb-4">
                                    <SelectBox
                                        key="select-1"
                                        identifier='select-default'
                                        label='text'
                                        :options="countriesOptions"
                                    />
                                </div> -->

                                <div class="select-multiple select-multiple--label not--lefticon" style="z-index:5;">
                                <div class="select-search">
                                    <div class="label-input">
                                        <span>Country</span>
                                    </div>
                                    <SelectBox 
                                        key="select-1"
                                        identifier='select-default'
                                        :options="countries"
                                        :value="cardInfo.country.value"
                                        @change="(value)=>{cardInfo.country.value = value;}"
                                    />
                                </div>
                                <div class="select-arrow">
                                    <span class="ico">
                                        <img src="@/assets/img/icons/ico-arrowdown.svg">
                                    </span>
                                </div>
                            </div>
                            </div>

                            
                        </div>
                        <div class="col-12 mb-5">
                            <p class="font-semibold text-text">*CVV or CVC is the card security code, unique three digits number on the back of your card separate from its number.</p>
                        </div>
                        <div class="col-12 mb-10">
                            <p class="font-semibold text-text">*Your card will be charged $0.50 for authorization, funds will be added to your balance</p>
                        </div>
                        <div v-if="errorText" class="col-12 mb-10">
                            <p class="font-semibold text-text error-text">{{ errorText }}</p>
                        </div>
                    </div>
                    <a href="#" class="btn btn--primary px-[23px] py-[13px] rounded-[8px] w-full"
                        @click.prevent="addCard" :class="{'disabled': disableSaveBtn}"
                    >
                        <span class="info">Save</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { paymentApi } from '@/api/paymentApi';
import SelectBox from '../Base/SelectBox.vue';
import { formatCardNumber } from '@/helpers/filters';
import formValidation from '@/mixins/formValidation';

export default {
    props:['payload'],
    components:{
        SelectBox,
    },
    mixins: [formValidation],
    data() {
        return {
            cardInfo: false,
            cardNumber: '',
            disableSaveBtn: false,
            errorText: '',
        };
    },
    computed: {
        countries() {
            return Object.entries(this.payload.dataprops.general.countryList).map((key) => ({value: key[0], label: key[1]}))
        },
    },
    methods: {
        closeModal() {
            this.$bus.$emit("modal", {
                name: "",
                payload: {},
            });
        },
        async addCard(){
            // this.payload.onSuccess({
            //     id: 44,
            //     name: 'iweruehruiwe weihwierh'
            // })
            const isValid = this.validate(this.cardInfo)

            if (!isValid) {
                return
            }

            const cardData = {
                ...this.getValues(this.cardInfo),
            }

            this.disableSaveBtn = true

            let requestData = {
                payment_type: 'cardinity',
				country: cardData.country || this.payload.dataprops.cartData.billingInformation.country,
                card: {
					name: cardData.card_name+'',
					number: cardData.card_number+'',
					cvv: cardData.cvv+'',
					month: String(cardData.date).split('/')[0]+'',
					year: String(new Date().getFullYear()).slice(0,2) + String(cardData.date).split('/')[1],
				},
				billingInformation: {
					email: this.payload.dataprops.cartData.billingInformation.email
				},
				browser_info: {
					browser_language: navigator.language,
					screen_width: window.innerWidth,
					screen_height: window.innerHeight,
					color_depth: screen.colorDepth,
					time_zone: new Date().getTimezoneOffset(),
				}
            };

            let $this = this

            // no csrf token in head
            const response = await paymentApi.createPaymentMethods({
                ...requestData, 
                _token: 'lNfvfqV6aleMaunJpbH14typOAmdui3QZ03Qzu0M'
                // _token: document.querySelector('meta[name="csrf-token"]').getAttribute('content') 
            })
            console.log('response', response)
            this.disableSaveBtn = false
            if(response.hasError) {
                $this.errorText = response.error.message;
                return
            }
				
            if (response.redirect) {
                window.location = response.redirect;
            }

            if(response.payment_method){
                // $this.$store.commit('dashboard/addPaymentMethod', response.body.data.payment_method);
                this.$bus.updateDataprops('paymentMethods', [...this.payload.dataprops.paymentMethods, response.payment_method]);
                $this.$emit('close');
            }

        },
        setCardNumber(e) {
            const onlyNumbers = e.target.value.replace(/\D/g, '')

            e.target.value = onlyNumbers

            this.cardNumber = formatCardNumber(onlyNumbers)
            this.cardInfo.card_number.value = onlyNumbers.replace(/\s/g, '')
        },
        setCardDate(e) {
            const onlyNumbers = e.target.value.replace(/\D/g, '')

            const regex = /^(\d{0,2})(\d{0,2})$/g

            e.target.value = onlyNumbers

            this.cardInfo.date.value = onlyNumbers.replace(regex, (regex, $1, $2) =>
                [$1, $2].filter(group => !!group).join('/')
            )
        },
        setCVV(e) {
            const onlyNumbers = e.target.value.replace(/\D/g, '')
            e.target.value = onlyNumbers
            this.cardInfo.cvv.value = onlyNumbers
        },
    },
    mounted(){
        console.log("this.$bus.payload = ", this.payload)
    },
    created() {
        
        this.cardInfo = this.createForm({
            card_number: {
                value: '',
                required: true
            },
            card_name: {
                value: '',
                required: true
            },
            date: {
                value: '',
                required: true
            },
            cvv: {
                value: '',
                required: true
            },
            country: {
                value: '',
                required: true,
            }
        });

        this.setValue(this.cardInfo, 'card_number', (this.payload.cardPayload?.card_number || ''), false);
        this.setValue(this.cardInfo, 'card_name', (this.payload.cardPayload?.card_name || ''), false);
        this.setValue(this.cardInfo, 'date', (this.payload.cardPayload?.date || ''), false);
        this.setValue(this.cardInfo, 'country', (this.payload.cardPayload?.country || this.payload.dataprops.cartData.billingInformation.country), false);
        this.cardNumber = this.payload.cardPayload?.card_number ? formatCardNumber(this.payload.cardPayload?.card_number) : ''
    },
};
</script>

<style scoped>
    .invalid {
        border: 1px solid red;
    }
    .error-text {
        text-align: center;
        color: red;
    }
</style>