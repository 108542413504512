<template>
    <div id="your-reward" class="modal modal-12 modal--show">
        <div class="modal__dialog modal__dialog--522">
            <div class="modal__content" v-click-outside="closeModal">
                <div class="modal__header pb-[17px] px-[28px] sm:px-[50px]">
                    <h6 class="font-bold text-[18px] leading-[24px]">Your reward:<br>
                        8% off everything
                        <div class="ml-[2px] w-[20px] inline-block">
                            <img src="@/assets/img/icon-fire.png">
                        </div>
                    </h6>
    
                    <a href="#" class="btn btn-close" data-close-modal="" @click.prevent="closeModal">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.4771 2.94469C17.9223 2.51467 18.1009 1.87788 17.9441 1.27907C17.7874 0.68025 17.3197 0.212602 16.7209 0.0558617C16.1221 -0.100878 15.4853 0.0776826 15.0553 0.522914L8.99914 6.57736L2.94469 0.522914C2.51467 0.0776826 1.87788 -0.100878 1.27907 0.0558617C0.68025 0.212602 0.212602 0.68025 0.0558617 1.27907C-0.100878 1.87788 0.0776826 2.51467 0.522914 2.94469L6.57736 8.99914L0.522914 15.0553C0.0776826 15.4853 -0.100878 16.1221 0.0558617 16.7209C0.212602 17.3197 0.68025 17.7874 1.27907 17.9441C1.87788 18.1009 2.51467 17.9223 2.94469 17.4771L8.99914 11.4226L15.0553 17.4771C15.4853 17.9223 16.1221 18.1009 16.7209 17.9441C17.3197 17.7874 17.7874 17.3197 17.9441 16.7209C18.1009 16.1221 17.9223 15.4853 17.4771 15.0553L11.4226 8.99914L17.4771 2.94469Z" fill="#3A4C66"></path>
                        </svg>
                    </a>
                </div>
                <div class="modal__body pb-[50px] px-[28px] sm:px-[50px]">
                    <div class="flex flex-col px-[18px] py-[14px] border-dashed rounded-[8px] mb-[18px]">
                        <span class="text-[12px] leading-[16px] font-[600] mb-[5px] text-text">Your code</span>
                        <span class="text-[14px] leading-[17px] font-[700] text-primary-500">{{cuponCode}}</span>
                    </div>
                    <a href="#" @click.prevent="copyToClipboard" class="btn btn--primary px-[27px] py-[19px] icon-btn rounded-[8px] mb-[29px] font-[700] text-[14px] leading-[19px]">
                        <div class="icon-left">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.6192 11.5239V7.71443C19.6192 5.87348 18.1268 4.38109 16.2859 4.38109H7.71443C5.87348 4.38109 4.38109 5.87348 4.38109 7.71443V16.2859C4.38109 18.1268 5.87348 19.6192 7.71443 19.6192H12.4763C13.2653 19.6192 13.9049 18.9796 13.9049 18.1906V16.2859C13.9049 14.4449 15.3973 12.9525 17.2382 12.9525H18.1906C18.9796 12.9525 19.6192 12.3129 19.6192 11.5239ZM19.5493 13.4794C19.164 13.7476 18.6957 13.9049 18.1906 13.9049H17.2382C15.9233 13.9049 14.8573 14.9709 14.8573 16.2859V18.1906C14.8573 18.5928 14.7576 18.9717 14.5815 19.3039C17.1897 18.5007 19.1608 16.244 19.5493 13.4794ZM7.71443 3.42871H16.2859C18.6528 3.42871 20.5716 5.34749 20.5716 7.71443V12.4763C20.5716 16.9472 16.9472 20.5716 12.4763 20.5716H7.71443C5.34749 20.5716 3.42871 18.6528 3.42871 16.2859V7.71443C3.42871 5.34749 5.34749 3.42871 7.71443 3.42871Z" fill="currentColor"></path>
                            </svg>
                        </div>
                        <span>Copy</span>
                    </a>
    
                    <div class="flex items-center justify-center mb-[29px]">
                        <span class="badge bg-teal mr-[20px]">
                            <span class="info">
                                VALID
                            </span>
                        </span>
                        <span class="font-[600] text-text2">Expires in 23 days, 16 hours</span>
                    </div>
                    <SelectBox
                        key="select-1"
                        identifier='select-icon'
                        :options="types"
                        :data-image="require('@/assets/img/icons/icon-tiktok-logo.svg')"
                    />
    
                    <div class="flex justify-between items-center mb-[17px]">
                        <span class="text-[14px] leading-[20px] font-[600]">Your rating</span>
    
                        <div class="rating-input">
                            <input type="radio" name="rating" id="rating-5">
                            <label for="rating-5"></label>
                            <input type="radio" name="rating" id="rating-4">
                            <label for="rating-4"></label>
                            <input type="radio" name="rating" id="rating-3">
                            <label for="rating-3"></label>
                            <input type="radio" name="rating" id="rating-2">
                            <label for="rating-2"></label>
                            <input type="radio" name="rating" id="rating-1">
                            <label for="rating-1"></label>
                        </div>
                    </div>
    
                    <div class="flex items-center px-[12px] py-[18px] rounded-[8px] border mb-[17px]">
                        <div class="mr-[9px]">
                            <span class="user-name-30x30 bg-black-100 text-white-100">KJ</span>
                        </div>
                        <span class="text-[14px] leading-[17px] font-[600]">@kylejenner</span>
                    </div>
    
                    <p class="text-[14px] leading-[20px] font-[600] mb-[29px]">
                        The service is great and the ordering process
                        is simple!
                    </p>
    
                    <SelectBox
                        key="select-2"
                        identifier='select-icon'
                        :options="types"
                        :data-image="require('@/assets/img/icons/icon-tiktok-logo.svg')"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SelectBox from "../Base/SelectBox.vue"
export default {
    components: {
        SelectBox,
    },
    data() {
        return {
            types: [
                {
                    value: "100",
                    label: "100 likes",
                    selected: true,
                },
                {
                    value: "250",
                    label: "250 likes",
                },
                {
                    value: "500",
                    label: "500 likes",
                },
                {
                    value: "750",
                    label: "750 likes",
                },
                {
                    value: "1000",
                    label: "1000 likes",
                }
            ],
            cuponCode: "HSDIJSA883HS",
        };
    },
    methods: {
        closeModal() {
            this.$bus.$emit("modal", {
                name: "",
                payload: {},
            });
        },
        copyToClipboard() {
            const el = document.createElement('textarea')
            el.value = this.cuponCode
            document.body.appendChild(el)
            el.select()
            document.execCommand('copy')
            document.body.removeChild(el)
        }
    },
}
</script>

<style>

</style>