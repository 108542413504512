<template>
    <div class="max-w-screen sm:max-w-[500px]">
        <div class="swiper swiper-reviews-single swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden">
        <swiper class="swiper-wrapper" :options="swiperOption" ref="mySwiper">

            <swiper-slide v-if="!isSecondVariant" v-for="item in 3" class="swiper-slide-small">
                <div class="review bg-[#E3E5F8]  bg-opacity-25 mt-[70px] max-w-[415px] mx-auto">
                <div class="review--head justify-center">
                    <div class="rating">
                        <label for="star-1" class="rating-star ico-35">
                            <svg width="40" height="40" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                            </svg>
                            <input type="radio" name="star-1" id="star-1" class="rating-input">
                        </label>
                        <label for="star-2" class="rating-star ico-35">
                            <svg width="40" height="40" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                            </svg>
                            <input type="radio" name="star-2" id="star-2" class="rating-input">
                        </label>
                        <label for="star-3" class="rating-star ico-35">
                            <svg width="40" height="40" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                            </svg>
                            <input type="radio" name="star-3" id="star-3" class="rating-input">
                        </label>
                        <label for="star-3" class="rating-star ico-35">
                            <svg width="40" height="40" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                            </svg>

                            <input type="radio" name="star-3" id="star-3" class="rating-input">
                        </label>
                        <label for="star-3" class="rating-star ico-35">
                            <svg width="40" height="40" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                            </svg>

                            <input type="radio" name="star-3" id="star-3" class="rating-input">
                        </label>
                    </div>
                </div>
                <div class="pt-[35px] pb-10 px-[40px] flex-col items-center text-center">
                    <div class="review--body--item mb-8">
                        <div class="review-text font-semibold">
                            Not only is it easy to use, but it’s also very powerful. I’ve been consistently hitting the explore page for the past few weeks.
                        </div>
                    </div>
                    <div class="review--body--item items-center justify-center">
                        <div class="mr-6">
                            <div class="wrapper-image rounded-full review-image flex flex-auto max-w-[56px] max-h-[56px]">
                                <img src="@/assets/img/review-img-tool.png">
                            </div>
                        </div>
                        <div class="flex flex-col items-start">
                            <span class="font-semibold text-[20px] leading-[24px] mb-4">Sofietje Boksem</span>
                            <div class="status flex items-center">
                                <span class="ico ico-20"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.044 6.71C19.228 7.3555 20 8.609 20 10C20 11.391 19.228 12.6445 18.044 13.29C18.4925 14.616 18.1695 16.1335 17.1515 17.1515C16.1335 18.169 14.6165 18.4925 13.29 18.044C12.6445 19.228 11.391 20 10 20C8.609 20 7.3555 19.228 6.71 18.044C5.385 18.4925 3.867 18.1695 2.8485 17.1515C1.8305 16.1335 1.508 14.616 1.956 13.29C0.772 12.6445 0 11.391 0 10C0 8.609 0.772 7.3555 1.956 6.71C1.5075 5.384 1.8305 3.8665 2.8485 2.8485C3.866 1.8305 5.383 1.5075 6.71 1.956C7.3555 0.772 8.609 0 10 0C11.391 0 12.6445 0.772 13.29 1.956C14.6165 1.509 16.1335 1.8305 17.1515 2.8485C18.1695 3.8665 18.492 5.384 18.044 6.71ZM9.2805 13.2805L14.2805 8.2805C14.573 7.9875 14.573 7.5125 14.2795 7.22C13.9865 6.927 13.512 6.927 13.219 7.22L8.7495 11.6895L6.78 9.72C6.487 9.427 6.0125 9.427 5.7195 9.72C5.4265 10.0125 5.4265 10.488 5.7195 10.7805L8.2195 13.2805C8.366 13.427 8.558 13.5 8.75 13.5C8.942 13.5 9.134 13.427 9.2805 13.2805Z" fill="#22E5E0"/>
                                </svg></span>
                                <span class="status-text def-text--3 font-semibold ml-[10px]">
                                    Verified customer
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </swiper-slide>


            
            
            <swiper-slide v-if="isSecondVariant" v-for="item in 3" class="swiper-slide-small second-variant ">
                <div class="review mt-[70px] max-w-[415px] mx-auto mb-[55px]">
                <div class="review--head justify-center">
                    <div class="rating">
                        <label for="star-1" class="rating-star ico-35">
                            <svg width="40" height="40" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                            </svg>
                            <input type="radio" name="star-1" id="star-1" class="rating-input">
                        </label>
                        <label for="star-2" class="rating-star ico-35">
                            <svg width="40" height="40" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                            </svg>
                            <input type="radio" name="star-2" id="star-2" class="rating-input">
                        </label>
                        <label for="star-3" class="rating-star ico-35">
                            <svg width="40" height="40" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                            </svg>
                            <input type="radio" name="star-3" id="star-3" class="rating-input">
                        </label>
                        <label for="star-3" class="rating-star ico-35">
                            <svg width="40" height="40" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                            </svg>
                            <input type="radio" name="star-3" id="star-3" class="rating-input">
                        </label>
                        <label for="star-3" class="rating-star ico-35">
                            <svg width="40" height="40" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                            </svg>
                            <input type="radio" name="star-3" id="star-3" class="rating-input">
                        </label>
                    </div>
                </div>
                <div class="pt-[55px] px-[40px] flex-col items-center text-center pb-[70px]">
                    <div class="review--body--item mb-8">
                        <div class="review-text font-semibold">
                            Not only is it easy to use, but it’s also very powerful. I’ve been consistently hitting the explore page for the past few weeks.
                        </div>
                    </div>
                    <div class="review--body--item items-center justify-center review--body--item-custom">
                        <div class="order-1 mb-[-125px] mt-[15px]">
                            <div class="wrapper-image rounded-full review-image flex flex-auto">
                                <img src="@/assets/img/user-img.png">
                            </div>
                        </div>
                        <div class="flex flex-col items-center text-center">
                            <span class="font-semibold text-[20px] leading-[24px] mb-4">Sofietje Boksem</span>
                            <div class="status flex items-center">
                                <span class="ico ico-20"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.044 6.71C19.228 7.3555 20 8.609 20 10C20 11.391 19.228 12.6445 18.044 13.29C18.4925 14.616 18.1695 16.1335 17.1515 17.1515C16.1335 18.169 14.6165 18.4925 13.29 18.044C12.6445 19.228 11.391 20 10 20C8.609 20 7.3555 19.228 6.71 18.044C5.385 18.4925 3.867 18.1695 2.8485 17.1515C1.8305 16.1335 1.508 14.616 1.956 13.29C0.772 12.6445 0 11.391 0 10C0 8.609 0.772 7.3555 1.956 6.71C1.5075 5.384 1.8305 3.8665 2.8485 2.8485C3.866 1.8305 5.383 1.5075 6.71 1.956C7.3555 0.772 8.609 0 10 0C11.391 0 12.6445 0.772 13.29 1.956C14.6165 1.509 16.1335 1.8305 17.1515 2.8485C18.1695 3.8665 18.492 5.384 18.044 6.71ZM9.2805 13.2805L14.2805 8.2805C14.573 7.9875 14.573 7.5125 14.2795 7.22C13.9865 6.927 13.512 6.927 13.219 7.22L8.7495 11.6895L6.78 9.72C6.487 9.427 6.0125 9.427 5.7195 9.72C5.4265 10.0125 5.4265 10.488 5.7195 10.7805L8.2195 13.2805C8.366 13.427 8.558 13.5 8.75 13.5C8.942 13.5 9.134 13.427 9.2805 13.2805Z" fill="#22E5E0"/>
</svg></span>
                                <span class="status-text">
                                    Verified customer
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </swiper-slide>

            
        </swiper>
        

        <div class="swiper-pagination style-pagination mt-[30px] swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal" slot="pagination">
            <span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 1"></span>
            <span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 2"></span>
            <span class="swiper-pagination-bullet swiper-pagination-bullet-active" tabindex="0" role="button" aria-label="Go to slide 3" aria-current="true"></span>
        </div>
        </div>
    </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
    require('swiper/dist/css/swiper.css')
  
 export default {
    components: {
        swiper,
        swiperSlide
    },
    props: ['isSecondVariant'],
    computed: {
        swiper() {
            return this.$refs.mySwiper.swiper
        }
    },
    mounted() {
        
        // this.swiper.slideTo(2, 250, false)
        
    },
    data() {
        return {
            swiperOption: {
            loop: true,
            infinity: true,
            slidesPerView: 1,
            // spaceBetween: 50,
            centeredSlides: true,
            pagination: '.swiper-pagination',
            paginationClickable: true,
            width: 500
            }
        }
    }
}
</script>

<style scoped>
    .style-pagination {
		position: relative;
	}

    .order-1 {
        width: 111.634px;
        height: 111.596px;
    }

    .review .review--body--item {
        flex-direction: column;
        align-items: center;
    }

    .status-text {
        color: #8F9BB3;

        font-family: Manrope;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;

        margin-left: 10px;
    }

    .review .review-text {
        margin-bottom: 42px;
        width: 268px;

        text-align: center;
        font-family: Manrope;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
    }

</style>