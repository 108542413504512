<template>
    <section
        class="py-[60px] sm:bg-primary-500 bg-black-100 promo-section section align-items-start flex-auto"
    >
        <div class="container mx-0 container-dash">
            <div class="steps-flex flex flex-col">
                <div
                    class="custom-columns custom-columns--50 mb-0-576 md:pt-[0px] pt-[40px] mb-[-50px]"
                >
                    <div
                        class="custom-col-6 justify-center steps-flex--text flex flex-col custom-col-12-1200 oder--1-1200 pb-[50px]"
                    >
                        <div class="heading-block mw-full-576">
                            <div class="flex items-center">
                                <h2
                                    class="heading heading-2 font-bold text-white-100"
                                >
                                    Check out our Tiktok free trials
                                </h2>
                            </div>
                            <div class="mt-[20px] mw-440">
                                <p
                                    class="def-text--2 font-semibold text-white-100"
                                >
                                    It's absolutely free! No surveys or annoying
                                    verifications. But choose wisely, as you can
                                    only get the free trial once.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        class="custom-col-6 mw-full-576 heading-block steps-flex--form flex flex-col custom-col-12-1200 pb-[50px]"
                    >
                        <div
                            class="social-card h-full flex items-center social-card--30 rounded-[10px] bg-white-100"
                        >
                            <div class="social-card--item w-full">
                                <ul class="flex flex-col mb-[-10px]">
                                    <li class="flex mb-[10px]">
                                        <a
                                            href="#"
                                            class="btn w-full justify-between font-semibold"
                                        >
                                            <span
                                                class="info text-left def-text--6"
                                            >
                                                10 Free TikTok Followers
                                            </span>
                                            <span class="ico ico-25 ml-[10px]">
                                                <img
                                                    src="@/assets/img/icons/chevron-left.svg"
                                                />
                                            </span>
                                        </a>
                                    </li>
                                    <li class="flex mb-[10px]">
                                        <a
                                            href="#"
                                            class="btn w-full justify-between font-semibold"
                                        >
                                            <span
                                                class="info text-left def-text--6"
                                            >
                                                20 Free TikTok Likes
                                            </span>
                                            <span class="ico ico-25 ml-[10px]">
                                                <img
                                                    src="@/assets/img/icons/chevron-left.svg"
                                                />
                                            </span>
                                        </a>
                                    </li>
                                    <li class="flex mb-[10px]">
                                        <a
                                            href="#"
                                            class="btn w-full justify-between font-semibold"
                                        >
                                            <span
                                                class="info text-left def-text--6"
                                            >
                                                100 Free Tiktok Views
                                            </span>
                                            <span class="ico ico-25 ml-[10px]">
                                                <img
                                                    src="@/assets/img/icons/chevron-left.svg"
                                                />
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    name: "Affiliate",
    components: {
    },
    props: [],
    data() {
        return {
        };
    },
    computed:{},
    methods: {},
    mounted() {},
};
</script>

