<template>
    <section class="pb-[65px] pt-[18px]">
        <div class="container mx-0 container-dash" v-if="ordersHistory">
            <div class="flex items-center justify-center relative mb-[50px] mt-[14px] lg:hidden">
                <a href="#" class="btn w-[40px] h-[40px] bg-primary-100 rounded-[11px] left-0 absolute">
                    <!-- <img src="./img/icons/left-arrow-long.svg" alt=""> -->
                </a>
                <span class="def-text--1 font-semibold">My Orders</span>
            </div>
            <div class="flex flex-wrap justify-between items-center ">
                <h6 class="mb-[15px]">Orders history</h6>
                <a @click.prevent="$router.push('/service-page/likes')" href="#" class="mb-[15px] btn btn--primary px-[23px] py-[13px] rounded-lg w-full max-w-[335px] def-text--1">New order</a>
            </div>
            <div class="table-def-wrapper">
                <table class="table-def" cellpadding="0" cellspacing="0">
                    <thead class="hidden md:table-header-group">
                        <tr>
                            <th class="text-left">Order Number</th>
                            <th class="text-left">Status</th>
                            <th class="text-left">Order Date</th>
                            <th class="text-left"></th>
                            <th class="text-left"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="ord in allOrders" >

                            <tr class="table--item" :key="ord.hash">
                                <td>
                                    <div class="user-socialinfo">
                                        <div class="socialinfo--header">
                                            <div class="info">
                                                <p>
                                                    <router-link :to="`/order-details/${ord.hash}`" class="underline text-primary-500">{{ ord.hash }}</router-link>
                                                    {{ ord?.line_items?.length ? `(${ord.line_items.length} items)` : null }}
                                                </p>
                                            </div>
                                            <div class="md:hidden">
                                                <span class="font-bold text-[18px]">${{ ord.total_price }}</span>
                                            </div>
                                        </div>
                                        <div class="socialinfo--body md:hidden">
                                            <div class="socialinfo--body--item">
                                                <div class="flex justify-between">
                                                    <span class="pill" :class="orderClass[ord.status]">
                                                        <span class="info">{{ statusText[ord.status] }}</span>
                                                    </span>
                                                    <span class="text-secondary-500 text-def text-def-1">
                                                        {{ String(ord.created_at).split('T')[0] }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div v-if="ord.status === statusEnum[0]" class="socialinfo--footer md:hidden justify-end">
                                            <router-link :to="`/order-details/${ord.hash}`" class="btn text-center bg-white-100 def-text--1 text-primary-500 px-[20px] py-[10px] rounded-lg btn--primary-outline">
                                                <span class="info">Pay now</span>
                                            </router-link>
                                        </div>

                                        <!-- // ask for review -->

                                        <template v-if="includeReview(ord)">

                                            <div v-if="!ord.review" class="socialinfo--footer md:hidden">
                                                <a href="#" data-modal="#write-review" class=" text-center def-text--1" @click.prevent="openModal('write-order-review')">
                                                    <span>Review your order</span>
                                                </a>
                                                <div class="rating">
                                                    <label v-for="(st, idx) in 5" for="star-1" class="rating-star ico-20" :key="idx">
                                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M9.05545 3.93816C9.21127 4.25708 9.62785 4.57403 9.9811 4.63233L12.0824 5.00669C13.4279 5.24347 13.7268 6.21673 12.7523 7.16912L11.0927 8.7821C10.8156 9.05246 10.6509 9.58719 10.7366 9.96456L11.1816 11.9885C11.5313 13.5863 10.6739 14.1964 9.26845 13.3473L7.30517 12.161C6.94765 11.9442 6.36167 11.9425 6.00429 12.1464L4.01581 13.2833C2.59241 14.0957 1.74702 13.468 2.13371 11.8799L2.62762 9.87026C2.71985 9.4946 2.57462 8.95611 2.30254 8.68074L0.670103 7.02858C-0.282213 6.05719 0.0386685 5.09144 1.38693 4.88312L3.49206 4.55934C3.84749 4.50179 4.27164 4.20288 4.4318 3.88463L5.62295 1.58317C6.27306 0.343622 7.30396 0.357185 7.91793 1.61463L9.05545 3.93816Z" fill="#77838F"></path>
                                                        </svg>
                                                        <input type="radio" name="star-1" id="star-1" class="rating-input">
                                                    </label>
                                                </div>
                                            </div>

                                            <div v-else class="socialinfo--footer md:hidden">
                                                <a data-modal="#your-reward" href="#" class="text-center def-text--1 text-primary-500" @click.prevent="openModal('see-order-review')">
                                                    <span class="underline">See your review</span>
                                                </a>
                                                <div class="rating">
                                                    <label v-for="(st, idx) in 5" for="star-1" class="rating-star ico-20" :key="idx">
                                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M9.05545 3.93816C9.21127 4.25708 9.62785 4.57403 9.9811 4.63233L12.0824 5.00669C13.4279 5.24347 13.7268 6.21673 12.7523 7.16912L11.0927 8.7821C10.8156 9.05246 10.6509 9.58719 10.7366 9.96456L11.1816 11.9885C11.5313 13.5863 10.6739 14.1964 9.26845 13.3473L7.30517 12.161C6.94765 11.9442 6.36167 11.9425 6.00429 12.1464L4.01581 13.2833C2.59241 14.0957 1.74702 13.468 2.13371 11.8799L2.62762 9.87026C2.71985 9.4946 2.57462 8.95611 2.30254 8.68074L0.670103 7.02858C-0.282213 6.05719 0.0386685 5.09144 1.38693 4.88312L3.49206 4.55934C3.84749 4.50179 4.27164 4.20288 4.4318 3.88463L5.62295 1.58317C6.27306 0.343622 7.30396 0.357185 7.91793 1.61463L9.05545 3.93816Z" 
                                                            :fill=" idx + 1 <= ord.review?.items[0]?.rating  ? '#F2AC23' : '#77838F'"></path>
                                                        </svg>
                                                        <input type="radio" name="star-1" id="star-1" class="rating-input">
                                                    </label>
                                                </div>
                                            </div>

                                        </template>

                                        

                                    </div>
                                </td>

                                <td class="hidden md:table-cell">
                                    <span class="pill" :class="orderClass[ord.status]">
                                        <span class="info">{{ statusText[ord.status] }}</span>
                                    </span>
                                </td>

                                <td class="hidden md:table-cell">
                                    <p class="text-secondary-500 text-def text-def-1">
                                        {{ String(ord.created_at).split('T')[0] }}
                                    </p>
                                </td>

                                <td class="hidden md:table-cell" v-if="includeReview(ord)">
                                    <a v-if="!ord.review" href="#" data-modal="#write-review" class=" text-center def-text--1 hover:!text-[#FE2C55]" @click.prevent="openModal('write-order-review')">
                                        <span>Review your order</span>
                                    </a>

                                    <a v-else data-modal="#your-reward" href="#" class="text-center def-text--1 text-primary-500" @click.prevent="openModal('see-order-review')">
                                        <span class="underline">See your review</span>
                                    </a>
                                </td>

                                <td v-else class="hidden md:table-cell" ></td>

                                <td class="hidden md:table-cell">
                                    <router-link :to="`/order-details/${ord.hash}`" v-if="ord.status === statusEnum[0]" class="btn text-center def-text--1 text-primary-500 btn--primary-outline w-[155px] py-[10px] rounded-lg">
                                        <span class="font-bold">Pay now</span>
                                    </router-link>
                                    

                                    <div v-if="includeReview(ord) && !ord.review" class="total-table--item">
                                        <div class="rating">
                                            <label v-for="(st, idx) in 5" for="star-1" class="rating-star ico-20" :key="idx">
                                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.05545 3.93816C9.21127 4.25708 9.62785 4.57403 9.9811 4.63233L12.0824 5.00669C13.4279 5.24347 13.7268 6.21673 12.7523 7.16912L11.0927 8.7821C10.8156 9.05246 10.6509 9.58719 10.7366 9.96456L11.1816 11.9885C11.5313 13.5863 10.6739 14.1964 9.26845 13.3473L7.30517 12.161C6.94765 11.9442 6.36167 11.9425 6.00429 12.1464L4.01581 13.2833C2.59241 14.0957 1.74702 13.468 2.13371 11.8799L2.62762 9.87026C2.71985 9.4946 2.57462 8.95611 2.30254 8.68074L0.670103 7.02858C-0.282213 6.05719 0.0386685 5.09144 1.38693 4.88312L3.49206 4.55934C3.84749 4.50179 4.27164 4.20288 4.4318 3.88463L5.62295 1.58317C6.27306 0.343622 7.30396 0.357185 7.91793 1.61463L9.05545 3.93816Z" fill="#77838F"></path>
                                                </svg>
                                                <input type="radio" name="star-1" id="star-1" class="rating-input">
                                            </label>
                                            
                                        </div>
                                    </div>

                                    <div v-if="includeReview(ord) && ord.review" class="total-table--item">
                                        <div class="rating">

                                            
                                            <label v-for="(st, idx) in 5" for="star-1" class="rating-star ico-20" :key="idx">
                                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.05545 3.93816C9.21127 4.25708 9.62785 4.57403 9.9811 4.63233L12.0824 5.00669C13.4279 5.24347 13.7268 6.21673 12.7523 7.16912L11.0927 8.7821C10.8156 9.05246 10.6509 9.58719 10.7366 9.96456L11.1816 11.9885C11.5313 13.5863 10.6739 14.1964 9.26845 13.3473L7.30517 12.161C6.94765 11.9442 6.36167 11.9425 6.00429 12.1464L4.01581 13.2833C2.59241 14.0957 1.74702 13.468 2.13371 11.8799L2.62762 9.87026C2.71985 9.4946 2.57462 8.95611 2.30254 8.68074L0.670103 7.02858C-0.282213 6.05719 0.0386685 5.09144 1.38693 4.88312L3.49206 4.55934C3.84749 4.50179 4.27164 4.20288 4.4318 3.88463L5.62295 1.58317C6.27306 0.343622 7.30396 0.357185 7.91793 1.61463L9.05545 3.93816Z" 
                                                    :fill=" idx + 1 <= ord.review?.items[0]?.rating  ? '#F2AC23' : '#77838F'"></path>
                                                </svg>
                                                <input type="radio" name="star-1" id="star-1" class="rating-input">
                                            </label>
                                        </div>
                                    </div>

                                </td>

                                <td class="hidden md:table-cell">
                                    <span class="font-bold text-[18px]">${{ord.total_price}}</span>
                                </td>

                            </tr>
                            <tr class="spacetable" :key="ord.hash + 'space'"></tr>
                        </template>
                    </tbody>
                </table>
            </div>

            <div class="flex flex-wrap justify-center items-center mt-[40px]" v-if="showLoadMore && allOrders.length && !isAllLoaded">
                <a 
                    href="#" class="mb-[15px] btn btn--primary px-[23px] py-[0px] rounded-lg w-full max-w-[335px] def-text--1 min-h-[48px]"
                    @click.prevent="loadMore" :disabled="dataLoading"
                >
                    <div style="display: flex; align-items: center; justify-content: center;" v-if="dataLoading">
                        <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve" style="width: 46px;">
                            <path fill="#FFFFFF" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s"  from="0 50 50" to="360 50 50" repeatCount="indefinite" />
                            </path>
                        </svg>
                    </div>
                    <span v-else>Show more</span>
                </a>
            </div>
        </div>
        <div class="container mx-0 container-dash" v-else>
            <div class="flex items-center justify-center relative mb-[50px] mt-[14px] lg:hidden">
                <a href="#" class="btn w-[40px] h-[40px] bg-primary-100 rounded-[11px] left-0 absolute">
                    <img src="@/assets/img/icons/left-arrow-long.svg" alt="">
                </a>
                <span class="def-text--1 font-semibold">Order details</span>
            </div>
            <div class="flex items-center justify-between mb-[25px]">
                <div class="flex flex-col items-center lg:items-start">
                    <h6 class="mb-[20px]">No orders yet</h6>
                    <p class="font-semibold text-text">It looks like you haven’t placed any orders yet.</p>
                </div>
            </div>
            <div>
                <a href="#" class="btn max-w-[335px] w-full btn--primary pl-[56px] pr-[27px] py-[13px] icon-btn rounded-[8px] mr-[12px] mb-[12px]">
                    <div class="flex icon-left">
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.1 5H6.9C5.57452 5 4.5 6.17525 4.5 7.625V16.375C4.5 17.8247 5.57452 19 6.9 19H18.1C19.4255 19 20.5 17.8247 20.5 16.375V7.625C20.5 6.17525 19.4255 5 18.1 5ZM17.564 6.75L12.5 10.9062L7.436 6.75H17.564ZM18.1 17.25H6.9C6.45817 17.25 6.1 16.8582 6.1 16.375V7.84375L12.02 12.7C12.1585 12.8136 12.3269 12.875 12.5 12.875C12.6731 12.875 12.8415 12.8136 12.98 12.7L18.9 7.84375V16.375C18.9 16.8582 18.5418 17.25 18.1 17.25Z" fill="white"></path>
                        </svg>
                    </div>
                    <span>Log in with email</span>
                </a>
                <a @click.prevent="$router.push('/service-page/likes')" href="#" class="btn max-w-[335px] w-full btn--primary-outline px-[27px] py-[13px] rounded-[8px] mb-[12px]">
                    <span>New order</span>
                </a>
            </div>
        </div>
    </section>
</template>

<script>
import { ordersApi } from '../api/ordersApi';

export default{
    data() {
        return {
            orderClass: {
                awaiting_payment: 'pill-warning',
                done: 'pill-completed',
                decline: 'pill-danger',
                refunded: 'pill-gray',
                paid: 'pill-success',
                pending: 'pill-progress'
            },
            statusText: {
                awaiting_payment: 'Awaiting payment',
                done: 'Completed',
                decline: 'Canceled',
                refunded: 'Refunded',
                paid: 'Paid',
                pending: 'In progress',
            },
            statusEnum: {
                0: 'awaiting_payment',
                1: 'done',
                2: 'decline',
                3: 'refunded',
                4: 'paid',
                5: 'pending'
            },
            ordersHistory: [],
            showLoadMore: true,
            dataLoading: false,
            fullInfoShow: {},
            loadedOrders: [],
            currentPage: 1,
        };
    },
    props: ['dataprops'],
    watch: {
        'dataprops.orders.items': {
            handler(newValue) {
                this.ordersHistory = this.dataprops.orders?.items;
            }
        }
    },
    computed: {
        isAllLoaded() {
            return window.orders_is_last
        },
        allOrders() {
            return [ ...this.ordersHistory, ...this.loadedOrders ].sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
        }
    },
    methods: {
        includeReview(ord) {
            return (ord.status === this.statusEnum[1] || ord.status === this.statusEnum[5] || ord.status === this.statusEnum[4])
        },
        openModal(name){
            this.$bus.$emit('modal',{
                name: name,
                overlay: true,
                payload: {},
            })
        },
        async loadMore() {
            this.dataLoading = true;
            this.currentPage = this.currentPage+1;

            let response = await ordersApi.getAllOrdersData(`?page=${this.currentPage}`);

            const existOrdersId = this.dataprops.orders?.items?.map(el => el.hash);

            response.items = response.items.filter(el => !existOrdersId.includes(el.hash));
            
            this.showLoadMore = !response.is_last;
            // this.loadedOrders = [...this.loadedOrders, ...response.items];
            this.dataLoading = false;

            // response.items.forEach(el => {
            //     this.$set(this.fullInfoShow, el.hash, false);
            // });

            this.$set(this.dataprops.orders, 'is_last', response.is_last);
            this.$set(this.dataprops.orders, 'items', [ ...this.dataprops.orders?.items, ...response.items ]);
        },
    
    },
    async mounted() {
        this.ordersHistory = this.dataprops.orders?.items;
        
        this.ordersHistory?.forEach(el => {
            this.$set(this.fullInfoShow, el.hash, false);
        });
    }
        
}
</script>