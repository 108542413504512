<template>
    <StepThree
        v-if="step === 1 && productGroup"

        :productGroup="productGroup"
        :config="config"
        :isPopUp="false"
        @stepThree="val => {cart = val; if (!isLoading) {afterPostsAction()}}"
    />

    <FreeTrialUsed
        v-else-if="step === 'used'"
        :productGroup="productGroup"

    />

    <WaitingForEmail
        v-else-if="step === 'noVerif'"
        :productGroup="productGroup"
        :config="config"
        :userManualData="userManualData"
        :orderHash="orderHash"

        @onSuccess="onSuccess"
    />

    <FreeTrialSuccess
        v-else-if="step === 'success'"
        :orderHash="order.hash"
    />

    <FreeTrialFailed
        v-else-if="step === 'failed'"
        :errorMessage="errorMessage"
    />




</template>

<script>
import StepThree from "@/components/Panel/StepThree.vue";
import FreeTrialUsed from "@/views/FreeTrialUsed.vue";
import WaitingForEmail from "@/views/WaitingForEmail.vue";
import {ordersApi} from "@/api/ordersApi";
import FreeTrialFailed from "@/views/FreeTrialFailed.vue";
import FreeTrialSuccess from "@/views/FreeTrialSuccess.vue";

export default {
    name: 'FreeTrialContainer',
    props: ['dataprops'],
    components: {FreeTrialSuccess, FreeTrialFailed, WaitingForEmail, FreeTrialUsed, StepThree},
    data() {
        return {
            step: 1,
            config: {
                selectedProduct: null,
                userData: null,
                mediaData: null,
                username: null,
            },
            isLoading: false,

            errorMessage: false,

            userManualData: {
                email: ''
            },

            productGroup: null,

            orderHash: null,

            cart: null,

            order: null,
        }
    },
    computed: {
    },
    methods: {
        async afterPostsAction() {
            const isLogged = Boolean(this.dataprops.user?.email)




            if (!isLogged) {
                this.$bus.$emit('modal',{
                    name: 'login',
                    overlay: true,
                    payload: {
                        email: this.userManualData.email
                    },
                })
            }

            const isUsed = this.dataprops.user.trial_used


            if (isUsed) {
                this.step = 'used'
                return
            }

            const orderObj = {
                email: this.userManualData.email,
                payment_type: 'free',
                items: [...this.cart.bought]
            }

            this.isLoading = true

            try {
                const order = await ordersApi.createOrderData(orderObj)

                let notConfirmed = false

                if (order.data?.success) {
                    this.order = order.data.order

                    console.log(order, 'order !!!!!!!!!!!!!!!!!')
                    notConfirmed = order.data.verify
                    this.orderHash = order.data.order.url_hash

                    this.step = 'success'

                } else {
                    this.errorMessage = order?.message || 'Something went wrong'
                    this.step = 'failed'
                    return
                }

                if (notConfirmed) {
                    this.step = 'noVerif'
                    return;
                }

            } catch (e) {

            } finally {
                this.isLoading = false
            }






        },

        onSuccess() {
            const old = this.dataprops?.orders?.items || []

            
            this.$bus.updateDataprops('orders', {
                ...this.dataprops?.orders,
                items: [this.order, ...old]
            })

            this.step = 'success'
        },

        async confirmFreeTrial() {

        }
    },

    mounted() {


        if (this.dataprops.platformConfig?.freePanel) {

            this.config.freePanel = true
            this.config.username = this.dataprops.platformConfig.usernameFreePanel
            this.userManualData.email = this.dataprops.platformConfig.email
            this.config.userData = this.dataprops.platformConfig.userData
            this.config.mediaData = this.dataprops.platformConfig.mediaData
            this.config.selectedProduct = this.dataprops.platformConfig.selectedProduct

            const identifier = this.dataprops.platformConfig.groupIdentifier
            this.config.groupIdentifier = identifier

            this.productGroup = this.dataprops.platformConfig.productGroup


            this.step = 1
        } else {
            this.$router.push(`/free-trial`)
        }

    },
}
</script>