
// export const getAvatarUrl = function(platform, item, type="panel"){
//     if(type==='panel'){
//         if(platform==='spotify'){
//             return item.images[0].url
//         }
//     }
// }
// export const getUserDataCountersTemplate = function(platform, item, type='panel'){
//     if(type==='panel'){
//         let config = {
//             spotify:[
//                 {
//                     name: 'Followers',
//                     count: item?.followers?.total
//                 }
//             ]
//         }
//         return config[platform]
//     }
// }
export const getServiceProductGroups = function (productGroups) {
    let hiddenGroups = [
        'instagram.autoComments',
        'instagram.tv.views',
        'instagram.tv.comments',
        'instagram.tv.likes',
        'instagram.tv.likes',
        'instagram.realComments',
        'instagram.reelsViews',
        'instagram.reelsLikes',
        'instagram.realLikes',
        'instagram.reelsComments',
        'instagram.realCustomComments',
        'instagram.verifiedCustomComments',
        'instagram.realFollowers'
    ]
    const services = []
    // console.log('getServiceProductGroups', productGroups);
    productGroups.forEach(item => {
        // console.log('item', item);
        let allAddedServices=[]
        services.forEach(s=>{
            if(s.subGroups){
                s.subGroups.forEach(sg=>{allAddedServices.push(sg.identifier)})
            }else{
                s.identifier
            }
        })
        // console.log();
        if (item.sub_groups) {
            if(!services.find(s=>(s.identifier===item.general_name))){
                services.push({
                    name: item.general_name,
                    identifier:item.general_name,
                    platform:item?.platform?.identifier,
                    subGroups:[
                        ...Object.entries(item.sub_groups).map(([identifier])=>{
                            let {name, active} = productGroups.find(item => item.identifier === identifier)
                            return {name, identifier, active}
                        })
                    ],
                    active: !!Object.entries(item.sub_groups).map(([identifier])=>{
                            let {name, active} = productGroups.find(item => item.identifier === identifier)
                            return {name, identifier, active}
                        }).find(item=>item.active)
                })

                services.at(-1).active = services.at(-1).subGroups.some(el => el.active);
            }
        } else {
            if(!allAddedServices.includes(item.identifier)){
                services.push({
                    name:item.name,
                    identifier: item.identifier,
                    active: item.active
                })
            }
        }
    });

    return services.filter(item=>item.active).filter(item=>!hiddenGroups.includes(item.identifier)).map(item=>{
        // console.log('!!!item', item);
        if(item.identifier === 'Comments' && item.platform==="instagram"){
            // let newItem = {...item}
            // newItem.subGroups.filter
            item.subGroups=item.subGroups.filter(subGroup=>subGroup.identifier!=='instagram.customComments')
            item.subGroups=item.subGroups.map(subGroup=>{
                if(subGroup.identifier==='instagram.randomComments'){
                    subGroup.name = "High Quality Comments"
                }
                return subGroup
            })
            item.subGroups = [
                item.subGroups.find(subGroup=>subGroup.identifier==='instagram.randomComments'),
                item.subGroups.find(subGroup=>subGroup.identifier==='instagram.realComments'),
                item.subGroups.find(subGroup=>subGroup.identifier==='instagram.verifiedComments'),
            ]
        }
        return item
    });
}

export const getGroupTypeName = function (productGroups, productGroupIdentifier) {
    let generalSubgroupsNames = {
        // "facebook.postLikes": translate('product-group-instant'),
        // "facebook.postRealLikes": translate('product-group-real'),
        // "facebook.postRandomComments": translate('product-group-random'),
        // "facebook.postCustomComments": translate('product-group-custom'),
        // "facebook.liveViews30": translate('product-group-30-min'),
        // "facebook.liveViews60": translate('product-group-60-min'),
        // "facebook.liveViews90": translate('product-group-90-min'),
        // "facebook.liveViews120": translate('product-group-120-min'),
        // "facebook.liveViews180": translate('product-group-180-min'),
        // "youtube.randomComments": translate('product-group-random'),
        // "youtube.customComments": translate('product-group-custom'),
        // "instagram.followers": translate('product-group-high-quality'),
        // "instagram.realFollowers": translate('product-group-real'),
        // "instagram.likes": translate('product-group-instant'),
        // "instagram.realLikes": translate('product-group-real'),
        // "instagram.randomComments": translate('product-group-random'),
        // "instagram.customComments": translate('product-group-custom'),
        // "instagram.verifiedComments": translate('product-group-verified'),
        // "instagram.realComments": translate('product-group-real'),
        // "tiktok.randomComments": translate('product-group-random'),
        // "tiktok.customComments": translate('product-group-custom')
    }
    if(generalSubgroupsNames[productGroupIdentifier]){
        return generalSubgroupsNames[productGroupIdentifier]
    }
   
    // let productSubGroups = () => { 
    //     let groupped = productGroups.reduce((acc, cur) => {
    //         // if (cur.sub_groups && !Array.isArray(cur.sub_groups) && !['instagram.randomComments','facebook.postRandomComments','youtube.randomComments','tiktok.randomComments'].includes(cur.identifier)) {
    //         if (cur.sub_groups && !Array.isArray(cur.sub_groups) ) {
    //             acc.push({
    //                 sub_groups: Object.entries(cur.sub_groups).map(([key,value])=>key),
    //                 identifier: cur.identifier,
    //                 name: cur.general_name
    //             })  
    //         } 
    //         return acc;
    //     },[]);

    //     return groupped
    // }
    let findedGlobalProductGroup = getServiceProductGroups(productGroups).filter(item=>item.subGroups).find(generalProductGroup=>generalProductGroup.subGroups.map(item1=>item1.identifier).includes(productGroupIdentifier))
    let productGroup = findedGlobalProductGroup.subGroups.find(item=>item.identifier===productGroupIdentifier)
    if(findedGlobalProductGroup){
        // если базовый тип в соединенных группах скрыть часть названия которая определяет базовый тип, пример : Hight quality followers -> Followers   
        if(findedGlobalProductGroup.name){
         
            return productGroup.name.replaceAll(findedGlobalProductGroup.name, '')
        }else{
            return productGroup.type_name
        }
        // return findedGlobalProductGroup.name  
    }else {
        return productGroup.name
    }
}
export const getGroupMainName = function (productGroups, productGroupIdentifier) {
    let generalSubgroupsNames = {
        // "facebook.postLikes": translate('product-group-instant'),
        // "facebook.postRealLikes": translate('product-group-real'),
        // "facebook.postRandomComments": translate('product-group-random'),
        // "facebook.postCustomComments": translate('product-group-custom'),
        // "facebook.liveViews30": translate('product-group-30-min'),
        // "facebook.liveViews60": translate('product-group-60-min'),
        // "facebook.liveViews90": translate('product-group-90-min'),
        // "facebook.liveViews120": translate('product-group-120-min'),
        // "facebook.liveViews180": translate('product-group-180-min'),
        // "youtube.randomComments": translate('product-group-random'),
        // "youtube.customComments": translate('product-group-custom'),
        // "instagram.followers": translate('product-group-high-quality'),
        // "instagram.realFollowers": translate('product-group-real'),
        // "instagram.likes": translate('product-group-instant'),
        // "instagram.realLikes": translate('product-group-real'),
        // "instagram.randomComments": translate('product-group-random'),
        // "instagram.customComments": translate('product-group-custom'),
        // "instagram.verifiedComments": translate('product-group-verified'),
        // "instagram.realComments": translate('product-group-real'),
        // "tiktok.randomComments": translate('product-group-random'),
        // "tiktok.customComments": translate('product-group-custom')
    }
    if(generalSubgroupsNames[productGroupIdentifier]){
        return generalSubgroupsNames[productGroupIdentifier]
    }
   
    
    let findedGlobalProductGroup = getServiceProductGroups(productGroups).find(generalProductGroup=>(generalProductGroup.subGroups ? generalProductGroup.subGroups.map(item1=>item1.identifier).includes(productGroupIdentifier) : generalProductGroup.identifier===productGroupIdentifier))
    return findedGlobalProductGroup.name
   
}
export function translate(key, payload) {
    const translations = window.translations;

    let translation = translations[key] || key;

    if (payload && Object.values(payload).length) {
        Object.values(payload).forEach((variable, value) => {
            translation = translation.replaceAll(`:${variable}`, value);
        });
    }

    return translation;
};
export const getCashbackValue = function (order, config) {
    let successStatuses = ['paid', 'confirmed', 'completed', 'processing'];
    if (successStatuses.includes(order.status)) {
        return order.balance_bonus??0;
    }
    try {
        let method = order.payment_information.payment_method;
        if (!config.enabled) return 0;
        let cBPercent = config.payment_methods[method];
        let total = order.total_price;
        return (total * cBPercent) / 100;
    } catch (error) {
        return 0;
    }
};

export const getCashbackPrecent = function(amount, cashback) {
    return Math.floor((cashback * 100) / amount);
};

export const validateCardNumberLength = function (val) {
    if(typeof val === 'string'){
        return [13,16].includes(val.length)
    }
}

export const localStorage = new class {
    add(key, data) {
        try {
            window.localStorage.setItem(key, JSON.stringify(data));
        } catch (err) { console.error(err); }
    }

    removeItem(key) {
        window.localStorage.removeItem(key);
    }

    getByKey(key, isJSON = true) {
        try {
            const item = window.localStorage.getItem(key);

            return isJSON ? JSON.parse(item) : item;
        } catch (err) {
            console.error(err);

            return false;
        }
    }
};

export const groupNameForProductTitle = function (productGroups, productGroup, postsNumber, randomCategory) {
    // {{ getGroupName(item.product.product_group) }} {{ + item.data.postsNumber > 0 ? ('('+ item.data.postsNumber+'x)'):null}} {{ item.data.randomCategory ? (`(${item.data.randomCategory})`):null}}

    function ucFirst(str) {
        if (!str) return str;
       
        let res = str.split(' ').map(item=>(item[0].toUpperCase() + item.slice(1))).join(' ')
        res = res.split('(').map(item=>(item[0].toUpperCase() + item.slice(1))).join('(')
     
        return res
        // return str[0].toUpperCase() + str.slice(1);
    }
    
    let productSubGroups = () => { 
        let groupped = productGroups.reduce((acc, cur) => {
            if (cur.sub_groups && !Array.isArray(cur.sub_groups) && !['instagram.randomComments','facebook.postRandomComments','youtube.randomComments','tiktok.randomComments'].includes(cur.identifier)) {
                acc.push({
                    sub_groups: Object.entries(cur.sub_groups).map(([key,value])=>key),
                    identifier: cur.identifier,
                    name: cur.general_name
                })  
            } 
            return acc;
        },[]);  

        return groupped
    }
    let fullName = null
    let findedGlobalProductGroup = productSubGroups().find(generalProductGroup=>generalProductGroup.sub_groups.includes(productGroup.identifier))
    if(findedGlobalProductGroup){
        // если базовый тип в соединенных группах скрыть часть названия которая определяет базовый тип, пример : Hight quality followers -> Followers   
        if(productGroup.general_name){
            fullName = ucFirst(productGroup.general_name)
        }else{
            fullName = ucFirst(productGroup.name)
        }
        // return findedGlobalProductGroup.name  
    }else if( ['instagram.verifiedComments','instagram.realComments'].includes(productGroup.identifier)){
        fullName = ucFirst({
            'instagram.verifiedComments' : translate('product-group-verified') + ' ' + translate('product-group-comments'),
            'instagram.realComments' : translate('product-group-real') + ' ' + translate('product-group-comments'),
        }[productGroup.identifier])
    }else if( ['instagram.verifiedCustomComments','instagram.realCustomComments'].includes(productGroup.identifier)){
        let globalIdentifier = productGroup.identifier==='instagram.verifiedCustomComments'?'instagram.verifiedComments':(productGroup.identifier==='instagram.realCustomComments'?'instagram.realComments':null)
        let findedLocalProductGroup = productGroups.find(item=>item.identifier===globalIdentifier)
       
        fullName = ucFirst(`${findedLocalProductGroup.type_name} ${String(translate('product-group-comments')).toLowerCase()} (${String(translate('product-group-custom')).toLowerCase()})`)
    }else{
        fullName = ucFirst(productGroup.name)
    }
    if( (+postsNumber) > 0){
        fullName = fullName + ' (' + postsNumber+'x)'
    }
    if(randomCategory && productGroup.identifier.includes('random')){
        fullName = fullName + ' (' + randomCategory + ')'
    }
    return fullName
}