<template>
    <div  class="data-drop" :class="{'active':activeDropdown}" v-click-outside="closeSelect">
        <div class="select__btn data-drop-btn" :class="{'select-btn-border':borderedDropdown}" @click="changeActive">
            <div class="select__current data-select-current choices-comment__amount">{{uniqCurrentValue || (currentItem && currentItem.title) || 'error'}}</div>
            <span class="select__arrow">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.74158 4.66666L2.91663 5.49162L7.0414 9.6164L11.1662 5.49164L10.3413 4.66667L7.0414 7.9665L3.74158 4.66666Z" fill="currentColor"></path>
                </svg>
            </span>
        </div>
        <div class="select__drop data-drop-list" >
            <ul class="select__list">
                <li class="select__list-item" v-for="(item, itemIndex) in options" :key="itemIndex">
                    <a href="#" @click.stop="$event.preventDefault(); selectItem(item.value)" class="data-select-btn choices-comment__amount" >
                        {{parseInt(item.title)}}
                    </a>
                </li>
            </ul>
        </div>
     
    </div>
</template>
<script>
export default {
    props: {
        options: {
            type: Array,
            default: () => ([])
        },
        isCategoryDropdown:{
            type: Boolean,
            default: () => (false)
        },
        isProductDropdown:{
            type: Boolean,
            default: () => (false)
        },
        borderedDropdown:{
            type: Boolean,
            default: () => (false)
        },
        viewField: {
            type: String,
            default: () => ('title')
        },
        uniqField: {
            type: String,
            default: () => ('val')
        },
        uniqCurrentValue: {
            type: String,
            default: () => (null)
        },
        value: {
            type: [String, Number, Object],
            default: () => ('')
        },
        title: {
            type: String,
            default: () => ('')
        }    
    },
    data(){
        return {
            updateKey: 0,
            activeDropdown:false
        }
    },
    methods:{
        closeSelect(){
            this.activeDropdown=false
            console.log('close');
        },
        selectItem(value, category){
            if(this.isCategoryDropdown && category){
                this.$emit('change', {
                    category: category,
                    value: value
                })
                
            }else{
                console.log('change:', value);
                this.$emit('change', {
                    value: value
                })

            }
            // this.$emit('change', value)
            this.activeDropdown=false
        },
        changeActive(e) {
            e.preventDefault()
            console.log('1213');
            this.activeDropdown=!this.activeDropdown
        }
    },
    computed:{
        currentItem(){
            if(this.isCategoryDropdown){
                let res = null
                this.options.forEach(item => {
                    let findedValue = item.values.find(item=>item.value === this.value)
                    if(findedValue && !res){
                        res=findedValue
                    }             
                });
                return res
                // (item=>item.value === this.value)
            }else{
                return this.options.find(item=>item.value === this.value)
            }
        },
        // categoryDropdownCurrentItem(){
        //     if(!this)
        //     return this.options.find(item=>item.value === this.value)
        // }
    },
    watch:{
        value(){
            this.updateKey++
        }
    }

}
</script>
<style scoped>
    .select__arrow {
        height: 10px;
    }
   .select-service-page .data-drop-list {
        max-height: 352px;
        left: -50px;
        border-radius: 20px;
        overflow: scroll;
        overflow-x: hidden;
    }
    .select-service-page .data-select-btn {
        flex: 1;
    }
    .service-page .select.select-sm .data-drop-btn{
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
    }
    .data-drop {
        z-index: 25;
        position: relative;
        display: inline-block;
    }

    .data-drop-btn {
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: all .2s ease;
    }

    .data-drop-list {
        display: none;
        position: absolute;
        top: 100%;
        background: #FFFFFF;
        border: 1px solid #E7E7E7;
        box-sizing: border-box;
        box-shadow: 0px 2px 13px rgba(2, 6, 20, 0.08);
        border-radius: 10px;
        z-index: 20;
        margin-top: 6px;
        max-height: 400px;
        overflow-x: hidden;
        overflow-y: auto;

        width: 100%;
    }

    .data-drop.active .data-drop-list {
        display: block;
    }

    .data-drop.active.data-drop-btn {
        background-color: #F5F5F6;
    }

    .select.select-sm.data-drop-btn {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 12px;
        color: #01030B;
        padding: 3px 4px;
        border-radius: 4px;
    }

    .default-select .select__btn.data-drop-btn {
        border: 1px solid #D7D8DC;
        border-radius: 4px;
        padding: 14px 20px;
        justify-content: space-between;
    }
    .default-select .select__arrow {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .default-select .select__list-item .data-select-btn {
        padding: 18px 20px;
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        cursor: pointer;
    }
    .default-select .select__list-item .data-select-btn:hover {
        color: #3F57FF;
    }

    .service-page .select.select-sm.product-type-bordered-select .data-drop-btn {
        border-radius: 24px;
        border: 1px solid #020614;
        padding: 11px 15px;
        font-weight: 600;
        font-size: 14px;
    }

    .data-select-btn {
        text-align: center;
        justify-content: center;
        padding: 3px;
    }

    .data-select-btn:hover {
        background: #f2f2f2;
    }

    @media (max-width: 991.98px) {
        .select-service-page .data-drop-list {
            left: -15px;
        }
    }
</style>