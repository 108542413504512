<template>
    <div class="container mx-0 container-dash">
        <div class="flex items-center justify-center relative mb-[30px] mt-[14px] lg:hidden">
            <a href="#" class="btn w-[40px] h-[40px] bg-primary-100 rounded-[11px] left-0 absolute">
                <img src="@/assets/img/icons/left-arrow-long.svg" alt="">
            </a>
            <span class="def-text--1 font-semibold">Add funds</span>
        </div>
        <div class="flex sm:flex-col flex-col-reverse">
            <div class="flex items-center justify-between mb-[15px] sm:mb-[25px]">
                <div class="flex flex-col items-center lg:items-start">
                    <h6>Add funds</h6>
                </div>
            </div>
            <div class="card md:pr-[50px] mb-[20px]" v-if="dataprops.user && !dataprops.user.guest">
                <div class="flex items-center justify-between md:justify-start mb-3">
                    <h6 class="mr-[35px]">My Balance</h6>
                    <h6 class="text-primary-500">{{ dataprops.cartData.currency }}{{ ((dataprops.userMe ? dataprops.userMe.balance : dataprops.user.balance) || 0) | floatNumberWithSpaces }}</h6>
                </div>
                <div class="flex items-center justify-between flex-wrap -mb-[20px]">
                    <p class="text-text font-semibold mb-[20px]">The amount you have at the moment and can use for orders.</p>
                    <router-link to="/add-funds/balance-history" href="#" class="btn btn--primary-outline p-[15px] w-full md:max-w-[240px] rounded-[8px] mb-[20px]">
                        <span class="info">Balance history</span>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="row gy-20 pb-[20px]">
            <div class="col-lg-6">
                <div class="max-sm-p-0 card bg-transparent h-full sm:bg-primary-100">
                    <div class="text-text font-semibold mb-4">Top up your balance</div>
                    <div class="bg-primary-100 flex items-center justify-between py-[40px] px-[15px] sm:max-w-[350px] mx-auto rounded-[8px] mb-[20px]">
                        <a @click.prevent="changeAmount('-')" href="#" class="btn rounded-full w-[30px] h-[30px] bg-white-100" :class="{ 'disabled': amount <= 1 }">
                            <svg width="15" height="2" viewBox="0 0 15 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.28906 0.942317H13.3305" stroke="#222B45" stroke-width="1.37617" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                        </a>
                        <div class="flex text-[25px] font-semibold px-3" v-if="dataprops.balanceTopUpBonus">
                            ${{ amount }} <span class="text-primary-500 ml-2" v-if="amount>(dataprops.balanceTopUpBonus.min_top_up-1)">(+${{(amount / 100) * dataprops.balanceTopUpBonus.bonus_percent}})</span> 
                        </div>
                        <a @click.prevent="changeAmount('+')" href="#" class="btn rounded-full w-[30px] h-[30px] bg-white-100">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.98376 0.934082V12.9498" stroke="#222B45" stroke-width="1.37617" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M0.962891 6.94183H13.0043" stroke="#222B45" stroke-width="1.37617" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                        </a>
                    </div>
                    <div class="form-group mb-[20px]">
                        <label class="form-group-title text-text mb-3">Or choose a custom amount</label>
                        <label class="input-wraper input-number bg-transparent relative">
                            <a 
                                href="#" style="display: flex;position: absolute;top: 50%;right: 10px;transform: translate(-50%, -50%);"
                                v-if="customAmount > 1" @click.prevent="customAmount = 0"
                            >
                                <svg width="12" height="12" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.4771 2.94469C17.9223 2.51467 18.1009 1.87788 17.9441 1.27907C17.7874 0.68025 17.3197 0.212602 16.7209 0.0558617C16.1221 -0.100878 15.4853 0.0776826 15.0553 0.522914L8.99914 6.57736L2.94469 0.522914C2.51467 0.0776826 1.87788 -0.100878 1.27907 0.0558617C0.68025 0.212602 0.212602 0.68025 0.0558617 1.27907C-0.100878 1.87788 0.0776826 2.51467 0.522914 2.94469L6.57736 8.99914L0.522914 15.0553C0.0776826 15.4853 -0.100878 16.1221 0.0558617 16.7209C0.212602 17.3197 0.68025 17.7874 1.27907 17.9441C1.87788 18.1009 2.51467 17.9223 2.94469 17.4771L8.99914 11.4226L15.0553 17.4771C15.4853 17.9223 16.1221 18.1009 16.7209 17.9441C17.3197 17.7874 17.7874 17.3197 17.9441 16.7209C18.1009 16.1221 17.9223 15.4853 17.4771 15.0553L11.4226 8.99914L17.4771 2.94469Z" fill="#3A4C66"></path>
                                </svg>
                            </a>
                            <input type="number" class="input font-semibold" placeholder="20" :value="customAmount" @input="customAmountChange">
                        </label>
                    </div>
                    <div class="mb-[25px]">
                        <p class="def-text--5 font-semibold mb-3">Minimum $20 - Maximum $500.</p>
                        <p class="def-text--5 font-semibold">Get 3% bonus when you add $100 or more to your balance.</p>
                    </div>
                    <a href="#" class="btn btn--primary pl-[27px] pr-[56px] py-[13px] icon-btn rounded-[8px]" @click.prevent="openModal('add-funds-checkout')">
                        <span>Proceed</span>
                        <div class="icon-right">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.31818 11.6361L21 11.6361" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M13.3636 19.2727L21 11.6364L13.3636 4" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="card bg-transparent h-full sm:bg-primary-100 max-sm-p-0">
                    <div class="card__header">
                        <h6>Top up history</h6>
                    </div>
                    <div class="card__body bg-primary-100 sm:border p-[25px] rounded-[5px] mb-[20px]">
                        <div class="card-body--item">
                            <span class="text-primary-500 font-semibold">+ $220</span>
                            <span class="font-semibold text-text">Sep 14, 2022</span>
                        </div>
                        <div class="card-body--item">
                            <span class="font-semibold">#H8JASK</span>
                            <div>
                                <span class="font-semibold mr-[10px]">Visa **38</span>
                                <span>
                                    <img src="@/assets/img/icons/visa.svg" alt="">
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="card__body bg-primary-100 sm:border p-[25px] rounded-[5px] mb-[25px]">
                        <div class="card-body--item">
                            <span class="text-primary-500 font-semibold">+ $220</span>
                            <span class="font-semibold text-text">Sep 14, 2022</span>
                        </div>
                        <div class="card-body--item">
                            <span class="font-semibold">#H8JASK</span>
                            <div>
                                <span class="font-semibold mr-[10px]">Visa **38</span>
                                <span>
                                    <img src="@/assets/img/icons/visa.svg" alt="">
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="flex items-center justify-center">
                        <a href="#" class="btn flex-col text-center def-text--1 text-primary-500">
                            <span class="font-semibold">
                                See more
                            </span>
                            <span class="ico ico-20 mt-[4px]">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5265 15.2603C11.4578 15.2322 11.3954 15.1906 11.343 15.138L5 8.792L5.792 8L11.743 13.951L17.694 8L18.486 8.792L12.139 15.138C12.0871 15.1901 12.0253 15.2314 11.9574 15.2595C11.8894 15.2877 11.8166 15.3021 11.743 15.302C11.6688 15.3026 11.5952 15.2885 11.5265 15.2603Z" fill="currentColor"></path>
                                </svg>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { floatNumberWithSpaces } from '@/helpers/filters.js';

export default {
    props: ['dataprops', 'innerDashboard',],
    filters: { floatNumberWithSpaces, },
    data() {
        return {
            amount: 20,
            customAmount: 0,
        };
    },
    methods:{
            openModal(name){
                this.$bus.$emit('modal',{
                    name: name,
                    overlay: true,
                    payload: {
                        login: ()=> this.isLogged = true,
                        selectedAmount: this.customAmount !== 0 ? this.customAmount : this.amount
                    },
                })
            },
            changeAmount(type){
                if(type === '-' && this.amount > 20){
                    this.amount -= this.amount <= 100 ? 20 : 50;
                }

                if(type === '+' && this.amount < 500){
                    this.amount += this.amount < 100 ? 20 : 50;
                }
            },
            customAmountChange(e){
                if(+e.target.value > 500){
                    this.customAmount = 500;
                    e.target.value = 500;
                    return false;
                } else {
                    this.customAmount = +e.target.value;
                }
            }
        },
}
</script>

<style>

</style>