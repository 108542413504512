<template>

<div v-if="!step2">    
    <div class="pt-[24px] pb-[50px] text-center">
        <div class="container">
            <div class="max-w-[690px] mx-auto">
                <div class="row">
                    <div class="col-12">
                        <div class="flex flex-col items-center sm:items-start">
                            <h2 class="font-[600] text-[24px] sm:text-[40px] leading-[32px] sm:leading-[60px] mb-[23px] sm:mb-[27px]">
                                Live Instagram <span class="text-primary-500">Followers Counter</span>
                            </h2>
                            <p class="mb-[17px] sm:mb-[27px] font-[600] text-[14px] sm:text-[16px] leading-[18px] text-secondary-500 max-w-[586px] mx-auto ff-fotnFamilyMontserrat">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.</p>
                        </div>
                        <div class="col-12">
                        
                            <SearchInput :placeholder="'Your Instagram username'" :call-back="search" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <section class="py-[60px] pb-[80px] bg-white section">
        <div class="container-px--lg">
            <div class="heading-block items-center text-center">
                <h2 class="heading heading-2 font-bold">
                    How to use Instagram Live Counter
                </h2>
            </div>
            <div class="sm:mt-[80px] mt-[24px]">
                <ul class="custom-steps custom-steps--even">
                    <li class="custom-step pt-[15px]">
                        <div class="custom-step--path">
                            <div class="step-card rounded-[26px] py-[23px] bg-primary-200">
                                <div class="step--count bg-primary-500 text-white-100 top-right">
                                    <span class="text">Step 1</span>
                                </div>
                                <div class="step-card--header px-[28px] pt-[23px] mt-[-23px]">
                                    <h5 class="def-text--1 font-semibold">
                                        Enter any IG username
                                    </h5>
                                </div>
                                <div class="step-card--body px-[28px] pt-[13px]">
                                    <p class="def-text--3 font-semibold text-text2">
                                        This works with any Instagram username, but it must the account must be set to public.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="custom-step">
                        <div class="custom-step--path">
                            <div class="step-card rounded-[26px] py-[23px] bg-primary-200">
                                <div class="step--count bg-primary-500 text-white-100 top-right">
                                    <span class="text">Step 2</span>
                                </div>
                                <div class="step-card--header px-[28px] pt-[23px] mt-[-23px]">
                                    <h5 class="def-text--1 font-semibold">
                                        Click Search
                                    </h5>
                                </div>
                                <div class="step-card--body px-[28px] pt-[13px]">
                                    <p class="def-text--3 font-semibold text-text2">
                                        Simply click 'Search' and allow us a moment to find the account you are looking for.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="custom-step">
                        <div class="custom-step--path">
                            <div class="step-card rounded-[26px] py-[23px] bg-primary-200">
                                <div class="step--count bg-primary-500 text-white-100 top-right">
                                    <span class="text">Step 3</span>
                                </div>
                                <div class="step-card--header px-[28px] pt-[23px] mt-[-23px]">
                                    <h5 class="def-text--1 font-semibold">
                                        See your results
                                    </h5>
                                </div>
                                <div class="step-card--body px-[28px] pt-[13px]">
                                    <p class="def-text--3 font-semibold text-text2">
                                        The followers count will appear before you within seconds.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </section>




  <!-- <section class="py-[60px] bg-grey--300 bg-primary-100-576 section">
        <div class="container-px--lg">
            <div class="heading-block items-center text-center">
                <h2 class="heading heading-2 font-bold">
                    Frequently Asked Questions
                </h2>
            </div>
            <div class="flex flex-col sm:mt-[53px] mt-[24px]">
                <div class="accordeons flex flex-col">
                    <div class="accordeons-container flex flex-col">
                        <AccordeonItem
                            v-for="(item, index) in faq" :key="index"
                            :title="item.title"
                            :content="`<p class='font-semibold def-text--1'>`+item.content+'</p>'"
                        />
                    </div>
                    <div class="accordeon-footer max-w-max mx-auto flex flex-col align-center">
                        <a href="#" class="btn flex-col text-center def-text--1 text-primary-500">
                            <span class="font-semibold">
                                See more
                            </span>
                            <span class="ico ico-20 mt-[4px]">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5265 15.2603C11.4578 15.2322 11.3954 15.1906 11.343 15.138L5 8.792L5.792 8L11.743 13.951L17.694 8L18.486 8.792L12.139 15.138C12.0871 15.1901 12.0253 15.2314 11.9574 15.2595C11.8894 15.2877 11.8166 15.3021 11.743 15.302C11.6688 15.3026 11.5952 15.2885 11.5265 15.2603Z" fill="currentColor"></path>
                                </svg>
                            </span>
                        </a>
                    </div>
                </div>
                <div class="mt-[24px]">
                    <div class="heading-block items-center text-center">
                        <h2 class="heading heading-6 font-bold">
                            Didn’t find your answer?
                        </h2>
                        <div class="mt-[20px]">
                            <a href="#" class="btn btn--lg btn--primary rounded-[8px]">
                                <span class="ico-24 mr-[20px] sm:hidden flex"><svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.1056 5H6.90555C5.58007 5 4.50555 6.17525 4.50555 7.625V16.375C4.50555 17.8247 5.58007 19 6.90555 19H18.1056C19.431 19 20.5056 17.8247 20.5056 16.375V7.625C20.5056 6.17525 19.431 5 18.1056 5ZM17.5696 6.75L12.5056 10.9062L7.44155 6.75H17.5696ZM18.1056 17.25H6.90555C6.46373 17.25 6.10555 16.8582 6.10555 16.375V7.84375L12.0256 12.7C12.164 12.8136 12.3325 12.875 12.5056 12.875C12.6787 12.875 12.8471 12.8136 12.9856 12.7L18.9056 7.84375V16.375C18.9056 16.8582 18.5474 17.25 18.1056 17.25Z" fill="currentColor"></path>
                                    </svg>
                                </span>
                                <span class="info text-[14px] font-bold">
                                    Contact Us
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->


    <section class="py-[60px] bg-grey--300 bg-primary-100-576 section">
        <div class="container-px--lg">
            <div class="heading-block items-center text-center">
                <h2 class="heading heading-2 font-bold">
                    Frequently Asked Questions
                </h2>
            </div>
            <div class="flex flex-col sm:mt-[53px] mt-[24px]">
                <FAQ></FAQ>
                <div class="mt-[24px]">
                    <div class="heading-block items-center text-center">
                        <h2 class="heading heading-6 font-bold">
                            Didn’t find your answer?
                        </h2>
                        <div class="mt-[20px]">
                            <a href="#" class="btn btn--lg btn--primary rounded-[8px]">
                                <span class="ico-24 mr-[20px] sm:hidden flex"><svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.1056 5H6.90555C5.58007 5 4.50555 6.17525 4.50555 7.625V16.375C4.50555 17.8247 5.58007 19 6.90555 19H18.1056C19.431 19 20.5056 17.8247 20.5056 16.375V7.625C20.5056 6.17525 19.431 5 18.1056 5ZM17.5696 6.75L12.5056 10.9062L7.44155 6.75H17.5696ZM18.1056 17.25H6.90555C6.46373 17.25 6.10555 16.8582 6.10555 16.375V7.84375L12.0256 12.7C12.164 12.8136 12.3325 12.875 12.5056 12.875C12.6787 12.875 12.8471 12.8136 12.9856 12.7L18.9056 7.84375V16.375C18.9056 16.8582 18.5474 17.25 18.1056 17.25Z" fill="currentColor"></path>
                                    </svg>
                                </span>
                                <span class="info text-[14px] font-bold">
                                    Contact Us
                                </span>
                            </a>
                        </div>
                    </div>
                </div>

                
            </div>
        </div>
    </section>
    <TryForFreeSection class="py-[60px] bg-black-100 section"></TryForFreeSection>
    
    
    <section class="py-[60px] bg-white-100 section">
        <div class="container-px--lg">
            <div class="flex flex-col">
                <div class="flex justify-between">
                    <h2>Rate this tool</h2>
                    <div class="rating-input">
                        <input type="radio" name="rating" id="rating-5">
                        <label for="rating-5"></label>
                        <input type="radio" name="rating" id="rating-4">
                        <label for="rating-4"></label>
                        <input type="radio" name="rating" id="rating-3">
                        <label for="rating-3"></label>
                        <input type="radio" name="rating" id="rating-2">
                        <label for="rating-2"></label>
                        <input type="radio" name="rating" id="rating-1">
                        <label for="rating-1"></label>
                    </div>
                </div>
              
            </div>
        </div>
        <ReviewsSection reviewBackground="bg-primary-100" :headerButtonsHidden="true"></ReviewsSection>
    </section>
    <section class="bg-grey--300 py-[60px]">
        <div class="container-px--lg">
            <div class="row gutters-30">
                <div class="col-lg-4">
                    <h2 class="max-w-[200px]">Try other tools</h2>
                </div>
                <div class="col-lg-4">
                    <div class="social-card h-full flex items-center social-card--30 rounded-[10px] bg-primary-100">
                        <div class="social-card--item w-full">
                            <ul class="flex flex-col mb-[-10px]">
                                <li class="flex mb-[10px]">
                                    <a href="#" class="btn w-full justify-between font-semibold">
                                        <span class="info text-left def-text--6 text-primary-500">
                                            10 Free TikTok Followers
                                        </span>
                                        <span class="ico ico-25 ml-[10px]">
                                            <img src="@/assets/img/icons/chevron-left.svg">
                                        </span>
                                    </a>
                                </li>
                                <li class="flex mb-[10px]">
                                    <a href="#" class="btn w-full justify-between font-semibold">
                                        <span class="info text-left def-text--6 text-primary-500">
                                            20 Free TikTok Likes
                                        </span>
                                        <span class="ico ico-25 ml-[10px]">
                                            <img src="@/assets/img/icons/chevron-left.svg">
                                        </span>
                                    </a>
                                </li>
                                <li class="flex mb-[10px]">
                                    <a href="#" class="btn w-full justify-between font-semibold">
                                        <span class="info text-left def-text--6 text-primary-500">
                                            100 Free Tiktok Views
                                        </span>
                                        <span class="ico ico-25 ml-[10px]">
                                            <img src="@/assets/img/icons/chevron-left.svg">
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="social-card h-full flex items-center social-card--30 rounded-[10px] bg-primary-100">
                        <div class="social-card--item w-full">
                            <ul class="flex flex-col mb-[-10px]">
                                <li class="flex mb-[10px]">
                                    <a href="#" class="btn w-full justify-between font-semibold">
                                        <span class="info text-left def-text--6 text-primary-500">
                                            10 Free TikTok Followers
                                        </span>
                                        <span class="ico ico-25 ml-[10px]">
                                            <img src="@/assets/img/icons/chevron-left.svg">
                                        </span>
                                    </a>
                                </li>
                                <li class="flex mb-[10px]">
                                    <a href="#" class="btn w-full justify-between font-semibold">
                                        <span class="info text-left def-text--6 text-primary-500">
                                            20 Free TikTok Likes
                                        </span>
                                        <span class="ico ico-25 ml-[10px]">
                                            <img src="@/assets/img/icons/chevron-left.svg">
                                        </span>
                                    </a>
                                </li>
                                <li class="flex mb-[10px]">
                                    <a href="#" class="btn w-full justify-between font-semibold">
                                        <span class="info text-left def-text--6 text-primary-500">
                                            100 Free Tiktok Views
                                        </span>
                                        <span class="ico ico-25 ml-[10px]">
                                            <img src="@/assets/img/icons/chevron-left.svg">
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="py-[60px] bg-white-100">
        <div class="container-px--lg">
            <div class="row gutters-64 gy-20">
                <div class="col-lg-6">
                    <div class="heading-block mb-[55px]">
                        <h1 class="heading heading-1 font-bold">
                            Get Started Now
                        </h1>
                    </div>
                    <tabs class="tabs blog-tabs tab-blog card-tabs" data-tabs="" :class-priority="3">
                        <div class="tabs-body" tabs-body="tabs-page">
                            <tab title="Likes" class="tabs-item active" id="likes">
                                <div class="tabs-item__content">
                                    <div class="review hidden sm:flex">
                                        <div class="review--head">
                                            <div class="rating">
                                                <label for="star-1" class="rating-star ico-35">
                                                    <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                                                    </svg>
                                                    <input type="radio" name="star-1" id="star-1" class="rating-input">
                                                </label>
                                                <label for="star-2" class="rating-star ico-35">
                                                    <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                                                    </svg>
                                                    <input type="radio" name="star-2" id="star-2" class="rating-input">
                                                </label>
                                                <label for="star-3" class="rating-star ico-35">
                                                    <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                                                    </svg>
                                                    <input type="radio" name="star-3" id="star-3" class="rating-input">
                                                </label>
                                                <label for="star-3" class="rating-star ico-35">
                                                    <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                                                    </svg>
                                                    <input type="radio" name="star-3" id="star-3" class="rating-input">
                                                </label>
                                                <label for="star-3" class="rating-star ico-35">
                                                    <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                                                    </svg>
                                                    <input type="radio" name="star-3" id="star-3" class="rating-input">
                                                </label>
                                            </div>
                                        </div>
                                        <div class="review--body">
                                            <div class="review--body--item">
                                                <div class="ico ico-30 mr-[14px] d-none-360"><img src="@/assets/img/icons/ico-quotes.svg"></div>
                                                <div class="review-text font-semibold">
                                                    Not only is it easy to use, but it’s also very powerful.
                                                    I’ve been consistently hitting the explore page for
                                                    the past few weeks.
                                                </div>
                                            </div>
                                            <div class="review--body--item">
                                                <div class="review-image--container after-shape">
                                                    <div class="wrapper-image rounded-[10px] review-image flex flex-auto">
                                                        <img src="@/assets/img/photo-user.png">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <label class="switch sm:hidden">
                                        <input type="checkbox" checked="">
                                        <span class="slider">
                                            <span class="switch-value">High Quality</span>
                                            <span class="switch-value">Real</span>
                                        </span>
                                    </label>
                                    <div class="text-text my-[15px] text-center font-semibold sm:hidden">What is the difference?</div>
                                </div>
                            </tab>
                            <tab title="Shares" class="tabs-item" id="shares">
                                <div class="tabs-item__content">
                                </div>
                            </tab>
                            <tab title="Comments" class="tabs-item active" id="comments">
                                <div class="tabs-item__content">

                                </div>
                            </tab>
                            <tab title="Views" class="tabs-item" id="views">
                                <div class="tabs-item__content">

                                </div>
                            </tab>
                            <tab title="Followes" class="tabs-item" id="followers">
                                <div class="tabs-item__content">
                                </div>
                            </tab>
                        </div>
                    </tabs>
                </div>
                <div class="col-lg-6">
                    <div class="step-flex--item step-products pb-[50px]">
                        <div class="choices-comment mb-[10px]">
                            <div class="choices-wrap">
                                <div class="col-choice">
                                    <label class="choice-label flex">
                                        <input type="radio" name="product" checked="" class="choice-input" id="100-likes" style="display:none">
                                        <div class="choices-comment__item w-full">
                                            <span class="choices-comment__icon"><img src="@/assets/img/icons/icon-tiktok-logo.svg"></span>
                                            <span class="choices-comment__amount">100 Likes</span>
                                            <span class="choices-comment__price">$6.99</span>
                                        </div>
                                    </label>
                                </div>
                                <div class="col-choice">
                                    <label class="choice-label flex">
                                        <input type="radio" name="product" class="choice-input" id="200-likes" style="display:none">
                                        <div class="choices-comment__item w-full">
                                            <span class="choices-comment__icon"><img src="@/assets/img/icons/icon-tiktok-logo.svg"></span>
                                            <span class="choices-comment__amount">200 Likes</span>
                                            <span class="choices-comment__price">
                                                $7.99
                                                <span class="text-[#C4C3C3] text-[10px] leading-[14px] line-through ml-[3px]">
                                                    $12.99
                                                </span>
                                            </span>
                                        </div>
                                    </label>
                                </div>
                                <div class="col-choice">
                                    <label class="choice-label flex">
                                        <input type="radio" name="product" class="choice-input" id="500-likes" style="display:none">
                                        <div class="choices-comment__item w-full">
                                            <span class="choices-comment__icon"><img src="@/assets/img/icons/icon-tiktok-logo.svg"></span>
                                            <span class="choices-comment__amount">500 Likes</span>
                                            <span class="choices-comment__price">
                                                $17.99
                                                <span class="text-[#C4C3C3] text-[10px] leading-[14px] line-through ml-[3px]">
                                                    $22.99
                                                </span>
                                            </span>
                                        </div>
                                    </label>
                                </div>
                                <div class="col-choice">
                                    <label class="choice-label flex">
                                        <input type="radio" name="product" class="choice-input" id="1000-likes" style="display:none">
                                        <div class="choices-comment__item w-full">
                                            <span class="choices-comment__icon"><img src="@/assets/img/icons/icon-tiktok-logo.svg"></span>
                                            <span class="choices-comment__amount">1,000 Likes</span>
                                            <span class="choices-comment__price">
                                                $24.99
                                                <span class="text-[#C4C3C3] text-[10px] leading-[14px] line-through ml-[3px]">
                                                    $32.99
                                                </span>
                                            </span>
                                        </div>
                                    </label>
                                </div>
                                <div class="col-choice">
                                    <label class="choice-label flex">
                                        <input type="radio" name="product" class="choice-input" id="2000-likes" style="display:none">
                                        <div class="choices-comment__item w-full">
                                            <span class="choices-comment__icon"><img src="@/assets/img/icons/icon-tiktok-logo.svg"></span>
                                            <span class="choices-comment__amount">2,000 Likes</span>
                                            <span class="choices-comment__price">
                                                $32.99
                                                <span class="text-[#C4C3C3] text-[10px] leading-[14px] line-through ml-[3px]">
                                                    $41.99
                                                </span>
                                            </span>
                                        </div>
                                    </label>
                                </div>
                                <div class="col-choice">
                                    <label class="choice-label flex">
                                        <input type="radio" name="product" class="choice-input" id="5000-likes" style="display:none">
                                        <div class="choices-comment__item w-full">
                                            <span class="choices-comment__icon"><img src="@/assets/img/icons/icon-tiktok-logo.svg"></span>
                                            <span class="flex items-center relative dropdown" style="z-index:2;" data-dropdown="dropdown">
                                                <span class="choices-comment__amount mr-[3px]">5,000 Likes</span>
                                                <a href="#" data-role="button" class="choices-comment__chevron">
                                                    <img src="@/assets/img/icons/icon-chev.svg" alt="arrow">
                                                </a>
                                                
                                            <div class="dropdown__body choices-comment__body-amount" data-role="dropdown">
                                                    <ul class="choices-comment__amount-list">
                                                        <li class="choices-comment__amount-item">
                                                            <a href="#"> 5,000</a>
                                                        </li>
                                                        <li class="choices-comment__amount-item">
                                                            <a href="#">10,000</a>
                                                        </li>
                                                        <li class="choices-comment__amount-item">
                                                            <a href="#">25,000</a>
                                                        </li>
                                                    </ul>
                                                </div></span>
                                            <span class="choices-comment__price">
                                                $44.99
                                                <span class="text-[#C4C3C3] text-[10px] leading-[14px] line-through ml-[3px]">
                                                    $52.99
                                                </span>
                                            </span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="flex items-center justify-center mb-[14px]">
                            <div class="flex py-[3px] px-[6px] rounded-[8px] bg-secondary-14 mr-[7px]">
                                <span class="text-teal text-[18px] leading-[25px] font-[600] mr-[2px]">24% </span>
                                <span class="text-text text-[10px] leading-[14px]">off</span>
                            </div>
                            <span class="mr-[5px] text-[20px] leading-[27px] font-[600]">$6.99</span>
                            <span class="text-[10px] leading-[14px] line-through text-secondary-350 font-[700]">$9.99</span>
                        </div>
                        <a href="#" class="btn btn--lg2 btn--primary rounded-[8px] w-full justify-between mt-auto">
                            <span class="info text-[14px] font-bold">
                                Continue
                            </span>
                            <span class="ico ico-24 ml-[10px]">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.31818 11.6361L21 11.6361" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                    <path d="M13.3636 19.2727L21 11.6364L13.3636 4" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                            </span>
                        </a>
                    </div>
                </div>
            
                
            </div>
        </div>
    </section>
</div>
<div v-else>
    <div class="pt-[24px] pb-[50px] text-center">
        <div class="container mx-0 container-dash">
            <div class="relative row md:flex hidden mb-[23px] sm:mb-[23px]">
                <div class="col-12 px-[70px]">
                    <a href="#" @click="$event.preventDefault(); step2=false" class="btn w-[40px] y-center h-[40px] bg-primary-100 rounded-[11px] left-0 absolute">
                        <img src="../../assets/img/icons/left-arrow-long.svg" alt="">
                    </a>
                    <div class="flex max-w-[712px] mx-auto flex-col items-center text-center">
                        <h2 class="font-[700] text-[24px] sm:text-[40px] leading-[32px] sm:leading-[60px] w-[250px] sm:w-auto">
                            Live Instagram <span class="text-primary-500">Followers Counter</span>
                        </h2>
                    </div>
                </div>
            </div>
            <div class="max-w-[712px] mx-auto">
                <div class="row md:flex hidden">
                    <div class="col-12">
                        <div class="flex flex-col items-center text-center">
                            <p class="mb-[17px] sm:mb-[32px] font-[600] text-[14px] leading-[18px] text-secondary-500">
                                Lorem ipsum dolor sit amet, 
                                consectetur adipiscing elit, sed do.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="profile-block">
                            <div class="profile-wrapper">
                                <b class="text-[18px] leading-[24px]">
                                    @janesharee
                                </b>
                            </div>
                            <div class="max-w-[336px] mx-auto">
                                <div class="profile-content items-center w-full">
                                    <div class="profile-photo mt-[-45px] z-[1] rounded-[10px] profile-photo--90">
                                        <img src="../../assets/img/profile--photo.png">
                                    </div>
                                </div>
                            </div>
                            <div class="max-w-[336px] mx-auto">
                                <ul class="profile-list sm:flex-row flex-col justify-center">
                                    <li class="profile-list--item sm:mt-[40px] mt-[25px] w-full">
                                        <h2 class="text-[32px] leading-[40px]">13,502,29</h2>
                                        <p class="def-text--2 text-secondary-500 font-[600] mt-[15px]">
                                            followers
                                        </p>
                                    </li>
                                    <li class="profile-list--item sm:mt-[40px] mt-[25px]">
                                        <h6 class="heading heading-6">1,417</h6>
                                        <p class="def-text--2 text-secondary-500 font-[600] mt-[15px]">
                                            posts
                                        </p>
                                    </li>
                                    <li class="profile-list--item sm:mt-[40px] mt-[25px]">
                                        <h6 class="heading heading-6">5,103,21</h6>
                                        <p class="def-text--2 text-secondary-500 font-[600] mt-[15px]">
                                            Following
                                        </p>
                                    </li>
                                </ul>
                                <a href="#" @click="$event.preventDefault(); step2=false" class="mt-[40px] btn btn--lg btn--primary w-full rounded-[8px]">
                                    <span class="info text-[14px] font-[800]">
                                        Change user
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import AccordeonItem from "@/components/Base/AccordeonItem";
import FAQ from "@/components/Dashboard/FAQ.vue";
import ReviewsSection from "@/components/Dashboard/ReviewsSection.vue";
import TryForFreeSection from "@/components/Order/TryForFreeSection.vue";
import SearchInput from "@/components/Base/SearchInput.vue";
import Tab from "@/components/Base/Tab.vue";
import Tabs from "@/components/Base/Tabs.vue";


export default {
    name: "ToolsTemplate",
    components: {
        AccordeonItem,
        FAQ,
        TryForFreeSection,
        ReviewsSection,
        SearchInput,
        Tab,
        Tabs

    },
    data(){
        return {
            step2:false
        }
    },
    methods: {
        search(val) {
            
        }
    }
}
</script>