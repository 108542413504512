<template>
    <div>
        <div :class="this.classes.first" >
            <div :class="this.classes.second" tabs-nav="">
                <ul class="tabs__list">
                    <li v-for="(tab, index) in tabs" :key="tab.title" @click="selectTab(index)" class="tab-button pointer mx-[4px]" :class="{'active': selectedIndex === index}">
                        <a href="" @click.prevent="">{{ tab.title }}</a>
                    </li>
                </ul>
            </div>
        </div>
        
        <slot></slot>
    </div>
    </template>

    <script>
    export default {
        mounted () {
            this.selectTab(0);
            this.getClass(this.classPriority)
            
        },
        props: ['classPriority'],
        data () {
            return {
                selectedIndex: 0, 
                tabs: [],
                classes: {}
            }
        },
        methods: {
            selectTab (i) {
                this.selectedIndex = i
                this.tabs.forEach((tab, index) => {
                tab.isActive = (index === i)
                })
            },
            getClass(priority) {
                switch (priority) {
                    case 1: return this.classes = {first: 'tabs tabs-custom-2', second: 'tabs-head relative'}
                    case 2: return this.classes = {first: '', second: 'tabs-head mb-[30px]'}
                    case 3: return this.classes = {first: '', second: 'tabs-head relative z-1 mb-[55px]'}
                    default: return this.classes = {first: '', second: ''}
                }
            }
           
        },
        created () {
        this.tabs = this.$children
        }
    }
</script>