<template>
    <div>
        <template v-for="modalWindow in modals">
            <div
                :class="{ 'active': modalWindow.visible }"
                :key="modalWindow.name"
            >
                <component
                    :is="modalWindow.name"
                    :payload="modalWindow.payload"
                    :dataprops="dataprops"
                    @close="closeModal(modalWindow)"
                ></component>
            </div>
        </template>
    </div>
</template>

<script>

import Modals from './index.js';

export default {
    components: {
        ...Modals
    },
    props: ["dataprops"],
    data: () => ({
        modals: [],
    }),
    methods: {
        async openModal(modalData) {
            if (!modalData.overlay) {
                await Promise.all(this.modals.map(el => this.closeModal(el)));
                this.modals = [];
            }

            let modal = {
                name: modalData.name || modalData,
                visible: false,
                payload: modalData.payload || {}
            }

            this.modals.push(modal);
            setTimeout(() => this.$set(modal, 'visible', true), 50);
        },
        async closeModal(modalData) {
            const index = this.modals.findIndex(el => el.name == modalData.name);
            
            this.$set(modalData, 'visible', false);

            return new Promise((resolve) => {
                setTimeout(() => {
                    this.modals.splice(index, 1);
                    resolve(true);
                }, 50);
            })
        }
    },
    created() {
        this.$bus.$on('modal', (event) => this.openModal(event));
    }
}
</script>