<template>
    <div id="verify-email" class="modal modal-23 modal-login modal--show">
        <div class="modal__dialog modal__dialog--587">
            <div class="modal__content">
                <div class="modal__header header-center">
                    <a href="#" class="btn btn-close" data-close-modal="" @click.prevent="closeModal()">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.6818 11.6366L3 11.6366" stroke="#222B45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M10.6364 19.2727L3 11.6364L10.6364 4" stroke="#222B45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </a>
                    <h6 class="font-[600] text-[14px] leading-[24px] sm:hidden">{{ payload.twoFa ? '2 FA' : 'Verify email'}}</h6>
                </div>
                <div class="modal__body pb-[57px] px-[27px] sm:px-[125px]">
                    <div class="flex justify-center mb-[32px]">
                        <img src="@/assets/img/decor-message sent.svg">
                    </div>
                    <h3 class="text-[24px] leading-[32px] font-[700] mb-[20px] text-center mx-auto w-[215px]">Please check
                        your email</h3>
                    <span class="text-[14px] leading-[18px] font-[600] text-secondary-500 text-center mb-[41px]">
                        We’ve sent a code to
                        <span class="text-primary-500">{{ email }}</span>
                    </span>

                    <ul class="verify-input-list mb-[34px]" @keydown="handleKeyDown">
                        <li v-for="(digit, index) in code" :key="index" class="verify-input-item">
                            <input
                            ref="inputRefs"
                            :value="code[index]"
                            type="number"
                            :class="{'verify-input': true, 'code-error': !code[index] && setError}"
                            maxlength="1"
                            @input="handleInputChange($event, index)"
                            />
                        </li>
                    </ul>

                    <span class="text-[14px] leading-[18px] font-[600] text-secondary-500 text-center mb-[12px]">
                        Didn’ t get a code?
                        <span class="text-primary-500"> Click to resend</span>
                    </span>

                    <a @click="verify" href="#" class="btn btn--primary px-[27px] py-[20px] rounded-[8px] font-[700] text-[14px] leading-[19px]">
                        <span>Verify</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { authApi } from '@/api/authApi';
export default {
    props: ['payload'],
    data() {
        return {
            email: null,
            code: ['', '', '', ''],
            currentIndex: 0,
            setError: false,
        };
    },
    computed: {},
    methods: {
        closeModal() {
            this.$bus.$emit("modal", {
                name: "",
                payload: {},
            });
        },
        openModal(name){
            this.$bus.$emit('modal',{
                name: name,
                overlay: true,
                payload: {},
            })
        },
        handleInputChange(event, index) {
            const value = event.target.value;

            if (/^\d*$/.test(value) && value.length <= 1) {
                if (value !== '' || this.code[index] !== '') {
                    this.$set(this.code, index, value.slice(-1));
                }
            } else {
                this.$set(this.code, index, value.slice(-1));
            }

            if (value !== '') {
                if (index < this.code.length - 1) {
                    this.moveFocus(index + 1);
                }
            } else {
                if (index > 0) {
                    this.moveFocus(index - 1);
                }
            }
        },
        handleKeyDown(event) {
            if (event.key === 'Backspace' && this.code[this.currentIndex] === '' && this.currentIndex > 0) {
                this.moveFocus(this.currentIndex - 1);
                this.$set(this.code, this.currentIndex - 1, '');
            } else if (event.key === 'ArrowLeft') {
                this.moveFocus(this.currentIndex - 1);
            } else if (event.key === 'ArrowRight') {
                this.moveFocus(this.currentIndex + 1);
            }
        },
        moveFocus(index) {
            this.currentIndex = Math.min(Math.max(index, 0), this.code.length - 1);
            this.$refs.inputRefs[this.currentIndex].focus();
        },
        async verify() {

            if (this.code.some(val => !val)) {
                console.log('code err');
                this.setError = true
                return
            }

            const loginData = {
                email: this.email,
                password: this.payload.password,
                '2fa_code': 1,
                code: this.code
            }

            const response = await authApi.login(loginData)

            if (response.token) {
                window.localStorage.setItem('token', response.token)
                this.payload.getUser()
            }
            
        }



    },
    mounted() {
        this.email = this.payload.email || null
    }
}
</script>