<template>
    <div class="container mx-0 container-dash">
        <div class="lg:flex items-center justify-between mb-[25px] hidden ">
            <div class="flex items-center">
                <router-link to="/settings" href="#" class="btn w-[40px] h-[40px] bg-primary-100 rounded-[11px] mr-[20px] hidden lg:flex">
                    <img src="@/assets/img/icons/left-arrow-long.svg" alt="">
                </router-link>
                <h6>Account details</h6>
            </div>
        </div>
        <div class="flex items-center justify-between relative py-[20px] lg:hidden">
            <a href="#" class="btn w-[40px] h-[40px] bg-primary-100 rounded-[11px]">
                <img src="@/assets/img/icons/left-arrow-long.svg" alt="">
            </a>
            <span class="def-text--1 font-semibold">Account details</span>
            <div class="w-[40px] h-[40px] rounded-full overflow-hidden my-[2px]">
                <img class="w-full h-full" src="@/assets/img/ava-40x40.png" alt="">
            </div>
        </div>
        <div class="row gutters-30" style="--bs-gutter-y: 22px;">
            <div class="col-lg-6">
                <div class="card-setting sm:bg-secondary-100 p-0 card-rounded">
                    <div class="card-body p-0 sm:p-[20px]">
                        <div class="flex items-center justify-between mb-[20px]">
                            <h6>General details</h6>
                            <a v-if="!changesSaved" @click.prevent="saveChanges" href="" class="btn underline font-bold text-[14px]">Edit</a>
                            <a v-else class="btn underline font-bold text-[14px]" @click.prevent>Changes Saved Successfully</a>
                        </div>
                        <div class="form-group mb-5">
                            <label class="input-wraper fill-input input-wraper--label"
                                    :class="{ 'invalid': userName.firstName.touched && !userName.firstName.isValid }"  style="background-color: transparent;">
                                <input 
                                    type="text" 
                                    id="first-name" 
                                    class="input font-semibold" 
                                    placeholder="First name"
                                    v-model="userName.firstName.value"
                                    
                                    @blur="onFieldBlur(userName, ['firstName'])"
                                >
                                <div class="label-input">
                                    <span>First name</span>
                                </div>
                            </label>
                        </div>
                        <div class="form-group mb-5">
                            <label class="input-wraper fill-input input-wraper--label" 
                            :class="{ 'invalid': userName.lastName.touched && !userName.lastName.isValid }" style="background-color: transparent;">
                                <input 
                                    type="text" 
                                    id="last-name" 
                                    class="input font-semibold" 
                                    placeholder="Last name"
                                    v-model="userName.lastName.value"
                                    
                                    @blur="onFieldBlur(userName, ['lastName'])"
                                >
                                <div class="label-input">
                                    <span>Last name</span>
                                </div>
                            </label>
                        </div>
                        <div class="form-group mb-5">
                            <label class="input-wraper fill-input input-wraper--label"
                                    :class="{ 'invalid': userEmail.email.touched && !userEmail.email.isValid }" style="background-color: transparent;">

                                <input 
                                    type="email" 
                                    id="email-address" 
                                    class="input font-semibold flex-auto w-auto"
                                    placeholder="Email"
                                    v-model="userEmail.email.value"
                                    
                                    @blur="onFieldBlur(userEmail, ['email'])"
                                >
                                <span class="badge bg-primary-500 text-[12px] mr-[20px] px-[15px]">
                                    <span class="info">Verified</span>
                                </span>
                                <div class="label-input">
                                    <span>Email</span>
                                </div>
                            </label>
                        </div>
                        <div class="form-group mb-5">
                            <label class="input-wraper fill-input input-wraper--label" style="background-color: transparent;">
                                <div class="flex items-center font-semibold input px-[15px] flex-auto w-auto">
                                    <img src="@/assets/img/icons8-google.svg" class="mr-[10px]" alt="">
                                    Google Account
                                </div>
                                <span class="badge bg-teal text-[12px] mr-[20px] px-[15px]">
                                    <span class="info">Connected</span>
                                </span>
                                <div class="label-input">
                                    <span>Linked account</span>
                                </div>
                            </label>
                        </div>
                    </div>
                    <!-- <hr class="hr my-[20px] sm:hidden"> -->
                </div>
            </div>
            <div class="col-lg-6">
                <div class="card-setting sm:bg-secondary-100 p-0 card-rounded">
                    <div class="card-body p-0 sm:p-[20px]">
                        <h6 class="mb-[18px]">Saved accounts</h6>
                        <div class="form-group mb-5">
                            <label class="input-wraper input-wrapper-ico--left mb-5" style="background-color: transparent;" v-for="account in accountsList" :key="account.id">
                                <span class="input-icon-user">
                                    <img :src="account.image" alt="">
                                </span>
                                <input type="text" class="input font-semibold" :value="`@${account.name}`" readonly="">
                                <a @click.prevent="removeAccount(account.id)" href="" class="btn mr-[20px]">
                                    <span class="btn-icon">
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.88427 7.00067L13.5933 2.29032C13.9396 1.95586 14.0785 1.46057 13.9566 0.994829C13.8346 0.529083 13.4709 0.165357 13.0052 0.043448C12.5394 -0.0784609 12.0441 0.0604198 11.7097 0.406711L6.99933 5.11573L2.29032 0.406711C1.95586 0.0604198 1.46057 -0.0784609 0.994829 0.043448C0.529083 0.165357 0.165357 0.529083 0.043448 0.994829C-0.0784609 1.46057 0.0604198 1.95586 0.406711 2.29032L5.11573 6.99933L0.406711 11.7097C0.0604198 12.0441 -0.0784609 12.5394 0.043448 13.0052C0.165357 13.4709 0.529083 13.8346 0.994829 13.9566C1.46057 14.0785 1.95586 13.9396 2.29032 13.5933L6.99933 8.88427L11.7097 13.5933C12.0441 13.9396 12.5394 14.0785 13.0052 13.9566C13.4709 13.8346 13.8346 13.4709 13.9566 13.0052C14.0785 12.5394 13.9396 12.0441 13.5933 11.7097L8.88427 6.99933V7.00067Z" fill="#77838F"></path>
                                        </svg>
                                    </span>
                                </a>
                            </label>
                        </div>
                        <div class="form-group mb-5">
                            <label class="input-wraper input-wrapper-ico--left" style="background-color: transparent;">
                                <span class="input-icon">
                                    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2102 5.64967H18.7554C21.8251 5.65276 24.313 8.14021 24.3166 11.2099V18.7551C24.3135 21.8249 21.826 24.3127 18.7563 24.3163H11.2111C8.14138 24.3132 5.65351 21.8258 5.6499 18.7561V11.2109C5.65299 8.14115 8.14045 5.65327 11.2102 5.64967ZM21.3602 21.36C22.051 20.6691 22.4391 19.7321 22.4391 18.7551V11.2109C22.4391 9.17673 20.7904 7.5276 18.7563 7.52708H11.2111C9.17697 7.52708 7.52784 9.1758 7.52732 11.2099V18.7551C7.52732 20.7893 9.17604 22.4384 11.2102 22.4389H18.7554C19.7324 22.4389 20.6694 22.0508 21.3602 21.36Z" fill="currentColor"></path>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1562 14.9825C10.1599 12.3184 12.3184 10.1594 14.9826 10.1553V10.1562C17.6474 10.1588 19.8076 12.3176 19.8117 14.9825C19.8086 17.6478 17.6487 19.8076 14.9835 19.8107C12.3186 19.8071 10.1593 17.6474 10.1562 14.9825ZM12.0327 14.9835C12.0327 16.6126 13.3534 17.9333 14.9826 17.9333C16.6117 17.9333 17.9324 16.6126 17.9324 14.9835C17.9324 13.3543 16.6117 12.0336 14.9826 12.0336C13.3534 12.0336 12.0327 13.3543 12.0327 14.9835Z" fill="currentColor"></path>
                                        <circle cx="19.8201" cy="10.1917" r="1.15656" fill="currentColor"></circle>
                                    </svg>
                                </span>
                                <input type="text" class="input font-semibold" placeholder="@username" v-model="newAccountName">
                            </label>
                        </div>
                        <a @click.prevent="addAccount" href="#" class="btn btn--primary-outline px-[23px] py-[20px] rounded-[8px] w-full text-[14px]">
                            <span class="info">Add account</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { dashboardApi } from '@/api/dashboardApi';
    import { platformsApi } from '@/api/platformsApi';
    import { profileApi } from '@/api/profileApi';
    import formValidation from '@/mixins/formValidation';
    export default{
        data() {
            return {
                isEditable: false,
                accountsList: [],
                newAccountName: "",
                userName: false,
                userEmail: false,
                changesSaved: false
            };
        },
        props: ['dataprops'],
        mixins: [formValidation],
        computed:{},
        watch: {
            'dataprops': {
                handler(newValue) {
                    if(!newValue) return
                    this.setValue(this.userName, 'firstName', this.dataprops.user.first_name);
                    this.setValue(this.userName, 'lastName', this.dataprops.user.last_name);
                    this.setValue(this.userEmail, 'email', this.dataprops.user.email);
                    if(newValue.autofillData) {
                        this.accountsList = newValue.autofillData
                    }
                },
                deep: false,
            }
        },
        methods: {
            openModal(name){
                this.$bus.$emit('modal',{
                    name: name,
                    overlay: true,
                    payload: {
                        login: ()=> this.isLogged = true,
                    },
                })
            },
            async addAccount(){
                // this.accountsList.push({id: new Date(), name: this.newAccountName}) 
                // this.newAccountName = "";
                const scrapperResponse = await platformsApi.getTikTokUser(this.newAccountName);
                console.log('scrapper resp', scrapperResponse)
                if(scrapperResponse.hasError) {
                    return
                }
                const autofillResponse = await dashboardApi.addAutofillsData({
                    name: this.newAccountName,  // имя пользователя
                    image: scrapperResponse.avatar_168x168.url_list[0],
                    platform_id: 9
                })
                this.accountsList.push(autofillResponse)
                this.newAccountName = "";
                console.log('autofill', autofillResponse)
                // this.$bus.updateDataprops('autofillData', [...this.payload.dataprops.paymentMethods, response.payment_method]);
            },
            async removeAccount(id){
                const response = await dashboardApi.removeAutofillsData(id);

                this.accountsList = this.accountsList.filter(accout => accout.id !== id)
            },
            async saveChanges() {

                // this.$bus.$emit('modal',{
                //         name: 'change-email',
                //         overlay: true,
                //         payload: {
                //             // publicCode: '21312312321',
                //             // newEmail: this.userEmail.email.value,
                //             dataprops: this.dataprops
                //         },
                //     })

                //     return

                this.isEditable = false;

                const isValid = this.validate(this.userName) && this.validate(this.userEmail)
                console.log(isValid, 'validate')
                if (!isValid) return

                const actualName = this.userName.firstName.value + ' ' + this.userName.lastName.value
                const actualEmail = this.userEmail.email.value

                const promises = [
                    this.dataprops.user.name !== actualName ? profileApi.update({...this.getValues(this.userName)}) : null,
                    this.dataprops.user.email !== actualEmail ? profileApi.emailChangeConfirm(this.getValues(this.userEmail).email) : null
                ]

                const [nameResp, emailResp] = await Promise.allSettled(promises).then(res => res.map(el => el.value))
                console.log('name resp', nameResp)
                if(nameResp && !nameResp?.hasError) {
                    this.changesSaved = true;
                    setTimeout(() => {
                        this.changesSaved = false;
                    }, 2000)
                    this.$set(this.dataprops.user, 'name', `${this.userName.firstName.value} ${this.userName.lastName.value}`);
                }
                if(!emailResp || emailResp.hasError) return;
                this.$bus.$emit('modal',{
                    name: 'change-email',
                    overlay: true,
                    payload: {
                        publicCode: emailResp.publicCode,
                        newEmail: this.userEmail.email.value,
                        dataprops: this.dataprops
                    },
                })
            }

        },
        created() {
            this.userName = this.createForm({
                firstName: {
                    value: '',
                    required: true
                },
                lastName: {
                    value: '',
                    required: true
                },
            });

            const emailValidator = (val) => /\S+@\S+\.\S+/.test(val);
            this.userEmail = this.createForm({
                email: {
                    value: '',
                    required: true,
                    validators: [
                        emailValidator
                    ]
                }
            });

            this.setValue(this.userName, 'firstName', (this.dataprops.user.name || '').split(' ')[0]);
            this.setValue(this.userName, 'lastName', (this.dataprops.user.name || '').split(' ')[1]);

            this.setValue(this.userEmail, 'email', this.dataprops.user.email);
        }
    }
</script>

<style scoped>

    .invalid {
        border: 1.5px solid red;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0px 1000px #f8f8f8 inset !important;
        
        height: 100%;
    }

    input[readonly]:-webkit-autofill {
        -webkit-text-fill-color: rgba(0,0,0,0.3);
        -moz-transition: all .5s ease; 
        -ms-transition: all .5s ease;
        -o-transition: all .5s ease;
        -pie-transition: all .5s ease;
        transition: all .5s ease;
    }

</style>