<template>
    <div id="modal-error" class="modal modal-10 modal--show">
        <div class="modal__dialog modal__dialog--522">
            <div class="modal__content" v-click-outside="closeModal">
                <div class="modal__header px-[28px] md:px-[92px] py-[34px] md:py-[40px]">
                    <h6 class="font-bold text-[18px] leading-[24px]"></h6>
                    <a href="#" class="btn btn-close mobail-11x11" data-close-modal="" @click.prevent="closeModal()">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.4771 2.94469C17.9223 2.51467 18.1009 1.87788 17.9441 1.27907C17.7874 0.68025 17.3197 0.212602 16.7209 0.0558617C16.1221 -0.100878 15.4853 0.0776826 15.0553 0.522914L8.99914 6.57736L2.94469 0.522914C2.51467 0.0776826 1.87788 -0.100878 1.27907 0.0558617C0.68025 0.212602 0.212602 0.68025 0.0558617 1.27907C-0.100878 1.87788 0.0776826 2.51467 0.522914 2.94469L6.57736 8.99914L0.522914 15.0553C0.0776826 15.4853 -0.100878 16.1221 0.0558617 16.7209C0.212602 17.3197 0.68025 17.7874 1.27907 17.9441C1.87788 18.1009 2.51467 17.9223 2.94469 17.4771L8.99914 11.4226L15.0553 17.4771C15.4853 17.9223 16.1221 18.1009 16.7209 17.9441C17.3197 17.7874 17.7874 17.3197 17.9441 16.7209C18.1009 16.1221 17.9223 15.4853 17.4771 15.0553L11.4226 8.99914L17.4771 2.94469Z" fill="#3A4C66"></path>
                        </svg>
                    </a>
                </div>
                <div class="modal__body pb-[65px] px-[28px] md:px-[92px]">
                    <div class="mb-[8px] flex justify-center">
                        <img src="@/assets/img/icon-error-market.svg">
                    </div>
                    <p class="max-w-[325px] text-[24px] leading-[32px] font-[700] text-center mb-[15px] mx-auto">Minimum
                        cart value has not been reached.</p>
                    <p class="text-secondary-500 text-[14px] leading-[18px] font-[600] mb-[50px] text-center mx-auto">Please
                        copy the coupon code and paste it in cart when order total is over ${{ total }}.</p>
                    <a @click.prevent="goToLink('/cart')" class="btn btn--primary px-[27px] py-[19px] icon-btn rounded-[8px] mb-[15px]">
                        <div class="icon-left">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.7646 7.55596C19.4742 7.01465 18.9409 6.67717 18.36 6.66711H7.78416L7.30495 4.65828C7.20525 4.25899 6.8628 3.98637 6.47871 4.00053H4.82624C4.36992 4.00053 4 4.39848 4 4.88939C4 5.38029 4.36992 5.77825 4.82624 5.77825H5.85077L8.13118 14.8979C8.23088 15.2972 8.57333 15.5699 8.95742 15.5557H16.3936C16.7047 15.5547 16.9889 15.3657 17.1289 15.0668L19.839 9.23591C20.0775 8.69793 20.0496 8.06712 19.7646 7.55596ZM15.8813 13.778H9.58536L8.23859 8.44482H18.36L15.8813 13.778ZM9.78366 18.6667C9.78366 19.4031 9.22878 20 8.5443 20C7.85983 20 7.30495 19.4031 7.30495 18.6667C7.30495 17.9304 7.85983 17.3334 8.5443 17.3334C9.22878 17.3334 9.78366 17.9304 9.78366 18.6667ZM16.8067 20C17.4911 20 18.046 19.4031 18.046 18.6667C18.046 17.9304 17.4911 17.3334 16.8067 17.3334C16.1222 17.3334 15.5673 17.9304 15.5673 18.6667C15.5673 19.4031 16.1222 20 16.8067 20Z" fill="currentColor"></path>
                            </svg>
                        </div>
                        <span>My Cart</span>
                    </a>
                    <a @click.prevent="goToLink('/service-page/likes')" class="btn btn--primary-outline px-[27px] py-[19px] icon-btn rounded-[8px] justify-start font-[700] text-[14px] leading-[19px]">
                        <span>New order</span>
                        <div class="icon-right">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.31818 11.6361L21 11.6361" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M13.3636 19.2727L21 11.6364L13.3636 4" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            total: null,
        };
    },
    props: ['payload'],
    computed: {},
    methods: {
        closeModal() {
            this.$bus.$emit("modal", {
                name: "",
                payload: {},
            });
        },
        goToLink(link) {
            this.$router.push(link)
            this.closeModal()
        },
    },
    mounted() {
        this.total = this.payload.total || null
    }
};
</script>

<style>

</style>