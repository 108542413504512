<template>
    <div class="container mx-0 container-dash">
        <div class="flex items-center justify-center relative mb-[50px] mt-[14px] lg:hidden">
            <router-link to="/add-funds" class="btn w-[40px] h-[40px] bg-primary-100 rounded-[11px] left-0 absolute">
                <img src="@/assets/img/icons/left-arrow-long.svg" alt="">
            </router-link>
            <span class="def-text--1 font-semibold">Add funds</span>
        </div>
        <div class="flex items-center justify-between mb-[25px]">
            <div class="flex items-center">
                <router-link to="/add-funds" class="btn w-[40px] h-[40px] bg-primary-100 rounded-[11px] mr-[20px] hidden lg:flex">
                    <img src="@/assets/img/icons/left-arrow-long.svg" alt="">
                </router-link>
                <h6>Balance history</h6>
            </div>
            <span class="flex items-center font-semibold">
                <span class="text-text mr-[15px]">Current</span>
                <span>{{ dataprops.cartData.currency }}{{ ((dataprops.userMe ? dataprops.userMe.balance : dataprops.user.balance) || 0) | floatNumberWithSpaces }}</span>
            </span>
        </div>
        <div class="table-def-wrapper mb-[20px]" v-if="dataprops.balanceHistory && dataprops.balanceHistory.length">
            <table class="table-def" cellpadding="0" cellspacing="0">
                <thead class="hidden md:table-header-group">
                    <tr>
                        <th class="text-left">Amount</th>
                        <th class="text-left">Date</th>
                        <th class="text-left">Transaction type</th>
                        <th class="text-left"></th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(item, index) in dataprops.balanceHistory">
                        <tr class="table--item" :key="index+'-1'">
                            <td>
                                <div class="user-socialinfo">
                                    <div class="socialinfo--header">
                                        <div class="info">
                                            <span class="font-semibold" :class="{'text-primary-500': item.change < 0}">{{ item.change > 0 ? '+' : '' }}{{ item.change }}</span>
                                        </div>
                                        <div class="md:hidden">
                                            <p class="text-secondary-500 text-def text-def-1">
                                                {{ new Date(item.created_at).toLocaleDateString() }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="socialinfo--body md:hidden">
                                        <div class="socialinfo--body--item">
                                            <div class="flex justify-between">
                                                <p class="text-secondary-500 text-def text-def-1">
                                                    Payment with balance
                                                </p>
                                                <span class="font-semibold">#H8JASK</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="hidden md:table-cell">
                                <p class="text-secondary-500 text-def text-def-1">
                                    {{ new Date(item.created_at).toLocaleDateString() }}
                                </p>
                            </td>
                            <td class="hidden md:table-cell">
                                <p class="text-secondary-500 text-def text-def-1">
                                    Payment with balance
                                </p>
                            </td>
                            <td class="hidden md:table-cell">
                                <span class="font-semibold">#H8JASK</span>
                            </td>
                        </tr>
                        <tr class="spacetable" :key="index+'-2'"></tr>
                    </template>
                </tbody>
            </table>
        </div>
        <div v-else>
            <h3>Balance history is empty</h3>
        </div>
        <div class="flex items-center justify-center sm:hidden">
            <a href="#" class="btn flex-col text-center def-text--1 text-primary-500">
                <span class="font-semibold">
                    See more
                </span>
                <span class="ico ico-20 mt-[4px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5265 15.2603C11.4578 15.2322 11.3954 15.1906 11.343 15.138L5 8.792L5.792 8L11.743 13.951L17.694 8L18.486 8.792L12.139 15.138C12.0871 15.1901 12.0253 15.2314 11.9574 15.2595C11.8894 15.2877 11.8166 15.3021 11.743 15.302C11.6688 15.3026 11.5952 15.2885 11.5265 15.2603Z" fill="currentColor"></path>
                    </svg>
                </span>
            </a>
        </div>
    </div>
</template>

<script>
import { floatNumberWithSpaces } from '@/helpers/filters.js';

export default {
    props: ["dataprops"],
    filters: { floatNumberWithSpaces, },
    methods:{
        openModal(name){
            this.$bus.$emit('modal',{
                name: name,
                overlay: true,
                payload: {
                    login: ()=> this.isLogged = true,
                },
            })
        },
    },
}
</script>

<style>

</style>