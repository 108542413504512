<template>
    <div id="write-review" class="modal modal-11 modal--show">
        <div class="modal__dialog modal__dialog--522">
            <div class="modal__content" v-click-outside="closeModal">
                <div class="modal__header pb-[17px]">
                    <h6 class="font-bold text-[18px] leading-[24px]">Write a review</h6>
                    <a href="#" class="btn btn-close" data-close-modal="" @click.prevent="closeModal">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.4771 2.94469C17.9223 2.51467 18.1009 1.87788 17.9441 1.27907C17.7874 0.68025 17.3197 0.212602 16.7209 0.0558617C16.1221 -0.100878 15.4853 0.0776826 15.0553 0.522914L8.99914 6.57736L2.94469 0.522914C2.51467 0.0776826 1.87788 -0.100878 1.27907 0.0558617C0.68025 0.212602 0.212602 0.68025 0.0558617 1.27907C-0.100878 1.87788 0.0776826 2.51467 0.522914 2.94469L6.57736 8.99914L0.522914 15.0553C0.0776826 15.4853 -0.100878 16.1221 0.0558617 16.7209C0.212602 17.3197 0.68025 17.7874 1.27907 17.9441C1.87788 18.1009 2.51467 17.9223 2.94469 17.4771L8.99914 11.4226L15.0553 17.4771C15.4853 17.9223 16.1221 18.1009 16.7209 17.9441C17.3197 17.7874 17.7874 17.3197 17.9441 16.7209C18.1009 16.1221 17.9223 15.4853 17.4771 15.0553L11.4226 8.99914L17.4771 2.94469Z" fill="#3A4C66"></path>
                        </svg>
                    </a>
                </div>
                <div class="modal__body pb-[50px] px-[50px]">
                    <SelectBox
                        key="select-1"
                        identifier='select-icon'
                        :options="types"
                        :data-image="require('@/assets/img/icons/icon-tiktok-logo.svg')"
                    />
    
                    <div class="flex justify-between items-center mb-[17px]">
                        <span class="text-[14px] leading-[20px] font-[600]">Rate this service</span>
    
                        <div class="rating-input">
                            <input type="radio" name="rating1" id="rating-25" :value="5" v-model="rating">
                            <label for="rating-25"></label>
                            <input type="radio" name="rating1" id="rating-24" :value="4" v-model="rating">
                            <label for="rating-24"></label>
                            <input type="radio" name="rating1" id="rating-23" :value="3" v-model="rating">
                            <label for="rating-23"></label>
                            <input type="radio" name="rating1" id="rating-22" :value="2" v-model="rating">
                            <label for="rating-22"></label>
                            <input type="radio" name="rating1" id="rating-21" :value="1" v-model="rating">
                            <label for="rating-21"></label>
                        </div>
                    </div>
    
                    <div class="flex items-center px-[12px] py-[19px] rounded-[8px] border mb-[19px]">
                        <div class="mr-[9px]">
                            <img src="@/assets/img/avatar-30x30.png">
                        </div>
                        <span class="text-[14px] leading-[17px] font-[600]">@kylejenner</span>
                    </div>
    
                    <div class="flex justify-between items-center mb-[17px]">
                        <span class="text-[14px] leading-[20px] font-[600]">Hide username</span>
                        <label class="switch-default">
                            <input type="checkbox" class="switch__field" v-model="hideUsername" hidden>
                            <span class="switch__slider"></span>
                        </label>
                    </div>
    
                    <div class="textarea-wrap mb-[27px]">
                        <textarea :class="{'invalid': errorTextarea}" @input="errorTextarea=false" class="textarea w-full mb-[16px]" rows="5" minlength="30" placeholder="Write your review" v-model="reviewText" ></textarea>
                        <span class="flex text-[12px] leading-[16px] font-[600]">Minimum: 30 characters</span>
                    </div>
    
                    <a @click.prevent="saveReview" href="#" class="btn btn--primary pl-[27px] pr-[56px] py-[13px] icon-btn rounded-[8px] justify-start font-[700] text-[14px] leading-[19px]" >
                        <span>Submit</span>
                        <div class="icon-right">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.31818 11.6361L21 11.6361" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M13.3636 19.2727L21 11.6364L13.3636 4" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ordersApi } from "@/api/ordersApi";
import SelectBox from "../Base/SelectBox.vue"
export default {
    components: {
        SelectBox,
    },
    props: ['payload'],
    created() {
        this.rating = this.payload.rating || 0
    },
    data() {
        return {
            rating: 0,
            hideUsername: false,
            errorTextarea: false,
            reviewText: '',
            types: [
                {
                    value: "100",
                    label: "100 likes",
                    selected: true,
                },
                {
                    value: "250",
                    label: "250 likes",
                },
                {
                    value: "500",
                    label: "500 likes",
                },
                {
                    value: "750",
                    label: "750 likes",
                },
                {
                    value: "1000",
                    label: "1000 likes",
                }
            ],
        };
    },
    methods: {
        closeModal() {
            this.$bus.$emit("modal", {
                name: "",
                payload: {},
            });
        },
        async saveReview() {
            // if (this.reviewText.length < 29) {
            //     this.errorTextarea = true
            // }
            const response = await ordersApi.createReview({
                hash: this.payload.hash,
                feedback: 'test',
                rating: 5,
                order_line_id: 1,
                hide_username: false
            })
        }
    },
}
</script>


<style scoped>

    .switch-default {
        --button-width: 52px;
        --button-height: 32px;
        --toggle-diameter: 28px;
        --button-toggle-offset: calc((var(--button-height) - var(--toggle-diameter)) / 2);
        --color-off: var(--text);
        --color-on: var(--primary-500);
        --button-radius: 24px;
        display: flex;
        cursor: pointer;
    }

    .switch-default input[type="checkbox"]:checked + .switch__slider {
        background-color: var(--color-on);
    }

    .switch-default input[type="checkbox"]:checked + .switch__slider::after {
        transform: translateX(calc(var(--button-width) - var(--toggle-diameter) - var(--button-toggle-offset)));
    }
    .invalid {
        border: 1px solid red;
    }

</style>