<template>
	<div id="app" style="height: 100%">
		<component :is="layout" :dataprops="data">
            <router-view />
		</component>
	</div>
</template>
  
<script>
    import {profileApi} from './api/profileApi'
    import { otherApi } from './api/othersApi';
    import { ordersApi } from './api/ordersApi';
    import { cartApi } from './api/cartApi';
    import { platformsApi } from './api/platformsApi'
    import staticDatapropsJson from './test.js'
    import DefaultLayout from './components/Layout/DefaultLayout.vue';
    import { dashboardApi } from './api/dashboardApi';
    import {cart} from "@/components/Panel/test";
    import { affiliatorApi } from './api/affiliatorApi';
    import { paymentApi } from './api/paymentApi';

    export default {
        data() {
            return {
                data: {
                    user: null,
                    notifications: [],
                    paymentMethods: []
                }
            }
        },
        watch: {
            $route: {
                immediate: true,
                handler(to, from) {
                    document.title = to.meta.title ? `TinkWS - ${to.meta.title}` : 'TinkWS';
                }
            },
            'data.user': {
                handler(newValue) {
                    if (newValue) {
                        this.getUserUpdateData()
                    }
                },
                deep: false,
            }
        },
        computed: {
            layout() {
            	return this.$route.meta.layout || DefaultLayout
            },
        },
        methods: {
            async getUser() {
                const res = await profileApi.user()

                if (res.hasError) {

                } else {
                    // this.$bus.updateDataprops('user', res);
                    this.data.user = res
                }

            },
            async getUserUpdateData() {
                const [notificationsData, ordersData, cartData, autofillData, paymentData, discountsData] = await Promise.allSettled([
                    otherApi.getNotifications(),
                    ordersApi.getAllOrdersData(),
                    cartApi.getCartData(),
                    dashboardApi.getAllAutofills(),
                    paymentApi.getPaymentMethods(),
                    paymentApi.getDiscountsData()
                ])

                notificationsData.value && !notificationsData.value.hasError ? this.$bus.updateDataprops('notifications', notificationsData.value) : null
                ordersData.value && !ordersData.value.hasError ? this.$bus.updateDataprops('orders', ordersData.value) : null
                autofillData.value && !autofillData.value.hasError ? this.$bus.updateDataprops('autofillData', autofillData.value) : null
                cartData.value && !cartData.value.hasError ? this.$bus.updateDataprops('cartData', cartData.value) : null
                paymentData.value && !paymentData.value.hasError ? this.$bus.updateDataprops('paymentMethods', paymentData.value.paymentMethods) : null
                discountsData.value && !discountsData.value.hasError ? this.$bus.updateDataprops('discounts', discountsData.value) : null
                // cartData.value && !cartData.value.hasError ? this.data.cartData = cartData.value : null
            },
            async getInitData() {
                const [platformsData, generalData, affiliatorData, freePlatforms] = await Promise.allSettled([
                    platformsApi.getPlatforms(),
                    dashboardApi.general(),
                    affiliatorApi.getAffiliators(),
                    platformsApi.getFreePlatforms()
                ]);
                platformsData.value && !platformsData.value.hasError ? this.$bus.updateDataprops('platforms', platformsData.value) : null
                generalData.value && !generalData.value.hasError ? this.$bus.updateDataprops('general', generalData.value) : null
                affiliatorData.value && !affiliatorData.value.hasError ? this.$bus.updateDataprops('affiliator', affiliatorData.value.affiliator) : null
                freePlatforms.value && !freePlatforms.value.hasError ? this.$bus.updateDataprops('freePlatforms', freePlatforms.value.platforms) : null
            }
        },
        mounted() {
            const token = window.localStorage.getItem('token')
            
            if (token) {
                this.getUser(token)
            }

            this.getInitData()


            Number.prototype.toFixedNoRounding = function(n) {
                const reg = new RegExp(`^-?\\d+(?:\\.\\d{0,${n}})?`, 'g')
                const a = this.toString().match(reg)[0];
                const dot = a.indexOf('.');

                if (dot === -1) {
                    return a + '.' + '0'.repeat(n);
                }

                const b = n - (a.length - dot) + 1;

                return b > 0 ? (a + '0'.repeat(b)) : a;
            }
        },

        created() {
            this.$bus.updateDataprops('dataprops', this.data);
            // this.$bus.on('serverMessage', (event) => {
            //     console.log('serverMessage', event);
            // })
        }
    }
</script>