import restInstance from "@/lib/rest"

const rest = restInstance

export const cartApi = {
    async getCartData() {
        return await rest.request('/cart', 'GET', null, 'cart')
    },
    
    async addCartData(payload) {
        return await rest.request('/cart/add', 'POST', { items: [...payload] }, 'cartAdd')
    },
    
    async removeCartData(identifier) {
        return await rest.request('/cart/remove', 'POST', { identifier }, 'cartRemove')
    },
    
    async addCartDiscount(code) {
        return await rest.request('/cart/discount', 'POST', { code }, 'cartDiscount')
    },
    
    async removeCartDiscount() {
        return await rest.request('/cart/discount', 'DELETE', null, 'cartDiscountRemove')
    },
    
    async addCartAddon(payload) {
        const data = {
            itemIdentifier: payload.itemIdentifier,
            addonName: payload.addonName,
            status: payload.status,
        }
        return await rest.request('/cart/addon', 'POST', data, 'addCartAddon')
    },
    
    async cartBilling(payload) {
        const data = {
            first_name: payload.first_name, //
            last_name: payload.last_name, //
            country: payload.country, //  - FR, IT, DE, …
            company_name: payload.company_name, // - optional parameter
            vat_number: payload.vat_number, //  - optional parameter

        }
        return await rest.request('/cart/billing', 'POST', data, 'cartBilling')
    },
    
    async cartEmail(payload) {
        const data = {
            email: payload.email,
            email_confirmation: payload.email_confirmation,

        }
        return await rest.request('/cart/email', 'POST', data, 'cartEmail')
    },
    
    
    async addCartComment(payload) {
        const data = {
            itemIdentifier: payload.itemIdentifier,
            mediaIndex: payload.mediaIndex,
            commentIndex: payload.commentIndex,
            commentText: payload.commentText,

        }
        return await rest.request('/cart/comment', 'POST', data, 'addCartComment')
    },
    
    async removeCartComment(payload) {
        const data = {
            itemIdentifier: payload.itemIdentifier,
            mediaIndex: payload.mediaIndex,
            commentIndex: payload.commentIndex, // array
        }
        return await rest.request('/cart/comment/remove', 'POST', data, 'removeCartComment')
    },


}