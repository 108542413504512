import restInstance from "@/lib/rest"

const rest = restInstance

export const affiliatorApi = {
    async getAffiliators()  {
        return await rest.request('/affiliators', 'GET', null, 'affiliators')
    },
    
    async getAffiliatorsUsers()  {
        return await rest.request('/affiliators/more-users', 'GET', null, 'affiliatorsUsers')
    },
    
    async createAffiliators(payload)  {
        const data = {
            traffic_source: payload.traffic_source,
            target_audience_region: payload.target_audience_region,
            promotion_services: payload.promotion_services,
        }
        return await rest.request('/affiliators', 'POST', data, 'createAffiliators')
    },
    
    async updateAffiliatorsTag(payload)  {
        const data = {
            tag: payload.tag,
        }
        return await rest.request('/affiliators/link', 'PUT', data, 'updateAffiliatorsTag')
    },
    
    async getAffiliatorsPayout(payload)  {
        const data = {
            affiliator_payout_method_id: payload.affiliator_payout_method_id, // айди выбранного метода выплаты
            agreement: payload.agreement || 1, // должно быть “1”
            amount: payload.amount, // обязательно если используется выплата на баланс

        }
        return await rest.request('/affiliators/payout', 'PUT', data, 'getAffiliatorsPayout')
    },
    
    async cancelAffiliatorsPayout(payout_id)  {
        
        return await rest.request(`/affiliators/payouts/${payout_id}/cancel`, 'PUT', _, 'cancelAffiliatorsPayout')
    },
    
    
    async createAffiliatorsPayout(payload)  {
        const data = {
            // type_id: айди типа выплаты
            // data: объект. Данные для метода выплаты. Для крипты: coin+address. Для пейпла: email. Для карты: iban+swift_code+full_name+full_address.
        }
        return await rest.request('/affiliators/payout-methods', 'POST', data, 'createAffiliatorsPayout')
    },
    
    async deleteAffiliatorsPayout(payout_method_id)  {
        
        return await rest.request(`/affiliators/payout-methods/${payout_method_id}`, 'DELETE', _, 'deleteAffiliatorsPayout')
    },


}