<template>
    <div class="container mx-0 container-dash">
        <div class="flex items-center justify-center relative mb-[50px] mt-[14px] lg:hidden">
            <router-link to="/settings" class="btn w-[40px] h-[40px] bg-primary-100 rounded-[11px] left-0 absolute">
                <img src="@/assets/img/icons/left-arrow-long.svg" alt="">
            </router-link>
            <span class="def-text--1 font-semibold">My Invoice</span>
        </div>
        <div class="lg:flex items-center justify-between mb-[25px] hidden ">
            <div class="flex items-center">
                <router-link to="/settings" href="#" class="btn w-[40px] h-[40px] bg-primary-100 rounded-[11px] mr-[20px] hidden lg:flex">
                    <img src="@/assets/img/icons/left-arrow-long.svg" alt="">
                </router-link>
                <h6>My Invoices</h6>
            </div>
        </div>
        <div class="table-def-wrapper mb-[20px]" v-if="allTransactions.length">
            <table class="table-def" cellpadding="0" cellspacing="0">
                <thead class="hidden md:table-header-group">
                    <tr>
                        <th class="text-left">Invoice ID</th>
                        <th class="text-left">Date</th>
                        <th class="text-left">Price</th>
                        <th class="text-right"></th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="invoice in allTransactions">
                        <tr class="table--item" :key="invoice.hash">
                            <td>
                                <div class="user-socialinfo">
                                    <div class="socialinfo--header">
                                        <div class="info">
                                            <span class="font-semibold lg:text-primary-500">{{invoice.hash}}</span>
                                        </div>
                                        <div class="md:hidden">
                                            <p class="text-secondary-500 text-def text-def-1">
                                                {{ String(invoice.created_at).slice(0, 10) }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="socialinfo--body md:hidden">
                                        <div class="socialinfo--body--item">
                                            <div class="flex justify-between">
                                                <p class="text-secondary-500 text-def text-def-1">
                                                    ${{ invoice.paid_amount }}
                                                </p>
                                                <a href="" @click.prevent="downloadInvoice(invoice)" class="underline text-primary-500 text-def text-def-1">Download invoice</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="hidden md:table-cell">
                                <p class="text-secondary-500 text-def text-def-1">
                                    {{ String(invoice.created_at).split('T')[0] }}
                                </p>
                            </td>
                            <td class="hidden md:table-cell">
                                <p class="text-secondary-500 text-def text-def-1">
                                    ${{ invoice.paid_amount }}
                                </p>
                            </td>
                            <td class="hidden md:table-cell text-right">
                                <a href="" @click.prevent="downloadInvoice(invoice)" class="underline text-secondary-500 text-def text-def-1">Download invoice</a>
                            </td>
                        </tr>
                        <tr class="spacetable"></tr>
                    </template>
                </tbody>
            </table>
        </div>

        <div v-else>
            <h6 style="text-align: center;">You have no transaction</h6>
        </div>

        <div class="flex items-center justify-center" v-if="(!is_last_transaction && !!allTransactions.length)">
            <a v-if="!isLoading" href="#" @click.prevent="loadMore" class="btn flex-col text-center def-text--1 text-primary-500">
                <span class="font-semibold">
                    See more
                </span>
                <span class="ico ico-20 mt-[4px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5265 15.2603C11.4578 15.2322 11.3954 15.1906 11.343 15.138L5 8.792L5.792 8L11.743 13.951L17.694 8L18.486 8.792L12.139 15.138C12.0871 15.1901 12.0253 15.2314 11.9574 15.2595C11.8894 15.2877 11.8166 15.3021 11.743 15.302C11.6688 15.3026 11.5952 15.2885 11.5265 15.2603Z" fill="currentColor"></path>
                    </svg>
                </span>
            </a>
            <div v-else style="transform: scale(0.5);">
                <Loader />
            </div>
        </div>
    </div>
</template>

<script>
import { paymentApi } from '@/api/paymentApi';
import { ordersApi } from '@/api/ordersApi'
import Loader from '../components/Base/Loader.vue'
export default {
    components: {
        Loader
    },
    data() {
        return {
            is_last_transaction: false,
            isLoading: false,
            page: 1
        };
    },
    props: ['dataprops'],
    computed: {
        allTransactions() {
            return this?.dataprops?.transactions ? [...this.dataprops?.transactions] :[]
        },
    },
    mounted() {
        this.loadPaymentMethods()
    },
    methods: {
        async loadMore() {
            this.isLoading = true
            this.page+=1
            const response = await paymentApi.getTransactionsData(this.page);
            if(response.hasError) return
            this.is_last_transaction = response.is_last


            // const response = await this.$api.loadTransactions();

            let exitingTransactions = this.dataprops.transactions.map(el => el.hash);
            let items = response.items.filter(el => !exitingTransactions.includes(el.hash));

            this.$set(this.dataprops, 'transactions', [ ...this.dataprops.transactions, ...items ]);
            
            this.isLoading = false;
        },
        async downloadInvoice(invoice) {
            const response = await ordersApi.getOrderInvoice(invoice.hash);
            console.log('response 123', response);
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Invoice-${invoice.hash}.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        async loadPaymentMethods() {
            const response = await paymentApi.getTransactionsData();
            if(response.hasError) return
            this.$set(this.dataprops, 'transactions', response.items);
            this.is_last_transaction = response.is_last
        }
    }
}
</script>

<style>

</style>