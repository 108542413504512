<template>
    <div id="forgot-password" class="modal modal-21 modal-login modal--show">
        <div class="modal__dialog modal__dialog--587">
            <div class="modal__content">
                <div class="modal__header header-center">
                    <a href="#" class="btn btn-close" data-close-modal="" @click.prevent="openModal('login')">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.6818 11.6366L3 11.6366" stroke="#222B45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M10.6364 19.2727L3 11.6364L10.6364 4" stroke="#222B45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </a>
                    <h6 class="font-[600] text-[14px] leading-[24px] sm:hidden">Reset password</h6>
                </div>
                <div class="modal__body pb-[57px] px-[27px] sm:px-[125px] ">
                    <h3 class="text-[24px] leading-[32px] font-[700] mb-[20px] text-center">Forgot your details?</h3>

                    <span class="text-[14px] leading-[18px] font-[600] text-secondary-500 text-center mb-[19px]">We’ll send
                        reset link to your email.</span>

                    <div class="form-group mb-[19px]">
                        <label class="input-wraper fill-input input-wraper--label" :class="{'error': emailError}">
                            <input 
                                type="text"
                                id="email"
                                class="input font-semibold" 
                                placeholder="Email Address"
                                @input="emailError = null"
                                v-model="email"
                            >
                            <div class="label-input">
                                <span>Email Address</span>
                            </div>
                        </label>

                        <div class="confirmError" v-if="emailError">{{ emailError }}</div>
                    </div>

                    <a @click.prevent="confirm" href="#" class="btn btn--primary px-[27px] py-[19px] icon-btn rounded-[8px] font-[700] text-[14px] leading-[19px]">
                        <span>Send</span>
                    </a>


                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { authApi } from '@/api/authApi';
export default {
    props: ['payload'],
    data() {
        return {
            email: null,
            emailError: null
        };
    },
    computed: {},
    methods: {
        closeModal() {
            this.$bus.$emit("modal", {
                name: "",
                payload: {},
            });
        },
        openModal(name, payload){
            this.$bus.$emit('modal',{
                name: name,
                overlay: true,
                payload,
            })
        },
        async confirm() {
            const emailValidator = (val) => /\S+@\S+\.\S+/.test(val);

            const isValid = emailValidator(this.email)

            if (!isValid) {
                this.emailError = 'Enter a valid email'
                return
            }

                const res = await authApi.forgotPass({email: this.email})
                console.log(res, 'forgot pass res');

                if (res) {
                    this.openModal('forgot-password-sent', {email: this.email})
                } else {
                    this.emailError = 'No exist account on this email' 
                }
            

            

        }
    },
}
</script>