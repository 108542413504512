<template>
    <div v-if="!payload.specialError && payload.order" class="modal modal-2 modal--show">
        <div class="modal__dialog modal__dialog--sm">
            <div :class="hidePopupElements ? 'w-[100%]' : 'modal__content'">
                <div :class="hidePopupElements ? 'w-[100%]' : 'modal__body quick-order-message-modal__body'">
                    <div :class="hidePopupElements ? 'w-[100%]' : 'modal-remove-body'">
                        <div class="quick-order-message quick-order-message--success" :class="{'w-[100%]' : !hidePopupElements}">
                            <div class="quick-order-message__holder">
                                <div class="quick-order-message__icon">
                                    <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25237 5.25237C8.81096 1.69379 14.136 0.333252 20.9999 0.333252H36.9999C43.8638 0.333252 49.1889 1.69379 52.7475 5.25237C56.3061 8.81096 57.6666 14.136 57.6666 20.9999V36.9999C57.6666 43.8638 56.3061 49.1889 52.7475 52.7475C49.1889 56.3061 43.8638 57.6666 36.9999 57.6666H20.9999C14.136 57.6666 8.81096 56.3061 5.25237 52.7475C1.69379 49.1889 0.333252 43.8638 0.333252 36.9999V20.9999C0.333252 14.136 1.69379 8.81096 5.25237 5.25237ZM8.0808 8.0808C5.63938 10.5222 4.33325 14.5305 4.33325 20.9999V36.9999C4.33325 43.4693 5.63938 47.4776 8.0808 49.919C10.5222 52.3605 14.5305 53.6666 20.9999 53.6666H36.9999C43.4693 53.6666 47.4776 52.3605 49.919 49.919C52.3605 47.4776 53.6666 43.4693 53.6666 36.9999V20.9999C53.6666 14.5305 52.3605 10.5222 49.919 8.0808C47.4776 5.63938 43.4693 4.33325 36.9999 4.33325H20.9999C14.5305 4.33325 10.5222 5.63938 8.0808 8.0808Z" fill="url(#paint0_linear_929_323)"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25237 5.25237C8.81096 1.69379 14.136 0.333252 20.9999 0.333252H36.9999C43.8638 0.333252 49.1889 1.69379 52.7475 5.25237C56.3061 8.81096 57.6666 14.136 57.6666 20.9999V36.9999C57.6666 43.8638 56.3061 49.1889 52.7475 52.7475C49.1889 56.3061 43.8638 57.6666 36.9999 57.6666H20.9999C14.136 57.6666 8.81096 56.3061 5.25237 52.7475C1.69379 49.1889 0.333252 43.8638 0.333252 36.9999V20.9999C0.333252 14.136 1.69379 8.81096 5.25237 5.25237ZM8.0808 8.0808C5.63938 10.5222 4.33325 14.5305 4.33325 20.9999V36.9999C4.33325 43.4693 5.63938 47.4776 8.0808 49.919C10.5222 52.3605 14.5305 53.6666 20.9999 53.6666H36.9999C43.4693 53.6666 47.4776 52.3605 49.919 49.919C52.3605 47.4776 53.6666 43.4693 53.6666 36.9999V20.9999C53.6666 14.5305 52.3605 10.5222 49.919 8.0808C47.4776 5.63938 43.4693 4.33325 36.9999 4.33325H20.9999C14.5305 4.33325 10.5222 5.63938 8.0808 8.0808Z" fill="url(#paint1_linear_929_323)"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M41.7487 20.0403C42.5291 20.822 42.528 22.0884 41.7462 22.8687L26.6262 37.962C25.845 38.7419 24.5796 38.7414 23.799 37.9608L16.2524 30.4141C15.4713 29.6331 15.4713 28.3668 16.2524 27.5857C17.0334 26.8047 18.2998 26.8047 19.0808 27.5857L25.2145 33.7194L38.9203 20.0378C39.702 19.2574 40.9684 19.2585 41.7487 20.0403Z" fill="url(#paint2_linear_929_323)"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M41.7487 20.0403C42.5291 20.822 42.528 22.0884 41.7462 22.8687L26.6262 37.962C25.845 38.7419 24.5796 38.7414 23.799 37.9608L16.2524 30.4141C15.4713 29.6331 15.4713 28.3668 16.2524 27.5857C17.0334 26.8047 18.2998 26.8047 19.0808 27.5857L25.2145 33.7194L38.9203 20.0378C39.702 19.2574 40.9684 19.2585 41.7487 20.0403Z" fill="url(#paint3_linear_929_323)"/>
                                        <defs>
                                            <linearGradient id="paint0_linear_929_323" x1="-3.0241" y1="-3.25008" x2="77.9931" y2="20.1559" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#20A7EE"/>
                                                <stop offset="1" stop-color="#0ED5E1"/>
                                            </linearGradient>
                                            <linearGradient id="paint1_linear_929_323" x1="-0.741747" y1="-15.4334" x2="-16.621" y2="88.2101" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#87E865"/>
                                                <stop offset="1" stop-color="#43CB59"/>
                                            </linearGradient>
                                            <linearGradient id="paint2_linear_929_323" x1="-3.0241" y1="-3.25008" x2="77.9931" y2="20.1559" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#20A7EE"/>
                                                <stop offset="1" stop-color="#0ED5E1"/>
                                            </linearGradient>
                                            <linearGradient id="paint3_linear_929_323" x1="-0.741747" y1="-15.4334" x2="-16.621" y2="88.2101" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#87E865"/>
                                                <stop offset="1" stop-color="#43CB59"/>
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                    <svg v-if="false" xmlns="http://www.w3.org/2000/svg" width="58" height="58" viewBox="0 0 58 58" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M37.9608 20.039C38.7418 20.8201 38.7418 22.0864 37.9608 22.8675L22.8675 37.9608C22.0864 38.7418 20.8201 38.7418 20.039 37.9608C19.258 37.1798 19.258 35.9134 20.039 35.1324L35.1324 20.039C35.9134 19.258 37.1798 19.258 37.9608 20.039Z" fill="#CCA52D"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.039 20.039C20.8201 19.258 22.0864 19.258 22.8675 20.039L37.9608 35.1324C38.7418 35.9134 38.7418 37.1798 37.9608 37.9608C37.1798 38.7418 35.9134 38.7418 35.1324 37.9608L20.039 22.8675C19.258 22.0864 19.258 20.8201 20.039 20.039Z" fill="#CCA52D"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25237 5.25237C8.81096 1.69379 14.136 0.333252 20.9999 0.333252H36.9999C43.8638 0.333252 49.1889 1.69379 52.7475 5.25237C56.3061 8.81096 57.6666 14.136 57.6666 20.9999V36.9999C57.6666 43.8638 56.3061 49.1889 52.7475 52.7475C49.1889 56.3061 43.8638 57.6666 36.9999 57.6666H20.9999C14.136 57.6666 8.81096 56.3061 5.25237 52.7475C1.69379 49.1889 0.333252 43.8638 0.333252 36.9999V20.9999C0.333252 14.136 1.69379 8.81096 5.25237 5.25237ZM8.0808 8.0808C5.63938 10.5222 4.33325 14.5305 4.33325 20.9999V36.9999C4.33325 43.4693 5.63938 47.4776 8.0808 49.919C10.5222 52.3605 14.5305 53.6666 20.9999 53.6666H36.9999C43.4693 53.6666 47.4776 52.3605 49.919 49.919C52.3605 47.4776 53.6666 43.4693 53.6666 36.9999V20.9999C53.6666 14.5305 52.3605 10.5222 49.919 8.0808C47.4776 5.63938 43.4693 4.33325 36.9999 4.33325H20.9999C14.5305 4.33325 10.5222 5.63938 8.0808 8.0808Z" fill="#CCA52D"/>
                                    </svg>
                                </div>
                                <div class="quick-order-message__title">
                                    <translate-wrapper :translateString="translate('free-trial-started')"></translate-wrapper>
                                </div>
                                <div class="quick-order-message__number">#{{ payload.order.hash }}</div>
                                <div class="mb-[15px] card__text"  v-if="payload.message">{{ payload.message }}</div>
                            </div>
                            <div class="quick-order-message__holder">
                                <div class="quick-order-message__total">
                                    <span><translate-wrapper :translateString="translate('confirmed')"></translate-wrapper></span>
                                    <!-- <strong><translate-wrapper :translateString="translate('free')"></translate-wrapper></strong> -->
                                    <strong>{{translate('free')}}</strong>
                                    <!-- <span></span> -->
                                </div>
                            </div>
                            <div class="quick-order-message__holder"></div>
                            <div class="dash-px-30">
                                <a v-if="hidePopupElements" class="btn btn--md btn--primary" @click="$emit('close')">
                                    <span><translate-wrapper :translateString="translate('see-order-details')"></translate-wrapper></span>
                                </a>
                                <router-link
                                    v-else
                                    :to="`/dashboard/order-details/${payload.order.hash}`"
                                    tag="a"
                                    class="btn btn--md btn--primary w-[100%]" 
                                ><translate-wrapper :translateString="translate('see-order-details')"></translate-wrapper></router-link>    
                                <a @click="redirectToNewOrder" class="btn btn--md btn--outline-primary" :class="hidePopupElements ? '' : 'w-[100%]'">
                                    <span class="btn__icon">
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M0.9375 6C0.9375 5.68934 1.18934 5.4375 1.5 5.4375H10.5C10.8107 5.4375 11.0625 5.68934 11.0625 6C11.0625 6.31066 10.8107 6.5625 10.5 6.5625H1.5C1.18934 6.5625 0.9375 6.31066 0.9375 6Z"
                                                fill="#05A6DB"
                                            ></path>
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M6 0.9375C6.31066 0.9375 6.5625 1.18934 6.5625 1.5V10.5C6.5625 10.8107 6.31066 11.0625 6 11.0625C5.68934 11.0625 5.4375 10.8107 5.4375 10.5V1.5C5.4375 1.18934 5.68934 0.9375 6 0.9375Z"
                                                fill="#05A6DB"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span><translate-wrapper :translateString="translate('new-order')"></translate-wrapper></span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="modal-box__footer mt-32 align-items-center text-center" v-if="!hidePopupElements">
                        <div class="mb-[13px] w-[100%]">
                            <button type="submit" class="btn btn-primary btn-md w-fit w-xs-100" @click="$emit('close')">
                                <span>Close</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else-if="!payload.specialError" class="modal modal-2 modal--show">
        <div class="modal__dialog modal__dialog--sm">
            <!-- <div class="d-flex w-[100%] justify-content-flex-end mb-12">
                <a href="#" class="close__modal" @click="closeModal">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.4083 1.4731C2.14676 1.26977 1.76861 1.28828 1.5283 1.52862C1.26797 1.78899 1.268 2.2111 1.52837 2.47143L7.05704 7.99932L1.52731 13.5286L1.47185 13.5914C1.26849 13.8529 1.28696 14.231 1.52727 14.4714C1.78761 14.7317 2.20972 14.7318 2.47008 14.4714L7.99992 8.94206L13.5301 14.4714L13.5929 14.5269C13.8544 14.7302 14.2326 14.7117 14.4729 14.4714C14.7332 14.211 14.7332 13.7889 14.4728 13.5286L8.94277 7.99929L14.4711 2.47142L14.5266 2.40862C14.7299 2.1471 14.7115 1.76894 14.4712 1.52861C14.2108 1.26825 13.7887 1.26823 13.5284 1.52857L7.99989 7.05655L2.47111 1.52856L2.4083 1.4731Z" fill="currentColor"></path>
                    </svg>
                </a>
            </div> -->
            <div class="modal__content">
                <div class="modal__header header-center px-[28px] md:px-[40px]">
                    <a href="#"
                        class="btn btn-close btn-back md-hidden mobail-absolute"
                        data-close-modal=""
                        @click.prevent="closeModal()" >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.6818 11.6366L3 11.6366" stroke="#222B45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M10.6364 19.2727L3 11.6364L10.6364 4" stroke="#222B45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </a>
                    <div></div>
                    <a href="#"
                        class="btn btn-close max-md-visible"
                        data-close-modal=""
                        @click.prevent="closeModal()" >
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M17.4771 2.94469C17.9223 2.51467 18.1009 1.87788 17.9441 1.27907C17.7874 0.68025 17.3197 0.212602 16.7209 0.0558617C16.1221 -0.100878 15.4853 0.0776826 15.0553 0.522914L8.99914 6.57736L2.94469 0.522914C2.51467 0.0776826 1.87788 -0.100878 1.27907 0.0558617C0.68025 0.212602 0.212602 0.68025 0.0558617 1.27907C-0.100878 1.87788 0.0776826 2.51467 0.522914 2.94469L6.57736 8.99914L0.522914 15.0553C0.0776826 15.4853 -0.100878 16.1221 0.0558617 16.7209C0.212602 17.3197 0.68025 17.7874 1.27907 17.9441C1.87788 18.1009 2.51467 17.9223 2.94469 17.4771L8.99914 11.4226L15.0553 17.4771C15.4853 17.9223 16.1221 18.1009 16.7209 17.9441C17.3197 17.7874 17.7874 17.3197 17.9441 16.7209C18.1009 16.1221 17.9223 15.4853 17.4771 15.0553L11.4226 8.99914L17.4771 2.94469Z"
                                fill="#3A4C66"
                            ></path>
                        </svg>
                    </a>
                </div>
                <div class="modal__body pb-[40px]">
                    <div class="modal-remove-body">
                        <span class="remove-svg-danger" v-html="icons[payload.success ? 'ico-check.svg' : 'ico-error.svg']"></span>
                        <h2 class="modal-remove-title" v-html="payload.title || payload.message"></h2>

                        <p v-if="payload.title && payload.message" class="text-70 mb-[20px] text-center" style="font-weight: 600">{{ payload.message }}</p>
                    </div>
                    <!-- <button type="button" class="btn btn--md btn--light w-[100%]" @click="closeModal">
                        <span><translate-wrapper :translateString="translate('close')"></translate-wrapper></span>
                    </button> -->
                    <div class="modal-box__footer mx-40">
                        <a href="#" class="btn btn--primary-outline px-[23px] py-[15px] rounded-[8px] font-[700] text-[14px] leading-[19px] w-[100%]" @click="closeModal">
                            <span>Close</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal modal-2 modal--show" v-else>
        <div class="modal__dialog modal__dialog--lg">
            <div class="modal__content">
                <div class="modal__header text-center">
                    <h2 class="modal__title">Oops...</h2>
                    <button type="button" class="modal__closer btn btn--round btn--shadow" @click="$emit('close')">
                        <img src="/img/close-square.svg" alt="Close modal" />
                    </button>
                </div>
                <div class="modal__body modal-message">
                    <p class="p-br-height-fix" v-html="payload.message"></p>
                    <a v-if="payload.recommended.title && payload.recommended.link" :href="payload.recommended.link" type="button" class="btn btn--md-less btn--primary">
                        <span><translate-wrapper :translateString="translate('buy')"></translate-wrapper> {{ payload.recommended.title }}</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { icons } from "../../helpers/icons.js";
import { translate } from "../../helpers/functions.js";

export default {
    props: {
        payload: {
            type: Object,
            default: () => ({
                success: true,
                message: translate("your-changes-have-been-saved"), //'Your changes have been saved'
            }),
        },
        hidePopupElements:{
            type: Boolean,
            default: ()=>(false)
        }
    },
    data: () => ({
        icons,
    }),

    methods: { 
        translate,
        redirectToNewOrder() {
            window.location.href = window.location.origin + (this.hidePopupElements ? '/dashboard/quick-order' : '/')
        },
        closeModal() {
            if(this.payload.callback_function){
                this.payload.callback_function(); 
            }

            this.$emit('close');
        }
    },
};
</script>

<style scoped>
.modal__dialog.modal__dialog--sm {
    max-width: 480px;
}
.modal-remove-body {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.modal-remove-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 160%;
    margin-bottom: 30px;
    text-align: center;
}
.price-list__item {
    align-items: flex-start;
}
.price-list__item-price {
    display: inline-block;
    padding-left: 10px;
}
.quick-order-message {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.quick-order-message__total strong {
    font-size: 24px;
    line-height: 1.6;
    font-weight: 700;
    display: block;
}
.quick-order-message.quick-order-message--error {
    border-top: 5px solid #F43D3D;
}
.quick-order-message .btn {
    width: 220px;
    margin-bottom: 10px;
}
.quick-order-message .btn--last {
    margin-top: auto;
}
.quick-order-message__icon {
    width: 58px;
    height: 58px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 18px;
    /* background-image: url(../../assets/img/order-success.svg); */
    /* background-size: cover; */
}
.quick-order-message--error .quick-order-message__icon {
    /* background-image: url(../../assets/img/order-error.svg); */
}
.quick-order-message__title{
    font-size: 24px;
    line-height: 38px;
    margin-bottom: 15px;
    font-weight: 700;
    color: #70DE61;
}
.quick-order-message--error .quick-order-message__title {
    color: #F43D3D;
}
.quick-order-message__number{
    font-size: 36px;
    line-height: 58px;
    font-weight: 700;
}
.quick-order-message__holder:not(:first-child) {
    margin-top: 20px;
    padding-top: 20px;
    position: relative;
}
.quick-order-message__holder:not(:first-child)::after {
    content: '';
    position: absolute;
    top: 0;
    left: calc(50% - 25px);
    width: 50px;
    height: 1px;
    background-color: rgba(62, 116, 134, 0.15);
}
.quick-order-message__total span {
    font-size: 18px;
    line-height: 1.6;
    color: rgba(48, 75, 101, 0.5);
    font-weight: 500;
    display: block;
}
.quick-order-message--error .quick-order-message__total span {
    color: #F43D3D;
}
.quick-order-message--error .quick-order-message__total {
    margin-bottom: 50px;
}
.quick-order-message__total strong {
    font-size: 24px;
    line-height: 1.6;
    font-weight: 700;
    display: block;
}
.quick-order-message .btn {
    width: 220px;
    margin-bottom: 10px;
}
.quick-order-message .btn--default-outline {
    color: rgba(48, 75, 101, 0.5);
    background-color: #fff;
    border: 1px solid rgba(62, 116, 134, 0.15);
}
.quick-order-message  .btn--outline-primary {
    background-color: #fff;
    color: #05A6DB;
    border: 1px solid rgba(62, 116, 134, 0.15);
}
.quick-order-message  .btn--outline-primary:hover {
    color: #fff;
    background: linear-gradient(106.11deg, #20A7EE -5.94%, #0ED5E1 112.84%);
}
.checkout-loader {
    background: #fff;
    border-radius: 20px;
    min-height: calc(100vh - 190px);
    width: 100%;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

</style>