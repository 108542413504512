<template>
    <!-- lg:block -->
    <section class="flex flex-auto lg:flex lg:flex-col section sm:pb-0 pt-[14px]">
        <div :class="output ? 'container mx-0 container-dash' : 'container-px--lg flex flex-col flex-auto'">
            <div class="relative row md:flex hidden mb-[23px] sm:mb-[58px]" v-if="output">
                <div class="col-12 px-[70px]">
                    <a href="#" @click.prevent="output = false" class="btn w-[40px] y-center h-[40px] bg-primary-100 rounded-[11px] left-0 absolute">
                        <img src="@/assets/img/left-arrow-long.svg" alt="">
                    </a>
                    <div class="flex">
                        <h2 class="font-[700] text-[24px] leading-[32px]">Bio Maker</h2>
                    </div>
                </div>
            </div>
            <div class="row gy-20 sm:mb-[30px] sm:pb-[30px] sm:border-bottom flex-col sm:flex-row flex-auto" v-if="!output">
                <div class="col-lg-8">
                    <div class="text-center sm:text-start mx-auto sm:mx-0">
                        <h1 class="mb-[16px] text-[24px] leading-[32px] sm:text-[40px] sm:leading-[60px]">Instagram <br><span class="text-primary-500">Bio Maker</span></h1>
                        <p class="text-text font-semibold">Let's create a unique bio for all your social media handles</p>
                    </div>
                </div>
                <div class="col-lg-4 flex sm:block flex-col flex-auto">
                    <div class="form-group mb-4">
                        <label class="input-wraper fill-input input-wraper--label">
                            <input type="text" class="input font-semibold" placeholder="Your username" v-model="description">
                            <div class="label-input">
                                <span>Type what you do or who you are</span>
                            </div>
                        </label>
                    </div>
                    <div class="form-group mb-4">
                        <div class="select-multiple filled select-multiple--label not--lefticon" style="z-index:5;">
                            <div class="select-search">
                                <div class="label-input">
                                    <span>Select your vibes or topics</span>
                                </div>
                                <SelectBox
                                    key="select-1"
                                    identifier='select-default'
                                    :options="categories"
                                    :value="category"
                                    @change="(value)=>{category = value;}"
                                />
                            </div>
                            <div class="select-arrow">
                                <span class="ico">
                                    <img src="@/assets/img/icons/ico-arrowdown.svg">
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="mt-auto sm:mt-0">
                        <a href="#" @click.prevent="generate" class="btn btn--primary px-[27px] py-[20px] icon-btn rounded-[8px] w-full">
                            <span>Generate bio</span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="row hidden sm:flex" v-if="loading">
                <div class="flex justify-center pb-[30px]">
                    <Loader />
                </div>
            </div>
            <div class="row hidden sm:flex" v-else-if="result">
                <div class="col-lg-7">
                    <div class="max-w-[330px]" :class="{ 'mx-auto':output }">
                        <h6 class="mb-[17px]">Your bio</h6>
                        <div class="p-10 bg-[#FAFCFF] border rounded-[7px] flex flex-col mb-[15px]">
                            <span class="font-semibold">{{ result }}</span>
                        </div>
                        <a href="#" class="btn btn--primary pl-[56px] pr-[27px] py-[20px] icon-btn rounded-[8px] w-full mb-[25px]" @click.prevent="copyToClipboard">
                            <div class="icon-left">
                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.2188 11.5238V7.7143C20.2188 5.87335 18.7264 4.38097 16.8855 4.38097H8.31403C6.47309 4.38097 4.9807 5.87335 4.9807 7.7143V16.2857C4.9807 18.1267 6.47309 19.6191 8.31403 19.6191H13.0759C13.8649 19.6191 14.5045 18.9795 14.5045 18.1905V16.2857C14.5045 14.4448 15.9969 12.9524 17.8378 12.9524H18.7902C19.5792 12.9524 20.2188 12.3128 20.2188 11.5238ZM20.1489 13.4793C19.7636 13.7475 19.2953 13.9048 18.7902 13.9048H17.8378C16.5229 13.9048 15.4569 14.9708 15.4569 16.2857V18.1905C15.4569 18.5927 15.3572 18.9716 15.1811 19.3038C17.7893 18.5005 19.7604 16.2439 20.1489 13.4793ZM8.31403 3.42859H16.8855C19.2524 3.42859 21.1712 5.34737 21.1712 7.7143V12.4762C21.1712 16.9471 17.5468 20.5714 13.0759 20.5714H8.31403C5.9471 20.5714 4.02832 18.6527 4.02832 16.2857V7.7143C4.02832 5.34737 5.9471 3.42859 8.31403 3.42859Z" fill="currentColor"></path>
                                </svg>
                            </div>
                            <span>{{ copied ? 'Copied' : 'Copy to clipboard' }}</span>
                        </a>
                        <h6 class="mb-10">Add username to preview</h6>
                        <div class="form-group mb-4">
                            <label class="form-group-title mb-3">Custom Name</label>
                            <label class="input-wraper input-wrapper-ico--left">
                                <span class="input-icon text-primary-500">
                                    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2102 5.64967H18.7554C21.8251 5.65276 24.313 8.14021 24.3166 11.2099V18.7551C24.3135 21.8249 21.826 24.3127 18.7563 24.3163H11.2111C8.14138 24.3132 5.65351 21.8258 5.6499 18.7561V11.2109C5.65299 8.14115 8.14045 5.65327 11.2102 5.64967ZM21.3602 21.36C22.051 20.6691 22.4391 19.7321 22.4391 18.7551V11.2109C22.4391 9.17673 20.7904 7.5276 18.7563 7.52708H11.2111C9.17697 7.52708 7.52784 9.1758 7.52732 11.2099V18.7551C7.52732 20.7893 9.17604 22.4384 11.2102 22.4389H18.7554C19.7324 22.4389 20.6694 22.0508 21.3602 21.36Z" fill="currentColor"></path>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1562 14.9825C10.1599 12.3184 12.3184 10.1594 14.9826 10.1553V10.1562C17.6474 10.1588 19.8076 12.3176 19.8117 14.9825C19.8086 17.6478 17.6487 19.8076 14.9835 19.8107C12.3186 19.8071 10.1593 17.6474 10.1562 14.9825ZM12.0327 14.9835C12.0327 16.6126 13.3534 17.9333 14.9826 17.9333C16.6117 17.9333 17.9324 16.6126 17.9324 14.9835C17.9324 13.3543 16.6117 12.0336 14.9826 12.0336C13.3534 12.0336 12.0327 13.3543 12.0327 14.9835Z" fill="currentColor"></path>
                                    <circle cx="19.8201" cy="10.1917" r="1.15656" fill="currentColor"></circle>
                                    </svg>
                                </span>
                                <input type="text" class="input font-semibold" placeholder="Instagram username" v-model="inputedData.username">
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 relative">
                    <div class="fake-ig-profile absolute right-[0px]">
                        <div class="fake-ig-profile__inner">
                            <div class="flex flex-auto justify-between items-center pl-4 pr-10 mb-5">
                                <div class="relative w-[60px] h-[60px] bg-black-100 rounded-full flex items-center justify-center">
                                    <span class="flex">
                                        <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                            <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" fill="white"></path>
                                        </svg>
                                    </span>
                                    <span class="absolute w-8 h-8 rounded-full bg-[#00a6ff] flex items-center justify-center border border-white-100 bottom-0 right-0 text-white-100">+</span>
                                </div>
                                <div class="flex flex-col text-center">
                                    <span class="font-bold">173</span>
                                    <span class="font-semibold">Posts</span>
                                </div>
                                <div class="flex flex-col text-center">
                                    <span class="font-bold">173</span>
                                    <span class="font-semibold">Posts</span>
                                </div>
                                <div class="flex flex-col text-center">
                                    <span class="font-bold">173</span>
                                    <span class="font-semibold">Posts</span>
                                </div>
                            </div>
                            <div class="border-bottom mx-2 pb-8">
                                <div class="flex flex-col px-2">
                                    <span class="font-semibold">@{{inputedData.username.length ? inputedData.username : 'username'}}</span>
                                    <span class="font-semibold">{{ result }}</span>
                                    <a href="#" class="btn w-full mt-4 rounded-[5px] py-3 border text-[12px] font-semibold">Edit profile</a>
                                </div>
                            </div>
                            <div class="px-2">
                                <div class="flex py-4">
                                    <div class="flex flex-1 justify-center">
                                        <img class="w-10 h-10" src="@/assets/img/icons/table-icon.png" alt="">
                                    </div>
                                    <div class="flex flex-1 justify-center">
                                        <img class="w-10 h-10" src="@/assets/img/icons/ig-ico-photo.png" alt="">
                                    </div>
                                </div>
                                <div>
                                    <ul class="columns-3 gap-x-0">
                                        <li>
                                            <img src="@/assets/img/b1af74c5671770fd36c422033ba26f82.jpg" alt="">
                                        </li>
                                        <li>
                                            <img src="@/assets/img/b1af74c5671770fd36c422033ba26f82.jpg" alt="">
                                        </li>
                                        <li>
                                            <img src="@/assets/img/b1af74c5671770fd36c422033ba26f82.jpg" alt="">
                                        </li>
                                        <li>
                                            <img src="@/assets/img/b1af74c5671770fd36c422033ba26f82.jpg" alt="">
                                        </li>
                                        <li>
                                            <img src="@/assets/img/b1af74c5671770fd36c422033ba26f82.jpg" alt="">
                                        </li>
                                        <li>
                                            <img src="@/assets/img/b1af74c5671770fd36c422033ba26f82.jpg" alt="">
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <a href="#" class="btn btn--primary px-[23px] py-[20px] rounded-[8px] w-full mt-[30px]" v-if="result" @click.prevent="generate">
                        <span class="info">Generate Again</span>
                    </a> -->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import SelectBox from "@/components/Base/SelectBox.vue"
    import { toolsApi } from "@/api/toolsApi";
    import Loader from "@/components/Base/Loader";

    export default{
        components: {
            SelectBox,
            Loader,
        },
        data() {
            return {
                categories: [
                    {
                        value: "",
                        label: "Select",
                        selected: true,
                    },
                    {
                        value: "General",
                        label: "General"
                    },
                    {
                        value: "Friends",
                        label: "Friends"
                    },
                    {
                        value: "Education",
                        label: "Education"
                    },
                    {
                        value: "For Girls",
                        label: "For Girls"
                    },
                    {
                        value: "For Men",
                        label: "For Men"
                    },
                    {
                        value: "Inspirational",
                        label: "Inspirational"
                    },
                    {
                        value: "Business profile",
                        label: "Business profile"
                    },
                    {
                        value: "Music",
                        label: "Music"
                    },
                    {
                        value: "Artist",
                        label: "Artist"
                    },
                    {
                        value: "Influencer",
                        label: "Influencer"
                    },
                    {
                        value: "Friends",
                        label: "Friends"
                    },
                    {
                        value: "Religious",
                        label: "Religious"
                    },
                    {
                        value: "Education",
                        label: "Education"
                    },
                    {
                        value: "Sports",
                        label: "Sports"
                    }

                ],
                inputedData: {
                    username: '',
                    whoYou: 'Footballer | Ronaldo fan',
                    vibesOrTopics: '',
                },
                copied: false,
                output: false,

                description: "",
                category: "",

                loading: false,
                result: null,
            };
        },
        methods: {
            copyToClipboard() {
                let self = this;
                self.copied = true;
                const el = document.createElement('textarea');
                el.value = self.result;
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);

                setTimeout(() => {
                    self.copied = false;
                }, 1500);
            },
            async generate(){
                this.loading = true;

                try {
                    const res = await toolsApi.instagramBioMaker({
                        description: this.description,
                        category: this.category,
                    });

                    this.result = res.bio;

                    this.generateStep = 2;
                } catch (error) {
                    console.log('error:', error);
                } finally {
                    this.loading = false;
                }
            }
        }
    }
</script>