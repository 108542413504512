<template>
    <div id="cart-details" class="modal modal-7 modal--show">
        <div class="modal__dialog modal__dialog--522">
            <div class="modal__content" v-click-outside="closeModal">
                <div class="modal__header px-[27px] sm:px-[43px]">
                    <div class="flex items-center">
                        <div class="mr-[7px]">
                            <img src="@/assets/img/icons/icon-red-facebook-square.svg">
                        </div>
                        <span class="font-bold text-[18px] leading-[24px]">{{ item.product.title }}</span>
                    </div>
                    <a @click.prevent="closeModal" href="#" class="btn btn-close mobail-11x11" data-close-modal="">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.4771 2.94469C17.9223 2.51467 18.1009 1.87788 17.9441 1.27907C17.7874 0.68025 17.3197 0.212602 16.7209 0.0558617C16.1221 -0.100878 15.4853 0.0776826 15.0553 0.522914L8.99914 6.57736L2.94469 0.522914C2.51467 0.0776826 1.87788 -0.100878 1.27907 0.0558617C0.68025 0.212602 0.212602 0.68025 0.0558617 1.27907C-0.100878 1.87788 0.0776826 2.51467 0.522914 2.94469L6.57736 8.99914L0.522914 15.0553C0.0776826 15.4853 -0.100878 16.1221 0.0558617 16.7209C0.212602 17.3197 0.68025 17.7874 1.27907 17.9441C1.87788 18.1009 2.51467 17.9223 2.94469 17.4771L8.99914 11.4226L15.0553 17.4771C15.4853 17.9223 16.1221 18.1009 16.7209 17.9441C17.3197 17.7874 17.7874 17.3197 17.9441 16.7209C18.1009 16.1221 17.9223 15.4853 17.4771 15.0553L11.4226 8.99914L17.4771 2.94469Z" fill="#3A4C66"></path>
                        </svg>
                    </a>
                </div>
                <div class="modal__body pb-[48px] px-[27px] sm:px-[43px]">
                    <template v-if="item.product_group.identifier !== 'tiktok.followers'">
                        <div class="comments-dropdown mb-[4px]" v-for="(post, index) in posts" :class="{ 'active-dropdown': post.isOpen }"  :key="index + '-cart'">
                            <div class="comments-dropdown--container">
                                <div class="flex items-center mb-[16px] flex-wrap">
                                    <div class="mr-[15px]" v-if="post.socialData.video && post.socialData.video.origin_cover.url_list[0]">
                                        <img class="post-image" :src="post.socialData.video.origin_cover.url_list[0]">
                                    </div>
                                    <div class="mr-[15px] flex-1 block" v-else>
                                        <p>{{ post.socialData.url }}</p>
                                    </div>
                                    <div class="flex flex-col">
                                        <span class="mb-[8px] text-[14px] leading-[18px] text-secondary-500">{{ post.units || countFilledComments(post, true) || (payload && payload.product && payload.product.product_units) || 0 }} {{ item.product_group.name }}</span>
                                        <a 
                                            v-if="item.product_group.identifier.toLowerCase().includes('custom')" href="#" class="comments-dropdown--btn btn" data-table-drdw=""
                                            @click.prevent="toggleDropdown(post)"
                                        >
                                            <span class="info font-semibold text-[14px] leading-[19px] mr-[12px] text-primary-500" :data-show="`Hide comments (${countFilledComments(post, true)})`" :data-hide="`Show comments (${countFilledComments(post, true)})`">
                                            </span>
                                            <span class="ico">
                                                <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.66931 5.25531L11.6193 0.305313C11.8704 0.0453564 12.2422 -0.0588996 12.5918 0.0326158C12.9415 0.124131 13.2145 0.397176 13.306 0.746806C13.3975 1.09644 13.2933 1.46824 13.0333 1.71931L7.37631 7.37631C6.98581 7.7667 6.35281 7.7667 5.96231 7.37631L0.305313 1.71931C0.0453564 1.46824 -0.0588996 1.09644 0.0326158 0.746806C0.124131 0.397176 0.397176 0.124131 0.746806 0.0326158C1.09644 -0.0588996 1.46824 0.0453564 1.71931 0.305313L6.66931 5.25531Z" fill="#FE2C55"></path>
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                                <div v-if="isCustomComments" class="comments-dropdown--body" style="box-sizing: border-box;" :style="post.isOpen ? 'display: block;' : 'display: none'">
                                    <div class="flex items-center justify-between bg-[#FFF0D1] py-[14px] pr-[19px] pl-[52px] rounded-[8px] mb-[20px]">
                                        <span class="text-[#FFBB2E]">{{ countFilledComments(post) }} comments remaining</span>
                                        <div>
                                            <img src="@/assets/img/icons/icon-shape.svg">
                                        </div>
                                    </div>
                                    <div class="text-right mb-[20px]">
                                        <a href="#" class="font-bold text-[14px] leading-[19px] underline">Import list</a>
                                    </div>
        
                                    <ul class="comments-dropdown--list mb-[20px] counter-number">
                                        <li class="comments-dropdown--item counter-increment flex" v-for="comment in post.newCustomComments" :key="comment.id+'-comment'">
                                            <textarea class="input-custom__textarea pl-[45px] w-full mr-[16px]" style="height:68px" placeholder="" v-model="comment.value"></textarea>
                                            <button @click.prevent="deleteField(post, comment.id)" class="btn">
                                                <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.66931 5.25531L10.2043 1.71931C10.4643 1.46824 10.5685 1.09644 10.477 0.746806C10.3855 0.397176 10.1124 0.124131 9.76282 0.0326158C9.41319 -0.0588996 9.04139 0.0453564 8.79031 0.305313L5.25431 3.84031L1.71931 0.305313C1.46824 0.0453564 1.09644 -0.0588996 0.746806 0.0326158C0.397176 0.124131 0.124131 0.397176 0.0326158 0.746806C-0.0588996 1.09644 0.0453564 1.46824 0.305313 1.71931L3.84031 5.25431L0.305313 8.79031C0.0453564 9.04139 -0.0588996 9.41319 0.0326158 9.76282C0.124131 10.1124 0.397176 10.3855 0.746806 10.477C1.09644 10.5685 1.46824 10.4643 1.71931 10.2043L5.25431 6.66931L8.79031 10.2043C9.04139 10.4643 9.41319 10.5685 9.76282 10.477C10.1124 10.3855 10.3855 10.1124 10.477 9.76282C10.5685 9.41319 10.4643 9.04139 10.2043 8.79031L6.66931 5.25431V5.25531Z" fill="#EF3A4C"></path>
                                                </svg>
                                            </button>
                                            <div>
                                        </div></li>
                                    </ul>
                                    <div class="flex justify-center mb-[20px]">
                                        <a @click.prevent="addCommentField(post)" href="" class="btn btn--primary px-[23px] py-[19px] max-w-[283px] w-full rounded-[8px] font-[700] text-[14px] leading-[19px]">
                                            Add comment field
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <div v-else>
                        <div class="p-9 flex items-center bg-primary-100 rounded-[8px] mb-[13px] relative">
                            <span class="rounded-full absolute left-9">
                                <img class="w-[35px] h-[35px]" :src="item.data.picture" alt="" v-if="item.data.picture">
                                <div class="w-[35px] h-[35px] bg-[#EAECF1] rounded-full"></div>
                            </span>
                            <span class="pl-[60px] font-semibold">{{ item.data.username }}</span>
                            <span class="pl-[15px] font-semibold">{{ item.data.userData.follower_count }} + {{ item.product.product_units }} followers</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "cartDetails",
    props: ['payload'],
    data(){
        return{
            item: null,
            posts: null,
            isCustomComments: false
        }
    },
    methods: {
        closeModal() {
            this.$bus.$emit("modal", {
                name: "",
                payload: {},
            });
        },
        openModal(name){
            this.$bus.$emit('modal',{
                name: name,
                overlay: true,
                payload: {},
            })
        },
        toggleDropdown(item){
            console.log(item)
            item.isOpen =  !item.isOpen 
        },
        countFilledComments(post, un=false) {
            if (un) {
                return post.newCustomComments.filter(el => el.value).length
            }
            return post.units - post.newCustomComments.filter(el => el.value).length
        },
        addCommentField(post) {
            const val = post.newCustomComments
            if (val.length < post.units) {
                post.newCustomComments = [...post.newCustomComments, {id: val.length, value: ''}]
            }

        },
        deleteField(post, id) {
            post.newCustomComments = post.newCustomComments.filter(el => el.id !== id)
        },
        save() {

        }
    },
    created() {

        if (this.payload.product_group.identifier === 'tiktok.customComments') {
            this.isCustomComments = true
        }

        const commentsFromLocal = window.localStorage.getItem(this.payload.identifier)

        this.item = this.payload

        console.log(JSON.parse(commentsFromLocal))

        this.posts = this.payload.data.mediaData.map((el,idx) => ({...el,
            ...(el.customComments ? {
                newCustomComments: commentsFromLocal ?
                    JSON.parse(commentsFromLocal).find(el => el.id == this.item.identifier + '-' + idx).comments.map((el) => ({
                        id: el.id,
                        value: el.value
                    }))
                    : Object.entries(el.customComments).map((key, idx) => ({
                        id: key[0],
                        value: key[1]
                    }))}
                : {}
            ),
            id: this.item.identifier + '-' + idx, index: idx, isOpen: false}))
    },
    beforeDestroy() {
        // window.localStorage.setItem(this.item.identifier, JSON.stringify(this.posts.map(el => ({comments: el.newCustomComments, id: el.id}))))

    }
}
</script>

<style scoped>
.post-image {
    width: 70px;
    height: 120px;
}
</style>