import restInstance from "@/lib/rest"

const rest = restInstance

export const paymentApi = {
    async getDiscountsData() {
        return await rest.request('/discounts', 'GET', null, 'discounts')
    },
    
    async getBalanceHistoryData() {
        return await rest.request('/balance-history', 'GET', _, 'balanceHistory')
    },
    
    async getTransactionsData(page = 1) {
        return await rest.request(`/transactions?page=${page}`, 'GET', null, 'transactions')
    },

    async getPaymentMethods() {
        return await rest.request('/payment-methods', 'GET', null, 'getPaymentMethods')
    },
    
    async deletePaymentMethods(methodId) {
        return await rest.request(`/payment-methods/${methodId}`, 'DELETE', null, 'deletePaymentMethods')
    },
    
    async createPaymentMethods(data) {
        // const data = {
        //     "payment_type": "cardinity",
        //     "country": "UA",
        //     "card": {
        //         "name": "Alex Dev",
        //         "number": "4242424242424242",
        //         "cvv": "123",
        //         "month": "05",
        //         "year": "2027"
        //     },
        //     "billingInformation": {
        //         "email": "alex.media.t@gmail.com"
        //     },
        //     "browser_info": {
        //         "browser_language": "en-GB",
        //         "screen_width": 1576,
        //         "screen_height": 1298,
        //         "color_depth": 24,
        //         "time_zone": -180
        //     },
        //     "_token": "lNfvfqV6aleMaunJpbH14typOAmdui3QZ03Qzu0M"
        // }
        
        return await rest.request('/payment-methods', 'POST', data, 'createPaymentMethods')
    },



}