<template>
    <div id="change-email" class="modal modal-1 modal--show">
        <div class="modal__dialog modal__dialog--443">
            <div class="modal__content" v-if="step === 1">
                <div class="modal__header">
                    <h6 class="font-bold text-[18px] leading-[24px] sm-d-none">Change email</h6>
                    <a href="#" class="btn btn-close mobail-11x11" data-close-modal="" @click.prevent="closeModal()">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.4771 2.94469C17.9223 2.51467 18.1009 1.87788 17.9441 1.27907C17.7874 0.68025 17.3197 0.212602 16.7209 0.0558617C16.1221 -0.100878 15.4853 0.0776826 15.0553 0.522914L8.99914 6.57736L2.94469 0.522914C2.51467 0.0776826 1.87788 -0.100878 1.27907 0.0558617C0.68025 0.212602 0.212602 0.68025 0.0558617 1.27907C-0.100878 1.87788 0.0776826 2.51467 0.522914 2.94469L6.57736 8.99914L0.522914 15.0553C0.0776826 15.4853 -0.100878 16.1221 0.0558617 16.7209C0.212602 17.3197 0.68025 17.7874 1.27907 17.9441C1.87788 18.1009 2.51467 17.9223 2.94469 17.4771L8.99914 11.4226L15.0553 17.4771C15.4853 17.9223 16.1221 18.1009 16.7209 17.9441C17.3197 17.7874 17.7874 17.3197 17.9441 16.7209C18.1009 16.1221 17.9223 15.4853 17.4771 15.0553L11.4226 8.99914L17.4771 2.94469Z" fill="#3A4C66"></path>
                        </svg>
                    </a>
                </div>
                <div class="modal__body pb-[65px]">
                    <div class="form-group mb-[17px]">
                        <!-- <input type="text" class="input input-default" placeholder="Enter email"> -->
                        
                        <input 
                            type="email" 
                            id="email-address" 
                            class="input input-default"
                            placeholder="Enter email"
                            v-model="userEmail.email.value"
                            @input="userEmail.email.isValid = true; isInvalid = false"
                            @blur="onFieldBlur(userEmail, ['email'])"
                            :class="{ 'invalid': userEmail.email.touched && !userEmail.email.isValid }"
                        >

                    </div>
                    <div class="form-group mb-[17px]">
                        <!-- <input type="text" class="input input-default" placeholder="Confirm email"> -->

                        <input 
                            type="email" 
                            id="confirm-email-address" 
                            class="input input-default"
                            placeholder="Confirm email"
                            v-model="userEmail.confirmEmail.value"
                            @input="userEmail.confirmEmail.isValid = true; isInvalid = false"
                            @blur="onFieldBlur(userEmail, ['confirmEmail'])"
                            :class="{ 'invalid': userEmail.confirmEmail.touched && !userEmail.confirmEmail.isValid }"
                        >

                    </div>

                    <button @click="changeEmail" :class="{'disabled': (!userEmail.email.value || !userEmail.confirmEmail.value || disableChangeEmailBtn)}" class="btn btn--primary px-[23px] py-[13px] rounded-[8px] font-[700] text-[14px] leading-[19px]" data-close-modal="">Save changes</button>

                    <span v-if="isInvalid" class="invalid-text-step-1">{{ isInvalid }}</span>
                </div>
            </div>

            <div class="modal__content" v-if="step === 2">
                <div class="modal__header">
                    <h6 class="font-bold text-[18px] leading-[24px] sm-d-none">Confirm new email</h6>
                    <a href="#" class="btn btn-close mobail-11x11" data-close-modal="" @click.prevent="closeModal()">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.4771 2.94469C17.9223 2.51467 18.1009 1.87788 17.9441 1.27907C17.7874 0.68025 17.3197 0.212602 16.7209 0.0558617C16.1221 -0.100878 15.4853 0.0776826 15.0553 0.522914L8.99914 6.57736L2.94469 0.522914C2.51467 0.0776826 1.87788 -0.100878 1.27907 0.0558617C0.68025 0.212602 0.212602 0.68025 0.0558617 1.27907C-0.100878 1.87788 0.0776826 2.51467 0.522914 2.94469L6.57736 8.99914L0.522914 15.0553C0.0776826 15.4853 -0.100878 16.1221 0.0558617 16.7209C0.212602 17.3197 0.68025 17.7874 1.27907 17.9441C1.87788 18.1009 2.51467 17.9223 2.94469 17.4771L8.99914 11.4226L15.0553 17.4771C15.4853 17.9223 16.1221 18.1009 16.7209 17.9441C17.3197 17.7874 17.7874 17.3197 17.9441 16.7209C18.1009 16.1221 17.9223 15.4853 17.4771 15.0553L11.4226 8.99914L17.4771 2.94469Z" fill="#3A4C66"></path>
                        </svg>
                    </a>
                </div>
                <div class="modal__body pb-[65px]">
                    <!-- <div class="form-group mb-[17px]">
                        <input type="text" class="input input-default" placeholder="Enter email">
                    </div>
                    <div class="form-group mb-[17px]">
                        <input type="text" class="input input-default" placeholder="Confirm email">
                    </div> -->

                    <p>We send the code to
                        <span v-if="payload.dataprops.user.email" class="color-span" >{{ payload.newEmail }}</span>
                        <span v-else>your new email</span>
                    </p>

                    <div class="code_inp">
                            <input v-for="(inp, idx) in 5" :key="idx" ref="code"  :value="codes[idx]" @input="setCode($event, idx)"
                         type="text" @keydown.backspace.prevent="removeCode(idx)" @keydown.right.prevent="switchArrow(idx, 'next')" @keydown.left.prevent="switchArrow(idx, 'prev')" />

                         <span v-if="isInvalid" class="invalid-text">{{ isInvalid }}</span>
                    </div>


                    <a href="#" @click="save" class="btn btn--primary px-[23px] py-[13px] rounded-[8px] font-[700] text-[14px] leading-[19px]" data-close-modal="" :class="{'disabled' : disableSaveBtn}">Save changes</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { profileApi } from '@/api/profileApi';
import formValidation from '@/mixins/formValidation';
export default {
    data() {
        return {
            codes: {
                0: '',
                1: '',
                2: '',
                3: '',
                4: '',
            },
            disableSaveBtn: true,
            isInvalid: false,
            publicCode: null,
            newEmail: null,
            step: 1,
            userEmail: false,
            disableChangeEmailBtn: false,
        };
    },
    computed: {},
    mounted() {
        this.newEmail = this.payload.newEmail || null
        this.publicCode = this.payload.publicCode || null

        this.step = this.payload.publicCode ? 2 : 1

    },
    mixins: [formValidation],
    props: ['payload'],
    methods: {
        closeModal() {
            this.$bus.$emit("modal", {
                name: "",
                payload: {},
            });
        },
        openModal(name){
            this.$bus.$emit('modal',{
                name: name,
                overlay: true,
                payload: {},
            })
        },

        switchArrow(idx, actName) {
            const lastIdx = this.$refs.code.length - 1
            if (actName === 'next' && idx !== lastIdx) {
                this.$refs.code[idx + 1].focus()
                return
            }

            if (actName === 'prev' && idx !== 0) {
                this.$refs.code[idx - 1].focus()
                return
            }
        },

        setCode(e, idx) {
            
            const onlyNumbers = e.target.value.replace(/\D/g, '')

            const lastIdx = this.$refs.code.length - 1
            
            if (this.codes[idx]?.length === 1 && this.codes[idx] !== '') {

                if (this.codes[idx + 1] !== '') {
                    e.target.value = String(onlyNumbers)[0]
                    this.disableBtnHelper()
                    return
                }
                
                e.target.value = String(onlyNumbers)[0]
                
                this.disableBtnHelper()
                if (!String(onlyNumbers)[1]) {
                    e.target.value = String(onlyNumbers)[0]
                    this.disableBtnHelper()
                    return
                }
                
                if (idx !== lastIdx) {
                    this.$refs.code[idx + 1].focus()
                    this.codes[idx + 1] = String(onlyNumbers)[1]
                    this.disableBtnHelper()
                    
                    return
                } else {
                    e.target.value = String(onlyNumbers)[0]
                    this.disableBtnHelper()
                    return
                }
                
            }
            e.target.value = onlyNumbers

            if(!onlyNumbers) return
            
            this.codes[idx] = onlyNumbers

            if (idx !== lastIdx) {
                this.$refs.code[idx + 1].focus()
            }

            this.disableBtnHelper()
        },
        removeCode(idx) {
            let currentVal = this.codes[idx]
            this.codes = {...this.codes, [idx]: ''}

            if (!currentVal) {
                idx !== 0 && this.$refs.code[idx - 1].focus()
            }

            this.disableBtnHelper()
        },
        disableBtnHelper() {
            if (Object.values(this.codes).includes('') || Object.values(this.codes).length < 5) {
                this.disableSaveBtn = true
            } else {
                this.disableSaveBtn = false
            }
        },

        async changeEmail() {
            const isValid = this.validate(this.userEmail)

            if (!isValid) return

            const payload = {...this.getValues(this.userEmail)}

            if (payload.email !== payload.confirmEmail) {
                this.isInvalid = "Email doesn't match"
                this.userEmail.confirmEmail.isValid = false
                return
            }

            const response = await this.$api.sendConfirmationCode({email: this.userEmail.email.value})

            if (response.success) {
                this.publicCode = response.data.publicCode
                this.newEmail = this.userEmail.email.value
                this.step = 2
                this.isInvalid = false

            } else {
                this.isInvalid = response.message
            }



        },

        async save() {

            this.disableSaveBtn = true

            // const response = await this.$api.updateEmail({
            //     email: this.newEmail,
            //     code: Object.values(this.codes).join(''),
            //     publicCode : this.publicCode 
            // });
            const response = await profileApi.value({
                new_email: this.newEmail,
                code: Object.values(this.codes).join(''),
                publicCode : this.publicCode 
            });
            console.log('response', response)

            this.disableSaveBtn = false

            // server always return error
            if (response.hasError) {
                this.isInvalid = response.error.message
                return
            }
            this.$set(this.payload.dataprops.user, 'email', `${this.userEmail.email.value}`)
            this.$emit('close')
        }
    },
    created() {
        const emailValidator = (val) => /\S+@\S+\.\S+/.test(val)
            this.userEmail = this.createForm({
                email: {
                    value: '',
                    required: true,
                    validators: [
                        emailValidator
                    ]
                },
                confirmEmail: {
                    value: '',
                    required: true,
                    validators: [
                        emailValidator
                    ]
                }
            })
    }
}
</script>

<style scoped>
    .code_inp {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 35px;
    }
        

    .code_inp input {
            margin-right: 10px;
            padding: 10px;
            border: 3px solid rgb(161 148 148);;
            text-align: center;
            max-width: 50px;
            max-height: 50px;
            border-radius: 10px;
            font-size: x-large;
            transition: none;
    }
    .code_inp input:focus {
        border-color: var(--primary-500);
    }

    .color-span {
        color: var(--primary-500);
    }
    

    .invalid-text {
        position: absolute;
        bottom: -25px;
        color: red;
    }

    .invalid {
        border: 1.5px solid red;
    }

    .invalid-text-step-1 {
        text-align: center;
        color: red;
        margin: 10px;
    }

</style>