<template>
    <div id="add-funds-checkout" class="modal modal-17 modal--show">
        <div class="modal__dialog modal__dialog--710">
            <div class="modal__content">
                <div class="modal__header pb-[33px] px-[32px]">
                    <span class="font-[600] text-[14px] leading-[20px]">Add funds <span class="text-text">- ${{ payload.selectedAmount }}</span>
                        <span class="text-primary-500" v-if="payload.selectedAmount>(dataprops.balanceTopUpBonus.min_top_up-1)">
                            (+${{(payload.selectedAmount / 100) * dataprops.balanceTopUpBonus.bonus_percent}})
                        </span>
                    </span>
                    <a href="#" class="btn btn-close" data-close-modal="" @click.prevent="closeModal">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.4771 2.94469C17.9223 2.51467 18.1009 1.87788 17.9441 1.27907C17.7874 0.68025 17.3197 0.212602 16.7209 0.0558617C16.1221 -0.100878 15.4853 0.0776826 15.0553 0.522914L8.99914 6.57736L2.94469 0.522914C2.51467 0.0776826 1.87788 -0.100878 1.27907 0.0558617C0.68025 0.212602 0.212602 0.68025 0.0558617 1.27907C-0.100878 1.87788 0.0776826 2.51467 0.522914 2.94469L6.57736 8.99914L0.522914 15.0553C0.0776826 15.4853 -0.100878 16.1221 0.0558617 16.7209C0.212602 17.3197 0.68025 17.7874 1.27907 17.9441C1.87788 18.1009 2.51467 17.9223 2.94469 17.4771L8.99914 11.4226L15.0553 17.4771C15.4853 17.9223 16.1221 18.1009 16.7209 17.9441C17.3197 17.7874 17.7874 17.3197 17.9441 16.7209C18.1009 16.1221 17.9223 15.4853 17.4771 15.0553L11.4226 8.99914L17.4771 2.94469Z" fill="#3A4C66"></path>
                        </svg>
                    </a>
                </div>
                <div class="modal__body pb-[47px] px-[32px]">
                    <span class="text-[18px] leading-[24px] font-[700] mb-[31px]">Select payment method</span>
    
                    <div class="select-method-wrapper mb-[20px]">
                        <label class="select-method" @click="setPm('card', 'cardinity')">
                            <input type="radio" class="hidden" name="select-method" :checked="selectedPaymentMethod === 'card'">
                            <span class="select-method-text">Credit/Debit Card</span>
                        </label>
                        <label class="select-method" @click="setPm('crypto', 'coinpayments')">
                            <input type="radio" class="hidden" name="select-method">
                            <span class="select-method-text">Cryptocurrencies</span>
                        </label>
                        <label class="select-method">
                            <input type="radio" class="hidden" name="select-method">
                            <span class="select-method-text flex items-center justify-between">
                                <span>Balance</span>
                                <span class="text-secondary-500">current: {{ dataprops.cartData.currency }}{{ ((dataprops.userMe ? dataprops.userMe.balance : dataprops.user.balance) || 0) | floatNumberWithSpaces }}</span>
                            </span>
                        </label>
                    </div>

                    <template v-if="selectedPaymentMethod == 'card'">
                        <template v-if="(savedCards && savedCards.length)">
                            <div class="flex items-center justify-between font-[600] text-[14px] leading-[18px] mb-[14px]">
                                <span class="text-secondary-500">Selected card</span>
                                <router-link to="/settings" @click.native="closeModal" class="text-primary-500 underline">Change card</router-link>
                            </div>
                            <div
                                class="gray-card flex items-center border-red px-[16px] py-[15px] rounded-[8px] border mb-[31px]"
                                v-for="(card, index) in savedCards" :class="{ 'disabled': card.is_expired }" :for="'payout-' + index" :key="index" v-show="!card.is_expired"
                            >
                                <div class="black-credit-card text-white-100 mr-[27px]">
                                    <div class="black-credit-card__head">
                                        <div class="flex">
                                            <img src="@/assets/img/card-visa-logo.svg">
                                        </div>
                                        <span class="text-[6px] leading-[8px]">12/34</span>
                                    </div>
                                    <div class="black-credit-card__body text-[6px] leading-[8px]">
                                        <span class="mb-[3px]">{{ card.name }}</span>
                                        <span class="font-[500]">{{ "**** " + card.card_last_digits }}</span>
                                    </div>
                                </div>
                                <span class="text-[14px] leading-[20px] font-[600]">Visa <span> {{ "**** **** **** " + card.card_last_digits }}</span></span>
                            </div>
                        </template>
                        <template v-else>
                            <p class="text-secondary-500 font-semibold mb-[20px] hidden md:block">Enter card details</p>
                            <div class="visa-block md:hidden mb-[15px]">
                                <div class="visa-block__top">
                                    <p class="font-semibold">Name on card:</p>
                                    <p class="font-medium opacity-50">{{ cardData.name.value || 'Name on the card' }}</p>
                                </div>
                                <div class="visa-block__body">
                                    {{ formattedCardNumber || 'Card number' }}
                                </div>
                                <div class="visa-block__footer">
                                    <div class="visa-block__footer-item">
                                        <p class="font-semibold">Expires on:</p>
                                        <p class="font-medium opacity-50">{{ date || 'Expiration date' }}</p>
                                    </div>
                                    <div class="visa-block__footer-item">
                                        <p class="font-semibold">CVV:</p>
                                        <p class="font-medium opacity-50">{{ cardData.cvv.value || 'cvv' }}</p>
                                    </div>
                                    <img src="@/assets/img/visa-big-icon.svg" class="visa-block__footer-img" alt="">
                                </div>
                            </div>
                            <div class="row gutters-18 mb-[26px]" style="--bs-gutter-y: 14px;">
                                <div class="col-md-6">
                                    <label class="input-wraper input-wraper--label" style="background-color: transparent;">
                                        <input
                                            type="text" class="input font-semibold flex-auto w-auto" placeholder="Name on the card"
                                            v-model="cardData.name.value"
                                            :class="{ 'invalid': !cardData.name.isValid && cardData.name.touched }"
                                            @blur="onFieldBlur(cardData, ['name'])"
                                        >
                                        <div class="label-input">
                                            <span>Name on the card</span>
                                        </div>
                                    </label>
                                </div>
                                <div class="col-md-6">
                                    <label class="input-wraper input-wraper--label" style="background-color: transparent;">
                                        <input
                                            type="text" class="input font-semibold flex-auto w-auto" placeholder="Card number"  
                                            maxlength="19" 
                                            :class="{ 'invalid': !cardData.number.isValid && cardData.number.touched }"
                                            :value="formattedCardNumber"
                                            @input="setCardNumber($event)"
                                            @blur="onFieldBlur(cardData, ['number'])"
                                        >
                                        <div class="label-input">
                                            <span>Card Number</span>
                                        </div>
                                        <img src="@/assets/img/master-card-image.svg" class="mr-[18px]" alt="">
                                    </label>
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-6">
                                            <label class="input-wraper input-wraper--label" style="background-color: transparent;">
                                                <input
                                                    type="text" class="input font-semibold flex-auto w-auto" placeholder="Expiration date"
                                                    :class="{ 'invalid': (!cardData.month?.isValid && cardData.month?.touched) || (!cardData.year?.isValid && cardData.year?.touched) }"
                                                    @input="onDateInput" v-model="date"
                                                >
                                                <div class="label-input">
                                                    <span>Expiration date</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div class="col-6">
                                            <label class="input-wraper input-wraper--label" style="background-color: transparent;" :class="{ 'error': !cardData.cvv.isValid && cardData.cvv.touched }">
                                                <input
                                                    type="text" class="input font-semibold flex-auto w-auto" placeholder="CVV" maxlength="3" 
                                                    v-model="cardData.cvv.value"
                                                    :class="{ 'invalid': !cardData.cvv.isValid && cardData.cvv.touched }"
                                                    @blur="onFieldBlur(cardData, ['cvv'])"
                                                    @input="$event.target.value.length > 3 ? $set(cardData, 'cvv', {...cardData.cvv, value: cardData.cvv.value.substr(0, 3)})  : false"
                                                >
                                                <div class="label-input">
                                                    <span>CVV</span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <div class="select-multiple select-multiple--label filled not--lefticon" style="z-index:5;">
                                            <div class="select-search">
                                                <div class="label-input">
                                                    <span>Country</span>
                                                </div>
                                                <SelectBox
                                                    key="select-1"
                                                    identifier='select-default'
                                                    :options="countryList.map(option => {return { label: option.name , value: option.code }})"
                                                    :value="cardData.country?.value"
                                                    :class="{ 'invalid': !cardData.country?.isValid && cardData.country?.touched}"
                                                    @change="(value)=>{cardData.country.value = value;}"
                                                />
                                            </div>
                                            <div class="select-arrow">
                                                <span class="ico">
                                                    <img src="@/assets/img/ico-arrowdown.svg">
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex items-center mb-[15px]"  v-if="dataprops.user && !dataprops.user.guest">
                                <label class="custom-chekbox ">
                                    <input type="checkbox" class="hidden" :value="1" v-model="saveCard">
                                    <span class="fake-check"></span>
                                    <span class="flex items-center font-semibold ml-[14px]">Remember this card</span>
                                </label>
                                <span class="flex items-center popover ml-2" data-tippy-content="Custom text">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4ZM12 9.2C11.5582 9.2 11.2 8.84183 11.2 8.4C11.2 7.95817 11.5582 7.6 12 7.6C12.4418 7.6 12.8 7.95817 12.8 8.4C12.8 8.84183 12.4418 9.2 12 9.2ZM12.8 14.8C12.8 15.2418 12.4418 15.6 12 15.6C11.5582 15.6 11.2 15.2418 11.2 14.8V10.8C11.2 10.3582 11.5582 10 12 10C12.4418 10 12.8 10.3582 12.8 10.8V14.8Z" fill="#8F9BB3"></path>
                                    </svg>
                                </span>
                            </div>
                            <p class="text-secondary-500 font-semibold">*CVV or CVC is the card security code, unique three digits number on the back of your card separate from its number.</p>
                        </template>
                    </template>
    
                    <div class="row lg:justify-end">
                        <a @click.prevent="closeModal" href="#" class="btn btn--primary btn-md-double btn-double rounded-[8px] col-md-6">
                            <span class="info">${{ payload.selectedAmount }}</span>
                            <span class="info">Confirm & Pay</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { floatNumberWithSpaces, formatCardNumber } from '@/helpers/filters.js';
import { icons } from '@/helpers/icons.js';
import { translate, validateCardNumberLength } from "@/helpers/functions.js";
import validationMixin from '@/mixins/formValidation.js';
// import CardForm from '../UI/CardForm.vue';
import SelectBox from '@/components/Base/SelectBox.vue';

export default {
    props: ['payload', 'dataprops'],
    mixins:[validationMixin],
    filters: {
        floatNumberWithSpaces, formatCardNumber,
        formatMonth(x) {
            return x.toString().length == 1 ? `0${x}` : x;
        }
    },
    components: {
        // CardForm
        SelectBox,
    },
    data: () => ({
        icons,
        radnId: Math.floor(Math.random() * 100000),
        selectedPaymentMethod: 'card', // cardinity or coinpayments or balance
        selectedPaymentType: 'cardinity', // cardinity, fenige, crypto, coinbase
        // formattedCardNumber: '',
        user: {},
        topUpStep: 0,
        saveCard: 0,
        selectedCard: false,
        showCardForm: false,
        cryptoForm: false,
        cardData: {
            name: '',
            number: '',
            cvv: '',
            month: '',
            year: '',
            country: '',
        },

        formattedCardNumber: '',
        date: '',
    }),
    computed: {
        topUpShow() {
            return this.topUpStep == 1;
        },
        payNowShow() {
            return this.topUpStep == 2;
        },
        nextFewYears() {
            let years = [];

            for (let i = 0; i < 8; i++) {
                let date = new Date((new Date()).getFullYear() + i, 0, 1);
                years.push({ title: date.getFullYear().toString().substr(2, 4), value: date.getFullYear() });
            }

            return years;
        },
        disableCheckout() {
            return (this.editEmail || !this.user) 
                ? (this.confirmEmailError || this.contactEmailError || !this.contactEmail || !this.confirmEmail)
                : false
        },
        savedCards() {
            const cards = this.dataprops?.paymentMethods?.filter(item => item.payment_type === "cardinity") || this.payload.savedCards;
            this.showCardForm = !(cards && cards.length);

            return cards;
        },
		storeDataprops(){
			return this.$store.state.dashboard.dataprops;
		},
        showPaymentInfo() {
            return this.cardData.country?.value == 'US' &&  this.cardData.number?.value?.toString().startsWith('5');
        },
        selectedAmountWithBonus() {
            let basePrice = this.payload.selectedAmount;

            if (this.dataprops.balanceTopUpBonus && this.dataprops.balanceTopUpBonus.enabled && basePrice >= this.dataprops.balanceTopUpBonus.min_top_up) {
                return basePrice + ((basePrice / 100) * this.dataprops.balanceTopUpBonus.bonus_percent)
            } else {
                return basePrice;
            }
        },
        countryList() {
            return this.dataprops.countryList ? Object.entries(this.dataprops.countryList).map(([code, name]) => ({ code, name })) : [];
        },
    },
    watch: {
        cryptoForm: {
            deep: true,
            handler(val) {
                if (!val) return;

                setTimeout(() => this.$refs.cryptoForm.submit(), 0)
            }
        }
    },
    methods: {
        translate,
        onDateInput(event) {
            const cleanedInput = event.target.value.replace(/\D/g, '');
            
            if (cleanedInput.length <= 2) {
                this.date = cleanedInput;
            } else {
                this.date = cleanedInput.slice(0, 2) + '/' + cleanedInput.slice(2, 4);
            }

            this.cardData.month.value = this.date.split('/')[0];
            this.cardData.year.value = this.date.split('/')[1];
        },
        closeModal() {
            this.$bus.$emit("modal", {
                name: "",
                payload: {},
            });
        },
        setPm(method, type) {
            if (method) {
                this.selectedPaymentMethod = method;
                if (!type) {
                    this.selectedPaymentType = method;
                }
            }
            if (type) {
                this.selectedPaymentType = type;
            }
        },
        setCardNumber(event) {
			let val = event.target.value,
				formatted = this.$options.filters.formatCardNumber(val.replace(/\D/g, '')),
				selectionEnd = event.target.selectionEnd + 1;
                // console.log('selectionEnd:', (formatted.slice(0, formatted.length - 1).endsWith(' ') ? 1 : 0));

            this.$set(this.cardData, 'number', {
                ...this.cardData.number,
                value: val.replace(/\D/g, '')
            });
            this.formattedCardNumber = formatted;

			event.target.value = formatted;
			event.target.focus();
			event.target.selectionEnd = selectionEnd;
		},
        checkEditEmail() {
            this.contactEmailError = !(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(this.contactEmail)); 
            this.confirmEmailError = this.confirmEmail != this.contactEmail;
        },
        resetEmail() {
            this.editEmail = !this.editEmail;

            let billingInfo = this.dataprops.cartData.billingInformation;
            this.$set(this.billingInfo, 'email', billingInfo.email);
            this.contactEmail = billingInfo.email;
            this.confirmEmail = billingInfo.email;
        },
        pay() {
            if (!this.selectedCard && this.selectedPaymentType == 'cardinity') {
                let isValid = this.validate(this.cardData);

                if (!isValid) return;
            }


            const $this = this;
			const payload = {
				amount: this.payload.selectedAmount,
				payment_type: this.selectedPaymentType,
                balance_top: true,
                country: this.payload.billingInfo.country || (this.cardData.country? this.cardData.country.value : null),
                currency: this.payload.currency,
                ...(this.selectedCard ? { payment_id: `${this.selectedCard}` } : {
                    ...(this.selectedPaymentType == 'cardinity' ? { card: this.getValues(this.cardData) } : {}),
                    billingInformation: this.payload?.user?.address || null,
                    save_card: Boolean(this.saveCard),
                }),
                browser_info: {
                    browser_language: navigator.language,
                    screen_width: window.innerWidth,
                    screen_height: window.innerHeight,
                    color_depth: screen.colorDepth,
                    time_zone: new Date().getTimezoneOffset(),
                },
                '_token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
			};

            Vue.http['post']('/checkout/create', payload)
                .then(function(response) {
                    response = response.body;
                    $this.cryptoForm = response.form;

                    if (response.redirect) return window.location.href = response.redirect;

                    // if (response.success === false) {
                    //     $this.$emit('popup', {
                    //         name: 'changes-saved',
                    //         payload: {
                    //             success: false,
                    //             message: response.error || response.message || $this.translate('server-error')
                    //         }
                    //     })
                    // } else if (response.redirect) {
                    //     $this.$emit('close');
                    //     window.location.href = response.redirect;
                    // }
                    // if (response.success === false) {
                        
                    if ($this.cryptoForm) return;

                    if (response.success) {
                        $this.$set($this.dataprops, 'paymentMethods', response.paymentMethods)
                        // if
                        $this.$emit('successPayAction',response)
                        
                        // $this.dataprops.user.balance=parseFloat($this.dataprops.user.balance) + response.order.paid_amount
                        // $this.dataprops.user.balance=parseFloat($this.dataprops.user.balance) + response.order.paid_amount
                        // $this.$set($this.dataprops.user, 'balance', (parseFloat($this.dataprops.user.balance) + response.order.paid_amount))
                        let newBalance = (parseFloat($this.dataprops.user.balance) + response.order.paid_amount)+''
                        $this.$emit('setValue', {key:'user', value:{...$this.dataprops.user, balance:newBalance}})
                    }

                    $this.$emit('popup', {
                        name: 'transaction-status',
                        payload: {
                            success: Boolean(response.success),
                            message: response.error || response.message || $this.translate('server-error'),
                            total: $this.payload.selectedAmount,
                            topUp: true
                        },
                    });
                    // } else if (response.redirect) {
                    //     $this.$emit('close');
                    //     window.location.href = response.redirect;
                    // }
                })
                .catch(function(response) {
                    response = response.body;

                    if (response?.redirect) return window.location.href = response.redirect;

                    $this.$emit('popup', {
                        name: 'changes-saved',
                        payload: {
                            success: false,
                            message: response?.error || response?.message || $this.translate('server-error')
                        }
                    })
                })
		},
    },
    created() {
        this.cartData = this.dataprops.cartData;
        this.billingInfo = this.dataprops.cartData.billingInformation;
        this.user = this.dataprops.user;
        
        if (this.user) {
            this.contactEmail = this.billingInfo.email || this.user.email;
            this.confirmEmail = this.billingInfo.email || this.user.email;

            this.$set(this.billingInfo, 'email', this.billingInfo.email ?? this.user.email)
        }

        const minLength = (val, length) => val.length >= length;
        const maxlength = (val, length) => val.length <= length;
        const nameString = (val) => /^[А-Яа-яa-zA-Z\s]+$/.test(val);

        this.cardData = this.createForm({
            name: {
                value: '',
                required: true,
                validators: [
                    (val) => minLength(val, 2),
                    (val) => maxlength(val, 32),
                    nameString
                ]
            },
            number: {
                value: '',
                required: true,
                validators: [
                    (val) => validateCardNumberLength(val)
                ]
            },
            cvv: {
                value: '',
                required: true,
                validators: [
                    (val) => minLength(val, 3),
                ]
            },
            month: {
                value: null,
                required: true
            },
            year: {
                value: null,
                required: true
            },
            country: {
                value: null,
                required: true
            }
        });

        if (this.dataprops.userCountryCode) {
           this.$set(this.cardData, 'country', {
                ...this.cardData.country,
                value: this.dataprops.userCountryCode
           });
        }
    },
}
</script>