<template>
    <div id="forgot-password-sent" class="modal modal-22 modal-login modal--show">
        <div class="modal__dialog modal__dialog--587">
            <div class="modal__content">
                <div class="modal__header header-center px-[57px] md:px-[125px]">
                    <a href="#" class="btn btn-close" data-close-modal="" @click.prevent="closeModal()">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.6818 11.6366L3 11.6366" stroke="#222B45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M10.6364 19.2727L3 11.6364L10.6364 4" stroke="#222B45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </a>
                    <h6 class="font-[600] text-[14px] leading-[24px] sm:hidden">Reset password</h6>
                </div>
                <div class="modal__body pb-[57px] px-[57px] md:px-[125px]">
                    <div class="flex justify-center mb-[42px]">
                        <img src="@/assets/img/decor-message sent.svg">
                    </div>
                    <h3 class="text-[24px] leading-[32px] font-[700] mb-[20px] text-center ">Check your email</h3>
                    <span class="text-[14px] leading-[18px] font-[600] text-text text-center">
                        Password reset link has just been sent to<br>
                        <span class="text-primary-500">{{ email }}</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['payload'],
    data() {
        return {
            email: null
        };
    },
    computed: {},
    methods: {
        closeModal() {
            this.$bus.$emit("modal", {
                name: "",
                payload: {},
            });
        },
        openModal(name){
            this.$bus.$emit('modal',{
                name: name,
                overlay: true,
                payload: {},
            })
        },
    },
    mounted() {
        this.email = this.payload.email
    }
}
</script>