<template>
    <div class="section">
        <div class="container mx-0 container-dash">
            <div class="flex items-center justify-center relative mb-[40px] mt-[20px] lg:hidden">
                <a href="#" class="btn w-[40px] h-[40px] bg-primary-100 rounded-[11px] left-0 absolute">
                    <img src="@/assets/img/icons/left-arrow-long.svg" alt="">
                </a>
                <span class="def-text--1 font-semibold">My Discounts</span>
            </div>
            <h6 class="mb-[20px] lg:mt-0 hidden lg:block">My Discounts</h6>
            <div v-if="items && items.length > 0">
                <div class="flex items-center justify-between discount-wrapper mb-[38px]">
                    <div class="flex items-center flex-col md:flex-row mb-[18px] md:mb-0 md:mr-[15px]">
                        <img src="@/assets/img/shopping-cart-promotion-discoynt.svg" class="hidden md:block mr-[40px]" alt="">
                        <img src="@/assets/img/shopping-cart-promotion-discoynt-2.svg" class="md:hidden mb-[24px]" alt="">
                        <h4 class="max-w-[325px] text-center md:text-start">Use your discount codes today!</h4>
                    </div>
                    <a href="#" class="btn btn--lg2 btn--primary rounded-[8px] w-full justify-between mt-auto md:max-w-[336px]" @click.prevent="openModal('quick-order')">
                        <span class="info text-[14px] font-bold">
                            Quick order
                        </span>
                        <span class="ico ico-24 ml-[10px]">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.31818 11.6361L21 11.6361" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M13.3636 19.2727L21 11.6364L13.3636 4" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                        </span>
                    </a>
                </div>
                <div class="table-def-wrapper">
                    <table class="table-def" cellpadding="0" cellspacing="0">
                        <thead class="hidden md:table-header-group">
                            <tr>
                                <th class="text-left">Coupon code</th>
                                <th class="text-left">Coupon type</th>
                                <th class="text-left">Expires in</th>
                                <th class="text-left"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- Awaiting payment -->
                            <template v-for="(cupon, index) in items" >
                                <tr class="table--item" :key="index">
                                    <td>
                                        <div class="user-socialinfo">

                                            <div class="socialinfo--header">
                                                <div class="info flex items-center w-full justify-between lg:justify-start">
                                                    <span>{{cupon.code}}</span>
                                                    <a v-if="copiedEl !== index" @click.prevent="copyToClipboard(cupon.code); copiedAnim(index)" href="" class="btn ml-[15px] text-primary-500">
                                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0V19.25H1.83333V1.83333H19.25V0H0ZM22 3.66667H3.66667V22H22V3.66667ZM20.1667 5.5V20.1667H5.5V5.5H20.1667Z" fill="currentColor"></path>
                                                        </svg>
                                                    </a>
                                                    <div v-else class="btn ml-[15px] checkmark"></div>
                                                </div>
                                                <!-- <div class="md:hidden">
                                                    <span class="font-bold text-[18px]">$9.99</span>
                                                </div> -->
                                            </div>
                                            <span class="text-primary-500" v-if="couponErrors[cupon.code]">{{ couponErrors[cupon.code] }}</span>
                                            <div class="socialinfo--body md:hidden">
                                                <div class="socialinfo--body--item">
                                                    <div class="flex flex-col">
                                                        <span class="text-secondary-500 text-def text-def-1 mb-[10px]">
                                                            {{cupon.description}}
                                                        </span>
                                                        <span class="text-secondary-500 text-def text-def-1 mb-[15px]">
                                                            {{ cupon.expiredCustom }}
                                                        </span>
                                                    </div>
                                                    <a @click.prevent="checkCouponApply(cupon)" :class="{'disabled': cupon.expiredCustom === 'Expired'}" href="#" class="btn text-center def-text--1 text-primary-500 px-[10px] py-[16px] rounded-lg btn--primary-outline w-full">
                                                        <span class="font-bold">Apply coupon</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="hidden md:table-cell">
                                        <p class="text-secondary-500 text-def text-def-1">
                                            {{cupon.description}}
                                        </p>
                                    </td>
                                    <td class="hidden md:table-cell">
                                        <p class="text-secondary-500 text-def text-def-1">
                                            {{ cupon.expiredCustom }}
                                        </p>
                                    </td>
                                    <td class="hidden md:table-cell text-end">
                                        <a @click.prevent="checkCouponApply(cupon)" :class="{'disabled': cupon.expiredCustom === 'Expired'}" href="#" class="btn text-center def-text--1 text-primary-500 btn--primary-outline w-[155px] py-[16px] rounded-lg">
                                            <span class="font-bold">Apply coupon</span>
                                        </a>
                                    </td>
                                </tr>
                                <tr class="spacetable"></tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
            <div v-else class="flex items-start justify-between discount-wrapper mb-[38px]">
                <div class="flex items-center flex-col md:flex-row mb-[18px] md:mb-0 md:mr-[15px]">
                    <img src="@/assets/img/no-discount-icon.svg" class="hidden md:block mr-[16px]" alt="">
                    <img src="@/assets/img/shopping-cart-promotion-discoynt-2.svg" class="md:hidden mb-[24px]" alt="">
                    <div class="max-w-[325px]">
                        <h4 class="text-center md:text-start text-[24px] mb-[7px]">Aah! You don't have any discount coupons yet.</h4>
                        <p class="text-secondary-500">You can earn them by rating your orders.</p>
                    </div>
                </div>
                <div class="md:max-w-[336px]">
                    <a href="#" class="btn btn--primary pl-[56px] pr-[27px] py-[20px] icon-btn rounded-[8px] w-full mb-[15px]">
                        <div class="icon-left">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.81741 6.96C7.28 5.16612 9.49741 4 12 4C16.4113 4 20 7.58871 20 12C20 16.4113 16.4113 20 12 20C7.58871 20 4 16.4113 4 12C4 11.1765 5.62476 11.0984 5.65959 12C5.797 15.5859 8.41224 18.3343 12 18.3343C15.5878 18.3343 18.3489 15.5887 18.3489 12C18.3489 8.41224 15.5887 5.63416 12 5.63416C9.90871 5.63416 8.05741 6.48 6.85741 8L7.71774 8.86033C8.03272 9.17531 7.80964 9.71388 7.36419 9.71388H4.2C4.08954 9.71388 4 9.62434 4 9.51388V6.34969C4 5.90424 4.53857 5.68116 4.85355 5.99614L5.81741 6.96ZM12.0003 8.66669C12.0003 8.30409 12.0003 8.00248 12.6653 8.00248C13.3303 8.00248 13.332 8.30129 13.332 8.66669V12.6837C13.332 13.3387 12.6602 14.0015 11.9847 14.0015H9.33365C9.33365 14.0015 8.66862 13.9759 8.66862 13.3238C8.66862 12.6717 9.33365 12.6837 9.33365 12.6837H12.0267L12.0003 8.66669Z" fill="currentColor"></path>
                                <mask id="mask0_513_3948" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="4" y="4" width="16" height="16">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.81741 6.96C7.28 5.16612 9.49741 4 12 4C16.4113 4 20 7.58871 20 12C20 16.4113 16.4113 20 12 20C7.58871 20 4 16.4113 4 12C4 11.1765 5.62476 11.0984 5.65959 12C5.797 15.5859 8.41224 18.3343 12 18.3343C15.5878 18.3343 18.3489 15.5887 18.3489 12C18.3489 8.41224 15.5887 5.63416 12 5.63416C9.90871 5.63416 8.05741 6.48 6.85741 8L7.71774 8.86033C8.03272 9.17531 7.80964 9.71388 7.36419 9.71388H4.2C4.08954 9.71388 4 9.62434 4 9.51388V6.34969C4 5.90424 4.53857 5.68116 4.85355 5.99614L5.81741 6.96ZM12.0003 8.66669C12.0003 8.30409 12.0003 8.00248 12.6653 8.00248C13.3303 8.00248 13.332 8.30129 13.332 8.66669V12.6837C13.332 13.3387 12.6602 14.0015 11.9847 14.0015H9.33365C9.33365 14.0015 8.66862 13.9759 8.66862 13.3238C8.66862 12.6717 9.33365 12.6837 9.33365 12.6837H12.0267L12.0003 8.66669Z" fill="currentColor"></path>
                                </mask>
                                <g mask="url(#mask0_513_3948)">
                                </g>
                            </svg>
                        </div>
                        <span>My order list</span>
                    </a>
                    <a href="#" class="btn btn--lg2 btn--primary rounded-[8px] w-full justify-between">
                        <span class="info text-[14px] font-bold">
                            Quick order
                        </span>
                        <span class="ico ico-24 ml-[10px]">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.31818 11.6361L21 11.6361" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M13.3636 19.2727L21 11.6364L13.3636 4" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                        </span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {cartApi} from "@/api/cartApi";

export default {
    props: ['dataprops'],
    data() {
        return{
            cuponsList:[
                {
                    cupponCode: 'RV8JG9KEC',
                    couponType: '10% on all cart items',
                    expiresIn: '1 month 7 days',
                },
                {
                    cupponCode: 'RV8JG9KEC',
                    couponType: '10% on all cart items',
                    expiresIn: '1 month 7 days',
                },
                {
                    cupponCode: 'RV8JG9KEC',
                    couponType: '10% on all cart items',
                    expiresIn: '1 month 7 days',
                },
                {
                    cupponCode: 'RV8JG9KEC',
                    couponType: '10% on all cart items',
                    expiresIn: '1 month 7 days',
                },
            ],
            items: null,
            isLast: null,
            copiedEl: null,

            couponErrors: {}
        }
    },
    watch: {
        'dataprops.discounts': {
            handler(newVal) {
                this.init()
            }
        }
    },
    methods: {
        openModal(name, payload){
            this.$bus.$emit('modal',{
                name: name,
                overlay: true,
                payload: payload || {},
            })
        },
        init() {
            if(!this.dataprops.discounts) return
            const {items, is_last: isLast} = this.dataprops.discounts

            this.items = items.map(el => ({...el, expiredCustom: this.getTimeDifference(el.ends_at)}))
            this.isLast = isLast
            console.log(items, isLast, 'pppppp')
        },
        copyToClipboard(text) {
            const textarea = document.createElement("textarea")
            textarea.value = text
            document.body.appendChild(textarea)
            textarea.select()
            document.execCommand('copy')
            document.body.removeChild(textarea)
        },
        copiedAnim(idx) {
            this.copiedEl = idx
            setTimeout(() => {
                this.copiedEl = null
            }, 500)
        },
        getTimeDifference(targetTime) {

            if (targetTime === null) {
                return "Term-less"
            }

            const currentTime = new Date();
            const targetDate = new Date(targetTime);

            if (currentTime > targetDate) {
                return "Expired";
            }

            const timeDifference = targetDate - currentTime;
            const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            // const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

            let result = "";
            if (days > 0) {
                result += `${days} ${days === 1 ? "day" : "days"}`;
            }

            if (hours > 0) {
                if (result !== "") {
                    result += " ";
                }
                result += `${hours} ${hours === 1 ? "hour" : "hours"}`;
            }

            if (result === "") {
                return "Expired";
            }

            return result;
        },
        async checkCouponApply(coupon) {
            const cartTotal = this.dataprops.cartData?.totals?.totalBeforeDiscount || 0

            if (coupon.cart_value > cartTotal) {
                this.openModal('cupon-error', {total: coupon.cart_value})
            } else {
                const res = await cartApi.addCartDiscount(coupon.code)

                console.log(res, 'res 1212')

                if (res.hasError) {
                    console.warn(res.error.error)
                    this.couponErrors = {...this.couponErrors, [coupon.code]: res.error.error}
                }
            }
        }
    },
    mounted() {
        this.init()
    }
}
</script>

<style scoped>

.checkmark {
    width: 25px;
    height: 25px;
}
.checkmark::before {
    content: '\2713'; /* Юникод для галочки */
    color: green;
    font-size: 20px; /* размер шрифта по желанию */
}
</style>