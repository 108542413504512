<template>
    <div id="sign-up" class="modal modal-18 modal-login modal--show">
        <div class="modal__dialog modal__dialog--587">
            <div class="modal__content">
                <div class="modal__header px-[27px] sm:px-[125px]">
                    <a href="#" class="btn btn-close" data-close-modal="" @click.prevent="closeModal()">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.6818 11.6366L3 11.6366" stroke="#222B45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M10.6364 19.2727L3 11.6364L10.6364 4" stroke="#222B45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </a>
                </div>
                <div class="modal__body pb-[57px] px-[27px] sm:px-[125px]">
                    <div class="flex justify-center mb-[42px]">
                        <div class="decor-box-black">
                            <img src="@/assets/img/logo-login.png">
                        </div>
                    </div>
                    <h3 class="text-[24px] leading-[32px] font-[700] mb-[16px] w-[234px] mx-auto text-center">Welcome to<br>
                        Tink.ws
                        <div class="ml-[2px] w-[20px] inline-block">
                            <img src="@/assets/img/icon-fire.png">
                        </div>
                    </h3>

                    <span class="text-[14px] leading-[18px] font-[600] text-secondary-500 text-center mb-[32px]">Sign up to
                        get started!</span>

                    <div class="form-group mb-[22px]">
                        <label class="input-wraper fill-input input-wraper--label" :class="{ 'error': registerForm.email.touched && !registerForm.email.isValid }">
                            <input 
                                type="text" 
                                id="email" 
                                class="input font-semibold" 
                                v-model="registerForm.email.value"
                                @blur="onFieldBlur(registerForm, ['email'])"
                                placeholder="email"
                                :disabled="registerForm.email.disabled"
                                @input="emailError = null"
                            >
                            <div class="label-input">
                                <span>Email Address</span>
                            </div>
                        </label>
                        <div class="confirmError" v-if="emailError">{{ emailError }}</div>
                    </div>

                    <a @click.prevent="goStepTwo" href="#" class="btn btn--primary px-[27px] py-[19px] icon-btn rounded-[8px] justify-start font-[700] text-[14px] leading-[19px] mb-[16px]">
                        <span>Continue</span>
                        <div class="icon-right">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.31818 11.6361L21 11.6361" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M13.3636 19.2727L21 11.6364L13.3636 4" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                        </div>
                    </a>

                    <div class="or-decor-wrap mb-[20px]">
                        <span class="or-decor"></span>
                        <span class="px-[16px]">or</span>
                        <span class="or-decor"></span>
                    </div>

                    <a :href="getGoogleUrl()" class="btn border text-text px-[27px] py-[18px] icon-btn rounded-[8px]  font-[600] text-[14px] leading-[17px] mb-[107px]">
                        <div class="mr-[15px] flex">
                            <img src="@/assets/img/google.png">
                        </div>
                        <span>Continue with Google</span>
                    </a>

                    <span class="block text-center text-[12px] leading-[24px]">Have an account ? 
                        <a @click.prevent="openModal('login')" href="#" class="btn font-[700] text-primary-500">Sign in</a>
                    </span>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import formValidation from '@/mixins/formValidation';
import { GOOGLE_URL } from '@/config';
export default {
    props: ['payload'],
    data() {
        return {
            registerForm: {},
            emailError: null,
        };
    },
    mixins: [formValidation],
    computed: {},
    methods: {
        getGoogleUrl() {
            return GOOGLE_URL;
        },
        closeModal() {
            this.$bus.$emit("modal", {
                name: "",
                payload: {},
            });
        },
        openModal(name, payload){
            this.$bus.$emit('modal',{
                name: name,
                overlay: true,
                payload,
            })
        },
        goStepTwo() {
            const isValid = this.validate(this.registerForm)

            if (!isValid) {
                this.emailError = 'Enter a valid email'
                return
            }

            this.openModal('register-two', {
                ...this.getValues(this.registerForm),
            })

        }
    },
    created() {
        const emailValidator = (val) => /\S+@\S+\.\S+/.test(val);

        this.registerForm = this.createForm({
            email: {
                value: '',
                require: true,
                validators: [emailValidator]
            },
        })

        if (this.payload.email) {
            this.registerForm.email.value = this.payload.email
        }

    }
}
</script>