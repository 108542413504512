<template>
    <div id="message-us" class="modal modal-13 modal--show">
        <div class="modal__dialog modal__dialog--797">
            <div class="modal__content" v-click-outside="closeModal">
                <div class="modal__header pb-[33px] px-[28px] sm:px-[59px]">
                    <h6 class="font-[700] text-[18px] leading-[24px]">Message Us</h6>
                    <a @click.prevent="closeModal" href="#" class="btn btn-close mobail-11x11" data-close-modal="">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.4771 2.94469C17.9223 2.51467 18.1009 1.87788 17.9441 1.27907C17.7874 0.68025 17.3197 0.212602 16.7209 0.0558617C16.1221 -0.100878 15.4853 0.0776826 15.0553 0.522914L8.99914 6.57736L2.94469 0.522914C2.51467 0.0776826 1.87788 -0.100878 1.27907 0.0558617C0.68025 0.212602 0.212602 0.68025 0.0558617 1.27907C-0.100878 1.87788 0.0776826 2.51467 0.522914 2.94469L6.57736 8.99914L0.522914 15.0553C0.0776826 15.4853 -0.100878 16.1221 0.0558617 16.7209C0.212602 17.3197 0.68025 17.7874 1.27907 17.9441C1.87788 18.1009 2.51467 17.9223 2.94469 17.4771L8.99914 11.4226L15.0553 17.4771C15.4853 17.9223 16.1221 18.1009 16.7209 17.9441C17.3197 17.7874 17.7874 17.3197 17.9441 16.7209C18.1009 16.1221 17.9223 15.4853 17.4771 15.0553L11.4226 8.99914L17.4771 2.94469Z" fill="#3A4C66"></path>
                        </svg>
                    </a>
                </div>
                <div class="modal__body pb-[47px] px-[28px] sm:px-[59px]">
                    <div class="row">
                        <div class="form-group mb-[22px] col-12 col-sm-6">
                            <label class="input-wraper input-wraper--label fill-input">
                                <input type="text" class="input font-semibold" placeholder="Your email" v-model="email">
                                <div class="label-input">
                                    <span>Your email</span>
                                </div>
                            </label>
                        </div>
                        <div class="form-group mb-[22px] col-12 col-sm-6">
                            <!-- <label class="input-wraper input-wraper--label fill-input">
                                <input type="text" class="input font-semibold" placeholder="Order number (optional)" v-model="orderNumber">
                                <div class="label-input">
                                    <span>Order number (optional)</span>
                                </div>
                            </label> -->
                            <label class="input-wraper fill-input input-wraper--label">
                                <div class="order-numbers-select" v-click-outside="closeOrdersSelect">
                                    <input
                                        type="text" class="input font-semibold" placeholder="Order number (optional)"
                                        :value="(isSlices ? allOrders.slice(0,2) : allOrders ).filter(item => selectedOrders.includes(item.id)).map(item => '#'+item.invoice_number).join(', ')"
                                        @focus="ordersSelectOpen = true"
                                    />
                                    <div class="label-input">
                                        <span>Order number (optional)</span>
                                    </div>
                                    <div class="product-list mb-15" v-if="ordersSelectOpen">
                                        <div
                                            data-accordion-item
                                            class="product-list__item card-outline"
                                            v-for="item in (isSlices ? allOrders.slice(0,2) : allOrders )"
                                            :key="item.id"
                                            :class="{ 'active': fullInfoShow[item.hash] }"
                                        >
                                            <div class="product-list-header variation-tab variation-standart">											
                                                <div class="product-info w-100">
                                                    <label class="module-check border-none p-0">
                                                        <input :value="item.hash" v-model="selectedOrders" type="checkbox" name="privacy">
                                                        <span class="check"></span>
                                                        <span class="text text-checkbox">
                                                            <span class="color-text"><span class="translate-wrapper">Order</span> #{{ item.invoice_number }}</span> <span class="dnone-768">|</span> {{ item.status | formatString }} | ${{ item.total_price | floatNumberWithSpaces }} | {{ item.created_at | dateFilter }}
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>
    
                    <div class="textarea-wrap mb-[22px]">
                        <textarea :class="{'invalid': errorField}" @input="errorField=false" v-model="message" class="textarea w-full" rows="5" placeholder="Message"></textarea>
                    </div>
    
                    <a @click.prevent="sendMessage" href="#" class="btn btn--primary px-[27px] py-[19px] icon-btn rounded-[8px] justify-start font-[700] text-[14px] leading-[19px]">
                        <span>Send</span>
                        <div class="icon-right">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.31818 11.6361L21 11.6361" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M13.3636 19.2727L21 11.6364L13.3636 4" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ordersApi } from '@/api/ordersApi';

export default {
    props: ['payload', 'dataprops'],
    name: "MessageUsModal",
    data() {
        return {
            email: '',
            orderNumber: '',
            message: '',
            errorField: false,

            selectedOrders: [],
            loadedOrders:[],
            fullInfoShow:{},
            tempSecondaryEmail:null,
            isSlices:true,
            ordersSelectOpen: false,
        }
    },
    computed: {
		allOrders() {
            console.log('TEST', this.dataprops)
			return [ ...this.dataprops?.orders?.items || [], ...this.loadedOrders ].sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
		},
	},
    methods: {
        closeModal() {
            this.$bus.$emit("modal", {
                name: "",
                payload: {},
            });
        },
        closeOrdersSelect(){
            this.ordersSelectOpen = false;
        },
        async sendMessage(){
            const response = await ordersApi.getFeedbacks({
                email: this.email,
                message: this.message,
                hash: this.selectedOrders?.[0] || null
            })
            console.log('RESPONSE', response);
            if(response.hasError) {
                this.$bus.$emit('modal', { 
                    name: 'changes-saved', 
                    payload: {
                        success: false,
                        message: response.message || 'Server error'
                    } 
                });
                return
            }
            this.$bus.$emit('modal', { 
                name: 'changes-saved', 
                payload: {
                    success: response.success,
                    message: response.message || 'Server error'
                } 
            });
        }
    },
    // created() {
    //     this.email = this.payload.email || ''
    //     this.orderNumber = this.payload.orderNumber || ''
    // },
    async created() {
        this.ordersHistory = this.dataprops?.orders?.items;
        this.ordersHistory.forEach(el => {
            this.$set(this.fullInfoShow, el.hash, false);
        });
        this.email = this.dataprops.user.email;
        this.tempSecondaryEmail = this.dataprops.user.support_email;
        this.requestType = this.requestTypesOptions?.[0]?.value;
	}
}
</script>

<style scoped>

.invalid {
    border: 1px solid red;
}

.order-numbers-select {
    position: relative;
}
.product-list {
    position: absolute;
    left: 0;
    top: calc(100% + 5px);
    background: #fff;
    z-index: 2;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 8px;
}
.product-list__item .module-check {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    cursor: pointer;
}
.product-list__item .module-check .check {
    background: #77838F;
    border-radius: 24px;
    width: 48px;
    height: 27px;
    min-width: 48px;
    min-height: 27px;
    position: relative;
    margin-right: 10px;
}
.product-list__item .module-check input {
    display: none;
}
.product-list__item .module-check .check::before {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    border-radius: 50%;
    background: #FFFFFF;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 4px;
    transition: 0.3s all ease-in-out;
}
.product-list__item .module-check input:checked~.check::before {
    left: calc(100% - 22px);
}
.product-list__item .module-check input:checked~.check {
    background: #FE2C55;
}
.product-list__item .module-check .text {
    white-space: nowrap;
}
</style>