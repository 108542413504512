<template>
    <section class="pb-[25px] pt-[25px]">
        <div class="container mx-0 container-dash">
            <div class="flex items-center justify-center relative mb-[50px] mt-[14px] lg:hidden">
                <router-link 
                    to="/orders"
                    class="btn w-[40px] h-[40px] bg-primary-100 rounded-[11px] left-0 absolute"
                >
                    <img src="@/assets/img/icons/left-arrow-long.svg" alt="" />
                </router-link>
                <span class="def-text--1 font-semibold">Order details</span>
            </div>
            <div class="flex items-center justify-between mb-[25px]">
                <div class="flex items-center">
                    <router-link to="/orders" class="btn w-[40px] h-[40px] bg-primary-100 rounded-[11px] mr-[20px] hidden lg:flex">
                        <img src="@/assets/img/icons/left-arrow-long.svg" alt="" />
                    </router-link>

                    <h6>Order {{ orderData && orderData.hash ? orderData.hash : 'not found' }}</h6>
                </div>
                <a
                    v-if="orderData && orderData.total" @click.prevent="payNow"
                    href="#" class="btn btn--primary px-[24px] py-[10px] lg:hidden rounded-[8px]"
                >Pay ${{ orderData.total }}</a>
            </div>
            <template v-if="orderData">
                <div class="row pb-[30px] mb-[20px]">
                    <div class="col-lg-6">
                        <div class="card mb-[20px]">
                            <div class="card__body">
                                <div class="card-body--item">
                                    <span class="text-text font-semibold">Order Number</span>
                                    <span class="font-semibold">{{ orderData && orderData.hash ? orderData.hash : 'not found' }}</span>
                                </div>
                                <div class="card-body--item">
                                    <span class="text-text font-semibold">Order Status</span>
                                    <span v-if="orderData" :class="classObject" >{{ textColorClass[orderData.status]?.label || orderData.status }}</span>
                                </div>
                                <div class="card-body--item">
                                    <span class="text-text font-semibold">Order Date</span>
                                    <span class="font-semibold">{{ String(orderData.created_at).split('T')[0] }}</span>
                                </div>
                                <div v-if="orderData.discount_id" class="card-body--item">
                                    <span class="text-text font-semibold">Discount code</span>
                                    <span class="font-semibold">{{ orderData.cart_data.discount.code }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="mb-[20px]">
                            <div class="flex justify-between items-center border rounded-[18px] px-[25px] py-[12px] mb-[14px]">
                                <span class="text-text font-semibold">Subtotal</span>
                                <span class="font-semibold">${{ orderData.total_price }}</span>
                            </div>
                            <div class="flex justify-between items-center border rounded-[18px] px-[25px] py-[12px] mb-[14px]" >
                                <span class="font-semibold">Discount</span>
                                <span class="font-semibold text-success-500">{{ orderData.total_discount ? `-$${orderData.total_discount}` : 0 }}</span>
                            </div>
                            <div class="bg-secondary-100 flex justify-between items-center rounded-[18px] px-[25px] py-[12px] mb-[14px]">
                                <span class="font-semibold">Order total</span>
                                <span class="font-semibold text-primary-500">${{ orderData.paid_amount }}</span>
                            </div>
                        </div>
                        <div class="divide-line lg:hidden"></div>
                        <div class="card">
                            <div class="card__header">
                                <h6>Payment information</h6>
                            </div>
                            <div class="card__body">
                                <div class="card-body--item">
                                    <span class="text-text font-semibold">Name</span>
                                    <span class="font-semibold">{{ orderData.buyer_information.first_name + ' ' + orderData.buyer_information.last_name }}</span>
                                </div>
                                <div class="card-body--item">
                                    <span class="text-text font-semibold">Country</span>
                                    <span class="font-semibold">{{ dataprops.general.countryList[orderData.buyer_information.country] }}</span>
                                </div>
                                <div class="card-body--item">
                                    <span class="text-text font-semibold">Email</span>
                                    <a
                                        href="#"
                                        class="font-semibold text-primary-500 underline"
                                    >
                                        {{ orderData.buyer_information.email }}
                                    </a>
                                </div>
                                <div class="card-body--item">
                                    <span class="text-text font-semibold">Payment method</span>
                                    <div>
                                        <span class="font-semibold mr-[10px]">Visa ** {{ orderData?.payment_information?.cardLastDigits?.slice(-2) }}</span>
                                        <span>
                                            <img
                                                src="@/assets/img/icons/visa.svg"
                                                alt=""
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="divide-line lg:hidden"></div>
                    </div>
                    <div class="col-lg-6">
                        <div
                            class="card py-[40px] items-center justify-center flex mb-[40px]"
                            :class="{'bg-primary-200': (orderData.review && orderData.status !== 'awaiting_payment')}"
                        >
                            <div class="max-w-[335px]" v-if="orderData.status === 'awaiting_payment'">
                                <h4 class="mb-[20px] text-center text-[24px] leading-[32px]">
                                    The order is awaiting for payment
                                </h4>
                                <a
                                    href="#"
                                    class="btn btn--primary btn-md-double btn-double rounded-[8px] w-full def-text--1"
                                    @click.prevent="payNow"
                                >
                                    <span class="info">${{ orderData.paid_amount }}</span>
                                    <span class="info">Pay Now</span>
                                </a>
                            </div>

                            <div class="max-w-[335px]" v-if="!orderData.review && orderData.status !== 'awaiting_payment'">
                                <div class="mb-[55px]">
                                    <h6 class="lg:text-center mb-[9px]">How was your order?</h6>
                                    <p class="lg:text-center text-text font-semibold">Help us improve and get up to 10% off for your next order</p>
                                </div>
                                <div class="rating-input rating-input--big lg:justify-center justify-end">
                                    <input type="radio" name="rating" id="rating-5" @click="addReview(5)" :value="5" v-model="rating">
                                    <label for="rating-5"></label>
                                    <input type="radio" name="rating" id="rating-4" @click="addReview(4)" :value="4" v-model="rating">
                                    <label for="rating-4"></label>
                                    <input type="radio" name="rating" id="rating-3" @click="addReview(3)" :value="3" v-model="rating">
                                    <label for="rating-3"></label>
                                    <input type="radio" name="rating" id="rating-2" @click="addReview(2)" :value="2" v-model="rating">
                                    <label for="rating-2"></label>
                                    <input type="radio" name="rating" id="rating-1" @click="addReview(1)" :value="1" v-model="rating">
                                    <label for="rating-1"></label>
                                </div>
                            </div>
                            <div class="max-w-[335px]" v-if="orderData.review && orderData.status !== 'awaiting_payment'" >
                                <div class="mb-[15px]">
                                    <h6 class="lg:text-center mb-[9px]">Your rating</h6>
                                    <p class="lg:text-center text-text font-semibold">Help us improve and get up to 10% off for your next order</p>
                                </div>
                                <div class="justify-end lg:justify-center mb-[25px] rev-rating-container">
                                    <div class="review-rating-img" v-for="(st, idx) in 5" :class="{'fill': idx + 1 <= orderData.review?.items[0]?.rating}" :key="idx"></div>
                                </div>
                                <a href="#" class="btn btn--primary pl-[27px] pr-[56px] py-[20px] icon-btn rounded-[8px] w-full" @click.prevent="openModal('see-order-review')">
                                    <span>See my review</span>
                                    <div class="icon-right">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.31818 11.6361L21 11.6361" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                        <path d="M13.3636 19.2727L21 11.6364L13.3636 4" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                        </svg>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="divide-line lg:hidden"></div>

                        <template v-if="orderData?.line_items?.length">
                            <h6 class="mb-[20px]">Products</h6>
                            <div v-for="(item, idx) in orderData?.line_items" class="social-card bg-primary-100 rounded-[8px] flex items-center p-[18px] pb-[9px] mb-[20px]" :key="idx">
                                <div class="social-card--item flex items-center pb-[9px]">
                                    <span class="ico ico-25">
                                        <img src="@/assets/img/icons/icon-tiktok-logo.svg" />
                                    </span>
                                    <div class="flex flex-col ml-[12px]">
                                        <p class="info def-text--1 font-semibold">
                                            {{ item.name }}
                                        </p>
                                        <!-- <a
                                            href="#"
                                            class="text-teal underline font-semibold"
                                            >3 posts</a
                                        > -->
                                    </div>
                                </div>
                                <div class="social-card--item flex items-center pb-[10px]">
                                    <span class="info text-primary-500 font-semibold">${{ item.price }}</span>
                                </div>
                            </div>
                            <a @click.prevent="downloadInvoice" href="#" 
                                class="btn btn--primary-outline p-[20px] w-full rounded-[8px]">
                                <span class="info">Download invoice</span>
                            </a>
                        </template>
                    </div>
                </div>
            </template>

            <div class="divide-line mb-[30px] lg:mt-[30px] lg:mb-[20px]"></div>
            <div class="row gy-20">
                <div class="col-lg-6">
                    <h6 class="mb-[20px]">Need asisstance?</h6>
                    <p class="text-text">
                        Our Client Service Center is available 7 days a week via
                        email. We respond to all queries in less than 24 hours.
                    </p>
                </div>
                <div class="col-lg-6">
                    <div class="flex items-center justify-center">
                        <a
                            href="#"
                            data-modal="#message-us"
                            class="btn btn--primary pl-[56px] pr-[20px] py-[20px] icon-btn rounded-[8px] w-full max-w-[335px]"
                            @click.prevent="messageUs"
                        >
                            <div class="icon-left">
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M17.6099 6.35381C14.9152 3.63745 10.6729 3.2231 7.50492 5.36683C4.33693 7.51056 3.13791 11.607 4.64889 15.1243C4.72524 15.2828 4.75028 15.4612 4.72055 15.6346L4.01995 19.0073C3.96512 19.27 4.0459 19.5426 4.23491 19.7328C4.38903 19.8861 4.59898 19.9696 4.81608 19.9641H4.97531L8.38275 19.2784C8.55593 19.2575 8.73155 19.2823 8.89227 19.3501C12.4044 20.8634 16.4947 19.6626 18.6352 16.4898C20.7757 13.3171 20.362 9.06849 17.6497 6.36976L17.6099 6.35381ZM18.2707 13.0194C17.9553 14.9482 16.7744 16.6257 15.0664 17.5712C13.3584 18.5167 11.3118 18.6257 9.51325 17.8671C9.19842 17.733 8.86021 17.6625 8.51809 17.6598C8.36864 17.6609 8.21953 17.6742 8.07226 17.6997L5.82717 18.1542L6.28096 15.9057C6.37134 15.4188 6.31308 14.9158 6.11378 14.4626C5.35629 12.6613 5.46519 10.6117 6.40925 8.90113C7.35332 7.19056 9.02834 6.00787 10.9542 5.69203C12.9785 5.35932 15.0388 6.0234 16.4893 7.47607C17.9398 8.92873 18.6029 10.9921 18.2707 13.0194ZM11.9813 12.7882C12.4209 12.7882 12.7774 12.4312 12.7774 11.9909C12.7774 11.5505 12.4209 11.1935 11.9813 11.1935C11.5416 11.1935 11.1851 11.5505 11.1851 11.9909C11.1851 12.4312 11.5416 12.7882 11.9813 12.7882ZM15.9619 11.9909C15.9619 12.4312 15.6055 12.7882 15.1658 12.7882C14.7261 12.7882 14.3696 12.4312 14.3696 11.9909C14.3696 11.5505 14.7261 11.1935 15.1658 11.1935C15.6055 11.1935 15.9619 11.5505 15.9619 11.9909ZM8.79673 12.7882C9.23643 12.7882 9.59286 12.4312 9.59286 11.9909C9.59286 11.5505 9.23643 11.1935 8.79673 11.1935C8.35704 11.1935 8.0006 11.5505 8.0006 11.9909C8.0006 12.4312 8.35704 12.7882 8.79673 12.7882Z"
                                        fill="currentColor"
                                    ></path>
                                </svg>
                            </div>
                            <span>Message us</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import {ordersApi} from "@/api/ordersApi";

    export default{
        data() {
            return {
                rating: 0,
                orderData: false,
                textColorClass: {
                    awaiting_payment: {
                        class: 'text-warning-300',
                        label: 'Awaiting payment'
                    },
                    paid: {
                        class: 'text-success-500',
                        label: 'Paid'
                    },
                    done: {
                        class: 'text-teal-500',
                        label: 'Completed'
                    },
                    decline: {
                        class: 'text-primary-500',
                        label: 'Canceled'
                    },
                    refunded: {
                        class: 'text-text',
                        label: 'Refunded'
                    },
                    pending: {
                        class: 'text-progress-500',
                        label: 'In progress'
                    }

                }
            };
        },
        props: ['dataprops'],
        computed:{
            countryList() {
                return Object.entries(this.dataprops.general.countryList).map(([code, name]) => ({ code, name }));
            },
            savedCards() {
                let cards = (this.dataprops.paymentMethods || []).filter(el => el.payment_type == 'cardinity');

                return cards.length ? cards : false;
            },
            paymentPayload() {
                return {
                    safechargeEnabled: this.dataprops.safechargeEnabled,
                    paypalEnabled: this.dataprops.paypalEnabled,
                    balancePaymentEnabled: this.dataprops.balancePaymentEnabled,
                    user: this.dataprops.user,
                    billingInfo: this.orderData?.cart_data.billingInformation,
                    currency: this.orderData?.cart_data.currency,
                    countryList: this.countryList,
                    savedCards: this.savedCards,
                    order: this.orderData,
                }
            },
            classObject() {
                return {
                    'font-semibold': true,
                    [this.textColorClass[this.orderData.status]?.class]: !!this.textColorClass[this.orderData.status]
                };
            }
        },
        mounted() {
            console.log(this.$route.params.id, this.dataprops.orders?.items?.find(el => el.hash === this.$route.params.id))
            this.orderData = this.dataprops.orders?.items?.find(el => el.hash === this.$route.params.id)

        },
        watch: {
            'dataprops.orders.items': {
                handler(newValue) {
                    this.orderData = this.dataprops.orders?.items?.find(el => el.hash === this.$route.params.id)
                }
            }
        },
        methods: {
            openModal(name){
                this.$bus.$emit('modal',{
                    name: name,
                    overlay: true,
                    payload: {},
                })
            },
            addReview(rating) {
                this.$bus.$emit('modal',{
                    name: 'write-order-review',
                    overlay: false,
                    payload: {
                        rating,
                        hash: this.orderData.hash
                    }
                })
            },
            messageUs() {

                console.log(this.orderData)

                this.$bus.$emit('modal',{
                    name: 'message-us',
                    overlay: false,
                    payload: {
                        email: this.orderData.buyer_information.email,
                        orderNumber: this.orderData.hash
                    }
                })
            },
            async downloadInvoice() {
                const response = await ordersApi.getOrderInvoice(this.orderData.hash);
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Invoice-${this.orderData.hash}.pdf`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            },
            payNow(withoutTransactionStatus) {
                // this.popup({ name: 'pay-now-2', payload: {
                //     ...this.paymentPayload,
                //     withoutTransactionStatus: withoutTransactionStatus===true,
                //     onSuccess: (function() {
                //         const order = this.dataprops.orders.find(el => el.id == this.order.id);
                        
                //         this.$set(order, 'status', 'paid');
                //         this.$forceUpdate()
                //     }).bind(this)
                // }});

                this.$bus.$emit('modal',{
                    name: 'pay-now-2',
                    // overlay: false,
                    payload: {
                        ...this.paymentPayload,
                        withoutTransactionStatus: withoutTransactionStatus===true,
                        onSuccess: (function() {
                            const order = this.dataprops.orders.find(el => el.id == this.order.id);
                            
                            this.$set(order, 'status', 'paid');
                            this.$forceUpdate()
                        }).bind(this)
                    }
                })
            },
        },
    }
</script>

<style scoped>
    h1, h2, h3, h4, h5, h6 {
        font-size: inherit;
        font-weight: bold;
        font-size: var(--h6Size);
        line-height: 120%;
    }
    .bg-primary-200 {
        --tw-bg-opacity: 1;
        background-color: rgb(255 239 242 / var(--tw-bg-opacity));
    }

    .rev-rating-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .review-rating-img {
        width: 35px;
        height: 35px;
        background-image: url(../assets/img/icons/icon-star-silver.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 76%;
    }

    .review-rating-img.fill {
        background-image: url(../assets/img/icons/icon-star-gold.svg);
    }

</style>