<template>
<div class="accordeon  bg-[#ffffff]" :class="{'show-accordeon': open}" >
    <a href="#" class="accordeon-btn" @click.prevent="openToggle">
        <div class="accordeon-heading mr-[15px]">
            <p class="font-semibold def-text--1">{{ title }}</p>
        </div>
        <div class="accordeon-arrow">
            <span class="ico ico-20">
                <img src="@/assets/img/icons/icon-chevron-down-cc.svg" alt="arrow">
            </span>
        </div>
    </a>
    
    <transition name="accordion"
        v-on:before-enter="beforeEnter" v-on:enter="enter"
        v-on:before-leave="beforeLeave" v-on:leave="leave"
    > 
        
        <div class="accordeon-body accordion_transition" v-show="open">
            <div class="accordeon-body accordion_transition pt-[20px]">
                <div class="max-w-[657px]" v-html="content"></div>

            </div>
        </div>
    </transition>

</div>
</template>

<script>
export default {
    name: "AccordeonItem",
    props: ["title", "content"],
    data(){
        return {
            open: false,
        }
    },
    methods:{
        openToggle(){
            this.resetAccordionTransitionHeight()
            this.open = !this.open;
        },



        beforeEnter: function(el) {
            // console.log('beforeEnter', el, el.scrollHeight)
            el.style.height = '0'+'px';
            el.style.height = '0'+'px';
        },
        enter: function(el) {
            // console.log('enter', el, el.scrollHeight)
            el.style.height = el.scrollHeight + 'px';
            el.style.height = el.scrollHeight + 'px';
        },
        beforeLeave: function(el) {
            // console.log('beforeLeave', el, el.scrollHeight)
            el.style.height = el.scrollHeight + 'px';
            el.style.height = el.scrollHeight + 'px';
        },
        leave: function(el) {
            // console.log('leave', el, el.scrollHeight)
            el.style.height = '0'+'px';
            el.style.height = '0'+'px';
        },
        resetAccordionTransitionHeight(){
            let elements = document.querySelectorAll('.accordion_transition');

            elements.forEach(element => {
                element.style.height = 'initial';
                setTimeout(() => {
                    // element.style.height = element.scrollHeight + 'px';
                }, 320);
            })
        }
    },








}
</script>
<style>
    .accordion_transition {
        transition: all 300ms ease-out;
        overflow: hidden;
    }
</style>