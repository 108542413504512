import restInstance from "@/lib/rest"

const rest = restInstance

export const ordersApi = {

    // Orders help func
    async getFeedbacks(payload) {
        const data = {
            email: payload.email, //required
            message: payload.message, //required
            order: payload.order || null, //nullable - хеш заказа
        }
        
        return await rest.request('/feedbacks', 'POST', data, 'feedbacks', 30000)
    },
    
    
    async getFaqs(payload) {

        const baseLink = '/faqs';

        let queryString = '';

        if (payload.slug) {
            queryString += `slug=${payload.slug}`;
        }

        if (payload.type) {
            queryString += `${queryString ? '&' : ''}type=${payload.type}`;
        }

        const link = `${baseLink}${queryString ? `?${queryString}` : ''}`;

        return await rest.request(link, 'GET', null, 'faqs')
    },
    
    async getReviews(payload) {

        const baseLink = '/reviews';

        let queryString = '';

        if (payload.slug) {
            queryString += `slug=${payload.slug}`;
        }

        if (payload.type) {
            queryString += `${queryString ? '&' : ''}type=${payload.type}`;
        }

        const link = `${baseLink}${queryString ? `?${queryString}` : ''}`;

        return await rest.request(link, 'GET', null, 'reviews')
    },
    
    async getReviewsMore(payload) {

        const baseLink = '/reviews';

        let queryString = '';

        if (payload.slug) {
            queryString += `slug=${payload.slug}`;
        }

        if (payload.type) {
            queryString += `${queryString ? '&' : ''}type=${payload.type}`;
        }
        
        if (payload.timesloaded) {
            queryString += `${queryString ? '&' : ''}timesloaded=${payload.timesloaded}`;
        }

        const link = `${baseLink}${queryString ? `?${queryString}` : ''}`;

        return await rest.request(link, 'GET', _, 'reviewsMore')
    },

    async createAnonymReview(payload) {
        const data = {
            platform_id: payload.platform_id,
            email: payload.email,
            first_name: payload.first_name,
            last_name: payload.last_name,
            rating: payload.rating,
            feedback: payload.feedback,
        }
        
        return await rest.request('/reviews/onsite', 'POST', data, 'anonymReview')
    },
    
    async createReview(payload) {
        const orderHash = payload.hash
        const data = {
            feedback: payload.feedback,
            rating: payload.rating,
            order_line_id: payload.order_line_id,
            hide_username: payload.hide_username,
        }
        
        return await rest.request(`/orders/${orderHash}/review`, 'POST', data, 'createReview')
    },



    // Orders
    async getAllOrdersData(params = '') {
        return await rest.request(`/orders${params}`, 'GET', null, 'ordersGetAll', 15000)
    },
    
    async getOrderData(hash) {
        return await rest.request(`/orders/${hash}`, 'GET', _, 'orderGetOne')
    },
    
    async getOrderInvoice(hash) {
        return await rest.request(`/orders/invoice/${hash}`, 'GET', null, 'orderGetInvoice', undefined, 'blob')
    },

    
    async createOrderData(payload) {

        const data = {
            // See buysmm for parameters format (/checkout)
        }

        return await rest.request('/orders', 'POST', payload, 'ordersCreate', 20000)
    },
    
    async resendTrialConfirmationData(payload) {
        const orderHash = payload.hash
        const data = {
            email: payload.email
        }

        return await rest.request(`/orders/trials/resend/${orderHash}`, 'POST', data, 'trialsResendConfirm')
    },

    async verifyTrial(payload) {
        const orderHash = payload.hash
        const data = {
            code: payload.code
        }

        return await rest.request(`orders/trials/confirm/${orderHash}`, 'POST', data, 'trialVerify')
    },


    async cardinityPay(payload) {
        const hash = payload.hash
        const data = {
            // fields in buysmm
        }
        return await rest.request(`/orders/${hash}/cardinity`, 'POST', data, 'cardinityPay')
    },
    
    async balancePay(hash) {
        return await rest.request(`/orders/${hash}/balance`, 'POST', _, 'balancePay')
    },
   
    async coinpaymentsPay(payload) {
        const hash = payload.hash
        const data = {
            // fields in buysmm
        }
        return await rest.request(`i/orders/${hash}/coinpayments`, 'POST', data, 'coinpaymentsPay')
    },

}