<template>
    <section class="section pt-[14px] lg:pt-0 flex-auto flex lg:block">
        <div class="container mx-0 container-dash flex flex-col flex-auto" v-if="step === 1">
            <h4 class="text-center lg:text-[40px] mb-[20px]">TikTok <span class="lg:text-primary-500">Video Downloader</span></h4>
            <p class="text-secondary-500 text-center mb-[40px]">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.
            </p>

            <SearchInput :small-variant="true" :placeholder="'TikTok Video URL'" :call-back="search" svgName="tikTok" />
            <p class="text-error">
                {{error}}
            </p>
        </div>
        <div class="pt-[24px] pb-[50px]" v-if="step === 2">
            <div class="container mx-0 container-dash">
                <div class="flex items-center justify-center relative mb-[50px] mt-[14px] md:hidden">
                    <a href="#" class="btn w-[40px] h-[40px] bg-primary-100 rounded-[11px] left-0 absolute" @click.prevent="step = 1">
                        <img src="@/assets/img/icons/left-arrow-long.svg" alt="">
                    </a>
                    <span class="def-text--1 font-semibold">Video Downloader</span>
                </div>
                <div class="relative row md:flex hidden mb-[23px] sm:mb-[58px]">
                    <div class="col-12 px-[70px]">
                        <a href="#" class="btn w-[40px] y-center h-[40px] bg-primary-100 rounded-[11px] left-0 absolute" @click.prevent="step = 1">
                            <img src="@/assets/img/icons/left-arrow-long.svg" alt="">
                        </a>
                        <div class="flex">
                            <h2 class="font-[700] text-[24px] leading-[32px]">
                                TikTok Video Downloader
                            </h2>
                        </div>
                    </div>
                </div>
                <div class="row mx-[-30px]">
                    <div class="col-xl-4 col-lg-12 px-[30px]">
                        <h6 class="heading heading-6">
                            Your video is ready for download
                        </h6>
                    </div>
                    <div class="pb-[50px] block-downlod">
                        <div class="w-full">
                            <div class="downloader-block">
                                <div class="downloader-head">
                                    <a href="#" class="flex items-center">
                                        <div class="user-wrappgradient user-wrappgradient-30" style="
                                        background: linear-gradient(46.57deg, #FBAC49 14.57%, #F67728 29.84%, #D81B46 46.27%, #CC136F 68.56%, #A20F96 85.02%);">
                                            <div class="user-icon rounded-full" style="border:1px solid transparent;">
                                                <img :src="user.picture">
                                            </div>
                                        </div>
                                        <div class="flex flex-col ml-[12px]">
                                            <div class="def-text--3 ff-fotnFamilyMontserrat 
                                            text-primary-500 font-[600]">
                                                @{{ user.name }}
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div class="download-body">
                                    <img class="download-poster" :src="video.thumb">
                                </div>
                                <div class="download-footer">
                                    <a :href="video.link" :download="videoName" class="w-full icon-btn px-[23px] btn btn--lg btn--primary rounded-[8px]">
                                        <span class="ico-24 icon-left mr-[20px] flex">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_0_16433)">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.4927 10.521L13.326 11.641V6C13.326 5.44772 12.8601 5 12.2853 5C11.7105 5 11.2445 5.44772 11.2445 6V11.641L10.0789 10.52C9.81599 10.2671 9.43264 10.1681 9.07321 10.2604C8.71379 10.3526 8.4329 10.6221 8.33636 10.9674C8.23982 11.3126 8.34229 11.6811 8.60517 11.934L11.5495 14.763C11.7442 14.9512 12.009 15.057 12.2853 15.057C12.5615 15.057 12.8263 14.9512 13.0211 14.763L15.9654 11.934C16.3595 11.5414 16.3534 10.9176 15.9517 10.5322C15.5499 10.1467 14.9008 10.1417 14.4927 10.521ZM19.5705 18C19.5705 17.4477 19.1046 17 18.5298 17H6.04075C5.46596 17 5 17.4477 5 18C5 18.5523 5.46596 19 6.04075 19H18.5298C19.1046 19 19.5705 18.5523 19.5705 18Z" fill="currentColor"></path>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_0_16433">
                                                        <rect width="24" height="24" fill="currentColor"></rect>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </span>
                                        <span class="info text-[14px] font-[800]">
                                            Download 
                                            <span class="standart_file">
                                                .MP4
                                            </span>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { toolsApi } from '@/api/toolsApi';
import SearchInput from '@/components/Base/SearchInput.vue';

export default {
    name: "TikTokVideoDownloader",
    data(){
        return{
            step: 1,
            user: null,
            video: null,
            error: null
        }
    },
    components: {
        SearchInput
    },
    computed:{
        videoName(){
            if(!this?.video?.link.includes('.mp4')){
                return this?.video?.link.split("/").pop()+".mp4"
            } else {
                return this?.video?.link.split("/").pop()
            }
        }
    },
    methods: {
        toggleAccordeon(item){
            item.isOpen = !item.isOpen;
        },
        nextStep(){
            this.step++;
            
        },
        downloadVideo() {
            const link = document.createElement('a');
            link.href = this.video.link;
            link.setAttribute('download', `video.mp4`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        async search(val) {
            const response = await toolsApi.tikTokVideoDownload(val);
            if(response.hasError) {
                this.error = response.error.message;
                return;
            }
            this.user = response.user;
            this.video = response.videos[0]
            this.nextStep()
        }
    }
}
</script>

<style scoped>
    .download-body {
        min-height: 185px;
        height: auto;
        max-height: 100vh;
        width: 100%;
    }

    .text-error {
        color: red;
        text-align: center;
    }
</style>