import restInstance from "@/lib/rest"

const rest = restInstance

export const platformsApi = {

    async getPlatforms() {
        return await rest.request('/platforms', 'GET', null, 'platforms')
    },
    
    async getTikTokUser(username) {
        return await rest.request('/social/tt/profile', 'POST', { username }, 'tikTokUser', 30000)
    },
    
    async getTikTokMedia(username) {
        return await rest.request('/social/tt/media', 'POST', { username }, 'tikTokMedia', 30000)
    },

    async getFreePlatforms() {
        return await rest.request('free-platforms', 'get', null, 'free-platforms', 30000)
    },


}