<template>
    <div>
        <div class="pt-[24px] pb-[50px] text-center text-white-100 bg-black-100">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="max-w-[445px] flex flex-col items-center sm:items-start">
                            <h2 class="font-[600] text-[24px] sm:text-[36px] leading-[32px] sm:leading-[48px] mb-[17px] sm:mb-[27px] w-[250px] sm:w-auto">TinkWs affiliate program</h2>
                            <p class="mb-[17px] sm:mb-[27px] font-[600] text-[14px] sm:text-[16px] leading-[18px] text-secondary-500 max-w-[586px] mx-auto ff-fotnFamilyMontserrat">Start earning money with our referral program. Sign up and get your referral link today! We pay up to 35% commission!</p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="max-w-[400px] mx-auto">
                            <div class="w-full py-[30px] rounded-[5px] flex justify-center mb-[20px] bg-[#2C2C2C]">
                                <h4 class="text-center text-[24px]">Up to 35% commission 🔥</h4>
                            </div>
                            <router-link to="/affiliate" class="btn btn--primary pl-[27px] pr-[56px] py-[13px] icon-btn rounded-[8px] w-full">
                                <span>Go to affiliate dashboard</span>
                                <div class="icon-right">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.31818 11.6361L21 11.6361" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                        <path d="M13.3636 19.2727L21 11.6364L13.3636 4" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section class="py-[60px] pb-[80px] bg-white section">
            <div class="container-px--lg">
                <div class="heading-block items-center text-center">
                    <h2 class="heading heading-2 font-bold">
                        How does it work?
                    </h2>
                </div>
                <div class="sm:mt-[80px] mt-[24px]">
                    <ul class="custom-steps custom-steps--even">
                        <li class="custom-step pt-[15px]">
                            <div class="custom-step--path">
                                <div class="step-card rounded-[26px] py-[23px] bg-primary-200">
                                    <div class="step--count bg-primary-500 text-white-100 top-right">
                                        <span class="text">Step 1</span>
                                    </div>
                                    <div class="step-card--header px-[28px] pt-[23px] mt-[-23px]">
                                        <h5 class="def-text--1 font-semibold">
                                            Sign up for TinkWs account
                                        </h5>
                                    </div>
                                    <div class="step-card--body px-[28px] pt-[13px]">
                                        <p class="def-text--3 font-semibold text-text2">
                                            Simply sign up with your email and password to access our affiliate dashboard. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="custom-step">
                            <div class="custom-step--path">
                                <div class="step-card rounded-[26px] py-[23px] bg-primary-200">
                                    <div class="step--count bg-primary-500 text-white-100 top-right">
                                        <span class="text">Step 2</span>
                                    </div>
                                    <div class="step-card--header px-[28px] pt-[23px] mt-[-23px]">
                                        <h5 class="def-text--1 font-semibold">
                                            Submit your affiliate <br>
                                            application
                                        </h5>
                                    </div>
                                    <div class="step-card--body px-[28px] pt-[13px]">
                                        <p class="def-text--3 font-semibold text-text2">
                                            Fill in information to get approved for our affiliate account. Both individuals and businesess can apply. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="custom-step">
                            <div class="custom-step--path">
                                <div class="step-card rounded-[26px] py-[23px] bg-primary-200">
                                    <div class="step--count bg-primary-500 text-white-100 top-right">
                                        <span class="text">Step 3</span>
                                    </div>
                                    <div class="step-card--header px-[28px] pt-[23px] mt-[-23px]">
                                        <h5 class="def-text--1 font-semibold">
                                            Share your referral link <br> with everyone
                                        </h5>
                                    </div>
                                    <div class="step-card--body px-[28px] pt-[13px]">
                                        <p class="def-text--3 font-semibold text-text2">
                                            Copy your referral URL and send it to your friends or share it on your social media, blog or elsewhere.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="custom-step">
                            <div class="custom-step--path">
                                <div class="step-card rounded-[26px] py-[23px] bg-primary-200">
                                    <div class="step--count bg-primary-500 text-white-100 top-right">
                                        <span class="text">Step 4</span>
                                    </div>
                                    <div class="step-card--header px-[28px] pt-[23px] mt-[-23px]">
                                        <h5 class="def-text--1 font-semibold">
                                            Get paid for each sale <br>
                                            you deliver
                                        </h5>
                                    </div>
                                    <div class="step-card--body px-[28px] pt-[13px]">
                                        <p class="def-text--3 font-semibold text-text2">
                                            Start earning money from every order that is placed through your affiliate link. Withdraw your earnings via bank wire, crypto or Paypal.   
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>

        <section class="py-[60px] pb-[80px]">
            <div class="container">
                <div class="row gx-60 max-w-[820px] mx-auto">
                    <div class="col-lg-6">
                        <div>
                            <h4 class="mb-[15px]">Affiliate commissions</h4>
                            <p class="text-text font-semibold text-[16px] mb-[35px] md:inline hidden">We offer 4 different commission tiers. When you sign up you will be earning 20% commission from every sale made with your referral link. Your commission rate will be upgraded when you reach our monthly revenue thresholds.</p>
                            <div class="dbl-btns dbl-btns-10 md:flex hidden">
                                <a href="#" class="btn btn--primary px-[23px] py-[13px] rounded-[8px] flex-1">
                                    <span class="info">Sign up</span>
                                </a>
                                <a href="#" class="btn btn--primary-outline px-[23px] py-[13px] rounded-[8px] flex-1">
                                    <span class="info">Log in</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="card mb-[10px]">
                            <div class="card__body">
                                <div class="card-body--item">
                                    <span class="font-semibold">Rolling 30-day revenue</span>
                                    <span class="font-semibold text-primary-500">Commission</span>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card__body">
                                <div class="card-body--item">
                                    <span class="font-semibold">Up to $1000</span>
                                    <span class="font-semibold">20%</span>
                                </div>
                                <div class="card-body--item">
                                    <span class="font-semibold">Up to $5000</span>
                                    <span class="font-semibold">25%</span>
                                </div>
                                <div class="card-body--item">
                                    <span class="font-semibold">Up to $10000</span>
                                    <span class="font-semibold">30%</span>
                                </div>
                                <div class="card-body--item">
                                    <span class="font-semibold">Above $10000</span>
                                    <span class="font-semibold">35%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:hidden">
                        <div class="dbl-btns dbl-btns-10 mt-[40px]">
                            <a href="#" class="btn btn--primary px-[23px] py-[13px] rounded-[8px] flex-1">
                                <span class="info">Sign up</span>
                            </a>
                            <a href="#" class="btn btn--primary-outline px-[23px] py-[13px] rounded-[8px] flex-1">
                                <span class="info">Log in</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="py-[60px] bg-grey--300 bg-primary-100-576 section">
            <div class="container-px--lg">
                <div class="heading-block items-center text-center">
                    <h2 class="heading heading-2 font-bold">
                        Frequently Asked Questions
                    </h2>
                </div>
                <div class="flex flex-col sm:mt-[53px] mt-[24px]">
                    <FAQ :params="{
                        type: 'affiliate',
                    }"/>

                    <div class="mt-[24px]">
                        <div class="heading-block items-center text-center">
                            <h2 class="heading heading-6 font-bold">
                                Didn’t find your answer?
                            </h2>
                            <div class="mt-[20px]">
                                <router-link to="/contact-us" class="btn btn--lg btn--primary rounded-[8px]">
                                    <span class="ico-24 mr-[20px] sm:hidden flex">
                                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.1056 5H6.90555C5.58007 5 4.50555 6.17525 4.50555 7.625V16.375C4.50555 17.8247 5.58007 19 6.90555 19H18.1056C19.431 19 20.5056 17.8247 20.5056 16.375V7.625C20.5056 6.17525 19.431 5 18.1056 5ZM17.5696 6.75L12.5056 10.9062L7.44155 6.75H17.5696ZM18.1056 17.25H6.90555C6.46373 17.25 6.10555 16.8582 6.10555 16.375V7.84375L12.0256 12.7C12.164 12.8136 12.3325 12.875 12.5056 12.875C12.6787 12.875 12.8471 12.8136 12.9856 12.7L18.9056 7.84375V16.375C18.9056 16.8582 18.5474 17.25 18.1056 17.25Z" fill="currentColor"></path>
                                        </svg>
                                    </span>
                                    <span class="info text-[14px] font-bold">
                                        Contact Us
                                    </span>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import FAQ from '@/components/Dashboard/FAQ.vue';

export default {
    components: {
        FAQ,
    }
}
</script>