<template>
    <div id="enter-your-comments" class="modal modal-25 modal--show">
        <div class="modal__dialog modal__dialog--522">
            <div class="modal__content">
                <div class="modal__header px-[28px] sm:px-[46px] pb-[18px] sm:pt-[42px] pt-[30]">
                    <div class="flex items-center">
                        <span class="font-bold text-[18px] leading-[24px]">Enter your comments</span>
                    </div>
                    <a href="#" class="btn btn-close sm-d-none" data-close-modal="" @click.prevent="close">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.4771 2.94469C17.9223 2.51467 18.1009 1.87788 17.9441 1.27907C17.7874 0.68025 17.3197 0.212602 16.7209 0.0558617C16.1221 -0.100878 15.4853 0.0776826 15.0553 0.522914L8.99914 6.57736L2.94469 0.522914C2.51467 0.0776826 1.87788 -0.100878 1.27907 0.0558617C0.68025 0.212602 0.212602 0.68025 0.0558617 1.27907C-0.100878 1.87788 0.0776826 2.51467 0.522914 2.94469L6.57736 8.99914L0.522914 15.0553C0.0776826 15.4853 -0.100878 16.1221 0.0558617 16.7209C0.212602 17.3197 0.68025 17.7874 1.27907 17.9441C1.87788 18.1009 2.51467 17.9223 2.94469 17.4771L8.99914 11.4226L15.0553 17.4771C15.4853 17.9223 16.1221 18.1009 16.7209 17.9441C17.3197 17.7874 17.7874 17.3197 17.9441 16.7209C18.1009 16.1221 17.9223 15.4853 17.4771 15.0553L11.4226 8.99914L17.4771 2.94469Z" fill="#3A4C66"></path>
                        </svg>
                    </a>
                </div>
                <div class="px-[28px] sm:px-[46px] pb-[26px]">
                    <div class="flex mb-[10px]">
                        <div class="mr-[17px] min-w-[70px] sm:min-w-[79px]">
                            <img :src="postData.image" />
                        </div>
                        <p class="text-[14px] leading-[20px] text-secondary-500 font-[600]">Comments with <span class="text-primary-500">@{{ postData.fullForCart.author && postData.fullForCart.author.unique_id || '-' }}</span> are not allowed and will not be posted! Do not write rude or offensive comments!</p>
                    </div>
                    <div class="py-[24px] px-[43px] rounded-[18px] bg-primary-100">
                        <span class="text-[14px] leading-[20px] text-black-100">{{ countFillComments }} comments entered / {{ commentsPerPost }} remaining</span>
                    </div>
                </div>
                <div class="modal__body pt-[25px] pb-[33px] border-bottom border-top px-[28px] sm:px-[46px]">
                    <div class="text-right mb-[20px]">
                        <a href="#" class="font-bold text-[14px] leading-[19px] underline">Import list</a>
                    </div>

                    <ul class="comments-dropdown--list mb-[20px] counter-number">
<!--                        <li class="comments-dropdown&#45;&#45;item counter-increment flex">-->
<!--                            <textarea class="input-custom__textarea pl-[45px] w-full mr-[16px]" style="height:68px" placeholder="">Wow love this!</textarea>-->
<!--                            <button class="btn">-->
<!--                                <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                                    <path d="M6.66931 5.25531L10.2043 1.71931C10.4643 1.46824 10.5685 1.09644 10.477 0.746806C10.3855 0.397176 10.1124 0.124131 9.76282 0.0326158C9.41319 -0.0588996 9.04139 0.0453564 8.79031 0.305313L5.25431 3.84031L1.71931 0.305313C1.46824 0.0453564 1.09644 -0.0588996 0.746806 0.0326158C0.397176 0.124131 0.124131 0.397176 0.0326158 0.746806C-0.0588996 1.09644 0.0453564 1.46824 0.305313 1.71931L3.84031 5.25431L0.305313 8.79031C0.0453564 9.04139 -0.0588996 9.41319 0.0326158 9.76282C0.124131 10.1124 0.397176 10.3855 0.746806 10.477C1.09644 10.5685 1.46824 10.4643 1.71931 10.2043L5.25431 6.66931L8.79031 10.2043C9.04139 10.4643 9.41319 10.5685 9.76282 10.477C10.1124 10.3855 10.3855 10.1124 10.477 9.76282C10.5685 9.41319 10.4643 9.04139 10.2043 8.79031L6.66931 5.25431V5.25531Z" fill="#EF3A4C"></path>-->
<!--                                </svg>-->
<!--                            </button>-->
<!--                            <div>-->
<!--                            </div></li>-->
<!--                        <li class="comments-dropdown&#45;&#45;item counter-increment flex">-->
<!--                            <textarea class="input-custom__textarea pl-[45px] w-full mr-[16px]" style="height:68px" placeholder="">This is so cool!</textarea>-->
<!--                            <button class="btn">-->
<!--                                <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                                    <path d="M6.66931 5.25531L10.2043 1.71931C10.4643 1.46824 10.5685 1.09644 10.477 0.746806C10.3855 0.397176 10.1124 0.124131 9.76282 0.0326158C9.41319 -0.0588996 9.04139 0.0453564 8.79031 0.305313L5.25431 3.84031L1.71931 0.305313C1.46824 0.0453564 1.09644 -0.0588996 0.746806 0.0326158C0.397176 0.124131 0.124131 0.397176 0.0326158 0.746806C-0.0588996 1.09644 0.0453564 1.46824 0.305313 1.71931L3.84031 5.25431L0.305313 8.79031C0.0453564 9.04139 -0.0588996 9.41319 0.0326158 9.76282C0.124131 10.1124 0.397176 10.3855 0.746806 10.477C1.09644 10.5685 1.46824 10.4643 1.71931 10.2043L5.25431 6.66931L8.79031 10.2043C9.04139 10.4643 9.41319 10.5685 9.76282 10.477C10.1124 10.3855 10.3855 10.1124 10.477 9.76282C10.5685 9.41319 10.4643 9.04139 10.2043 8.79031L6.66931 5.25431V5.25531Z" fill="#EF3A4C"></path>-->
<!--                                </svg>-->
<!--                            </button>-->
<!--                        </li>-->

                        <li v-for="item in values" class="comments-dropdown--item counter-increment flex" :key="item.id">
                            <textarea @input="(event) => setComment(event.target.value, item)" :value="item.value" :class="{'textarea-err': setFieldsErrors[item.id]}" class="input-custom__textarea pl-[45px] w-full mr-[16px]" style="height:68px"></textarea>
                            <button @click.prevent="delComment(item.id)" class="btn">
                                <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.66931 5.25531L10.2043 1.71931C10.4643 1.46824 10.5685 1.09644 10.477 0.746806C10.3855 0.397176 10.1124 0.124131 9.76282 0.0326158C9.41319 -0.0588996 9.04139 0.0453564 8.79031 0.305313L5.25431 3.84031L1.71931 0.305313C1.46824 0.0453564 1.09644 -0.0588996 0.746806 0.0326158C0.397176 0.124131 0.124131 0.397176 0.0326158 0.746806C-0.0588996 1.09644 0.0453564 1.46824 0.305313 1.71931L3.84031 5.25431L0.305313 8.79031C0.0453564 9.04139 -0.0588996 9.41319 0.0326158 9.76282C0.124131 10.1124 0.397176 10.3855 0.746806 10.477C1.09644 10.5685 1.46824 10.4643 1.71931 10.2043L5.25431 6.66931L8.79031 10.2043C9.04139 10.4643 9.41319 10.5685 9.76282 10.477C10.1124 10.3855 10.3855 10.1124 10.477 9.76282C10.5685 9.41319 10.4643 9.04139 10.2043 8.79031L6.66931 5.25431V5.25531Z" fill="#EF3A4C"></path>
                                </svg>
                            </button>
                        </li>
                    </ul>
                    <div class="flex justify-center mb-[20px]">
                        <a @click.prevent="addField" href="" class="btn btn--black px-[23px] py-[19px] max-w-[283px] w-full rounded-[8px] font-[700] text-[14px] leading-[19px]">
                            Add comment field
                        </a>
                    </div>
                    <div v-if="showErrorNot" class="flex items-center justify-between bg-primary-16 py-[11px] px-[19px] rounded-[8px] mx-auto max-w-[333px] w-full">
                        <span class="text-[#FE2C55] font-[600] ">You must enter at least 5 comments</span>
                        <div>
                            <img class="min-w-[25px]" src="@/assets/img/icons/icon-shape-red.svg">
                        </div>
                    </div>
                </div>
                <div class="modal__footer pt-[24px] pb-[54px] px-[28px] sm:px-[46px]">
                    <a @click.prevent="close" href="#" class="btn btn--primary-outline px-[23px] py-[19px] rounded-[8px] w-full mr-[10px]">
                        <span class="info">Cancel</span>
                    </a>
                    <a @click.prevent="save" href="#" class="btn btn--primary px-[23px] py-[19px] rounded-[8px] w-full">
                        <span class="info">Save</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['postData', 'commentsPerPost', 'commentsData'],
    data() {
        return {
            values: [],
            setFieldsErrors: {},
            showErrorNot: false,
        }
    },
    watch: {
        commentsData() {
            console.log(this.commentsData);
            this.values = Object.keys(this.commentsData).length > 1 ? Object.entries(this.commentsData).map((key, idx) => ({
                id: key[0],
                value: key[1]
            })) : Array(5).fill('').map((_, idx) => ({id: idx, value: ''}));
        },

    },
    computed: {
        countFillComments() {
            return this.values.filter(el => el.value).length
        },
        showComments() {
            return this.values.length
        }
    },
    methods: {
        addField() {
            const val = this.values
            if (val.length < this.commentsPerPost) {
               this.values = [...this.values, {id: val.length, value: ''}]
            }
            this.showErrorNot = false
        },
        delComment(id) {
            this.setFieldsErrors = {}
            this.showErrorNot = false
            this.values = this.values.filter(el => el.id !== id)
        },
        setComment(val, item) {
            const errors = {...this.setFieldsErrors}
            delete errors[item.id]
            this.setFieldsErrors = errors

            item.value = val
        },
        save() {

            let check = [...this.values]

            check.forEach((el, idx) => {
                if (!el.value) {
                    check[idx] = false
                    this.setFieldsErrors = {...this.setFieldsErrors, [el.id]: true}
                }
            })
            check = check.filter(el => el)


            if (check.length < 5) {
                this.showErrorNot = true
                return
            }

            this.$emit('fillComment', {key: this.postData.id, values: Object.fromEntries(check.map((el, idx) => [idx, el.value]))})
            this.values = []
        },
        close(){
            this.show = false;
            setTimeout(() => {
                this.$emit('close');
            }, 150);
        },
        closeModal() {
            this.$bus.$emit("modal", {
                name: "",
                payload: {},
            });
        },
        
    },
    mounted() {
        this.values = Object.keys(this.commentsData).length > 1 ? Object.entries(this.commentsData).map((key, idx) => ({
            id: key[0],
            value: key[1]
        })) : Array(5).fill('').map((_, idx) => ({id: idx, value: ''}));

        console.log('postData:', this.postData);
    }
}
</script>

<style>
.textarea-err {
    border: 1px solid red;
}
</style>